import { useEffect, useState } from "react";
import OrderTable from "./OrderTable";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import ViewOrder from "./ViewOrder";
import TrackShipment from "./TrackShipment";
import axiosInstance from "../../services/AxiosInstance";

export default function OrderManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [message, setMessage] = useState(""); // Success message
  const [error, setError] = useState(""); // Error state
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch vendors on component mount
    const fetchVendors = async () => {
      setLoading(true); // Start loading
      setMessage(""); // Clear previous messages
      setError(""); // Clear previous errors

      try {
        const response = await axiosInstance.get("/backend/api/vendor-purchase");
        // console.log(response.data);

        setOrders(response.data);
        setMessage("Orders fetched successfully!"); // Success message
      } catch (err) {
        setError("Failed to fetch orders. Please try again."); // Error message
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchVendors();
  }, []);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterStatus, setFilterStatus] = useState("All");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredOrders = sortedOrders
    // First filter based on order status
    .filter((order) =>
      filterStatus === "All" ? true : order.orderStatus === filterStatus
    )

    .filter((order) =>
      searchQuery === ""
        ? true
        : order.vendor?.name.toLowerCase().includes(searchQuery.toLowerCase())

    );


  const handleCreateOrder = (newOrder) => {
    setOrders((prevOrders) => [...prevOrders, newOrder]);
    setShowCreateModal(false); // Close the modal after adding the order
    setCurrentOrder(null); // Reset currentOrder to null
  };


  const handleEditOrder = (editedOrder) => {
    // Update the existing order in the orders array
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === editedOrder.id ? { ...editedOrder } : order
      )
    );
  };

  const handleSaveOrder = (orderData) => {
    setOrders((prevOrders) => {
      // Check if orderData has an ID and if it exists in the current orders
      const orderExists = prevOrders.some((order) => order.id === orderData.id);

      if (orderExists) {
        // Update the existing order
        return prevOrders.map((order) =>
          order.id === orderData.id ? { ...order, ...orderData } : order
        );
      } {
        // Generate a new ID if creating a new order
        const newOrderWithId = { ...orderData, orderData };

        // Add the new order
        return [...prevOrders, newOrderWithId];
      }
    });

    // Close the modal and reset the current order state after saving
    setShowCreateModal(false);
    setCurrentOrder(null);
  };



  const updateTrackment = (editedOrder) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === editedOrder.id ? { ...editedOrder } : order
      )
    );
  }

  return (
    <div className="container mr-0 ml-0 p-2 space-y-8">
      <div className="container mx-auto px-4 py-2">
        {/* Header Section with White Background and Search Bar */}
        <header className="mb-8 bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow-md flex items-center justify-between">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Order Management</h1>

          {/* Search Bar */}
          <div className="w-full max-w-xs">
            <input
              onChange={(e) => setSearchQuery(e.target.value)}
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </header>

        {/* Loading, Success, or Error Message */}
        {loading && <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
        <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
        <p className='dark:text-secondaryDark' >Loading orders</p>
      </div>}
        {/* {message && !loading && <p style={{ color: 'green' }}>{message}</p>} */}
        {error && !loading && <p style={{ color: 'red' }}>{error}</p>}

        <div className="mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
          <button
            onClick={() => {
              setCurrentOrder(null);
              setShowCreateModal(true);
            }}
            className="bg-colorBlue hover:bg-blue-500 text-white font-bold py-2 px-4 rounded dark:bg-primaryDark  dark:hover:bg-secondaryDark"
          >
            Create Purchase Order
          </button>

          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="w-full sm:w-auto border border-gray-300 rounded px-2 py-1 dark:bg-primaryDark dark:text-textDark"
          >
            <option value="All">All Orders</option>
            <option value="Pending">Pending</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
          </select>
        </div>

        <div className="bg-gray-50  rounded-lg shadow-lg">
          <OrderTable
            orders={filteredOrders}
            handleSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            setCurrentOrder={setCurrentOrder}
            setShowViewModal={setShowViewModal}
            setShowTrackingModal={setShowTrackingModal}
            setShowCreateModal={setShowCreateModal}
          />
        </div>

        {showCreateModal && (
          <CreatePurchaseOrder
            order={currentOrder}
            onSave={handleSaveOrder}
            onCancel={() => setShowCreateModal(false)}
          />
        )}

        {showViewModal && (
          <ViewOrder order={currentOrder} onClose={() => setShowViewModal(false)} />
        )}

        {showTrackingModal && (
          <TrackShipment order={currentOrder}
            onSave={updateTrackment}
            onClose={() => setShowTrackingModal(false)} />
        )}
      </div>
    </div>
  );
}
