import React from 'react';

export default function DeleteConfirmationModal({ isOpen, onDelete, onCancel }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this conversation?</h3>
        <div className="flex justify-end space-x-4">
          <button 
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={onDelete}
          >
            Delete
          </button>
          <button 
            className="px-4 py-2 bg-gray-300 text-black rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
