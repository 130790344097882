import { useState, useEffect, useRef } from "react";
import { FaEdit, FaTrash, FaFile, FaFileWord, FaFilePdf, FaFileExcel, FaFilePowerpoint, FaFileArchive, FaFileImage, FaFileAlt } from "react-icons/fa";

export default function MessageList({ messages, employees, onEditMessage, onDeleteMessage }) {
  const [editMode, setEditMode] = useState(null);
  const [editedText, setEditedText] = useState("");
  const bottomRef = useRef(null);

  const handleEdit = (index, text) => {
    setEditMode(index);
    setEditedText(text);
  };

  const handleSaveEdit = (index) => {
    onEditMessage(index, editedText);
    setEditMode(null);
    setEditedText("");
  };

  const handleDelete = (index) => {
    onDeleteMessage(index);
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const getFileIcon = (fileType) => {
    if (fileType.includes('pdf')) return <FaFilePdf className="w-5 h-5 sm:w-6 sm:h-6 text-red-500" />;
    if (fileType.includes('word') || fileType.includes('doc')) return <FaFileWord className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />;
    if (fileType.includes('sheet') || fileType.includes('excel') || fileType.includes('csv')) return <FaFileExcel className="w-5 h-5 sm:w-6 sm:h-6 text-green-500" />;
    if (fileType.includes('presentation') || fileType.includes('powerpoint')) return <FaFilePowerpoint className="w-5 h-5 sm:w-6 sm:h-6 text-orange-500" />;
    if (fileType.includes('zip') || fileType.includes('rar') || fileType.includes('7z')) return <FaFileArchive className="w-5 h-5 sm:w-6 sm:h-6 text-yellow-500" />;
    if (fileType.includes('image')) return <FaFileImage className="w-5 h-5 sm:w-6 sm:h-6 text-purple-500" />;
    if (fileType.includes('text')) return <FaFileAlt className="w-5 h-5 sm:w-6 sm:h-6 text-gray-500" />;
    return <FaFile className="w-5 h-5 sm:w-6 sm:h-6 text-gray-500" />;
  };

  const renderMessageContent = (message) => {
    switch (message.type) {
      case 'video':
        return (
          <div className="mt-2">
            <video className="w-full max-w-xs sm:w-48 sm:h-28 rounded" controls>
              <source src={message.content} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      case 'audio':
        return (
          <div className="mt-2 w-full max-w-xs">
            <audio className="w-full" controls>
              <source src={message.content} type="audio/webm" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        );
      case 'file':
        if (message.fileType.startsWith('image/')) {
          return (
            <div className="mt-2 space-y-2">
              <img 
                src={message.content} 
                alt={message.fileName}
                className="max-w-full sm:max-w-sm rounded-lg shadow-md"
              />
              <div className="flex items-center space-x-2 sm:space-x-3">
                {getFileIcon(message.fileType)}
                <a
                  href={message.content}
                  download={message.fileName}
                  className="text-blue-500 hover:text-blue-700 underline flex items-center space-x-1 sm:space-x-2 text-sm sm:text-base truncate"
                >
                  <span className="truncate max-w-[200px]">{message.fileName}</span>
                </a>
              </div>
            </div>
          );
        }
        return (
          <div className="mt-2 flex items-center space-x-2 sm:space-x-3 bg-gray-50 p-2 sm:p-3 rounded-lg hover:bg-gray-100 transition-colors">
            {getFileIcon(message.fileType)}
            <a
              href={message.content}
              download={message.fileName}
              className="text-blue-500 hover:text-blue-700 underline flex items-center space-x-1 sm:space-x-2 text-sm sm:text-base truncate"
            >
              <span className="truncate max-w-[200px]">{message.fileName}</span>
            </a>
          </div>
        );
      default:
        return (
          <p
            className="text-gray-500 text-xs sm:text-sm mt-1"
            dangerouslySetInnerHTML={{ __html: message.content }}
          />
        );
    }
  };

  return (
    <div className="flex-grow overflow-y-auto p-2 sm:p-4">
      <div className="space-y-3 sm:space-y-4">
        {messages.map((message, index) => (
          <div key={index} className="flex items-start space-x-2 sm:space-x-4 group">
            <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-300 flex items-center justify-center text-white font-semibold flex-shrink-0 text-sm sm:text-base">
              {message.sender.charAt(0)}
            </div>
            <div className="flex flex-col items-start w-full">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-1 sm:space-x-2">
                  <p className="text-gray-700 font-semibold text-sm sm:text-base">{message.sender}</p>
                  <p className="text-gray-400 text-xs sm:text-xs">
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
                <div className="flex space-x-1 sm:space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {message.type === 'text' && (
                    <button
                      onClick={() => handleEdit(index, message.content)}
                      className="text-blue-500 text-xs hover:underline flex items-center space-x-1"
                    >
                      <FaEdit className="w-3 h-3 sm:w-4 sm:h-4" />
                    </button>
                  )}
                  <button
                    onClick={() => handleDelete(index)}
                    className="text-red-500 text-xs hover:underline flex items-center space-x-1"
                  >
                    <FaTrash className="w-3 h-3 sm:w-4 sm:h-4" />
                  </button>
                </div>
              </div>
              {editMode === index ? (
                <div className="mt-2 w-full">
                  <textarea
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    className="w-full p-1 sm:p-2 text-sm sm:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div className="flex space-x-2 mt-2">
                    <button
                      onClick={() => handleSaveEdit(index)}
                      className="text-xs sm:text-sm bg-blue-500 text-white py-1 px-2 sm:py-1 sm:px-3 rounded hover:bg-blue-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditMode(null)}
                      className="text-xs sm:text-sm bg-gray-300 py-1 px-2 sm:py-1 sm:px-3 rounded hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                renderMessageContent(message)
              )}
            </div>
          </div>
        ))}
        <div ref={bottomRef} />
      </div>
    </div>
  );
}
