import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const RestockTable = () => {
    const [restockNotifications, setRestockNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRestockData = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await axiosInstance.get('/backend/api/purchase-orders');

                // Parse response to extract required fields
                const parsedData = response.data
                    .filter(order => {
                        const deliveryDate = new Date(order.expectedDeliveryDate);
                        const today = new Date();
                        const daysDiff = (today - deliveryDate) / (1000 * 60 * 60 * 24);

                        // Exclude orders where status is "Delivered" or "Canceled" and delivery date is more than 5 days old
                        return !(
                            (order.status === 'Delivered' || order.status === 'Canceled') &&
                            daysDiff > 5
                        );
                    })
                    .map(order => ({
                        id: order.id,
                        supplier: order.supplier.name,
                        status: order.status,
                        expectedDate: order.expectedDeliveryDate,
                        products: order.purchaseOrderProducts.map(product => ({
                            name: product.product.supplierProduct,
                            quantity: product.quantity,
                        })),
                    }));

                setRestockNotifications(parsedData);
            } catch (err) {
                setError(err.message || 'Failed to fetch restock data');
            } finally {
                setLoading(false);
            }
        };

        fetchRestockData();
    }, []);

    return (
        <div className="overflow-x-auto bg-white shadow-md rounded-lg mt-8">
            {loading ? (
                <div className="flex justify-center items-center gap-3 h-[calc(100vh-3.5rem)]">
                    <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                    <p>Loading</p>
                </div>
            ) : error ? (
                <p className="text-center text-red-500">Error: {error}</p>
            ) : (
                <table className="min-w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Supplier
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Product
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Quantity
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Expected Delivery Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {restockNotifications.map((order) =>
                            order.products.map((product, index) => (
                                <tr key={`${order.id}-${index}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {order.supplier}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {product.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.quantity}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {new Date(order.expectedDate).toLocaleDateString()}
                                    </td>
                                    <td
                                        className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${order.status === 'Pending' ? 'text-red-500' : 'text-green-500'
                                            }`}
                                    >
                                        {order.status}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default RestockTable;
