import { useMemo } from "react";
import PropTypes from "prop-types";
import "./ExecuteCard.css";

const ExecuteCard = ({
    className = "",
    cardBody = "Our team will handle all the details, from sourcing to installation, ensuring a seamless and stress-free process.",
    cardHeading = "Project Management",
    cardNumbering = "3",
    propWidth,
    propDisplay,
    propMinWidth,
    propAlignSelf,
    propHeight,
    propDisplay1,
}) => {
    const cardNumbering1Style = useMemo(() => {
        return {
            width: propWidth,
        };
    }, [propWidth]);

    const b1Style = useMemo(() => {
        return {
            display: propDisplay,
            minWidth: propMinWidth,
            alignSelf: propAlignSelf,
        };
    }, [propDisplay, propMinWidth, propAlignSelf]);

    const textStyle = useMemo(() => {
        return {
            height: propHeight,
            display: propDisplay1,
        };
    }, [propHeight, propDisplay1]);

    return (
        <div className={`card ${className}`}>
            <div className="card-numbering1" style={cardNumbering1Style}>
                <b className="b1" style={b1Style}>
                    {cardNumbering}
                </b>
            </div>
            <div className="content24">
                <h2 className="heading1">{cardHeading}</h2>
                <div className="text2" style={textStyle}>
                    {cardBody}
                </div>
            </div>
        </div>
    );
};

ExecuteCard.propTypes = {
    className: PropTypes.string,
    cardBody: PropTypes.string,
    cardHeading: PropTypes.string,
    cardNumbering: PropTypes.string,

    /** Style props */
    propWidth: PropTypes.any,
    propDisplay: PropTypes.any,
    propMinWidth: PropTypes.any,
    propAlignSelf: PropTypes.any,
    propHeight: PropTypes.any,
    propDisplay1: PropTypes.any,
};

export default ExecuteCard;
