import React, { useState } from 'react'
import PurchesOrder from "./PurchesOrder";

import PurchaseOrderTable from './PurchesOrderTable'

const MaterialRequirementLayout = () => {

    const [order, setOrder] = useState([])


    // Function to add new orders to the state
    const addOrders = (newOrder) => {
        setOrder(newOrder);
    };
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl">
                {/* Left side - Sales Pipeline Component */}
                <PurchesOrder addOrder={addOrders} />


                {/* Right side - Kanban Board and Deal Table */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    <PurchaseOrderTable purchaseOrder={order} />
                    {/* <DealTable /> */}
                </div>
            </div>
        </div>
    )
}

export default MaterialRequirementLayout