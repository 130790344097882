import React, { useEffect, useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"; // Import the plus icon
import Select from "react-select";
import axiosInstance from '../../services/AxiosInstance';

const MilestoneSection = ({ projects, projectError, projectLoading, addMilestone }) => {
    // const [milestoneForm, setMilestoneForm] = useState({ date: "", description: "", status: "" });
    const [milestoneForm, setMilestoneForm] = useState({
        date: '',
        endDate: '',
        description: '',
        status: '',
        completionPercentage: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [upcomingMilestones, setUpcomingMilestones] = useState([]);
    const [showMilestoneForm, setShowMilestoneForm] = useState(false);
    const [milestoneFilter, setMilestoneFilter] = useState('All');

    // Fetch milestones when project or filter changes
    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get(
                    selectedProject
                        ? `/backend/projects/${selectedProject.value}/milestones`
                        : '/backend/projects/milestones'
                );
                // const upcoming = response.data.filter(milestone => new Date(milestone.date) > new Date());
                setUpcomingMilestones(response.data);
                addMilestone(response.data)
                // console.log(upcoming);

                // console.log(response.data);

            } catch (error) {
                setError('Error fetching milestone data');
            } finally {
                setLoading(false);
            }
        };

        fetchMilestones();
    }, [selectedProject]);

    const handleMilestoneSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post(`/backend/projects/${selectedProject.value}/milestones`, milestoneForm);

            setMilestones([...milestones, response.data]);
            setMilestoneForm({ date: "", description: "", status: "" });
            setShowMilestoneForm(false);
        } catch (err) {
            setError("Failed to add milestone. Please try again.");
        }
    };

    // Filter the milestones based on the selected filter
    const filteredMilestones = upcomingMilestones.filter((milestone) => {
        if (milestoneFilter === 'All') return true;
        return milestone.status === milestoneFilter;
    });

    return (
        <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow-lg">
            {error && <div className="text-red-500">{error}</div>}
            {loading && <div className="text-gray-500">Loading...</div>}

            <h2 className="text-2xl font-semibold mb-4">Milestone Tracking</h2>

            {/* Button to show milestone form */}
            <div
                onClick={() => setShowMilestoneForm(!showMilestoneForm)}
                className="mb-4 inline-flex p-2 rounded-lg bg-teal-100 items-center space-x-2 cursor-pointer text-sm text-blue-600 hover:underline"
            >
                {showMilestoneForm ? (
                    <>
                        <AiOutlineMinus className="text-blue-600" />
                        <span>Cancel</span>
                    </>
                ) : (
                    <>
                        <AiOutlinePlus className="text-blue-600" />
                        <span>Add Milestone</span>
                    </>
                )}
            </div>

            {/* Milestone Form */}
            {showMilestoneForm && (
                <form onSubmit={handleMilestoneSubmit} className="grid grid-cols-1 gap-2 mb-6">
                    {/* Project Selector */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Select Project</label>
                        <Select
                            value={selectedProject}
                            onChange={(option) => setSelectedProject(option)}
                            options={projects.map((project) => ({ value: project.id, label: project.name }))}
                            placeholder="Search and select a project"
                            isClearable
                        />
                    </div>

                    {/* Milestone Date */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Milestone Date</label>
                        <input
                            type="date"
                            value={milestoneForm.date}
                            onChange={(e) => setMilestoneForm({ ...milestoneForm, date: e.target.value })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>

                    {/* Milestone End Date */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Milestone End Date</label>
                        <input
                            type="date"
                            value={milestoneForm.endDate}
                            onChange={(e) => setMilestoneForm({ ...milestoneForm, endDate: e.target.value })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            value={milestoneForm.description}
                            onChange={(e) => setMilestoneForm({ ...milestoneForm, description: e.target.value })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            rows="4"
                        />
                    </div>

                    {/* Status */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Status</label>
                        <select
                            value={milestoneForm.status}
                            onChange={(e) => setMilestoneForm({ ...milestoneForm, status: e.target.value })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        >
                            <option value="">Select status</option>
                            <option value="in-progress">In Progress</option>
                            <option value="Upcoming">Upcoming</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>

                    {/* Completion Percentage */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Completion Percentage</label>
                        <input
                            type="tel"
                            value={milestoneForm.completionPercentage}
                            onChange={(e) => setMilestoneForm({ ...milestoneForm, completionPercentage: e.target.value })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            min="0"
                            max="100"
                        />
                    </div>

                    {/* Form Buttons */}
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={() => setShowMilestoneForm(false)}
                            className="px-4 py-2 text-nowrap text-sm border border-gray-300 rounded-md  hover:bg-gray-100 dark:bg-primaryDark dark:hover:bg-dark dark:text-gray-50  "
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-nowrap text-sm bg-colorBlue text-white rounded-md hover:bg-blue-500"
                        >
                            Add Milestone
                        </button>
                    </div>
                </form>
            )}

            {/* Milestone Filter Section */}
            <div className="mb-4 flex flex-col md:flex-row p-2 justify-between items-center text-sm">
                {/* Filter by Project */}
                <Select
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e)}
                    options={projects.map((project) => ({ value: project.id, label: project.name }))}
                    placeholder="Filter by project"
                    className="mb-4 md:mb-0 w-full md:w-56" // Make the Select full width on mobile
                    isClearable
                />

                {/* Status Filter Buttons */}
                <div className="flex flex-wrap md:flex-nowrap space-x-0 md:space-x-2">
                    {['All', 'Upcoming', 'in-progress', 'Completed'].map((status) => (
                        <button
                            key={status}
                            className={`px-4 py-2 rounded-full text-sm font-medium ${milestoneFilter === status ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} w-full md:w-auto mb-2 md:mb-0`} // Make buttons full width on mobile
                            onClick={() => setMilestoneFilter(status)}
                        >
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </button>
                    ))}
                </div>
            </div>


            {/* Milestone List */}
            <div className="space-y-2">
                {filteredMilestones.length > 0 ? (
                    filteredMilestones.map((milestone) => (
                        <div
                            key={milestone.id}
                            className="p-2  rounded-lg shadow-md border-l-4 border-colorBlue"
                        >
                            <h4 className="text-lg font-semibold">{milestone.description}</h4>
                            <p className="text-sm">Date: {new Date(milestone.date).toLocaleDateString()}</p>
                            <span
                                className={`inline-block mt-2 px-3 py-1 rounded-full text-xs font-medium ${milestone.status === 'Upcoming'
                                    ? 'bg-blue-100 text-colorBlue'
                                    : milestone.status === 'in-progress'
                                        ? 'bg-yellow-100 text-yellow-700'
                                        : 'bg-green-100 text-colorGreen'
                                    }`}
                            >
                                {milestone.status}
                            </span>
                        </div>
                    ))
                ) : (
                    <div className="p-4 bg-white rounded-lg shadow-md text-center text-gray-500">
                        No milestones available.
                    </div>
                )}
            </div>
        </div>
    );
};

export default MilestoneSection;
