import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';

export function ProjectOverview({ projects }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All Projects');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null); // For storing the clicked project
  const [isModalOpen, setIsModalOpen] = useState(false); // For controlling modal visibility
  const projectsPerPage = 3;

  const filteredProjects = projects.filter((project) => {
    const matchesSearchTerm = project.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'All Projects' || project.status === filterStatus;
    return matchesSearchTerm && matchesStatus;
  });

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filterStatus]);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white shadow-md p-6 mb-6 flex flex-col md:flex-row justify-between items-center">
          <h2 className="text-2xl mt-2 font-bold text-gray-800 mb-4 md:mb-0 w-full md:w-auto text-center md:text-left">
            Project Overview
          </h2>

          <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto items-center">
            <div className="relative w-full md:w-auto">
              <input
                type="text"
                placeholder="Search projects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
              <Search className="absolute left-2 top-2 w-5 h-5 ml-2 text-gray-500" />
            </div>

            <div>
              <select
                className="border border-gray-300 rounded-lg px-2 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-auto"
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="All Projects">All Projects</option>
                <option value="Active">Active</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
          </div>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {currentProjects.length > 0 ? (
            currentProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white rounded-lg border border-gray-200 overflow-hidden"
              >
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-900 mb-2">{project.name}</h3>
                  <p className="text-sm text-gray-600 mb-4">{project.description}</p>

                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <dt className="text-sm font-medium text-gray-600">Start Date</dt>
                      <dd className="text-sm text-gray-900">{project.startDate}</dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-600">End Date</dt>
                      <dd className="text-sm text-gray-900">{project.endDate}</dd>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-4">
                    <span className="text-sm font-medium text-gray-600">Status</span>
                    <span
                      className={`px-3 py-1 text-xs font-semibold rounded-full ${project.status === 'Active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                        }`}
                    >
                      {project.status}
                    </span>
                  </div>

                  <button
                    className="text-blue-500 underline text-sm font-semibold"
                    onClick={() => openModal(project)}
                  >
                    View More
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full">
              <p className="text-center text-gray-500">No projects found</p>
            </div>
          )}
        </div>

        <div className="flex items-center justify-center mt-6 space-x-4 sm:justify-center">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-2 bg-blue-500 text-white rounded-lg transition duration-200 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>

          <span className="text-lg text-gray-700">
            Page {currentPage} of {totalPages}
          </span>

          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-2 bg-blue-500 text-white rounded-lg transition duration-200 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && selectedProject && (
        <div className="fixed inset-0 bg-gray-400 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 sm:p-8 md:p-10 rounded-lg shadow-lg w-full max-w-4xl relative max-h-[90vh] overflow-y-auto">
            <button
              className="absolute top-4 right-4 bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded transition duration-200 z-10"
              onClick={closeModal}
            >
              Close
            </button>

            <h3 className="text-2xl font-bold mb-4 sm:mb-6 text-center text-gray-800">{selectedProject.name}</h3>
            <p className="mb-4 sm:mb-6 text-gray-600 text-base">{selectedProject.description}</p>

            <div className="mb-6">
              <h4 className="text-lg font-semibold mb-2 text-gray-800">Time Scheduling:</h4>
              <p className="text-gray-700">Start Date: <span className="font-medium">{selectedProject.startDate}</span></p>
              <p className="text-gray-700">End Date: <span className="font-medium">{selectedProject.endDate}</span></p>
            </div>

            <div className="mb-6">
              <h4 className="text-lg font-semibold mb-2 text-gray-800">Planning:</h4>
              <ul className="list-disc list-inside text-gray-700 text-base">
                {selectedProject.planning && selectedProject.planning.length > 0 ? (
                  selectedProject.planning.map((item, index) => (
                    <li key={index} className="mb-2 p-3 border rounded-lg shadow-md bg-white list-none">
                      <div className="font-semibold">{item.description || 'No description available.'}</div>
                      <div className="text-sm text-gray-500">
                        <span className="font-medium">Start Date:</span> {item.startDate}
                      </div>
                      <div className="text-sm text-gray-500">
                        <span className="font-medium">End Date:</span> {item.endDate}
                      </div>
                    </li>
                  ))
                ) : (
                  <li>No planning details available.</li>
                )}
              </ul>
            </div>

            <div className="mb-4 sm:mb-6 overflow-y-auto max-h-60">
              <h4 className="text-lg font-semibold mb-2 text-gray-800">Project Tasks:</h4>
              {/* <div className="overflow-x-auto"> */}
                <table className="min-w-full border border-gray-300 table-fixed ">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="py-2 px-4 text-left font-semibold text-gray-700 border-b">Task</th>
                      <th className="py-2 px-4 text-left font-semibold text-gray-700 border-b">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProject.tasks.map((task, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="py-2 px-4 text-gray-800 border-b">{task.task}</td>
                        <td className="py-2 px-4 border-b">
                          {task.status === 'Done' ? (
                            <span className="text-green-600 font-medium">Completed</span>
                          ) : (
                            <span className="text-red-600 font-medium">
                              {task.status}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              {/* </div> */}
            </div>

            {/* Additional information can go here */}
          </div>
        </div>
      )}
    </div>
  );
}
