import React, { useState } from 'react';
import AddCustomerForm from './AddCustomerForm';
import CustomerTable from './CustomerTable';
import ContactHistoryTable from './ContactHistoryTable';


const CustomerProfile = () => {
    const [activeTable, setActiveTable] = useState("customer")
    const [customer, setCustomer] = useState([])




    const addCustomer = (newCustomer) => {
        setActiveTable("customer")
        setCustomer((prev) => [...prev, newCustomer])
    }

    // // add equipments to previous list
    // const addEquipment = (newEquipment) => {
    //     setActiveTable('equipment')
    //     setEquipment((prev) => [...prev, newEquipment])
    // }


    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)]  bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full h-[calc(100vh-4rem)] max-w-7xl ">

                {/* Left side - Tab buttons */}
                <div className=" rounded-lg shadow-lg h-[calc(100vh-4rem)] overflow-y-auto  ">
                    <AddCustomerForm newCustomer={addCustomer} />


                </div>

                {/* Right side */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto  ">
                    <div className="mx-3">

                        <CustomerTable customer={customer} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerProfile;

