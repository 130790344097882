import OrderManagement from "./OrderManagement"

const OrderLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark overflow-y-auto">

            <OrderManagement />

        </div>
    )
}

export default OrderLayout