import React, { useState } from "react";
import axiosInstance from "../../services/AxiosInstance";

const PersonalDetails = ({ employeeId, employee, addPersonal }) => {
    const [employeeData, setEmployeeData] = useState({
        id: employee ? employee.personalDetails?.id : "",
        firstName: employee ? employee.personalDetails?.firstName : "",
        middleName: employee ? employee.personalDetails?.middleName : "",
        lastName: employee ? employee.personalDetails?.lastName : "",
        nationality: employee ? employee.personalDetails?.nationality : "",
        maritalStatus: employee ? employee.personalDetails?.maritalStatus : "",
        dateOfBirth: employee ? employee.personalDetails?.dob : "",
        gender: employee ? employee.personalDetails?.gender : "",
        attachment: null,
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("")
    const [uploadProgress, setUploadProgress] = useState(0)

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setEmployeeData((prev) => ({
            ...prev,
            attachment: file,
        }));
    };

    // Handle form submission

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess("");
        setError("");
        setUploadProgress(0); // Initialize progress state to 0

        const formData = new FormData();

        // Append employee personal details directly to formData
        formData.append("firstName", employeeData.firstName);
        formData.append("middleName", employeeData.middleName);
        formData.append("lastName", employeeData.lastName);
        formData.append("nationality", employeeData.nationality);
        formData.append("maritalStatus", employeeData.maritalStatus);
        formData.append("dob", employeeData.dateOfBirth);
        formData.append("gender", employeeData.gender);

        // Append any files to form data
        if (employeeData.attachment) {
            formData.append("attachment", employeeData.attachment);
        }

        try {
            // Check if employeeData.id exists to decide whether to use POST or PUT
            const url = employeeData.id
                ? `/backend/api/employee/personal/${employeeData.id}` // PUT request for update
                : `/backend/api/employee/personal/${employeeId}`; // POST request for create

            const method = employeeData.id ? "put" : "post"; // Set method based on ID availability

            const response = await axiosInstance[method](url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress); // Update progress state
                },
            });

            if (response.status === 200) {
                setSuccess(employeeData.id ? "Employee details updated successfully!" : "Employee details saved successfully!");
                addPersonal(response.data);
            }
        } catch (err) {
            console.error("Error submitting the form:", err);
            setError("Failed to save employee details. Please try again.");
        } finally {
            setLoading(false);
            setUploadProgress(0); // Reset progress after submission
        }

        // Reset employee data after successful submission
        // setEmployeeData({
        //     firstName: "",
        //     middleName: "",
        //     lastName: "",
        //     nationality: "",
        //     maritalStatus: "",
        //     dateOfBirth: "",
        //     gender: "",
        //     attachment: null,
        // });
    };





    return (
        <div>
            <div className="bg-gray-50 dark:bg-secondaryDark  p-4 rounded-lg shadow mb-8">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Personal Details</h1>
            </div>
            <form className="bg-gray-50 dark:bg-secondaryDark shadow-md rounded p-2 px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmit}
            >
                <div className=" p-3">
                    <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                        Employee Full Name*
                    </label>
                    <div className="grid grid-cols-3 gap-4">
                        <input
                            className="border rounded w-full py-2 px-3 "
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            value={employeeData.firstName}
                            onChange={handleInputChange}
                        />
                        <input
                            className="border rounded w-full py-2 px-3"
                            name="middleName"
                            type="text"
                            placeholder="Middle Name"
                            value={employeeData.middleName}
                            onChange={handleInputChange}
                        />
                        <input
                            className="border rounded w-full py-2 px-3"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            value={employeeData.lastName}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4  p-3">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark" htmlFor="nationality">
                            Nationality
                        </label>
                        <select
                            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="nationality"
                            name="nationality"
                            value={employeeData.nationality}
                            onChange={handleInputChange}
                        >
                            <option value="">-- Select --</option>
                            <option value="IN">India</option>
                            <option value="US">United States</option>
                            <option value="UK">United Kingdom</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark" htmlFor="maritalStatus">
                            Marital Status
                        </label>
                        <select
                            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="maritalStatus"
                            name="maritalStatus"
                            value={employeeData.maritalStatus}
                            onChange={handleInputChange}
                        >
                            <option value="">-- Select --</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                        </select>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 p-3">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark" htmlFor="dateOfBirth">
                            Date of Birth
                        </label>
                        <input
                            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="date"
                            value={employeeData.dateOfBirth}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">Gender</label>
                        <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="gender"
                                    value="male"
                                    checked={employeeData.gender === "male"}
                                    onChange={handleInputChange}
                                />
                                <span className="ml-2">Male</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="gender"
                                    value="female"
                                    checked={employeeData.gender === "female"}
                                    onChange={handleInputChange}
                                />
                                <span className="ml-2">Female</span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Show progress bar if uploading */}
                {loading && (
                    <div className="mt-4 w-72">
                        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 h-2.5 shadow-lg">
                            <div
                                className="h-full bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full transition-all duration-300 ease-out"
                                style={{ width: `${uploadProgress}%` }}
                            >
                                <span className="text-xs font-medium text-white flex items-center justify-center h-full">
                                    {uploadProgress}%
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                {/* File input field */}
                <div className="mb-6 p-3">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark"
                        htmlFor="attachment"
                    >
                        Profile Image
                    </label>
                    <input
                        type="file"
                        id="attachment"
                        name="attachment"
                        onChange={handleFileChange}
                        accept="image/*"
                        className="border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-blue-300"
                    />
                    {employeeData.attachment && (
                        <p className="mt-2 text-sm text-gray-500 dark:text-textDark">
                            Attached file: <span className="font-semibold ">{employeeData.attachment.name}</span>
                        </p>
                    )}
                </div>


                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                {success && <p className="text-green-800 text-sm mb-4">{success}</p>}

                <div className="flex items-center justify-end">
                    <button
                        className={`${loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-700 dark:bg-dark dark:hover:bg-primaryDark"
                            } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            "Saving..."
                        ) : (
                            employeeData.id ? "Update" : "Save"
                        )}

                    </button>

                </div>
            </form>
        </div>
    );
};

export default PersonalDetails;
