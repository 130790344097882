import React, { useEffect, useState } from 'react';
import EquipmentAllocation from './EquipmentAllocation';
import ManpowerAssessment from './ManpowerAssessment';
import EquipmentAllocationTable from './EquipmentAllocationTable';
import QuotationsInvoicing from './QuotationsInvoicing';
import Invoicing from './Invoicing';
import QuotationTable from './QuotationTable';
import InvoiceTable from './InvoiceTable';
import axiosInstance from '../../services/AxiosInstance';

const QuotationLayout = () => {


    const [quotation, setQuotation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, seterror] = useState('');
    const [customers, setCustomers] = useState([])
    const [invoice, setInvoice] = useState([]);

    useEffect(() => {
        const fetchCustomer = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get("/backend/api/contact/customer");
                setCustomers(response.data.map(customer => ({ label: `${customer.name} - ${customer.phone}`, value: customer.id, phone: customer.phone, email: customer.email, address: customer.address, name: customer.name })));
            } catch (error) {
                seterror("fail to fetch customers")
            } finally {
                setLoading(false);
            }


        }

        fetchCustomer();


    }, [])





    const newQuotation = (newquotation) => {
        // setActiveTable("quotation")
        setQuotation((prev) => [...prev, newquotation])
    }

    // const addNewInvoice = (newInvoice) => {
    //     setActiveTable('invoicing')
    //     setInvoice((prev) => [...prev, newInvoice])
    // }


    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 h-[calc(100vh-4rem)] gap-4 w-full max-w-7xl ">

                {/* Left side - Tab buttons */}
                <div className=" rounded-lg shadow-lg h-[calc(100vh-4rem)] overflow-y-auto  ">

                    <QuotationsInvoicing newQuotation={newQuotation} customers={customers} />
                </div>

                {/* Right side */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto  ">
                    <div className="mx-3">


                        <QuotationTable quotation={quotation} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuotationLayout;
