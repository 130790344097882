import { useMemo } from "react";
import PropTypes from "prop-types";
import "./CountStats.css";

const CountStats = ({
  className = "",
  cOuntDescription = "Unique Styles",
  count = "100+",
  propWidth,
  propAlignSelf,
  propWidth1,
  propDisplay,
  propAlignSelf1,
  projectComplete,
  propAlignSelf2,
  propWidth2,
}) => {
  const countStatsStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const bStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      width: propWidth1,
      display: propDisplay,
    };
  }, [propAlignSelf, propWidth1, propDisplay]);

  const projectCompleteStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  const projectComplete1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf2,
      width: propWidth2,
    };
  }, [propAlignSelf2, propWidth2]);

  return (
    <div className={`count-stats `} >
      <b className="b" style={bStyle}>
        {count}
      </b>
      <div className="project-complete" >
        {cOuntDescription}
      </div>
      <div className="project-complete1" >
        {projectComplete}
      </div>
    </div>
  );
};

CountStats.propTypes = {
  className: PropTypes.string,
  cOuntDescription: PropTypes.string,
  count: PropTypes.string,
  projectComplete: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propWidth1: PropTypes.any,
  propDisplay: PropTypes.any,
  propAlignSelf1: PropTypes.any,
  propAlignSelf2: PropTypes.any,
  propWidth2: PropTypes.any,
};

export default CountStats;
