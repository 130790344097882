// ViewSwitcher.js
import React from 'react';

const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked }) => {
    return (
        <div className="flex flex-col mb-2 justify-center items-center">
            <div className="flex justify-center space-x-2">
                <button
                    onClick={() => onViewModeChange('Day')}
                    className="py-1 px-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-md shadow-lg transition-transform transform hover:scale-105 focus:outline-none text-xs sm:text-sm"
                >
                    Day
                </button>
                <button
                    onClick={() => onViewModeChange('Week')}
                    className="py-1 px-3 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-md shadow-lg transition-transform transform hover:scale-105 focus:outline-none text-xs sm:text-sm"
                >
                    Week
                </button>
                <button
                    onClick={() => onViewModeChange('Month')}
                    className="py-1 px-3 bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-md shadow-lg transition-transform transform hover:scale-105 focus:outline-none text-xs sm:text-sm"
                >
                    Month
                </button>
            </div>
            <div className="flex items-center mt-2">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={onViewListChange}
                    className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    id="show"
                />
                <label htmlFor="show" className="text-gray-700 cursor-pointer select-none text-xs sm:text-sm">
                    Show Task List
                </label>
            </div>
        </div>
    );
};

export default ViewSwitcher;
