import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Star } from "lucide-react";
import axiosInstance from "../../services/AxiosInstance";

const PerformanceReview = ({ addReview }) => {
  const [selectedMetric, setSelectedMetric] = useState("");
  const [reviewPeriod, setReviewPeriod] = useState("");
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [goalsAchieved, setGoalsAchieved] = useState("");
  const [formError, setFormError] = useState("");
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [errorEmployees, setErrorEmployees] = useState("");

  const [formLoading, setFormLoading] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState("");
  const [success, setSuccess] = useState('')

  // Fetch employees when the component mounts
  useEffect(() => {
    const fetchEmployees = async () => {
      setLoadingEmployees(true);
      setErrorEmployees("");

      try {
        const response = await axiosInstance.get("/backend/api/employees/list");
        const employeeOptions = response.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
        }));
        setEmployees(employeeOptions);
      } catch (error) {
        setErrorEmployees("Failed to load employees. Please try again.");
      } finally {
        setLoadingEmployees(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!employee || !selectedMetric || !reviewPeriod || !rating || !feedback || !goalsAchieved) {
      setFormError("All fields are required");
      return;
    }

    setFormLoading(true);
    setFormSubmitError("");
    setSuccess(" ");


    try {
      const response = await axiosInstance.post(`/backend/api/performance-reviews/${employee.value}`, {
        metric: selectedMetric,
        reviewPeriod,
        rating,
        feedback,
        goalAchieved: goalsAchieved,
      });
      // console.log(response.data)
      addReview(response.data)
      setSuccess("Performance review Added Successfully")

      // Reset form fields
      setEmployee(null);
      setSelectedMetric("");
      setReviewPeriod("");
      setRating(0);
      setFeedback("");
      setGoalsAchieved("");
      setFormError("");
    } catch (error) {
      setFormSubmitError(error.response?.data || "Failed to submit the review. Please try again.");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 dark:bg-secondaryDark shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4 dark:text-dark">Performance Review</h2>
      <form onSubmit={handleSubmit} className="space-y-4 dark:text-dark text-gray-700">
        {formError && <div className="text-red-500 text-sm">{formError}</div>}
        {formSubmitError && <div className="text-red-500 text-sm">{formSubmitError}</div>}
        {success && <div className="text-green-500 text-sm">{success}</div>}

        {/* Employee Selection */}
        <div>
          <label htmlFor="employee" className="block mb-2 text-sm font-medium">
            Select Employee
          </label>
          {loadingEmployees ? (
            <div className="text-gray-500">Loading employees...</div>
          ) : errorEmployees ? (
            <div className="text-red-500 text-sm">{errorEmployees}</div>
          ) : (
            <Select
              id="employee"
              options={employees}
              value={employee}
              onChange={setEmployee}
              placeholder="Select an employee"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
            />
          )}
        </div>

        {/* Performance Metric */}
        <div>
          <label htmlFor="performanceMetric" className="block mb-2 text-sm font-medium">
            Performance Metric
          </label>
          <select
            id="performanceMetric"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            className="block w-full p-2 border border-gray rounded-md bg-gray-50"
          >
            <option value="">Select Metric</option>
            <option value="Punctuality">Punctuality</option>
            <option value="TeamCollaboration">Team Collaboration</option>
            <option value="ProductivityScore">Productivity Score</option>
          </select>
        </div>

        {/* Review Period */}
        <div>
          <label htmlFor="reviewPeriod" className="block mb-2 text-sm font-medium">
            Review Period
          </label>
          <select
            id="reviewPeriod"
            value={reviewPeriod}
            onChange={(e) => setReviewPeriod(e.target.value)}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          >
            <option value="">Select Period</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
          </select>
        </div>

        {/* Rating */}
        <div>
          <label htmlFor="rating" className="block mb-2 text-sm font-medium">
            Rating
          </label>
          <div className="flex items-center mt-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                className={`h-6 w-6 cursor-pointer ${star <= rating ? "text-yellow-400 fill-current" : "text-gray-300"}`}
                onClick={() => setRating(star)}
              />
            ))}
          </div>
        </div>

        {/* Feedback */}
        <div>
          <label htmlFor="feedback" className="block mb-2 text-sm font-medium">
            Feedback
          </label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows={3}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          ></textarea>
        </div>

        {/* Goals Achieved */}
        <div>
          <label htmlFor="goalsAchieved" className="block mb-2 text-sm font-medium">
            Goals Achieved
          </label>
          <textarea
            id="goalsAchieved"
            value={goalsAchieved}
            onChange={(e) => setGoalsAchieved(e.target.value)}
            rows={3}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          ></textarea>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={`w-full py-2 px-4 rounded-md ${formLoading ? "bg-colorBlue cursor-not-allowed" : "bg-colorBlue hover:bg-blue-500"
            } text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          disabled={formLoading}
        >
          {formLoading ? "Submitting..." : "Submit Review"}
        </button>
      </form>
    </div>
  );
};

export default PerformanceReview;
