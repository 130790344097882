import { useState } from "react";
import axiosInstance from "../../services/AxiosInstance";
import { FaSpinner } from "react-icons/fa";

export default function TrackShipment({ order, onClose, onSave }) {
  const [trackingData, setTrackingData] = useState({
    shippingStatus: "",
    estimatedDeliveryDate: ""
  });

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrackingData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)

    // Here you would typically send this data to your backend

    try {
      const response = await axiosInstance.put(`/backend/api/vendor-purchase/${order.id}`, {
        orderStatus: trackingData.shippingStatus,
        estimateDeliveryDate: trackingData.estimatedDeliveryDate,

      })
      // console.log(response.data);
      
      
      if (response.status === 200) {
        setSuccess("Shipment Tracking Updated Successfully!!")
        onSave(response.data)
      } else {
        setError("Fail to Update shipment tracking")
      }
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      setError("Fail to Update shipment tracking")
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-50 dark:bg-secondaryDark rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 dark:text-dark">Shipment Tracking for Order #{order.id}</h2>

        <p className="mb-2 text-center text-red-300" > Your Current Status: {order.orderStatus}</p>

        {success &&
          <p className="text-green-800"> {success} </p>
        }


        {
          error &&
          <p className="text-red-500"> {error} </p>
        }
        <form onSubmit={handleSubmit} className="grid gap-4">
          {/* <div>
            <label htmlFor="trackingNumber" className="block text-sm font-medium text-gray-700 mb-1">Tracking Number</label>
            <input
              type="text"
              id="trackingNumber"
              name="trackingNumber"
              value={trackingData.trackingNumber}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded px-2 py-1"
              required
            />
          </div> */}
          <div>
            <label htmlFor="shippingStatus" className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">Shipping Status</label>
            <select
              id="shippingStatus"
              name="shippingStatus"
              value={trackingData.shippingStatus}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded px-2 py-1"
              required
            >
              <option value="">Select Shipping Status</option>
              <option value="In Transit">In Transit</option>
              <option value="Delivered">Delivered</option>
            </select>
          </div>
          <div>
            <label htmlFor="estimatedDeliveryDate" className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">Estimated Delivery Date</label>
            <input
              type="date"
              id="estimatedDeliveryDate"
              name="estimatedDeliveryDate"
              value={trackingData.estimatedDeliveryDate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded px-2 py-1"
              required
            />
          </div>
          <div className="flex justify-end gap-2">
            <button type="button" onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">Cancel</button>
            <button type="submit" disabled={loading} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-dark dark:hover:bg-primaryDark">
              {loading ? <FaSpinner /> : "Update Status"}




            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
