import React, { useState } from 'react';

export default function GroupDetailsModal({ isOpen, onClose, groupDetails }) {
  const [copied, setCopied] = useState(false);

  if (!isOpen) return null;

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(groupDetails.inviteLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-96 shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Group Details</h2>
        <p><strong>Group Name:</strong> {groupDetails.name}</p>
        <p><strong>Group Admin:</strong> {groupDetails.admin}</p>
        <p><strong>Total Members:</strong> {groupDetails.totalMembers}</p>
        <p><strong>Description:</strong> {groupDetails.description}</p>
        <p><strong>Created Date:</strong> {groupDetails.createdDate}</p>
        
        <div className="mt-4">
          <p><strong>Invite Link:</strong></p>
          <div className="flex items-center mt-1">
            <input
              type="text"
              value={groupDetails.inviteLink}
              readOnly
              className="border border-gray-300 rounded px-2 py-1 flex-grow mr-2"
            />
            <button 
              onClick={handleCopyInviteLink} 
              className="bg-blue-600 text-white px-4 py-1 rounded"
            >
              {copied ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>

        <button 
          onClick={onClose} 
          className="mt-4 bg-purple-600 text-white px-4 py-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}