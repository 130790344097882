import React, { useState } from 'react';

function EmployeeEditModal({ employee, onClose, onSubmit, isEditMode }) {
  const [formData, setFormData] = useState({
    id: isEditMode && employee ? employee.employeeId : '',
    name: isEditMode && employee ? employee.fullName : '',
    email: isEditMode && employee ? employee.email : '',
    phone: isEditMode && employee ? employee.phoneNumber : '',
    joinDate: isEditMode && employee ? employee.dateOfJoining : '',
    department: isEditMode && employee ? employee.department : '',
    role: isEditMode && employee ? employee.role : '',
    status: isEditMode && employee ? employee.status : '',
    profilePicture: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, profilePicture: file }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-gray-50 dark:bg-secondaryDark rounded-lg p-6 w-full max-w-lg shadow-lg transition-transform transform duration-300 scale-95 sm:scale-100">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-dark mb-6">
          {isEditMode ? 'Edit Employee' : 'Add Employee'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4 dark:text-dark text-gray-700">
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="fullName" className="text-sm font-medium   sm:w-1/4">
              Full Name
            </label>
            <input
              id="fullName"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="email" className="text-sm font-medium  -300 sm:w-1/4">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="phone" className="text-sm font-medium text-gray-700  sm:w-1/4">
              Phone Number
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="joinDate" className="text-sm font-medium   sm:w-1/4">
              Date of Joining
            </label>
            <input
              id="joinDate"
              name="joinDate"
              type="date"
              value={formData.joinDate}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="role" className="text-sm font-medium   sm:w-1/4">
              Role
            </label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            >
              <option value="" disabled>Select role</option>
              <option value="Developer">Developer</option>
              <option value="Manager">Manager</option>
              <option value="Designer">Designer</option>
              <option value="Analyst">Analyst</option>
            </select>
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <label htmlFor="status" className="text-sm font-medium  sm:w-1/4">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm w-full sm:w-3/4 focus:ring-blue-500 focus:border-blue-500 "
            >
              <option value="Active">Active</option>
              <option value="On Leave">On Leave</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>

          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition dark:bg-gray-700 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition  dark:bg-dark dark:hover:bg-primaryDark"
            >
              {isEditMode ? 'Save' : 'Add'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmployeeEditModal;
