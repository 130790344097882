import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from "react-select";

const TaskAssignmentForm = ({ addTask }) => {
    const [formData, setFormData] = useState({
        taskTitle: '',
        description: '',
        deadline: '',
        assignee: '',
        project: '',
        priority: 'Medium',
        taskComplete: false,
    });

    const [teamMembers, setTeamMembers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    // Fetch team members from the backend
    useEffect(() => {
        const fetchTeamMembers = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/backend/api/employees');
                // console.log(response.data)
                // const workers = response.data.filter(member => member.role === 'worker');
                setTeamMembers(response.data);
            } catch (error) {
                console.error('Error fetching team members:', error);
                setMessage('Failed to load team members');
            } finally {
                setLoading(false);
            }
        };

        fetchTeamMembers();
    }, []);

    // Fetch projects from the backend
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axiosInstance.get('/backend/projects');
                // console.log(response.data);

                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
                setMessage('Failed to load projects');
            }
        };

        fetchProjects();
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { taskTitle, description, deadline, assignee } = formData;

        if (!taskTitle || !description || !deadline || !assignee) {
            setMessage('Please fill in all required fields.');
            return;
        }

        const newTask = {
            title: taskTitle,
            description,
            deadline,
            priority: formData.priority,
            complete: formData.taskComplete,
            employee: assignee ? { employeeId: assignee } : null,
            status: 'To Do', 
        };

        try {
            const response = await axiosInstance.post(`/backend/projects/${selectedProject}/tasks`, newTask);
            // console.log(response.status)
            if (response.status === 201 || response.status === 200) {
                // console.log(response.data)
                // const createdTask = { ...newTask, id: response.data.id }; // Add the ID from the response
                addTask(response.data);
                setMessage('Task assigned successfully.');
                // Reset form data
                setFormData({
                    taskTitle: '',
                    description: '',
                    deadline: '',
                    assignee: '',
                    project: '',
                    priority: 'Medium',
                    taskComplete: false,
                });
                setSelectedProject(null);
            } else {
                setMessage('Task assignment failed. Please try again.');
            }
        } catch (error) {
            console.error('Error assigning task:', error);
            setMessage('An error occurred while assigning the task. Please try again.');
        } finally {
            setLoading(false)
        }
    };



    return (
        <form onSubmit={handleSubmit} className="p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg max-w-full sm:max-w-4xl mx-auto dark:bg-secondaryDark transition-colors duration-1000 ease-in">
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-800 mb-6 text-center dark:text-dark">Assign a New Task</h2>

            {message && (
                <div className={`text-center p-4 mb-4 rounded-lg ${message.includes('successfully') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                    {message}
                </div>
            )}

            <div className="grid grid-cols-1 gap-3 ">

                {/* Project */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700 dark:text-dark">Project</label>
                    {loading ? (
                        <p>Loading projects...</p>
                    ) : (
                        <Select
                            value={selectedProject ? { value: selectedProject, label: projects.find(p => p.id === selectedProject).name } : null}
                            onChange={(option) => setSelectedProject(option.value)}
                            options={projects.map((project) => ({ value: project.id, label: project.name }))}
                            placeholder="Select a project"
                            required
                        />
                    )}
                </div>

                {/* Task Title */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700 dark:text-dark">Task Title</label>
                    <input
                        type="text"
                        name="taskTitle"
                        value={formData.taskTitle}
                        onChange={handleChange}
                        placeholder="Enter task title"
                        className="block w-full h-12 px-3 sm:px-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm lg:text-base"
                        required
                    />
                </div>

                {/* Description */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700 dark:text-dark">Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter task description"
                        className="block w-full h-24 px-3 sm:px-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm lg:text-base"
                        required
                    />
                </div>

                {/* Deadline */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700  dark:text-dark">Deadline</label>
                    <input
                        type="date"
                        name="deadline"
                        value={formData.deadline}
                        onChange={handleChange}
                        className="block w-full h-12 px-3 sm:px-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm lg:text-base"
                        required
                    />
                </div>

                {/* Assignee */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700  dark:text-dark">Assignee</label>
                    {loading ? (
                        <p>Loading team members...</p>
                    ) : (
                        <Select
                            value={formData.assignee ? {
                                value: formData.assignee,
                                label: teamMembers.find(member => member.employeeId === formData.assignee)?.fullName
                            } : null}
                            onChange={(option) => setFormData({ ...formData, assignee: option.value })}
                            options={teamMembers.map((member) => ({
                                value: member.employeeId,
                                label: member.fullName
                            }))}
                            placeholder="Select a team member"
                            required
                        />

                    )}
                </div>

                {/* Priority */}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700  dark:text-dark">Priority</label>
                    <select
                        name="priority"
                        value={formData.priority}
                        onChange={handleChange}
                        className="block w-full h-12 px-3 sm:px-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm lg:text-base"
                    >
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                </div>
            </div>

            <div className="flex gap-4 mt-8">
                {/* <button
                    type="submit"
                    className={`px-4 py-2 text-sm bg-black text-white  text-nowrap rounded-md shadow-sm hover:bg-gray-800 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? "loading.." : "Assign Task"}
                </button>
 */}


                <button disabled={loading} type="submit" className="text-white  bg-colorBlue w-full  hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg  px-5 py-2.5 text-center me-2 dark:focus:ring-blue-800 inline-flex items-center justify-center text-sm ">
                    {loading ? <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg> : ""}
                    {loading ? "Loading..." : "Assign Task"}
                </button>

            </div>
        </form>
    );
};

export default TaskAssignmentForm;
