import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axiosInstance from '../services/AxiosInstance';

const ContactModal = ({ openContact, onCloseContact }) => {
    const [isOpen, setIsOpen] = useState(openContact);
    const [selectedProjectType, setSelectedProjectType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        projectType: '',
        description: '',
        priceRange: '',
        communicationMethod: '',
        contact: true,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleCloseContact = () => {
        setIsOpen(false);
        onCloseContact();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleProjectTypeChange = (e) => {
        const projectType = e.target.value;
        setSelectedProjectType(projectType);
        setFormData((prevData) => ({
            ...prevData,
            projectType: projectType,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.post('/backend/api/contact/customer/save',formData);
            console.log(response.data);
            // Show SweetAlert success message
            Swal.fire({
                title: 'Success!',
                text: 'Form submitted successfully! We will contact you soon..!',
                icon: 'success',
                confirmButtonText: 'OK'
            });

            handleCloseContact();
        } catch (err) {
            console.error('Error saving contact:', err);
            setError('Error saving contact.');
            // Show SweetAlert error message
            Swal.fire({
                title: 'Error!',
                text: 'There was an error submitting the form. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {isOpen && (
                <div
                    id="contact-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
                >
                    <div className="relative p-4 w-full max-w-4xl max-h-[90vh] overflow-y-auto bg-white rounded-lg shadow">
                        <button
                            type="button"
                            onClick={handleCloseContact}
                            className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>

                        <div className="flex justify-center p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-lg font-semibold text-gray-900 text-center">
                                Contact Us
                            </h3>
                        </div>

                        <form className="p-4 md:p-5" onSubmit={handleSubmit}>
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2 sm:col-span-1">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        placeholder="First name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="col-span-2 sm:col-span-1">
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        placeholder="your-email@gmail.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                                        Phone Number
                                    </label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        placeholder="98765-43210"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="col-span-2 sm:col-span-1">
                                    <label htmlFor="project-type" className="block mb-2 text-sm font-medium text-gray-900">
                                        Choose Interior Project Type
                                    </label>
                                    <select
                                        id="project-type"
                                        name="projectType"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                        value={selectedProjectType}
                                        onChange={handleProjectTypeChange}
                                    >
                                        <option value="" disabled>
                                            Select project type
                                        </option>
                                        <option value="Hospital">Hospital</option>
                                        <option value="Hotels">Hotels</option>
                                        <option value="Residential">Residential</option>
                                        <option value="Corporate offices">Corporate offices</option>
                                        <option value="College">College</option>
                                        <option value="University">University</option>
                                        <option value="School">School</option>
                                        <option value="Showroom">Showroom</option>
                                        <option value="Landscape">Landscape</option>
                                        <option value="Bank">Bank</option>
                                        <option value="Industrial">Industrial</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                {selectedProjectType && (
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="price-range" className="block mb-2 text-sm font-medium text-gray-900">
                                            What price range do you expect for {selectedProjectType}?
                                        </label>
                                        <select
                                            id="price-range"
                                            name="priceRange"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            value={formData.priceRange}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>
                                                Select price range
                                            </option>

                                            {selectedProjectType === 'Hospital' && (
                                                <>
                                                    <option value="under-500000">₹5,00,000 or less</option>
                                                    <option value="500000-1000000">₹5,00,000 - ₹10,00,000</option>
                                                    <option value="over-1000000">₹10,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Hotels' && (
                                                <>
                                                    <option value="under-1000000">₹10,00,000 or less</option>
                                                    <option value="1000000-3000000">₹10,00,000 - ₹30,00,000</option>
                                                    <option value="over-3000000">₹30,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Residential' && (
                                                <>
                                                    <option value="under-500000">₹5,00,000 or less</option>
                                                    <option value="500000-1500000">₹5,00,000 - ₹15,00,000</option>
                                                    <option value="over-1500000">₹15,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Corporate offices' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'College' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'University' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'School' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Showroom' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Landscape' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Bank' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Industrial' && (
                                                <>
                                                    <option value="under-2000000">₹20,00,000 or less</option>
                                                    <option value="2000000-5000000">₹20,00,000 - ₹50,00,000</option>
                                                    <option value="over-5000000">₹50,00,000 or more</option>
                                                </>
                                            )}
                                            {selectedProjectType === 'Other' && (
                                                <>
                                                    <option value="under-100000">₹1,00,000 or less</option>
                                                    <option value="100000-500000">₹1,00,000 - ₹5,00,000</option>
                                                    <option value="over-500000">₹5,00,000 or more</option>
                                                </>
                                            )}
                                        </select>
                                    </div>
                                )}


                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">
                                        Preferred Method of Communication:
                                    </label>
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="radio"
                                            id="visit-center"
                                            name="communicationMethod"
                                            value="visit-center"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                            checked={formData.communicationMethod === 'visit-center'}
                                            onChange={handleChange}
                                        />
                                        <label
                                            htmlFor="visit-center"
                                            className="ml-2 text-sm font-medium text-gray-900"
                                        >
                                            Visit Office
                                        </label>
                                    </div>

                                    <div className="flex items-center mb-2">
                                        <input
                                            type="radio"
                                            id="on-your-side"
                                            name="communicationMethod"
                                            value="on-your-side"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                            checked={formData.communicationMethod === 'on-your-side'}
                                            onChange={handleChange}
                                        />
                                        <label
                                            htmlFor="on-your-side"
                                            className="ml-2 text-sm font-medium text-gray-900"
                                        >
                                            At Your Location
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="call-back"
                                            name="communicationMethod"
                                            value="call-back"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                            checked={formData.communicationMethod === 'call-back'}
                                            onChange={handleChange}
                                        />
                                        <label
                                            htmlFor="call-back"
                                            className="ml-2 text-sm font-medium text-gray-900"
                                        >
                                            Need a Call Back
                                        </label>
                                    </div>
                                </div>

                                <div className="col-span-2 sm:col-span-1">
                                    <label htmlFor="description" className=" select-none block mb-2 text-sm font-medium text-gray-900">
                                        Address
                                    </label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Write your project description here"
                                        value={formData.address}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>

                                <div className="col-span-2">
                                    <label htmlFor="description" className=" select-none block mb-2 text-sm font-medium text-gray-900">
                                        Describe Your Need
                                    </label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Write your project description here"
                                        value={formData.description}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className="col-span-2">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                            required
                                        />
                                        <label htmlFor="terms" className="ml-2 select-none text-sm font-medium text-gray-900">
                                            I accept the terms and conditions
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="text-white inline-flex items-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                disabled={loading}
                            >
                                <svg
                                    className="me-1 -ms-1 w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactModal;
