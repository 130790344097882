import { useState, useEffect } from 'react';
import { Search, Plus, Edit, Trash2, Eye, ChevronLeft, ChevronRight } from 'lucide-react';
import axiosInstance from '../../services/AxiosInstance';
import Swal from 'sweetalert2';
import InvoiceDisplay from './InvoiceDisplay';

export default function MockInvoices({ customer, error, loading }) {
  const [activeTab, setActiveTab] = useState('All Invoices');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortBy, setSortBy] = useState('Newest First');
  const [currentPage, setCurrentPage] = useState(1);
  const [invoices, setInvoices] = useState([]);
  const [editingInvoice, setEditingInvoice] = useState(null);

  const [showInvoice, setShowInvoice] = useState(false)

  const [selectedInvoice, setSelectedInvoice] = useState([])

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');
        const allInvoices = response.data.flatMap(customer =>
          customer.invoices ? customer.invoices.map(invoice => ({ ...invoice, customerName: customer.name, customerEmail: customer.email, phone: customer.phone })) : []
        );
        setInvoices(allInvoices);
      } catch (error) {
        console.error('Failed to fetch invoices:', error);
      }
    };

    if (!customer) {
      fetchInvoices();
    } else {
      const allInvoices = customer.flatMap(customer =>
        customer.invoices ? customer.invoices.map(invoice => ({ ...invoice, customerName: customer.name, customerEmail: customer.email, phone: customer.phone })) : []
      );
      setInvoices(allInvoices);
    }
  }, [customer]);

  const filteredInvoices = invoices.filter((invoice) => {
    const matchesTab =
      (activeTab === 'All Invoices') ||
      (activeTab === 'Pending Payments' && invoice.status === 'Pending') ||
      (activeTab === 'Paid Invoices' && invoice.status === 'Paid');

    const matchesStatus =
      (statusFilter === 'All' || invoice.status === statusFilter);

    const matchesSearchTerm =
      invoice.invoiceNumber.includes(searchTerm.toLowerCase()) ||
      invoice.customerName.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesTab && matchesStatus && matchesSearchTerm;
  });

  const sortInvoices = (invoices) => {
    switch (sortBy) {
      case 'Newest First':
        return invoices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case 'Oldest First':
        return invoices.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case 'Amount High to Low':
        return invoices.sort((a, b) => b.totalAmount - a.totalAmount);
      case 'Amount Low to High':
        return invoices.sort((a, b) => a.totalAmount - b.totalAmount);
      default:
        return invoices;
    }
  };

  const sortedInvoices = sortInvoices(filteredInvoices);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(sortedInvoices.length / itemsPerPage);
  const paginatedInvoices = sortedInvoices.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleDeleteInvoice = async (invoiceId) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (isConfirmed) {
      try {
        await axiosInstance.delete(`/backend/api/invoices/${invoiceId}`);
        setInvoices(invoices.filter((invoice) => invoice.id !== invoiceId));
        Swal.fire('Deleted!', 'Your invoice has been deleted.', 'success');
      } catch (error) {
        console.error("Failed to delete invoice:", error);
        Swal.fire('Error!', 'There was an error deleting your invoice.', 'error');
      }
    }
  };

  const handleEditInvoice = (invoice) => {
    setEditingInvoice(invoice);
  };

  const handleCloseEdit = () => {
    setEditingInvoice(null); // Close the edit modal
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/backend/api/invoices/${editingInvoice.id}`, editingInvoice); // Update invoice
      setInvoices(invoices.map(inv => (inv.id === editingInvoice.id ? editingInvoice : inv))); // Update state
      handleCloseEdit(); // Close modal after saving changes
      Swal.fire('Success!', 'Invoice updated successfully.', 'success');
    } catch (error) {
      console.error("Failed to update invoice:", error);
      Swal.fire('Error!', 'There was an error updating your invoice.', 'error');
    }
  };


  const handleShowInvoice = (order) => {
    setSelectedInvoice(order)
    setShowInvoice(true)
  }

  const onClose = () => {
    setSelectedInvoice("")
    setShowInvoice(false);
  }

  return (
    <div className="mx-auto px-2 py-3 w-full">
      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark">
        <h1 className="text-3xl font-black dark:text-dark">Invoice Management</h1>
      </div>

      {/* Tabs */}
      <div className="flex mb-4 border-b">
        {['All Invoices', 'Pending Payments', 'Paid Invoices'].map((tab) => (
          <button
            key={tab}
            className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search and Filters */}
      <div className="flex flex-wrap gap-4 mb-4">
        <div className="flex-1 min-w-[200px]">
          <div className="relative">
            <input
              type="text"
              placeholder="Search invoices..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
        </div>
        <select
          className="border rounded-lg px-4 py-2"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="All">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="Paid">Paid</option>
        </select>
        <select
          className="border rounded-lg px-4 py-2"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="Newest First">Newest First</option>
          <option value="Oldest First">Oldest First</option>
          <option value="Amount High to Low">Amount High to Low</option>
          <option value="Amount Low to High">Amount Low to High</option>
        </select>
      </div>

      {/* Invoice List */}
      {loading ? (
        <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
          <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
          <p className='dark:text-secondaryDark' >Loading projects</p>
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : paginatedInvoices.length > 0 ? (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {paginatedInvoices.map((invoice) => (
            <div key={invoice.id} className="border rounded-lg px-2 py-3 shadow-sm dark:bg-secondaryDark">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}
                  >
                    {invoice.status}
                  </span>
                  <h3 className="font-semibold">{invoice.customerName}</h3>
                  <p className="text-sm text-gray-500 dark:text-dark">{invoice.customerEmail}</p>
                  <p className="text-sm text-gray-500 dark:text-dark">{invoice.invoiceNumber}</p>
                </div>
              </div>
              <p className="text-lg font-bold mb-2 dark:text-textDark">₹{invoice.totalAmount}</p>
              <p className="text-sm mb-2 text-gray-600 dark:text-dark"> {invoice.description} </p>
              <div className="text-sm text-gray-500 mb-4 dark:text-textDark">
                <p>Issued: {invoice.invoiceDate}</p>
                <p>Due: {invoice.dueDate}</p>
              </div>
              <div className="flex justify-end gap-2">

                <button className="p-2 text-gray-500 dark:text-dark hover:text-blue-500" onClick={() => handleShowInvoice(invoice)}>  <Eye size={20} /></button>

                <button
                  className="p-2 text-gray-500 hover:text-green-500 dark:text-dark "
                  onClick={() => handleEditInvoice(invoice)} // Open edit modal
                >
                  <Edit size={20} />
                </button>
                <button
                  className="p-2 text-gray-500 hover:text-red-500 dark:text-dark"
                  onClick={() => handleDeleteInvoice(invoice.id)}
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No invoices found.</div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="px-4 py-2 border rounded-l-lg bg-white"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="px-4 py-2">{currentPage} / {totalPages}</span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="px-4 py-2 border rounded-r-lg bg-white"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}

      {/* Edit Invoice Modal */}
      {editingInvoice && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <form
            onSubmit={handleSaveChanges}
            className="bg-gray-50 dark:bg-secondaryDark rounded-lg p-6 w-full max-w-md"
          >
            <h2 className="text-xl font-bold mb-4 dark:text-dark">Edit Invoice</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Issue Date</label>
              <input
                type="date"
                className="border rounded-lg w-full px-3 py-2"
                value={editingInvoice.invoiceDate}
                onChange={(e) => setEditingInvoice({ ...editingInvoice, invoiceDate: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Due Date</label>
              <input
                type="date"
                className="border rounded-lg w-full px-3 py-2"
                value={editingInvoice.dueDate}
                onChange={(e) => setEditingInvoice({ ...editingInvoice, dueDate: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Status</label>
              <select
                className="border rounded-lg w-full px-3 py-2"
                value={editingInvoice.status}
                onChange={(e) => setEditingInvoice({ ...editingInvoice, status: e.target.value })}
              >
                <option value="Pending">Pending</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Amount</label>
              <input
                type="number"
                className="border rounded-lg w-full px-3 py-2"
                value={editingInvoice.totalAmount}
                onChange={(e) => setEditingInvoice({ ...editingInvoice, totalAmount: parseFloat(e.target.value) })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                className="border rounded-lg w-full px-3 py-2"
                value={editingInvoice.description}
                onChange={(e) => setEditingInvoice({ ...editingInvoice, description: e.target.value })}
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-4 py-2 mr-2 border rounded-lg text-gray-50 bg-gray-500"
                onClick={handleCloseEdit} // Close modal
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg dark:bg-dark dark:hover:bg-primaryDark"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )}

      {showInvoice && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <InvoiceDisplay close={onClose} invoiceData={selectedInvoice} />
        </div>
      )}
    </div>
  );
}
