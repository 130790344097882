import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axiosInstance from "../../services/AxiosInstance";
import { FaRegCheckCircle, FaArrowRight, FaCheck } from 'react-icons/fa';

const KanbanBoard = ({ tasks: initialTasks, loading }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [tasks, setTasks] = useState(initialTasks); // Manage tasks in state

    const fetchProjects = async () => {
        try {
            const response = await axiosInstance.get('/backend/projects');
            const projectOptions = Array.isArray(response.data)
                ? response.data.map(project => ({
                    value: project.id,
                    label: project.name,
                }))
                : [];

            setProjects(projectOptions);
        } catch (error) {
            toast.error("Failed to fetch projects.");
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    // Update the local tasks state whenever the prop changes
    useEffect(() => {
        setTasks(initialTasks);
    }, [initialTasks]);

    const handleDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) return;

        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        // Clone tasks array to update
        const updatedTasks = [...tasks];
        const draggedTask = updatedTasks.find(task => task.id === result.draggableId);

        if (draggedTask) {
            // Update the task status
            draggedTask.status = destination.droppableId;

            // Update the local state
            const reorderedTasks = [...updatedTasks];
            const [removed] = reorderedTasks.splice(source.index, 1);
            reorderedTasks.splice(destination.index, 0, removed);

            setTasks(reorderedTasks);

            // Update the backend
            try {
                await axiosInstance.put(`/backend/project/tasks/${draggedTask.id}`, {
                    status: draggedTask.status,
                });
                toast.success("Task status updated successfully.");
            } catch (error) {
                toast.error("Failed to update task status.");
            }
        }
    };

    const getFilteredTasks = () => {
        let filteredTasks = tasks;

        if (selectedProject) {
            filteredTasks = filteredTasks.filter(task => task.project === selectedProject.label);
        }

        if (selectedStatus) {
            filteredTasks = filteredTasks.filter(task => task.status === selectedStatus);
        }

        return filteredTasks;
    };

    const getTasksByStatus = (status) => getFilteredTasks().filter(task => task.status === status);

    const sectionColors = {
        'To Do': 'bg-blue-100 dark:bg-colorBlue',
        'In Progress': 'bg-yellow-100 dark:bg-yellow-500',
        'Done': 'bg-green-100 dark:bg-colorGreen',
    };

    const cardColors = {
        'To Do': 'bg-blue-50 dark:bg-blue-700',
        'In Progress': 'bg-yellow-50 dark:bg-yellow-700',
        'Done': 'bg-green-50 dark:bg-green-700',
    };

    const filteredTasks = getFilteredTasks();
    const hasTasks = filteredTasks.length > 0;

    return (
        <div className="relative dark:text-dark dark:bg-secondaryDark">
            <h2 className="text-xl sm:text-2xl lg:text-3xl text-gray-800 dark:text-dark dark:bg-secondaryDark mb-6 text-center">
                Project Tracking
            </h2>

            <div className="mb-2">
                <Select
                    value={selectedProject}
                    onChange={setSelectedProject}
                    options={projects}
                    placeholder="Select a project to filter tasks"
                    isClearable
                    className="w-full max-w-md mx-auto"
                    maxMenuHeight={250}
                />
            </div>

            <div className="flex flex-wrap justify-center mb-2 space-x-4">
                {['To Do', 'In Progress', 'Done'].map((status) => (
                    <button
                        key={status}
                        onClick={() => setSelectedStatus(prevStatus => (prevStatus === status ? null : status))}
                        className={`flex items-center px-3 py-1.5 text-white text-xs rounded-full font-satoshi shadow-md transition-all duration-300 transform 
            ${selectedStatus === status ? 'bg-gradient-to-r from-[#034930] to-green-600 hover:scale-105' : 'bg-gradient-to-r from-[#192C4B] to-gray-600 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 hover:scale-105'} 
            sm:px-4 sm:py-2 sm:text-sm`} // Adjust sizes for larger screens
                    >
                        {status === 'To Do' && <FaRegCheckCircle className="w-4 h-4 mr-1" />}
                        {status === 'In Progress' && <FaArrowRight className="w-4 h-4 mr-1" />}
                        {status === 'Done' && <FaCheck className="w-4 h-4 mr-1" />}
                        {status}
                    </button>
                ))}
            </div>


            {loading && (
                <div className="absolute inset-0 flex items-center justify-center gap-3 bg-gray-800 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full border-t-4 border-blue-600 w-16 h-16"></div>
                    <div className="text-white text-xl">Loading...</div>
                </div>
            )}

            {!hasTasks && (
                <div className="text-center text-lg text-gray-700 dark:text-textDark mb-4">
                    {selectedProject ? "No tasks assigned to this project." : "No tasks available."}
                </div>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
                <div className="flex flex-wrap gap-4 md:p-4 sm:p-3 xs:p-2">
                    {['To Do', 'In Progress', 'Done'].map((status) => (
                        <Droppable key={status} droppableId={status}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`p-2 rounded-md flex-1 min-w-[250px] max-w-full ${sectionColors[status]} transition-colors duration-1000 ease-in`}
                                >
                                    <h2 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2 dark:text-textDark">
                                        {status}
                                    </h2>
                                    {getTasksByStatus(status).map((task, index) => (
                                        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`p-2 mb-2 rounded-md shadow-md transition-all duration-200 transform ${cardColors[status]} hover:shadow-lg`}
                                                >
                                                    <h3 className="text-md font-bold">{task.title}</h3>
                                                    <p className="text-sm text-gray-600 dark:text-textDark">
                                                        Assigned to: {task.assignee}
                                                    </p>
                                                    <p className="text-sm text-gray-600 dark:text-textDark">
                                                        Due: {new Date(task.dueDate).toLocaleDateString()}
                                                    </p>
                                                    <p className="text-sm text-gray-600 dark:text-textDark">
                                                        Priority: {task.priority}
                                                    </p>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </div>
            </DragDropContext>
        </div>
    );
};

export default KanbanBoard;
