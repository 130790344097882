import React, { useState } from "react";
import Header from "./CommunicationHeader";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

export default function MainContent({ currentChannel, selectedEmployee }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [messages, setMessages] = useState({
    'Group 1': [
      { content: 'Welcome to Group 1!', sender: 'System', timestamp: new Date(), type: 'text' }
    ],
    'Group 2': [
      { content: 'Welcome to Group 2!', sender: 'System', timestamp: new Date(), type: 'text' }
    ],
    'Group 3': [
      { content: 'Welcome to Group 3!', sender: 'System', timestamp: new Date(), type: 'text' }
    ],
    'Employee 1': [
      { content: 'Hi there!', sender: 'Employee 1', timestamp: new Date(), type: 'text' }
    ],
    'Employee 2': [
      { content: 'Hello!', sender: 'Employee 2', timestamp: new Date(), type: 'text' }
    ]
  });

  const employees = [
    "Employee 1",
    "Employee 2",
    "Employee 3",
    "Employee 4",
    "Employee 5",
    "Employee 6",
    "Employee 7",
    "Employee 8",
  ];

  const filteredEmployees = employees.filter((name) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getCurrentChat = () => {
    const chatId = currentChannel || (selectedEmployee?.name || selectedEmployee);
    return messages[chatId] || [];
  };

  const addMessage = (text, type = 'text') => {
    const chatId = currentChannel || (selectedEmployee?.name || selectedEmployee);
    if (!chatId) return;

    setMessages(prevMessages => ({
      ...prevMessages,
      [chatId]: [
        ...(prevMessages[chatId] || []),
        { 
          content: text, 
          sender: "You", 
          timestamp: new Date(),
          type 
        }
      ]
    }));
  };

  const editMessage = (index, newText) => {
    const chatId = currentChannel || (selectedEmployee?.name || selectedEmployee);
    if (!chatId) return;

    setMessages(prevMessages => ({
      ...prevMessages,
      [chatId]: prevMessages[chatId].map((msg, i) => 
        i === index ? { ...msg, content: newText } : msg
      )
    }));
  };

  const deleteMessage = (index) => {
    const chatId = currentChannel || (selectedEmployee?.name || selectedEmployee);
    if (!chatId) return;

    setMessages(prevMessages => ({
      ...prevMessages,
      [chatId]: prevMessages[chatId].filter((_, i) => i !== index)
    }));
  };

  const handleVideoMessage = (videoUrl) => {
    addMessage(videoUrl, 'video');
  };

  const handleAudioMessage = (audioUrl) => {
    addMessage(audioUrl, 'audio');
  };

  const handleFileUpload = (fileUrl, fileName, fileType) => {
    const chatId = currentChannel || (selectedEmployee?.name || selectedEmployee);
    if (!chatId) return;

    setMessages(prevMessages => ({
      ...prevMessages,
      [chatId]: [
        ...(prevMessages[chatId] || []),
        {
          content: fileUrl,
          fileName: fileName,
          fileType: fileType,
          sender: "You",
          timestamp: new Date(),
          type: 'file'
        }
      ]
    }));
  };

  return (
    <main className="flex-grow bg-white flex flex-col">
      <Header
        employeeCount={employees.length}
        onSearch={setSearchTerm}
        currentChannel={currentChannel}
        selectedEmployee={selectedEmployee}
      />
      {(currentChannel || selectedEmployee) ? (
        <>
          <MessageList
            messages={getCurrentChat()}
            employees={filteredEmployees}
            onEditMessage={editMessage}
            onDeleteMessage={deleteMessage}
          />
          <MessageInput 
            onSendMessage={(text) => addMessage(text, 'text')}
            onVideoMessage={handleVideoMessage}
            onAudioMessage={handleAudioMessage}
            onFileUpload={handleFileUpload}
          />
        </>
      ) : (
        <div className="flex-grow flex items-center justify-center text-gray-500">
          Select a channel or employee to start messaging
        </div>
      )}
    </main>
  );
}