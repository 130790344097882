function EmployeeViewModal({ employee, onClose }) {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-gray-50 dark:bg-secondaryDark  rounded-lg p-8 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 dark:text-dark">Employee Details</h2>
        <div className="space-y-4 dark:text-dark">
          <div className="flex justify-between">
            <strong>Employee ID:</strong> <span>{employee.employeeId}</span>
          </div>
          <div className="flex justify-between">
            <strong>Full Name:</strong> <span>{employee.fullName}</span>
          </div>
          <div className="flex justify-between">
            <strong>Email:</strong> <span>{employee.email}</span>
          </div>
          <div className="flex justify-between">
            <strong>Phone Number:</strong> <span>{employee.phoneNumber}</span>
          </div>
          <div className="flex justify-between">
            <strong>Date of Joining:</strong> <span>{employee.dateOfJoining}</span>
          </div>
          {/* <div className="flex justify-between">
              <strong>Department:</strong> <span>{employee.department}</span>
            </div> */}
          <div className="flex justify-between">
            <strong>Role:</strong> <span>{employee.role}</span>
          </div>
          <div className="flex justify-between">
            <strong>Status:</strong> <span>{employee.status}</span>
          </div>
          {/* Display profile picture if available */}
          {employee.profilePicture && (
            <div className="flex justify-between">
              <strong>Profile Picture:</strong>
              <img src={URL.createObjectURL(employee.profilePicture)} alt="Profile" className="w-24 h-24 rounded" loading='lazy' />
            </div>
          )}
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmployeeViewModal;
