import React, { useMemo, useState } from 'react';

export function LeaveStatus({ employee, error, loading }) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  // State to handle selected filters
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedStatus, setSelectedStatus] = useState('');

  // List of months and recent years for the dropdowns
  const months = [
    { value: 0, label: 'January' },
    { value: 1, label: 'February' },
    { value: 2, label: 'March' },
    { value: 3, label: 'April' },
    { value: 4, label: 'May' },
    { value: 5, label: 'June' },
    { value: 6, label: 'July' },
    { value: 7, label: 'August' },
    { value: 8, label: 'September' },
    { value: 9, label: 'October' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' },
  ];
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  // Filtered leave data based on selected year, month, and status
  const { filteredLeaves, statusCounts } = useMemo(() => {
    const leaveRequests = employee.leaveRequests || [];
    const filteredLeaves = leaveRequests.filter((leave) => {
      const leaveDate = new Date(leave.startDate);
      const matchesYear = leaveDate.getFullYear() === selectedYear;
      const matchesMonth = leaveDate.getMonth() === selectedMonth;
      const matchesStatus = selectedStatus ? leave.status === selectedStatus : true;
      return matchesYear && matchesMonth && matchesStatus;
    });

    // Count leaves by status
    const statusCounts = leaveRequests.reduce(
      (acc, leave) => {
        acc[leave.status] = (acc[leave.status] || 0) + 1;
        return acc;
      },
      { Approved: 0, Rejected: 0, Pending: 0 }
    );

    return { filteredLeaves, statusCounts };
  }, [employee.leaveRequests, selectedYear, selectedMonth, selectedStatus]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-600 bg-green-100 dark:text-green-400 dark:bg-green-800';
      case 'Rejected':
        return 'text-red-600 bg-red-100 dark:text-red-400 dark:bg-red-800';
      case 'Pending':
        return 'text-yellow-600 bg-yellow-100 dark:text-yellow-400 dark:bg-yellow-800';
      default:
        return 'text-gray-600 bg-gray-100 dark:text-gray-300 dark:bg-gray-700';
    }
  };


  return (
    <div className="bg-gray-50 p-4 rounded-lg shadow-md dark:bg-secondaryDark">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-center mb-4 md:mb-0 dark:text-dark" >Leave Status</h2>

        {/* Year Filter */}
        <select
          className="p-2 border rounded-md bg-gray-100 mr-2"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        {/* Month Filter */}
        <select
          className="p-2 border rounded-md bg-gray-100 mr-2"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
        >
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>

        {/* Status Filter */}
        <select
          className="p-2 border rounded-md bg-gray-50"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>

      {/* Display leave counts with color indicators */}
      <div className="flex justify-around items-center mb-4">
        <div className="text-center">
          <span className="text-gray-700 dark:text-dark">Total Leaves: <strong>{filteredLeaves.length}</strong></span>
        </div>
        <div className="flex space-x-4">
          <span className="text-green-600">Approved: <strong>{statusCounts.Approved}</strong></span>
          <span className="text-yellow-600">Pending: <strong>{statusCounts.Pending}</strong></span>
          <span className="text-red-600">Rejected: <strong>{statusCounts.Rejected}</strong></span>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-50 rounded-lg shadow-md">
          <thead>
            <tr className="text-left bg-gray-200 text-gray-600 uppercase text-sm dark:bg-primaryDark dark:text-gray-50">
              <th className="p-4">Leave Type</th>
              <th className="p-4">Start Date</th>
              <th className="p-4">End Date</th>
              <th className="p-4">Status</th>
              <th className="p-4">Leave Reason</th>
            </tr>
          </thead>
          <tbody>
            {filteredLeaves.map((leave, index) => (
              <tr key={index} className="border-t dark:bg-secondaryDark">
                <td className="p-4">{leave.leaveType}</td>
                <td className="p-4">{leave.startDate}</td>
                <td className="p-4">{leave.endDate}</td>
                <td className={`p-4 ${getStatusColor(leave.status)} rounded-md font-semibold`}>
                  {leave.status}
                </td>
                <td className="p-4">{leave.leaveReason}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
