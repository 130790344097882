import React from 'react';

const AboutHeader = () => {
    return (
        <div className='bg-blue-50'>
            <div className="about-section py-4 mt-8 px-4 ">
                <div className="about-header text-center mb-5">
                    <div className="about-heading-wrapper flex flex-col items-center gap-3">
                        <b className="about-title text-3xl sm:text-4xl md:text-5xl font-extrabold text-colorBlue uppercase">
                            About
                        </b>
                        <div className="icon-container flex justify-center gap-4">
                            <img className="icons w-10 h-10 object-contain" alt="" src="/images/star-icons.svg" loading="lazy" />
                            <img className="icons w-10 h-10 object-contain" alt="" src="/images/frame-1.svg" loading="lazy" />
                            <img className="icons w-10 h-10 object-contain" alt="" src="/images/frame-2.svg" loading="lazy" />
                            <img className="icons w-10 h-10 object-contain" alt="" src="/images/frame-3.svg" loading="lazy" />
                            <img className="icons w-10 h-10 object-contain" alt="" src="/images/frame-4.svg" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="about-content">
                    <div className="intro-wrapper max-w-2xl mx-auto text-center">
                        <h2 className="intro-title text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 mb-6">
                            A Design Team from Pune, India
                        </h2>
                        <p className="intro-description text-base sm:text-lg md:text-xl text-gray-600 leading-relaxed">
                            Our team of experienced interior designers will transform your home or office into a beautiful, functional space that reflects your unique style.
                        </p>
                    </div>
                </div>
            </div>

            <div className="quote-container px-4 rounded-xl">
                <div className="quote-background bg-colorBlue p-8 rounded-xl max-w-4xl mx-auto text-center text-white">
                    <blockquote className="quote-text text-xl sm:text-2xl md:text-3xl font-bold leading-relaxed">
                        <span className="quote-start text-3xl sm:text-4xl md:text-5xl font-extrabold text-yellow-400">“</span>
                        We Believe & Work on
                        <br />
                        <span className="quote-highlight text-yellow-400">Creating Balance & Harmony</span>
                        <br />
                        within Living Spaces
                        <br />
                        To Make your Every Day
                        <span className="quote-end text-3xl sm:text-4xl md:text-5xl font-extrabold text-yellow-400">”</span>
                    </blockquote>
                    <div className="quote-subtext mt-6 text-lg sm:text-xl md:text-2xl italic text-gray-300">
                        <i>Prosperous & Energetic</i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutHeader;
