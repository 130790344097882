import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai'; // Add icons

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState({
    pastNotifications: [],
    todayNotifications: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch notifications from the API
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');
        const contactsData = response.data.flatMap(customer =>
          customer.contactHistories.map(history => ({
            ...history,
            customerName: customer.name,
            customerId: customer.id,
            customerEmail: customer.email,
            customerPhone: customer.phone,
          }))
        );

        console.log(contactsData);

        setNotifications(contactsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching notifications:', err);
        setError('Failed to load notifications');
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  // Filter notifications based on the current date
  useEffect(() => {
    if (notifications.length > 0) {
      const filtered = getFilteredNotifications(notifications);
      setFilteredNotifications(filtered);
    }
  }, [notifications]);

  // Utility function to filter notifications by past and today
  const getFilteredNotifications = (notifications) => {
    const today = new Date().toISOString().split('T')[0]; // Current date in 'YYYY-MM-DD' format

    const pastNotifications = notifications.filter(notification => notification.nextFollowUpDate < today);
    const todayNotifications = notifications.filter(notification => notification.nextFollowUpDate === today);

    return {
      pastNotifications,
      todayNotifications,
    };
  };

  if (loading) return <p className="text-center text-gray-500">Loading notifications...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="p-4 bg-white shadow-md rounded-lg mt-20 ">
      <h2 className="text-lg font-semibold mb-2">Today's Notifications</h2>
      {filteredNotifications.todayNotifications.length > 0 ? (
        filteredNotifications.todayNotifications.map(notification => (
          <div key={notification.id} className="flex items-center bg-green-100 border border-green-300 rounded-md p-4 mb-2 hover:shadow-lg transition-shadow">
            <AiOutlineCheckCircle className="text-green-600 mr-2" />
            <div>
              <p className="text-green-800 font-medium sm:text-sm ">Today you have to follow-Up this customer <span className='font-semibold' > {notification.customerName} </span> details <span className='font-semibold' > {notification.notes}</span>   </p>
              <span className="text-gray-500 text-sm">  {notification.nextFollowUpDate}</span>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No notifications for today.</p>
      )}

      <h2 className="text-lg font-semibold mt-6 mb-2">Previous Notifications</h2>
      {filteredNotifications.pastNotifications.length > 0 ? (
        filteredNotifications.pastNotifications.map(notification => (
          <div key={notification.id} className="flex items-center bg-red-100 border border-red-300 rounded-md p-4 mb-2 hover:shadow-lg transition-shadow">
            <AiOutlineWarning className="text-red-600 mr-2" />
            <div>
              <p className="text-red-800 font-medium sm:text-sm ">Past Notification to follow-Up this customer <span className='font-semibold' > {notification.customerName} </span> details <span className='font-semibold' > {notification.notes}</span></p>
              <span className="text-gray-500 text-sm">{notification.nextFollowUpDate}</span>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No past notifications.</p>
      )}
    </div>
  );
};

export default NotificationComponent;
