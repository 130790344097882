import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const LoginsTable = () => {
    const [logins, setLogins] = useState([]);
    const [filteredLogins, setFilteredLogins] = useState([]);
    const [filter, setFilter] = useState('ADMIN');
    const [message, setMessage] = useState('');

    // Fetch all logins from API
    useEffect(() => {
        const fetchLogins = async () => {
            try {
                const response = await axiosInstance.get('/backend/api/auth/all');
                // console.log(response.data)
                setLogins(response.data);
                setFilteredLogins(response.data.filter(login => login.role === 'Admin'));
            } catch (error) {
                console.error('Error fetching logins:', error);
            }
        };
        fetchLogins();
    }, []);

    // Update filtered logins when filter changes
    useEffect(() => {
        const updatedLogins = logins.filter(login =>
            filter === 'All' ? true : login.role === filter
        );
        setFilteredLogins(updatedLogins);
    }, [filter, logins]);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this login?')) {
            try {
                const res = await axiosInstance.delete(`/backend/api/logins/${id}`);
                // console.log(res.data)
                setLogins(logins.filter(login => login.id !== id));
                setMessage('Login deleted successfully!');
                setTimeout(() => setMessage(''), 3000);
            } catch (error) {
                console.error('Error deleting login:', error);
                setMessage('Failed to delete login. Please try again.');
            }
        }
    };

    const handleEdit = (id) => {
        // alert(`Edit functionality for login ID: ${id} (to be implemented)`);
    };

    return (
        <div className="p-4 w-full mt-16 h-[calc(100vh-4rem)] dark:bg-dark">

            <header className="mb-6 bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between gap-4">
                {/* Title Section */}
                <h1 className="text-3xl font-bold text-gray-700 dark:text-dark">
                    Logins Management
                </h1>

                {/* Filter Section */}
                <div className="w-full md:w-auto">
                    <select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="w-full md:w-auto border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200 p-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="ADMIN">Admin Logins</option>
                        <option value="CUSTOMER">Customer Logins</option>
                        <option value="EMPLOYEE">Employee Logins</option>
                        <option value="All">All Logins</option>
                    </select>
                </div>
            </header>



            {/* Success/Error Message */}
            {message && <div className="mb-4 text-green-600">{message}</div>}

            {/* Logins Table */}
            <table className="min-w-full bg-gray-50 dark:bg-secondaryDark border border-gray-200 text-sm">
                <thead className="bg-gray-200 dark:bg-primaryDark text-gray-700 dark:text-gray-50">
                    <tr>
                        <th className="px-3 py-3 border-b">#</th>
                        <th className="px-3 py-3 border-b">Name</th>
                        <th className="px-3 py-3 border-b">Email</th>
                        <th className="px-3 py-3 border-b">Username</th>
                        <th className="px-3 py-3 border-b">Password</th>
                        <th className="px-3 py-3 border-b">Role</th>
                        <th className="px-3 py-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredLogins.length > 0 ? (
                        filteredLogins.map((login, index) => (
                            <tr key={login.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                <td className="px-3 py-4 border-b text-center">{index + 1}</td>
                                <td className="px-3 py-4 border-b">
                                    {/* Check the available fields for displaying the name */}
                                    {login?.employee?.fullName ||
                                        login?.contactQuoteCustomer?.name ||
                                        login?.name ||
                                        "ADMIN"}
                                </td>
                                <td className="px-3 py-4 border-b">{login.email || "N/A"}</td>
                                <td className="px-3 py-4 border-b">{login.username || "N/A"}</td>
                                <td className="px-3 py-4 border-b">{login.details || "N/A"}</td>
                                <td className="px-3 py-4 border-b">{login.role || "N/A"}</td>
                                <td className="px-3 py-4 border-b">
                                    <button
                                        onClick={() => handleEdit(login.id)}
                                        className="bg-blue-500 text-white rounded px-3 py-1 mr-2"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(login.id)}
                                        className="bg-red-500 text-white rounded px-3 py-1"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>

                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center py-4 text-gray-500">
                                No logins found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default LoginsTable;
