import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title);

const progressData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
        {
            label: 'Project Progress',
            data: [20, 40, 60, 80, 90, 100], // Example data
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
        },
    ],
};

const ProjectDashboard = () => {
    return (
        <div className="md:p-4 sm:p-3 lg:p-4 xs:p-2 transition-colors duration-1000 ease-in">
            <div className="bg-gray-50 p-2 sm:p-3 lg:p-4 rounded-lg shadow-md dark:bg-primaryDark transition-colors duration-1000 ease-in">
                <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 dark:text-textDark mb-6 text-center">
                    Project Dashboard
                </h2>
                <div className="relative h-48 sm:h-64 lg:h-80 dark:text-textDark">
                    <Line
                        data={progressData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: { display: true },
                                tooltip: { callbacks: { label: (context) => `${context.dataset.label}: ${context.parsed.y}%` } },
                            },
                            scales: {
                                x: {
                                    title: { display: true, text: 'Month' },
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 6,
                                    },
                                },
                                y: {
                                    title: { display: true, text: 'Progress (%)' },
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 10,
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProjectDashboard;
