import React, { useState } from 'react';
import { FaUser, FaHeadphones } from 'react-icons/fa';
import { AiOutlineMore } from 'react-icons/ai';
import VideoCallModal from './VideoCallModel';
import GroupDetailsModal from './GroupDetailsModal';
import LeaveConfirmationModal from './LeaveConfirmationModal';
import GroupSettingsModal from './GroupSettingsModal';
import ViewProfileModal from './ViewProfileModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

export default function Header({ employeeCount, onSearch, currentChannel, selectedEmployee }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isVideoCallOpen, setIsVideoCallOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const groupDetails = {
    name: "Group2",
    admin: "John Doe",
    totalMembers: employeeCount,
    description: "This is kala interior project group",
    createdDate: "2024-01-01",
    inviteLink: "https://example.com/invite/group2"
  };

  const openGroupDetails = () => {
    setIsDropdownOpen(false);
    setIsModalOpen(true);
  };

  const openLeaveConfirmation = () => {
    setIsDropdownOpen(false);
    setIsLeaveModalOpen(true);
  };

  const openGroupSettings = () => {
    setIsDropdownOpen(false);
    setIsSettingsModalOpen(true);
  };

  const handleLeaveGroup = () => {
    setIsLeaveModalOpen(false);
    alert("You have left the group."); // Replace with actual leave group logic
  };
  const openDeleteConfirmation = () => {  // Function to open the delete modal
    setIsDropdownOpen(false);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConversation = () => {
    setIsDeleteModalOpen(false);
    alert("Conversation deleted."); // Replace with actual delete logic
  };

  return (
    <div className="flex flex-wrap items-center p-4 border-b border-gray-200 bg-white sm:justify-between">
      {/* Title or Channel */}
      <h2 className="text-lg ml-11 sm:text-xl font-semibold flex-grow mb-1   sm:mb-0">
        {selectedEmployee ? (
          <div className="flex items-center">
            <span className={`w-3 h-3 rounded-full mr-2 ${
              selectedEmployee.online ? 'bg-green-500' : 'bg-gray-500'
            }`} />
            {selectedEmployee.name}
          </div>
        ) : (
          <># {currentChannel}</>
        )}
      </h2>
      {!selectedEmployee && (
        <div className="flex items-center justify-center bg-purple-600 text-white text-sm font-semibold rounded-full px-3 py-1 mr-4">
          <FaUser className="mr-1" />
          {employeeCount}
        </div>
      )}
      <FaHeadphones 
        className="text-xl text-gray-600 mr-4 cursor-pointer hover:text-purple-600" 
        onClick={() => setIsVideoCallOpen(true)}
      />
      
      <input
        type="text"
        placeholder="Search kalainteriors"
        className="border border-gray-300 p-2 rounded-lg w-1/3"
        onChange={(e) => onSearch(e.target.value)}
      />
      
      <div className="relative" onBlur={() => setIsDropdownOpen(false)} tabIndex={0}>
        <AiOutlineMore
          className="ml-2 text-2xl font-bold cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
            {selectedEmployee ? (
              // Dropdown for Employee
              <ul className="py-1">
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setIsProfileModalOpen(true);
                  }}
                >
                  View Profile
                </li>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" >               
                  Star Conversation
                </li> */}
                <li 
                  className="px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer"
                  onClick={openDeleteConfirmation}  // Handle click to open the Delete modal
                >
                  Delete Conversation
                </li>
              </ul>
            ) : (
              // Dropdown for Group/Channel
              <ul className="py-1">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={openGroupDetails}>
                  Open Channel Details
                </li>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Star Channel</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={openGroupSettings}>
                  Group Settings
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer"
                  onClick={openLeaveConfirmation}
                >
                  Leave Group
                </li>
              </ul>
            )}
          </div>
        )}
      </div>     
      <GroupDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} groupDetails={groupDetails} />
      <LeaveConfirmationModal isOpen={isLeaveModalOpen} onConfirm={handleLeaveGroup} onCancel={() => setIsLeaveModalOpen(false)} />
      <GroupSettingsModal isOpen={isSettingsModalOpen} onClose={() => setIsSettingsModalOpen(false)} />
      <VideoCallModal isOpen={isVideoCallOpen} onClose={() => setIsVideoCallOpen(false)} />
      <ViewProfileModal isOpen={isProfileModalOpen} onClose={() => setIsProfileModalOpen(false)} employee={selectedEmployee} />
      <DeleteConfirmationModal 
        isOpen={isDeleteModalOpen} 
        onDelete={handleDeleteConversation} 
        onCancel={() => setIsDeleteModalOpen(false)} 
      />    
    </div>
  );
}