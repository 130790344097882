import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ThemeToggle from "../ThemeToggle";
import { IoMdNotifications } from "react-icons/io";
import axiosInstance from "../../services/AxiosInstance";
import { FaChartBar, FaFileInvoice, FaHandshake, FaMoneyBillWave, FaSignOutAlt, FaTachometerAlt, FaUserCheck, FaUserCircle } from 'react-icons/fa';


const EmployeeSidebar = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [openProfile, setOpenProfile] = useState(false);








    useEffect(() => {
        const email = localStorage.getItem("email")
        const role = localStorage.getItem("userRole")
        // console.log(role)
        const fetchLoggedInUserData = async () => {


            try {
                const response = await axiosInstance.get(`backend/api/auth/${email}`)
                // console.log(response.data)
                if (response.status === 200) {
                }

            } catch (error) {
                console.log(error)
            }
        }

        // fetchLoggedInUserData();

    }, [])







    // Toggle function to show/hide the sidebar
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // toggle profile
    const toggleProfie = () => {
        setOpenProfile(!openProfile);
    };

    const navigate = useNavigate();

    return (
        <div>
            <nav className="fixed top-0 z-50 w-full max-h-16  overflow-hidden  bg-gray-50 border-b border-gray-200 dark:bg-primaryDark dark:border-secondaryDark">
                <div className="px-3 py-2 mt-[-35px] lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start rtl:justify-end">
                            <button
                                onClick={toggleSidebar}
                                aria-controls="logo-sidebar"
                                type="button"
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            >
                                <span className="sr-only">Open sidebar</span>
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                    ></path>
                                </svg>
                            </button>
                            <NavLink
                                to="#"
                                className="flex items-center space-x-3 rtl:space-x-reverse"
                            >
                                <img
                                    src="/images/navbar-logo.png"
                                    className="h-32 object-contain "
                                    alt="Company logo"
                                    loading='lazy' 
                                />
                            </NavLink>
                        </div>

                        <div className="flex items-center">
                            <div className="flex justify-center items-center p-2 bg-gray-100 dark:bg-dark shadow-lg border rounded-lg mr-2">
                                <Link to="/admin/notification">
                                    <IoMdNotifications className="text-green-600" />
                                </Link>
                            </div>
                            <div className="flex justify-center items-center ">
                                <ThemeToggle />
                            </div>

                        </div>
                    </div>
                </div>
            </nav>

            <aside
                id="logo-sidebar"
                className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16  transition-transform bg-white border-r border-gray-200 dark:bg-primaryDark dark:border-secondaryDark ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } md:translate-x-0 lg:translate-x-0 sm:translate-x-0 `}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-50 dark:bg-primaryDark">
                    <ul className="space-y-2 font-medium">

                        {/* Attendence and leave */}
                        <li onClick={() => setIsSidebarOpen(false)} className="flex items-center cursor-pointer">
                            <NavLink
                                to="/employee/HRM-Attendence"
                                className={({ isActive }) =>
                                    `p-2 md:p-0 flex items-center gap-2 ${isActive
                                        ? "text-blue-700 font-semibold"
                                        : "text-gray-900 hover:text-blue-700 dark:text-white"
                                    }`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <FaUserCheck size={25} color={isActive ? "#007bff" : "currentColor"} />
                                        <span className="flex-1 ms-3 whitespace-nowrap">
                                            Attendance & Leaves
                                        </span>
                                    </>
                                )}
                            </NavLink>
                        </li>

                        {/* Payroll & Payslips */}
                        <li onClick={() => setIsSidebarOpen(false)} className="flex items-center cursor-pointer">
                            <NavLink
                                to="/employee/HRM-Payroll"
                                className={({ isActive }) =>
                                    `p-2 md:p-0 flex items-center gap-2 ${isActive
                                        ? "text-blue-700 font-semibold"
                                        : "text-gray-900 hover:text-blue-700 dark:text-white"
                                    }`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <FaMoneyBillWave size={25} color={isActive ? "#007bff" : "currentColor"} />
                                        <span className="flex-1 ms-3 whitespace-nowrap">
                                            Payroll & Payslips
                                        </span>
                                    </>
                                )}
                            </NavLink>
                        </li>
                        {/* Performance & Goals */}
                        <li onClick={() => setIsSidebarOpen(false)} className="flex items-center cursor-pointer">
                            <NavLink
                                to="/employee/HRM-Performance"
                                className={({ isActive }) =>
                                    `p-2 md:p-0 flex items-center gap-2 ${isActive
                                        ? "text-blue-700 font-semibold"
                                        : "text-gray-900 hover:text-blue-700 dark:text-white"
                                    }`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <FaTachometerAlt size={25} color={isActive ? "#007bff" : "currentColor"} />
                                        <span className="flex-1 ms-3 whitespace-nowrap">
                                            Performance & Goals
                                        </span>
                                    </>
                                )}
                            </NavLink>
                        </li>

                        {/* Profile */}
                        <li onClick={() => setIsSidebarOpen(false)} className="flex items-center cursor-pointer w-full">
                            <NavLink
                                to="/employee/Profile"
                                className={({ isActive }) =>
                                    `p-2 md:p-0 flex items-center gap-2 ${isActive
                                        ? "text-blue-700 font-semibold"
                                        : "text-gray-900 hover:text-blue-700 dark:text-white"
                                    }`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <FaUserCircle size={25} color={isActive ? "#007bff" : "currentColor"} />
                                        <span className="flex-1 ms-3 whitespace-nowrap">
                                            Profile
                                        </span>
                                    </>
                                )}
                            </NavLink>
                        </li>



                        <li>
                            <button
                                onClick={() => {
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("email");
                                    localStorage.removeItem("userRole")
                                    navigate("/login");
                                }}
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <FaSignOutAlt size={25} color="#dc3545" />

                                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
                            </button>
                        </li>



                        <li className="relative">
                            <div className="flex items-center ms-3">
                                <button
                                    type="button"
                                    className="flex items-center text-sm w-full rounded-full p-1.5 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                    onClick={toggleProfie}
                                >
                                    <img
                                        className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-600"
                                        src="/images/Kala_Int_PNGLogo.png"
                                        alt="user photo"
                                        loading='lazy' 
                                    />
                                    <span className="ml-3 text-gray-900 uppercase font-medium dark:text-textDark">
                                        {/* {user.username || "No Name"} */}
                                    </span>
                                </button>
                            </div>

                            {/* Profile Dropdown Menu */}
                            <div
                                className={`${openProfile ? "block" : "hidden"} z-50 absolute left-0 bottom-14 mt-3 w-60 text-base list-none bg-white dark:bg-secondaryDark rounded-lg shadow-lg`}
                                id="dropdown-user"
                            >
                                <div className="px-4 py-3 text-center border-b dark:border-primaryDark">
                                    {/* <p className="text-sm text-gray-800 font-semibold dark:text-textDark uppercase">{user.username || "No Name"}</p> */}
                                    {/* <p className="text-sm text-gray-500 dark:text-textDark">{user.email || "No Email"}</p> */}
                                </div>
                                <ul className="py-2">


                                    <li>
                                        <button
                                            onClick={() => {
                                                localStorage.removeItem("token");
                                                localStorage.removeItem("email");
                                                localStorage.removeItem("userRole");
                                                navigate("/login");
                                            }}
                                            className="block px-4 py-2 text-sm w-full text-left text-gray-700 hover:bg-gray-100 dark:text-textDark dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            Sign out
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>


                    </ul>
                </div>
            </aside >

            <div
                className=" px-1 mt-16 sm:ml-64 dark:bg-dark"
                onClick={() => setIsSidebarOpen(false) || setOpenProfile(false)}
            >
                <Outlet />

            </div>
        </div >
    );
};

export default EmployeeSidebar;
