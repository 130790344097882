import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import axiosInstance from '../../services/AxiosInstance';

export default function SupplierManagement({ products, supplier }) {
  const [suppliers, setSuppliers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Fetch suppliers from API
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/suppliers');
        console.log(response.data);
        setSuppliers(response.data);
        setFilteredSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching supplier data:', error);
      }
    };
    fetchSuppliers();
  }, []);

  // Update supplier products based on the products prop
  useEffect(() => {
    if (products.length > 0) {
      const updatedSuppliers = suppliers.map((supplier) => {
        // Filter products related to the current supplier
        const supplierProducts = products
          .filter((product) => product.supplier && product.supplier.id === supplier.id) // Match by supplier ID
          .map((product) => product.supplierProduct); // Extract the supplierProduct field from product

        // Update the supplier object with the new list of supplierProducts
        return {
          ...supplier,
          supplierProducts: supplierProducts.length > 0 ? supplierProducts : supplier.supplierProducts || [],
        };
      });

      // Update the state for suppliers and filteredSuppliers
      // setSuppliers(updatedSuppliers);
      setFilteredSuppliers(updatedSuppliers); // Update the filtered list as well
    }
  }, [products, suppliers]); // This effect depends on both products and suppliers

  useEffect(() => {
    console.log("products get ", products); // Log to check the products data
  }, [products]);


  // Add new supplier from props to the suppliers list
  useEffect(() => {
    if (supplier) {
      const updatedSuppliers = [...suppliers, ...supplier];
      setSuppliers((prev) => [...prev, supplier]);
      setFilteredSuppliers(updatedSuppliers);
    }
  }, [supplier]);


  // Handle search input change
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = suppliers.filter((supplier) => {
      return (
        supplier?.name?.toLowerCase().includes(term) ||
        supplier?.supplierProducts?.some((product) =>
          product?.supplierProduct?.toLowerCase().includes(term)
        )
      );
    });

    setFilteredSuppliers(filtered);
  };

  // Sorting function
  const sortSuppliers = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedSuppliers = [...filteredSuppliers].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredSuppliers(sortedSuppliers);
  };

  // Sort icon component
  const SortIcon = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) {
      return <FiChevronDown className="w-4 h-4 inline-block ml-1" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <FiChevronUp className="w-4 h-4 inline-block ml-1" />
    ) : (
      <FiChevronDown className="w-4 h-4 inline-block ml-1" />
    );
  };

  return (
    <div className="container mx-auto   bg-gray-50 dark:bg-secondaryDark rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 text-center">Supplier Management</h1>

      {/* Search Input */}
      <div className="mb-4">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by supplier or product name..."
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150"
        />
      </div>

      <div className="overflow-x-auto bg-gray-50 dark:bg-secondaryDark shadow-xl rounded-lg border border-gray-200">
        <table className="min-w-full">
          <thead className="bg-indigo-100 dark:bg-primaryDark dark:text-gray-50 text-gray-700 ">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer text-nowrap hover:text-indigo-600"
                onClick={() => sortSuppliers('name')}
              >
                Supplier Name <SortIcon columnKey="name" />
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                Contact
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                Email
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                Phone
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                Product Supplied
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-50 divide-y divide-gray-200 dark:bg-secondaryDark dark:text-dark text-gray-600 font-semibold">
            {filteredSuppliers.map((supplier) => (
              <tr key={supplier.id} className="dark:hover:bg-gray-500 hover:bg-gray-100 transition duration-150">
                <td className="px-6 py-4 whitespace-nowrap text-sm  ">
                  {supplier.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                  {supplier.contactPerson}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                  {supplier.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                  {supplier.contactNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                  {supplier?.supplierProducts?.length > 0 ? (
                    supplier.supplierProducts.map((product) => (
                      <p key={product.id}>{product.supplierProduct}</p>
                    ))
                  ) : (
                    <p>No products supplied</p>
                  )}

                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="hover:text-blue-500 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm bg-colorBlue text-white transition duration-150 ease-in-out">
                    <FaEdit className="w-5 h-5 mr-2" /> Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
