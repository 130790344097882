import PropTypes from "prop-types";
import { useState } from "react";
import ImageSlider from "./ImageSlider"; // Import the slider component
import styles from "./frame-component3.module.css";

const FrameComponent3 = ({ className = "" }) => {
  const [selectedDesign, setSelectedDesign] = useState("Hospital");

  // Image data for each button
  const images = {

    Hospital: [
      "/siteimages/prathmesh-hos1.png",
      "/siteimages/prathmesh-hos2.png",
      "/siteimages/prathmesh-hos3.png",
    ],
    Hotels: [
      "/siteimages/restaurant-1.jpeg",
      "/siteimages/restaurant-2.jpeg",
      "/siteimages/restaurant-4.jpeg",
      "/siteimages/restaurant-5.jpeg",
      "/siteimages/restaurant-6.jpeg",
    ],
    University: [
      "/siteimages/conferancemeetingroom.jpeg",
      "/siteimages/room-empty.jpeg",
      "/siteimages/room-w-tbl1.jpeg",
    ],
    School: [
      "/siteimages/school1.png",
      "/siteimages/school2.png",
      "/siteimages/school3.png",
    ],
    Showroom: [
      "/siteimages/shree-jwel-showroom1.png",
      "/siteimages/shree-jwel-showroom2.png",
      "/siteimages/shree-jwel-showroom1.png",
    ],
    Residential: [
     "/siteimages/house-1.jpeg",
     "/siteimages/house-2.jpeg",
     "/siteimages/house-3.jpeg",
     "/siteimages/house-4.jpeg",
     "/siteimages/house-5.jpeg",
     "/siteimages/house-6.jpeg",
     "/siteimages/house-7.jpeg",
     "/siteimages/house-8.jpeg",
     "/siteimages/house-9.jpeg",
     "/siteimages/house-10.jpeg",
     "/siteimages/house-11.jpeg",

    ],
    "Corporate offices": [
      "/siteimages/absolute-company-4.jpeg",
      "/siteimages/absolute-company1.jpeg",
      "/siteimages/absolute-company2.jpeg",
      "/siteimages/absolute-company3.jpeg",
      "/siteimages/absolute-company5.jpeg",
      "/siteimages/nacl-1.jpeg",
      "/siteimages/nacl-2.jpeg",
      "/siteimages/nacl-3.jpeg",
      "/siteimages/nacl-4.jpeg",
      "/siteimages/nacl-5.jpeg",
      "/siteimages/nacl-6.jpeg",
      "/siteimages/nacl-7.jpeg",
      "/siteimages/nacl-8.jpeg",

    ],
    Landscape: [
      "/siteimages/jsw-1.png",
      "/siteimages/jsw-2.png",
      "/siteimages/jsw-3.png",
    ],
    Bank: [
      "/siteimages/bank-1.png",
      "/siteimages/bank-2.png",
      "/siteimages/bank-3.png",
    ],
    Industrial: [
      "/siteimages/meeting-room-office.jpeg",
      "/siteimages/office-meeting1.jpeg",
      "/siteimages/meeting-room1.jpeg",
      "/siteimages/meeting-room2.jpeg",
      "/siteimages/meeting-room3.jpeg",
      "/siteimages/house-int2.jpeg",
      "/siteimages/industry1.jpeg",
    ],
    College: [
      "/siteimages/college1.png",
      "/siteimages/college2.png",
      "/siteimages/college3.png",
    ],
  };

  // Text data for each design category
  const serviceText = {
    Hospital:
      "Our hospital interior designs prioritize the creation of serene, functional, and efficient spaces that foster healing and well-being for both patients and staff. We understand the importance of hygiene and infection control, which is why we focus on using antimicrobial materials and easy-to-clean surfaces. We ensure smooth workflow integration for medical staff by incorporating modern medical technology and creating efficient pathways. From waiting areas that reduce anxiety to patient rooms that promote comfort, our hospital designs are tailored to enhance patient care while improving operational efficiency.",
    
    Hotels:
      "In hotel design, we blend luxury with practicality, creating environments that cater to both relaxation and function. Whether it’s a five-star hotel or a boutique accommodation, our designs incorporate the perfect balance of aesthetics and functionality. From grand lobbies that make an unforgettable first impression to cozy rooms that provide ultimate comfort, we ensure that every space in the hotel serves its purpose while adding to the overall guest experience. We emphasize personalized design elements that reflect the unique identity of the hotel, ensuring guests have a memorable stay.",
    
    College:
      "Our college interior designs are crafted to foster creativity, collaboration, and academic excellence. We design dynamic classrooms that support flexible learning, labs that inspire innovation, and communal spaces that encourage student engagement. With the increasing importance of technology in education, we integrate state-of-the-art equipment and multimedia facilities to create a future-proof learning environment. Additionally, we ensure that student lounges and libraries are inviting spaces where students can relax and collaborate, providing a well-rounded educational experience.",
    
    University:
      "At the university level, we create spaces that reflect a balance between tradition and innovation. Our designs cater to both the academic rigor and the cultural richness of universities. We build lecture halls that facilitate focused learning, research centers equipped with cutting-edge technology, and common areas that inspire collaboration and intellectual discourse. Beyond classrooms, we consider the entire campus experience—designing student housing, cafeterias, and recreational spaces that contribute to a vibrant university culture, ensuring the space is conducive to both study and social life.",
    
    School:
      "School design is about creating environments that nurture young minds. Our school projects are developed with safety, creativity, and growth in mind. We design classrooms that are bright, vibrant, and adaptable to different teaching styles. Playgrounds are crafted for fun and safety, while auditoriums and sports facilities encourage active participation. We prioritize flexibility in learning spaces to accommodate both group collaboration and individual focus, ensuring the environment enhances both academic and social development for students of all ages.",
    
    Showroom:
      "In retail showroom design, our focus is on creating visually stunning environments that elevate the brand and make products stand out. Strategic lighting, clean lines, and modern display techniques are key elements in our designs. We ensure the customer’s journey through the space is intuitive and engaging, allowing them to interact with the products in a way that enhances their shopping experience. Whether it’s for a fashion boutique or a tech showroom, we build layouts that not only showcase products effectively but also tell a compelling brand story.",
    
    Residential:
      "In residential design, we believe in creating spaces that are not only luxurious but also deeply personal. Each project is a reflection of the client's lifestyle, taste, and desires. From contemporary urban apartments to traditional family homes, we ensure that comfort, functionality, and aesthetics coexist harmoniously. Our designs consider every detail—be it bespoke furniture, intelligent storage solutions, or lighting that sets the mood. Whether you are looking for an elegant retreat or a cozy family home, we craft spaces that make every day feel special.",
    
    "Corporate offices":
      "Corporate office design is about creating a balance between productivity, collaboration, and employee well-being. Our office spaces are designed to foster creativity and efficiency, with flexible workstations, private focus areas, and open-plan layouts that encourage teamwork. We incorporate ergonomic furniture, advanced technology infrastructure, and spaces that cater to both formal meetings and casual interactions. From tech startups to large corporations, we understand the diverse needs of modern workspaces and design with both functionality and aesthetics in mind.",
    
    Landscape:
      "Landscape design involves transforming outdoor spaces into beautiful, functional areas that seamlessly blend with their surroundings. Whether it's an urban rooftop garden, a tranquil courtyard, or a vast estate, our landscape designs prioritize sustainability, beauty, and usability. We carefully select plants, materials, and features to create spaces that not only look stunning but are also eco-friendly and easy to maintain. Water features, outdoor seating, and lighting are often incorporated to enhance the ambiance, creating outdoor environments where people can relax and enjoy nature.",
    
    Bank:
      "In bank design, we focus on creating secure, professional, and customer-friendly environments. Our designs prioritize privacy and security while ensuring a seamless flow of operations for both staff and customers. We create layouts that incorporate modern banking technology, from ATMs to digital service stations, while maintaining a welcoming atmosphere. Customer service areas are designed to ensure comfort and discretion, while back-office spaces are optimized for efficiency and data security. Our bank designs reflect professionalism and trustworthiness, key attributes in the financial sector.",
    
    Industrial:
      "Industrial design is centered on efficiency, safety, and functionality. We create spaces that are tailored to meet the specific needs of manufacturing, logistics, and production operations. Our designs prioritize the smooth flow of materials, machinery, and personnel, ensuring that the space maximizes productivity while adhering to strict safety regulations. Whether it’s a factory floor or a large-scale warehouse, we focus on creating practical layouts that enhance operational efficiency, while also integrating modern technology and sustainability features to future-proof the facility.",
  };
  

  return (
    <section
      className={[styles.desktop1280x800Inner, className].join(" ")}
      id="service"
    >
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.headingParent}>
            <h1 className={styles.heading}>Our Services</h1>
            <h3 className={styles.text}>
              Explore a selection of our most recent projects that highlight our interior design expertise across diverse sectors. Whether it’s healthcare, education, or hospitality, each project reflects our dedication to quality and innovation. Take a closer look at how we bring spaces to life with thoughtful design solutions.
            </h3>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.buttonParent}>
            {/* Buttons to select design category */}
            {Object.keys(images).map((design) => (
              <button
                key={design}
                type="button"
                style={{
                  backgroundColor:
                    selectedDesign === design ? "#47556C" : "transparent",
                  color: selectedDesign === design ? "white" : "black",
                }}
                onClick={() => setSelectedDesign(design)}
              >
                {design}
              </button>
            ))}
          </div>

          {/* Replace the static image display with the ImageSlider component */}
          <div className={styles.frameContainer}>
            <ImageSlider images={images[selectedDesign]} isLTR={true} />{" "}
            {/* Pass selected images */}
            <div className={styles.textWrapper}>
              <h3 className={styles.text1}>
                {serviceText[selectedDesign]} {/* Display the service text */}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
