import React from 'react'
import ExecuteCard from './ExecuteCard';
import "../pages/PROJECTPAGE.css"

const ExecutionCards = () => {
    return (
        <section className="execution-cards">
            <div className="process-steps">
                <ExecuteCard
                    cardBody="Our designers will meet with you to understand your specific needs, preferences, and goals. We focus on your personal style and practical requirements, ensuring that the project aligns with your vision. During this consultation, we’ll also discuss timelines and budgets."
                    cardHeading="Initial Consultation"
                    cardNumbering="1"
                    propWidth="57px"
                    propDisplay="unset"
                    propMinWidth="unset"
                    propAlignSelf="stretch"
                    propHeight="unset"
                    propDisplay1="unset"
                />
                <ExecuteCard
                    cardBody="Based on the initial consultation, we’ll create a tailored design proposal. This includes a detailed floor plan, color schemes, furniture layout, and materials selection. We aim to blend aesthetics with functionality, ensuring that the design fits your lifestyle."
                    cardHeading="Design Proposal"
                    cardNumbering="2"
                    propWidth="unset"
                    propDisplay="inline-block"
                    propMinWidth="19px"
                    propAlignSelf="unset"
                    propHeight="unset"
                    propDisplay1="unset"
                />
                <ExecuteCard
                    cardBody="Once the design is approved, our team handles all the logistics, including sourcing materials, ordering furniture, coordinating with contractors, and overseeing the installation process. We ensure everything is completed on time and within budget, giving you peace of mind."
                    cardHeading="Project Management"
                    cardNumbering="3"
                    propWidth="unset"
                    propDisplay="inline-block"
                    propMinWidth="19px"
                    propAlignSelf="unset"
                    propHeight="unset"
                    propDisplay1="unset"
                />
            </div>
        </section>
    )
}

export default ExecutionCards;
