import { X } from "lucide-react";
import React from "react";

const PurchaseOrderInvoice = ({ orderInvoice, close }) => {
    const formatDate = (dateString) =>
        new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
            new Date(dateString)
        );



    const handlePrint = () => {
        // Create a new window
        const printWindow = window.open("", "_blank");

        // Prepare the invoice content
        const content = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Invoice</title>
                <style>
                    body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
                    .container { max-width: 800px; margin: auto; padding: 20px; border: 1px solid #ddd; }
                    .header { text-align: center; background: #4a4e69; color: #fff; padding: 20px; }
                    .header h1 { margin: 0; font-size: 24px; }
                    .header p { margin: 5px 0; font-size: 14px; }
                    table { width: 100%; border-collapse: collapse; margin: 20px 0; }
                    table, th, td { border: 1px solid #ddd; }
                    th, td { padding: 10px; text-align: left; }
                    .summary { margin-top: 20px; }
                    .summary div { display: flex; justify-content: space-between; margin-bottom: 10px; }
                    .footer { text-align: center; margin-top: 30px; font-size: 12px; color: #555; }
                </style>
            </head>
            <body>
                <div class="container">
                    <div class="header">
                        <h1>Kala Interiors</h1>
                        <p>Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, Maharashtra 411026</p>
                    </div>
                    <div>
                        <p><strong>Created At:</strong> ${formatDate(orderInvoice.createdAt)}</p>
                        <p><strong>Supplier:</strong> ${orderInvoice.supplier?.name || "N/A"}</p>
                        <p><strong>Expected Delivery:</strong> ${formatDate(orderInvoice.expectedDeliveryDate)}</p>
                        <p><strong>Status:</strong> ${orderInvoice.status}</p>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${orderInvoice.purchaseOrderProducts
                .map(
                    (product, index) => `
                                <tr>
                                    <td>${index + 1}</td>
                                    <td>${product.product?.supplierProduct}</td>
                                    <td>${product.quantity}</td>
                                    <td>${product.unitPrice}</td>
                                    <td>${product.quantity * product.unitPrice}</td>
                                </tr>
                            `
                )
                .join("")}
                        </tbody>
                    </table>
                    <div class="summary">
                        <div><strong>Total:</strong> ${orderInvoice.totalPrice}</div>
                        <div><strong>Tax:</strong> 0</div>
                        <div><strong>Grand Total:</strong> ${orderInvoice.totalPrice}</div>
                    </div>
                    <div class="footer">
                        <p>Thank you for your business!</p>
                    </div>
                </div>
            </body>
            </html>
            `;

        // Write content to the new window
        printWindow.document.write(content);
        printWindow.document.close();

        // Trigger the print dialog
        printWindow.print();
    };


    return (
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg print:border print:border-gray-300">
            {/* Header: Company Name and Close Button */}
            <div className="bg-gradient-to-r from-primaryDark to-secondaryDark text-white p-4 rounded-t-md flex justify-between items-center">
                <div>
                    <h1 className="text-2xl font-bold text-center text-blue-400">
                        Kala Interiors
                    </h1>
                    <p className="text-sm text-center">
                        Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar,
                        Bhosari, Pune, Maharashtra 411026
                    </p>
                </div>
                <button className="cursor-pointer text-white" onClick={close}>
                    <X />
                </button>
            </div>

            {/* Main Content */}
            <div className="bg-white p-6 rounded-b-md">
                {/* Order Information */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <p className="text-gray-700 font-medium">Created At:</p>
                        <p className="mt-1 text-gray-900 font-semibold">
                            {formatDate(orderInvoice.createdAt)}
                        </p>
                    </div>
                    <div>
                        <p className="text-gray-700 font-medium">Supplier:</p>
                        <p className="mt-1 text-gray-900 font-semibold">
                            {orderInvoice.supplier?.name || "N/A"}
                        </p>
                    </div>
                    <div>
                        <p className="text-gray-700 font-medium">Expected Delivery:</p>
                        <p className="mt-1 text-gray-900 font-semibold">
                            {formatDate(orderInvoice.expectedDeliveryDate)}
                        </p>
                    </div>
                    <div>
                        <p className="text-gray-700 font-medium">Status:</p>
                        <p className="mt-1 text-gray-900 font-semibold">
                            {orderInvoice.status}
                        </p>
                    </div>
                </div>

                {/* Product Table */}
                <table className="w-full mb-6 text-left">
                    <thead className="bg-gray-200 text-gray-700">
                        <tr>
                            <th className="py-2 px-3">#</th>
                            <th className="py-2 px-3">Product</th>
                            <th className="py-2 px-3 text-right">Quantity</th>
                            <th className="py-2 px-3 text-right">Unit Price</th>
                            <th className="py-2 px-3 text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderInvoice.purchaseOrderProducts.map((product, index) => (
                            <tr key={product.id} className="border-t">
                                <td className="py-3 px-3">{index + 1}</td>
                                <td className="py-3 px-3">{product.product?.supplierProduct}</td>
                                <td className="py-3 px-3 text-right">{product.quantity}</td>
                                <td className="py-3 px-3 text-right">{product.unitPrice}</td>
                                <td className="py-3 px-3 text-right">
                                    {product.quantity * product.unitPrice}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pricing Details */}
                <div className="mb-6">
                    <div className="flex justify-between text-gray-700 mb-2">
                        <p className="font-medium">Total:</p>
                        <span className="font-semibold">{orderInvoice.totalPrice}</span>
                    </div>
                    <div className="flex justify-between text-gray-700">
                        <p className="font-medium">Tax:</p>
                        <span className="font-semibold">0</span>
                    </div>
                    <div className="flex justify-between text-gray-900 font-bold border-t pt-2">
                        <p>Grand Total:</p>
                        <span>{orderInvoice.totalPrice}</span>
                    </div>
                </div>

                {/* Print Button */}
                <div className="text-right">
                    <button
                        className="bg-primaryDark text-white px-6 py-2 rounded-md shadow-md hover:bg-secondaryDark transition duration-150"
                        onClick={handlePrint}

                    >
                        Print Invoice
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrderInvoice;
