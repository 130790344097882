import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';

const SalesPipeline = ({ newLead, error, customers, loading }) => {
  const [leadName, setLeadName] = useState('');
  const [status, setStatus] = useState('New');
  const [projectType, setProjectType] = useState('')
  const [priceRange, setPriceRange] = useState('')
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [customerSelect, setCustomerSelect] = useState([])



  useEffect(() => {

    setCustomerSelect(customers.map(customer => ({ value: customer.id, label: customer.name })));

  }, [customers])



  const handleAddLead = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    // Clear messages
    setSuccessMessage('');
    setErrorMessage('');
    console.log(leadName);

    try {
      const response = await axiosInstance.post(`/backend/api/leads/${leadName}`, {
        status,
        projectType,
        priceRange
      });

      // Check if response is successful (usually 2xx status codes)
      if (response.status === (201 || 200)) {
        setSuccessMessage('Lead added successfully!');
        newLead(response.data);
      }

      // Reset the form fields
      setLeadName('');
      setStatus('New');
    } catch (error) {
      // Set the error message if the request fails
      setErrorMessage('Error adding lead. Please try again.');
    } finally {
      setFormLoading(false);
    }
  };


  const handleCustomerChange = (selectedCustomer) => {
    setLeadName(selectedCustomer.value);
  }

  return (
    <form className="p-4 md:p-6 w-full bg-gray-50 rounded-lg shadow-lg dark:bg-secondaryDark dark:text-dark" onSubmit={handleAddLead}>
      <h2 className="text-lg md:text-xl font-semibold mb-4 text-center">ADD LEAD</h2>

      {/* Success and Error Messages */}
      {successMessage && <div className="text-green-600 mb-4">{successMessage}</div>}
      {errorMessage && <div className="text-red-600 mb-4">{errorMessage}</div>}
      {error && <div className="text-red-600 mb-4">{error}</div>}

      {/* Lead Name Input */}
      <div className="mb-4"> 
        <label className="block text-sm md:text-md font-medium text-gray-700 dark:text-dark">
          Lead Name:

          <Select
            id='leadName'
            options={customerSelect}
            onChange={handleCustomerChange}
            placeholder="Select customers"
            isSearchable
            menuPlacement='auto'
            isLoading={loading}
            isDisabled={formLoading}

          />

        </label>
      </div>

      <div className="mb-2">
        <label htmlFor="project-type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-dark">
          Choose Interior Project Type
        </label>
        <select
          id="project-type"
          name="projectType"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
          value={projectType}
          onChange={(e) => setProjectType(e.target.value)}
        >
          <option value="" disabled>
            Select project type
          </option>
          <option value="Hospital">Hospital</option>
          <option value="Hotels">Hotels</option>
          <option value="Residential">Residential</option>
          <option value="Corporate offices">Corporate offices</option>
          <option value="College">College</option>
          <option value="University">University</option>
          <option value="School">School</option>
          <option value="Showroom">Showroom</option>
          <option value="Landscape">Landscape</option>
          <option value="Bank">Bank</option>
          <option value="Industrial">Industrial</option>
          <option value="Other">Other</option>
        </select>
      </div>


      {/* Price Range */}
      <div className='mb-2' >
        <label className="block text-left mb-1 text-sm font-medium text-gray-700 dark:text-dark">
          Estimated Budget
        </label>
        <select
          name="priceRange"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
          value={priceRange}
          onChange={(e) => setPriceRange(e.target.value)}
          required
        >
          <option value="" disabled>
            Select Price Range
          </option>
          <option value="under-1-lakh">₹1 Lakh or less</option>
          <option value="1-3-lakh">₹1-3 Lakh</option>
          <option value="3-5-lakh">₹3-5 Lakh</option>
          <option value="over-5-lakh">₹5 Lakh or more</option>
        </select>
      </div>

      {/* Status Select */}
      <div className="mb-4">
        <label className="block text-sm md:text-md font-medium text-gray-700 dark:text-dark">
          Select Status:
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          >
            <option value="New">New</option>
            <option value="Contacted">Contacted</option>
            <option value="Qualified">Qualified</option>
            <option value="Negotiation">Negotiation</option>
            <option value="Won">Won</option>
            <option value="Lost">Lost</option>
          </select>
        </label>
      </div>




      <button disabled={formLoading} type="submit" className="text-white  bg-colorBlue w-full  hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg  px-5 py-2.5 text-center me-2  dark:focus:ring-blue-800 inline-flex items-center justify-center text-sm ">
        {formLoading ? <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
        </svg> : ""}
        {formLoading ? "Loading..." : "Add Lead"}
      </button>

    </form>
  );
};

export default SalesPipeline;
