import React from 'react';
import { FiBold, FiItalic } from 'react-icons/fi';

export default function FormattingButtons() {
  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
  };

  const handleItalicClick = () => {
    document.execCommand('italic', false, null);
  };

  return (
    <div>
      <button className="text-gray-500 hover:text-gray-800" onClick={handleBoldClick}>
        <FiBold size={20} />
      </button>
      <button className="text-gray-500 hover:text-gray-800 px-1" onClick={handleItalicClick}>
        <FiItalic size={20} />
      </button>
    </div>
  );
}