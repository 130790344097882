import React, { useState, useEffect } from "react";
import MoodBoardGrid from "./MoodBoardGrid";
import CreateMoodBoardModal from "./CreateMoodBoardModal";
import EditMoodBoardModal from "./EditMoodBoardModal";

function MoodBoardManagement() {
  const [moodBoards, setMoodBoards] = useState([]); // State to store mood boards
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingBoard, setEditingBoard] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query

  useEffect(() => {
    // Load mood boards from localStorage
    const storedBoards = JSON.parse(localStorage.getItem("moodBoards"));
    if (storedBoards) {
      setMoodBoards(storedBoards);
    }
  }, []);

  const handleCreateMoodBoard = (newBoard) => {
    const updatedBoards = [...moodBoards, newBoard];
    setMoodBoards(updatedBoards);
    localStorage.setItem("moodBoards", JSON.stringify(updatedBoards));
    setShowModal(false);
  };

  const handleEditMoodBoard = (updatedBoard) => {
    const updatedBoards = moodBoards.map((board) =>
      board.id === updatedBoard.id ? updatedBoard : board
    );
    setMoodBoards(updatedBoards);
    localStorage.setItem("moodBoards", JSON.stringify(updatedBoards));
    setShowEditModal(false);
  };

  const handleDeleteMoodBoard = (id) => {
    const updatedBoards = moodBoards.filter((board) => board.id !== id);
    setMoodBoards(updatedBoards);
    localStorage.setItem("moodBoards", JSON.stringify(updatedBoards));
  };

  // Filter the mood boards based on the search query
  const filteredBoards = moodBoards.filter((board) =>
    board.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    board.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <header className="flex justify-between items-center mb-6">
        <button
          onClick={() => setShowModal(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Create Mood Board
        </button>
        <input
          type="text"
          placeholder="Search mood boards..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border rounded px-4 py-2 w-1/3"
        />
      </header>

      <main>
        <MoodBoardGrid
          moodBoards={filteredBoards} // Display filtered boards
          onEdit={(board) => {
            setEditingBoard(board);
            setShowEditModal(true);
          }}
          onDelete={handleDeleteMoodBoard}
        />
      </main>

      {showModal && (
        <CreateMoodBoardModal
          onClose={() => setShowModal(false)}
          onCreate={handleCreateMoodBoard}
        />
      )}

      {showEditModal && (
        <EditMoodBoardModal
          moodBoard={editingBoard}
          onClose={() => setShowEditModal(false)}
          onEdit={handleEditMoodBoard}
        />
      )}
    </div>
  );
}

export default MoodBoardManagement;
