import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axiosInstance from '../../services/AxiosInstance';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DealsKanbanBoard = ({ leads, error, loading, updateStatus }) => {
  const [deals, setDeals] = useState({
    activeContactedDeals: [],
    negotiationDeals: [],
    closedDeals: [],
    lostDeals: [],
    wonDeals: [],
  });

  useEffect(() => {
    const categorizedDeals = {
      activeContactedDeals: [],
      negotiationDeals: [],
      closedDeals: [],
      lostDeals: [],
      wonDeals: [],
    };

    leads.forEach((lead) => {
      if (lead.status === 'New' || lead.status === 'Contacted') {
        categorizedDeals.activeContactedDeals.push(lead);
      } else if (lead.status === 'Negotiation') {
        categorizedDeals.negotiationDeals.push(lead);
      } else if (lead.status === 'Lost') {
        categorizedDeals.lostDeals.push(lead);
      } else if (lead.status === 'Won') {
        categorizedDeals.wonDeals.push(lead);
      }
    });

    setDeals(categorizedDeals);
  }, [leads]);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }

    const sourceColumn = Array.from(deals[source.droppableId]);
    const destinationColumn = Array.from(deals[destination.droppableId]);
    const [movedDeal] = sourceColumn.splice(source.index, 1);
    movedDeal.dateUpdated = new Date().toLocaleString();

    const statusMap = {
      activeContactedDeals: 'Contacted',
      negotiationDeals: 'Negotiation',
      closedDeals: 'Closed',
      lostDeals: 'Lost',
      wonDeals: 'Won',
    };

    const newStatus = statusMap[destination.droppableId];
    movedDeal.status = newStatus;
    destinationColumn.splice(destination.index, 0, movedDeal);

    setDeals({
      ...deals,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destinationColumn,
    });

    try {
      const response = await axiosInstance.put(`/backend/api/leads/${movedDeal.id}`, {
        ...leads,
        status: newStatus,
      });
      updateStatus(response.data.id, response.data.status);
      toast.success(`Deal moved to ${newStatus} successfully!`);
    } catch (error) {
      console.error('Error updating deal status:', error);
      toast.error('Failed to update deal status.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
        <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
        <p className='dark:text-secondaryDark' >Loading Deals</p>
      </div>
    )
  }

  return (
    <div className="w-full mx-auto p-2">
      <ToastContainer />
      <h1 className="text-3xl text-center font-bold text-gray-700 mb-6 dark:text-secondaryDark">Deal Tracking</h1>
      {error && <div className="text-red-600 mb-4">{error}</div>}

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="flex flex-row gap-4 overflow-x-auto">
          {/* Active and Contacted Deals */}
          <Droppable droppableId="activeContactedDeals">
            {(provided) => (
              <div
                className="bg-blue-100 p-4 rounded min-w-[250px] flex-shrink-0 max-w-xs shadow-lg"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <h2 className="text-xl font-semibold text-blue-700 mb-4">Active / Contacted Deals</h2>
                {(deals.activeContactedDeals || []).map((deal, index) => (
                  <Draggable key={deal.id} draggableId={deal.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="bg-white p-3 mb-3 rounded shadow-md hover:shadow-lg transition cursor-pointer"
                      >
                        <div className="text-lg font-medium text-gray-800">{deal.leadName}</div>
                        <div className="text-xs text-gray-500">Status: {deal.status}</div>
                        <div className="text-xs text-gray-500">Last updated: {deal.dateUpdated}</div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {/* Other Deal Categories */}
          {['negotiationDeals', 'lostDeals', 'wonDeals'].map((category) => (
            <Droppable droppableId={category} key={category}>
              {(provided) => (
                <div
                  className={`p-4 rounded min-w-[250px] flex-shrink-0 max-w-xs shadow-lg ${category === 'negotiationDeals' ? 'bg-yellow-100' :
                    category === 'lostDeals' ? 'bg-red-100' : 'bg-green-100'
                    }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <h2 className={`text-xl font-semibold ${category === 'negotiationDeals' ? 'text-yellow-700' :
                    category === 'lostDeals' ? 'text-red-700' : 'text-green-700'
                    } mb-4`}>
                    {category.replace('Deals', ' Deals')}
                  </h2>
                  {(deals[category] || []).map((deal, index) => (
                    <Draggable key={deal.id} draggableId={deal.id.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white p-3 mb-3 rounded shadow-md hover:shadow-lg transition cursor-pointer"
                        >
                          <div className="text-lg font-medium text-gray-800">{deal.leadName}</div>
                          <div className="text-xs text-gray-500">Status: {deal.status}</div>
                          <div className="text-xs text-gray-500">Last updated: {deal.dateUpdated}</div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default DealsKanbanBoard;
