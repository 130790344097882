import { useState, useEffect } from "react";
import Select from "react-select";
import axiosInstance from "../../services/AxiosInstance";

function CreateInvoiceForm({ customer, loadings, error, refreshCustomers }) {
  const [invoiceNumber, setInvoiceNumber] = useState(
    `INV${Math.floor(1000 + Math.random() * 9000)}`
  );

  const todayDate = new Date().toLocaleDateString();
  const [clientName, setClientName] = useState(""); // For Select
  const [issueDate, setIssueDate] = useState(todayDate);
  const [dueDate, setDueDate] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("Pending")
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    const newInvoice = {
      invoiceNumber,
      // clientId: customers.value, // Send the selected customer ID
      invoiceDate: issueDate,
      dueDate,
      totalAmount: parseFloat(amount),
      description,
      status
        };

    try {
      const response = await axiosInstance.post(`/backend/api/invoices/${clientName.value}`, newInvoice);

      if (response.status === 200 || response.status === 201) {
        refreshCustomers();
        setMessage({ type: "success", text: "Invoice created successfully!" });

      } else {
        setMessage({ type: "error", text: "Error creating invoice. Please try again. else" })
      }

      // Reset the form after success
      setInvoiceNumber(`INV${Math.floor(1000 + Math.random() * 9000)}`);
      setClientName("");
      setIssueDate("");
      setDueDate("");
      setAmount("");
      setDescription("");
    } catch (error) {
      setMessage({ type: "error", text: "Error creating invoice. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 dark:bg-secondaryDark p-6 rounded-lg shadow-lg max-w-md w-full">
      <div className="flex justify-center items-center mb-4">
        <h2 className="text-xl font-black">Create Invoice</h2>
      </div>
      {/* Display message if it exists */}
      {message && (
        <div
          className={`mt-4 p-3 rounded ${message.type === "success"
            ? "bg-green-100 text-green-800"
            : "bg-red-100 text-red-800"
            }`}
        >
          {message.text}
        </div>
      )}

      {error && (
        <p>{error}</p>
      )}
      <form onSubmit={handleSubmit} className="z-0 w-full  text-gray-700 dark:text-dark">
        <div className="mb-4">
          <label className="block mb-1  text-left">
            Invoice Number
          </label>
          <input
            type="text"
            value={invoiceNumber}
            readOnly
            className="w-full border px-3 py-2 rounded-lg bg-gray-100 transition-transform duration-300 ease-in-out transform hover:scale-105"
          />
        </div>

        <div className="mb-4 z-50">
          <label className="block mb-1  text-left">
            Customer Name
          </label>
          <Select
            value={clientName}
            onChange={(selectedOption) => setClientName(selectedOption)}
            options={customer.map((data) => ({ value: data.id, label: data.name }))}
            isLoading={customer.length === 0}
            isClearable
            isSearchable
            menuPlacement="auto"
            maxMenuHeight={150}
            className="mt-1"
            placeholder="Select a client"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1  text-left">Issue Date</label>
          <input
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
            className="w-full border px-3 py-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1  text-left">Due Date</label>
          <input
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="w-full border px-3 py-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
            min={issueDate}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1  text-left">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full border px-3 py-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
            placeholder="0.00"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1  text-left">Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full border px-3 py-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
          >
            <option value="Pending">Pending</option>
            <option value="Paid">Paid</option>
          </select>
        </div>


        <div className="mb-4">
          <label className="block mb-1  text-left">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full border px-3 py-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
            rows="3"
            placeholder="Additional details"
          />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-colorBlue hover:bg-blue-500 text-white px-4 py-2 rounded-lg dark:bg-dark dark:hover:bg-primaryDark "
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Invoice"}
          </button>
        </div>
      </form>


    </div>
  );
}

export default CreateInvoiceForm;
