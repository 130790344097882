import React, { useState } from 'react';
import ProjectDashboard from './ProjectDashboard';
import CircularProgressIndicator from './CircularProgressIndicator';
import ProgressReports from './ProgressReports';

const ProjectStatusMonitoring = () => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const completionPercentage = 75; // Example value

    return (
        <div className="w-full transition-colors duration-1000 ease-in ">
            <div className="container mx-auto md:p-4  shadow-xl rounded-lg max-w-full">
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-blue-600 mb-4 dark:text-textDark">
                    Project Status Monitoring
                </h2>

                {/* Tab Buttons */}
                <ul className="flex gap-1 sm:gap-2 p-1 m-1 bg-blue-500 rounded-full shadow-sm dark:bg-primaryDark transition-colors duration-1000 ease-in">
                    <li className="flex-1">
                        <button
                            className={`w-full py-1 sm:py-2 px-2 sm:px-4 text-sm sm:text-base font-semibold rounded-full transition-all duration-300 ease-in-out 
                            ${activeTab === 'dashboard' ? 'bg-gray-50 text-blue-500 dark:bg-secondaryDark dark:text-textDark transition-colors duration-1000 ease-in' : 'bg-transparent text-white hover:bg-blue-600'}`}
                            onClick={() => setActiveTab('dashboard')}
                        >
                            Project Dashboard
                        </button>
                    </li>
                    <li className="flex-1">
                        <button
                            className={`w-full py-1 sm:py-2 px-2 sm:px-4 text-sm sm:text-base font-semibold rounded-full transition-all duration-300 ease-in-out 
                            ${activeTab === 'reports' ? 'bg-gray-50 text-blue-500 dark:bg-secondaryDark dark:text-textDark transition-colors duration-1000 ease-in' : 'bg-transparent text-white hover:bg-blue-600'}`}
                            onClick={() => setActiveTab('reports')}
                        >
                            Progress Reports
                        </button>
                    </li>
                </ul>

                {/* Components */}
                <div className="md:mx-2 my-2 lg:p-8 bg-gray-50 dark:bg-secondaryDark shadow-inner rounded-lg transition-colors duration-1000 ease-in">
                    {activeTab === 'dashboard' && (
                        <div>
                            <ProjectDashboard />
                            <CircularProgressIndicator progress={completionPercentage} />
                        </div>
                    )}
                    {activeTab === 'reports' && <ProgressReports />}
                </div>
            </div>
        </div>
    );
};

export default ProjectStatusMonitoring;
