import React from 'react'
import FollowUpReminders from './FollowUpReminders'
import ContactHistoryTable from './ContactHistoryTable'

const FollowUpLayout = () => {
    return (
        <div className="flex justify-center items-start h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl"> */}
                {/* Left side - Sales Pipeline Component */}
                {/* <FollowUpReminders /> */}

                {/* Right side - Kanban Board and Deal Table */}
                {/* <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                </div> */}
                    {/* <DealsKanbanBoard /> */}
                    {/* <DealTable /> */}
            {/* </div> */}

            <ContactHistoryTable/>
        </div>
    )
}

export default FollowUpLayout