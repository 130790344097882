import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axiosInstance from '../services/AxiosInstance';

const GetQuoteModal = ({ open, onCloseQuote }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        projectType: '',
        priceRange: '',
        description: '',
        gst: '',
        quote:true
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Disable body scrolling when modal is open
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [open]);

    const handleCloseQuote = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            projectType: '',
            priceRange: '',
            description: '',
            address: '',
            gst: '',
        });
        setError(null);
        onCloseQuote();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.post('/backend/api/contact/customer/save', formData);
            console.log(response);

            // Show SweetAlert success message
            Swal.fire({
                title: 'Success!',
                text: 'Your quote request has been submitted successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });

            handleCloseQuote();
        } catch (err) {
            console.error('Error submitting quote:', err);
            setError('There was an issue submitting your request.');

            // Show SweetAlert error message
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue submitting your request. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {open && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
                    <div className="relative p-4 max-h-[90vh] overflow-y-auto bg-white rounded-lg shadow w-full max-w-lg md:max-w-xl lg:max-w-2xl">
                        {/* Close Button */}
                        <button
                            onClick={handleCloseQuote}
                            className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            aria-label="Close modal"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                        {/* Modal Header */}
                        <div className="flex justify-center p-4 md:p-5 border-b rounded-t">
                            <h2 className="text-lg font-semibold text-gray-900 text-center">Get a Free Quote</h2>
                        </div>

                        {/* Form */}
                        <form onSubmit={handleSubmit} className="p-4 md:p-5 space-y-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {/* Name */}
                                <div>
                                    <label className="block text-left text-sm font-medium mb-1 text-gray-700">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Your name"
                                        required
                                    />
                                </div>

                                {/* Email */}
                                <div>
                                    <label className="block text-left text-sm font-medium mb-1 text-gray-700">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="your-email@gmail.com"
                                        required
                                    />
                                </div>

                                {/* Phone */}
                                <div>
                                    <label className="block text-left text-sm font-medium mb-1 text-gray-700">
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="123-456-7890"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-left text-sm font-medium mb-1 text-gray-700">
                                        GST Number
                                    </label>
                                    <input
                                        type="text"
                                        name="gst"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.gst}
                                        onChange={handleChange}
                                        placeholder="yout GST No."
                                        required
                                    />
                                </div>

                                {/* Project Type */}
                                <div>
                                    <label className="block text-left text-sm font-medium mb-1 text-gray-700">
                                        Project Type
                                    </label>
                                    <select
                                        name="projectType"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.projectType}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>
                                            Select project type
                                        </option>
                                        <option value="Hospital">Hospital</option>
                                        <option value="Hotels">Hotels</option>
                                        <option value="College">College</option>
                                        <option value="University">University</option>
                                        <option value="School">School</option>
                                        <option value="Showroom">Showroom</option>
                                        <option value="Residential">Residential</option>
                                        <option value="Corporate offices">Corporate offices</option>
                                        <option value="Landscape">Landscape</option>
                                        <option value="Bank">Bank</option>
                                        <option value="Industrial">Industrial</option>
                                    </select>
                                </div>

                                {/* Price Range */}
                                <div>
                                    <label className="block text-left mb-1 text-sm font-medium text-gray-700">
                                        Estimated Budget
                                    </label>
                                    <select
                                        name="priceRange"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.priceRange}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Price Range
                                        </option>
                                        <option value="under-1-lakh">₹1 Lakh or less</option>
                                        <option value="1-3-lakh">₹1-3 Lakh</option>
                                        <option value="3-5-lakh">₹3-5 Lakh</option>
                                        <option value="over-5-lakh">₹5 Lakh or more</option>
                                    </select>
                                </div>

                                <div className="">
                                    <label htmlFor="description" className=" select-none block mb-2 text-sm font-medium text-gray-900">
                                        Address
                                    </label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Write your project description here"
                                        value={formData.address}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>

                                {/* Description */}
                                <div className="">
                                    <label className="block text-left mb-1 text-sm font-medium text-gray-700">
                                        Project Description
                                    </label>
                                    <textarea
                                        name="description"
                                        rows="4"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.description}
                                        placeholder="Add description"
                                        onChange={handleChange}
                                    ></textarea>
                                </div>

                                {/* Submit Button */}
                                <button
                                    type="submit"
                                    className="sm:col-span-2 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GetQuoteModal;
