import React from 'react';

export default function ViewProfileModal({ isOpen, onClose, employee }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="text-center p-6 w-80 relative bg-white rounded-lg">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        {/* Profile Icon */}
        <div className="w-20 h-20 bg-purple-600 rounded-full mx-auto mb-4 flex items-center justify-center text-white text-2xl">
          {employee?.name[0]}
        </div>
        {/* Profile Information */}
        <h2 className="text-xl font-bold">{employee?.name}</h2>
        <p className={`text-${employee?.online ? 'green' : 'gray'}-500`}>
          {employee?.online ? 'Active' : 'Offline'}
        </p>
        <p className="text-gray-500">{new Date().toLocaleTimeString()}</p>
        {/* Set Status Button */}
        <button className="mt-4 bg-gray-200 px-4 py-2 rounded hover:bg-gray-300">
          Set a status
        </button>
        {/* Contact Information */}
        <div className="mt-6 border-t pt-4 text-center">
          <h3 className="font-bold mb-2">Contact Information</h3>
          <p>Email: {employee?.email || 'N/A'}</p>
        </div>
      </div>
    </div>
  );
}
