import React, { useState } from 'react'

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosInstance.post('/backend/api/auth/forgot-password', { email });
            if (response.data) {
                setErrorMessage('');
                setSuccessMessage('Reset link sent to your email!');
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Failed to send reset link. Please try again.');
        }
    };
    return (
        <div className="container-fluid d-flex align-items-center justify-content-center pb-3"
            style={{
                backgroundImage: 'url(.././images/loginkala-bg.jpeg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh'
            }}>
            <div style={{ width: '400px' }}>
                <div className="card shadow-lg bg-white text-black align-items-center mt-3">
                    <div style={{ width: '-webkit-fill-available' }}>
                        <h3 className="card-title text-center mb-2 fw-bold fs-4">
                            FORGOT PASSWORD
                        </h3>
                        <p className="text-muted text-center fs-6">
                            Enter your email to reset your password
                        </p>

                        {errorMessage && <div className="alert alert-danger fs-6 mt-2">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success fs-6 mt-2">{successMessage}</div>}

                        <form onSubmit={handleForgotPassword}>
                            <div className="mb-2">
                                <label htmlFor="email" className="fs-6 fw-bold form-label">Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-dark mt-2">
                                    Send Reset Link
                                </button>
                            </div>
                        </form>

                        <div className="text-center mt-3">
                            <p className="text-muted fs-6">
                                <button onClick={() => window.history.back()} className="btn btn-link p-0">
                                    Back to Login
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword