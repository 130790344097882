import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';

const CustomerTable = ({ customer }) => {
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [customersPerPage] = useState(10); // Adjust number of items per page as needed

    // Fetch customers from API
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axiosInstance.get('/backend/api/contact/customer');
                setCustomers(response.data); 
            } catch (error) {
                console.error('Error fetching customers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (customer && customer.length > 0) {
            setCustomers(prev => [...prev, ...customer])
        }
    }, [customer]);

    // Handle sorting
    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);

        const sortedCustomers = [...customers].sort((a, b) => {
            if (order === 'asc') {
                return a[field] > b[field] ? 1 : -1;
            } else {
                return a[field] < b[field] ? 1 : -1;
            }
        });
        setCustomers(sortedCustomers);
    };

    // Handle search
    const filteredCustomers = Array.isArray(customers) ? customers.filter((customer) =>
        customer.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.gst?.toLowerCase().includes(searchTerm.toLowerCase()) 

    ) : [];

    // Pagination logic
    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

    const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="p-6 bg-gray-50 dark:bg-secondaryDark dark:text-dark shadow-md rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-center dark:to-dark">Customer Management</h2>

            {/* Search Input */}
            <div className="mb-4">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search customers by name, email, phone, or address"
                    className="p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="overflow-x-auto">

                {/* Table */}
                <table className="min-w-full table-auto bg-gray-50 dark:bg-secondaryDark dark:text-dark shadow-md rounded-md">
                    <thead>
                        <tr className="bg-gray-100 text-left text-nowrap select-none dark:bg-primaryDark dark:text-gray-50 ">
                            <th className="px-4 py-2 cursor-pointer" >
                                #
                            </th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('customerName')}>
                                Customer Name {sortField === 'customerName' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('email')}>
                                Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('phone')}>
                                Phone {sortField === 'phone' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('address')}>
                                Address {sortField === 'address' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('gst')}>
                                GST No. {sortField === 'gst' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2">Customer Entry</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('dateAdded')}>
                                Date Added {sortField === 'dateAdded' && (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th className="px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="9" className="text-center py-4">Loading...</td>
                            </tr>
                        ) : currentCustomers.length > 0 ? (
                            currentCustomers.map((customer, index) => (
                                <tr key={customer.id} className="border-t text-nowrap">
                                    <td className="px-4 py-2">{index + 1 + (currentPage - 1) * customersPerPage}</td>
                                    <td className="px-4 py-2">{customer.name}</td>
                                    <td className="px-4 py-2">{customer.email}</td>
                                    <td className="px-4 py-2">{customer.phone}</td>
                                    <td className="px-4 py-2">{customer.address}</td>
                                    <td className="px-4 py-2">{customer.gst}</td>
                                    <td className="px-4 py-2">{customer.contact ? "Contact" : customer.quote ? "Quote" : "Self Add"}</td>
                                    <td className="px-4 py-2">{new Date(customer.createdAt).toLocaleDateString()}</td>
                                    <td className="px-4 py-2">
                                        <button className="text-blue-500 mr-2"><FaEye /></button>
                                        <button className="text-green-500 mr-2"><FaEdit /></button>
                                        <button className="text-red-500"><FaTrash /></button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center py-4">No customers found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4 items-center space-x-2">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                >
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-4 py-2 rounded-md ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default CustomerTable;
