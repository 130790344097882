import { AiFillEye, AiFillEdit } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';

export default function OrderTable({ orders, handleSort, sortColumn, sortDirection, setCurrentOrder, setShowViewModal, setShowTrackingModal, setShowCreateModal }) {
  return (
    <div className="overflow-x-auto mb-8 dark:bg-secondaryDark rounded-lg">
      <table className="min-w-full bg-gray-50 dark:bg-secondaryDark">
        <thead>
          <tr className="bg-gray-50 dark:bg-primaryDark dark:text-gray-50">
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("id")}>
              Order ID {sortColumn === "id" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer " onClick={() => handleSort("vendor")}>
              Vendor Name {sortColumn === "vendor" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("product")}>
              Product/Service {sortColumn === "product" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("quantity")}>
              Quantity {sortColumn === "quantity" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("price")}>
              Price {sortColumn === "price" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("orderDate")}>
              Order Date {sortColumn === "orderDate" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2 cursor-pointer" onClick={() => handleSort("status")}>
              Status {sortColumn === "status" && (sortDirection === "asc" ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
            </th>
            <th className="border-b px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className="border-b px-4 py-2">{order.id}</td>
              <td className="border-b px-4 py-2">{order.vendor?.name}</td>
              <td className="border-b px-4 py-2">{order.productService}</td>
              <td className="border-b px-4 py-2">{order.quantity}</td>
              <td className="border-b px-4 py-2">₹{order.unitPrice.toFixed(2)}</td>
              <td className="border-b px-4 py-2">{order.orderDate}</td>
              <td className="border-b px-4 py-2">{order.orderStatus}</td>
              <td className="border-b px-4 py-2">
                <div className="flex gap-2">
                  <button 
                    className=" p-2 rounded mr-2 flex items-center border: 'none'"
                    onClick={() => {
                      setCurrentOrder(order)
                      setShowViewModal(true)
                    }}
                  >
                    <AiFillEye className= 'text-green-600' /> 
                  </button>
                  <button 
                    className=" p-2 rounded mr-2 flex items-center"
                    onClick={() => {
                      setCurrentOrder(order)
                      setShowTrackingModal(true)
                    }}
                  >
                    <BiMap className='text-yellow-600' /> 
                  </button>
                  <button 
                    className=" p-2 rounded mr-2 flex items-center"
                    onClick={() => {
                      setCurrentOrder(order)
                      setShowCreateModal(true)
                    }}
                  >
                    <AiFillEdit className='text-red-600' /> 
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
