import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import Select from 'react-select'; // Import react-select
import axiosInstance from '../../services/AxiosInstance';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const BudgetTracking = ({ expense }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [chartData, setChartData] = useState({
        labels: ['Planned', 'Actual'],
        datasets: [
            {
                label: 'Budget',
                data: [0, 0], // Default values
                backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                borderWidth: 1,
            },
        ],
    });
    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false); // Add loading state

    // Fetch data from the backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                const expenseResponse = await axiosInstance.get('/backend/projects/budget/expenses');
                const sortedExpenses = sortExpenses(expenseResponse.data); // Sort fetched expenses
                setExpenses(sortedExpenses);
                setFilteredExpenses(sortedExpenses); // Set initial filtered expenses

                const projectResponse = await axiosInstance.get('/backend/projects'); // Adjust the endpoint for projects
                const projectOptions = projectResponse.data.map(project => ({
                    value: project.id,
                    label: project.name,
                }));
                setProjects(projectOptions); // Set formatted project options
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // If there's a new expense passed in as prop, update the expenses and chart
        if (expense && expense.length > 0) {
            setExpenses(prevExpenses => {
                const updatedExpenses = [...prevExpenses, ...expense];
                const sortedExpenses = sortExpenses(updatedExpenses); // Sort combined expenses
                return sortedExpenses;
            });
            const latestExpense = expense[expense.length - 1]; // Assuming the latest expense is the last one
            const newChartData = {
                labels: ['Planned', 'Actual'],
                datasets: [
                    {
                        label: 'Budget',
                        data: [latestExpense.plannedCost, latestExpense.actualCost],
                        backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                        borderWidth: 1,
                    },
                ],
            };
            setChartData(newChartData);
        }
    }, [expense]);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        const newChartData = {
            labels: ['Planned', 'Actual'],
            datasets: [
                {
                    label: 'Budget',
                    data: [item.plannedCost, item.actualCost],
                    backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                    borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                    borderWidth: 1,
                },
            ],
        };
        setChartData(newChartData);
    };

    const handleProjectChange = async (selectedOption) => {
        setSelectedProject(selectedOption); // Set selected project

        if (selectedOption) {
            setLoading(true); // Start loading
            try {
                const response = await axiosInstance.get(`/backend/projects/budget/${selectedOption.value}/expenses`); // Fetch expenses for the selected project
                const sortedExpenses = sortExpenses(response.data); // Sort expenses
                setFilteredExpenses(sortedExpenses);
            } catch (error) {
                console.error('Error fetching project expenses:', error);
            } finally {
                setLoading(false); // End loading
            }
        } else {
            setFilteredExpenses(expenses); // Show all expenses if no project is selected
        }
    };

    // Sorting function
    const sortExpenses = (expensesToSort) => {
        return expensesToSort.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt); // Ensure createdAt is a date
        });
    };

    return (
        <div className="space-y-3 sm:p-3 lg:p-4">
            <div className="p-6 lg:p-8 rounded-lg shadow-md dark:bg-secondaryDark transition-colors duration-1000 ease-in">
                <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-6 text-center text-gray-800 dark:text-textDark">
                    Budget Tracking Dashboard
                </h2>

                {/* Project Filter Dropdown */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Filter by Project:</label>
                    <Select
                        value={selectedProject}
                        onChange={handleProjectChange}
                        options={projects}
                        isLoading={loading} // Show loading state in dropdown
                        isClearable // Allow clearing selection
                        placeholder="Select a project..."
                    />
                </div>

                <div className="mb-8">
                    <div className="relative h-60 sm:h-72 lg:h-80 dark:text-textDark">
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: { display: false },
                                    tooltip: {
                                        callbacks: {
                                            label: (context) => {
                                                const label = context.label || '';
                                                const planned = chartData.datasets[0].data[0];
                                                const actual = chartData.datasets[0].data[1];
                                                const difference = actual - planned;
                                                return `${label}: ₹${context.raw} (Difference: ₹${difference})`;
                                            },
                                        },
                                    },
                                },
                                layout: {
                                    padding: {
                                        top: 20,
                                        bottom: 20,
                                    },
                                },
                                scales: {
                                    x: {
                                        barPercentage: 0.6,
                                        categoryPercentage: 0.8,
                                    },
                                    y: {
                                        beginAtZero: true,
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="bg-gray-100 p-4 sm:p-6 rounded-md dark:bg-secondaryDark transition-colors duration-1000 ease-in">
                    <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-center mb-4 dark:text-textDark">Expense List</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-left text-sm sm:text-base lg:text-lg">
                            <thead>
                                <tr className="bg-gray-200 dark:bg-primaryDark dark:text-textDark transition-colors duration-1000 ease-in">
                                    <th className="py-3 px-4 sm:px-6 border">#</th>
                                    <th className="py-3 px-4 sm:px-6 border">Item</th>
                                    <th className="py-3 px-4 sm:px-6 border">Planned Cost</th>
                                    <th className="py-3 px-4 sm:px-6 border">Actual Cost</th>
                                    <th className="py-3 px-4 sm:px-6 border">Variance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredExpenses.length > 0 ? (
                                    filteredExpenses.map((item, index) => (
                                        <tr
                                            key={item.item}
                                            onClick={() => handleItemClick(item)}
                                            className={`cursor-pointer transition-colors duration-200 ${selectedItem === item ? 'bg-blue-50' : 'dark:text-dark hover:bg-gray-200'}`}
                                        >
                                            <td className="py-3 px-4 sm:px-6 border">{index + 1}</td>
                                            <td className="py-3 px-4 sm:px-6 border">{item.name}</td>
                                            <td className="py-3 px-4 sm:px-6 border">₹{item.plannedCost}</td>
                                            <td className="py-3 px-4 sm:px-6 border">₹{item.actualCost}</td>
                                            <td className={`py-3 px-4 sm:px-6 border ${item.plannedCost > item.actualCost ? 'text-red-500' : 'text-green-500'}`}>
                                                ₹{item.actualCost - item.plannedCost}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center py-4">No expenses found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BudgetTracking;
