import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddEmployeeModal = ({ onClose, onAddEmployee }) => {
  const [formData, setFormData] = useState({ email: '', role: 'Member' });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // To track submission state
  const modalRef = useRef(null);
  const navigate = useNavigate(); 

  // Close the modal if the user clicks outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError('');
  };


  const handleInvite = async (e) => {
    e.preventDefault();
    const { email, role } = formData;
  
    // Early return if email is invalid - THIS PREVENTS THE REQUEST
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false); // Ensure submission state is reset
      return; // IMPORTANT: This stops the entire function execution
    }
  
    setIsSubmitting(true);
    try {
      // Rest of the code remains the same
      const response = await axiosInstance.post('/backend/employees/add', formData);
      if (response.status === 200 || response.status === 201) {
        toast.success(`Invited ${formData.email} as ${formData.role}`);
        
        if (onAddEmployee) onAddEmployee(email);
        
        setFormData({ email: '', role: 'Member' });
        navigate('/communication');
        onClose();
      } else {
        throw new Error('Unexpected response from the server.');
      }
    } catch (err) {
      // Error handling
      console.error('Error:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'Failed to invite employee. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div ref={modalRef} className="w-full max-w-sm p-6 bg-gray-100 rounded-lg shadow-lg relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-900 hover:text-gray-300"
        >
          ✕
        </button>

        <h2 className="text-xl text-gray-800 font-semibold mb-4">Invite people to Kala Interiors</h2>

        <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="email">
          To:
        </label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="name@gmail.com"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full p-2 mb-2 text-gray-900 rounded-lg border border-gray-300 focus:ring focus:ring-blue-500"
        />
        {error && <p className="text-sm text-red-500 mb-2">{error}</p>}

        <label className="block text-sm font-medium mb-2" htmlFor="role">
          Invite as:
        </label>
        <select
          id="role"
          name="role"
          value={formData.role}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 text-gray-900 rounded-lg border border-gray-300 focus:ring focus:ring-blue-500"
        >
          <option value="Member">Member</option>
          <option value="Admin">Admin</option>
        </select>

        <p className="text-sm text-gray-400 mb-4">
          Working with people from external organizations? See options for inviting them to your channel with{' '}
          <a href="#" className="text-blue-400 hover:underline">Slack Connect</a> or{' '}
          <a href="#" className="text-blue-400 hover:underline">guest accounts</a>.
        </p>

        <button
          onClick={handleInvite}
          disabled={isSubmitting}
          className={`w-full text-white py-2 px-4 ${
            isSubmitting ? 'bg-gray-400' : 'bg-purple-500 hover:bg-purple-600'
          } rounded-lg font-small`}
        >
          {isSubmitting ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
