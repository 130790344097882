import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 p-5">
            <div className="text-center">
                <h1 className="text-9xl font-bold text-white mb-5">404</h1>
                <p className="text-2xl md:text-3xl font-light text-white mb-8">
                    Oops! Page not found
                </p>
                <p className="text-lg text-white mb-6">
                    The page you're looking for doesn't exist or has been moved.
                </p>
                <Link
                    to="/"
                    className="px-6 py-3 text-lg font-medium text-white bg-indigo-600 rounded-lg shadow-lg hover:bg-indigo-700 transition-all duration-200"
                >
                    Go to Homepage
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
