import React from 'react'
import { FaUser, FaSignOutAlt, FaBars } from 'react-icons/fa'
import { useState } from 'react';
import AddChannelModal from './AddChannelModal';
import AddEmployeeModal from './AddEmployeeModal';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <button onClick={onClose} className="absolute top-2 right-2 text-xl">&times;</button>
        {children}
      </div>
    </div>
  );
};

const ProfileModal = ({ isOpen, onClose }) => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const handleLogout = () => {
    // Add your logout logic here
    console.log("Logged out successfully!");
    setLogoutModalOpen(false); // Close the modal after logout
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="text-center p-6 w-80 relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        {/* Profile Icon */}
        <div className="w-20 h-20 bg-purple-600 rounded-full mx-auto mb-4 flex items-center justify-center text-white text-2xl">
          R
        </div>
        {/* Profile Information */}
        <h2 className="text-xl font-bold">Rushikesh</h2>
        <p className="text-green-500">Active</p>
        <p className="text-gray-500">3:38 PM local time</p>
        {/* Set Status Button */}
        <button className="mt-4 bg-gray-200 px-4 py-2 rounded hover:bg-gray-300">
          Set a status
        </button>
        {/* Contact Information */}
        <div className="mt-6 border-t pt-4 text-center">
          <h3 className="font-bold mb-2">Contact Information</h3>
          <p>Email: rushibhau69@gmail.com</p>
        </div>
        {/* Logout Button */}
        <div className='flex flex-col items-center mt-4'>
          <button
            className="flex items-center bg-gray-200 p-2 hover:bg-gray-300 rounded-md"
            onClick={() => setLogoutModalOpen(true)}
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </button>
        </div>
      {/* Logout Modal */}
      {isLogoutModalOpen && (
        <LogoutModal
          onClose={() => setLogoutModalOpen(false)}
          onLogout={handleLogout}
        />
      )}
      </div>
    </Modal>
  );
};

const LogoutModal = ({ onClose, onLogout }) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">Are you sure you want to logout?</h2>
      <div className="flex justify-end space-x-2">
        <button onClick={onClose} className="px-4 py-2 border rounded hover:bg-gray-100">
          Cancel
        </button>
        <button onClick={onLogout} className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">
          Logout
        </button>
      </div>
    </Modal>
  );
};

export default function Sidebar({ currentChannel, setCurrentChannel, selectedEmployee, setSelectedEmployee }) {
  const [channels, setChannels] = useState([
    { name: 'Group 1', starred: false },
    { name: 'Group 2', starred: false },
    { name: 'Group 3', starred: false },
  ]);
  
  const [employees, setEmployees] = useState([
    { name: 'Employee 1', online: true, starred: false },
    { name: 'Employee 2', online: true, starred: false },
    { name: 'Employee 3', online: false, starred: false },
    { name: 'Employee 4', online: false, starred: false },
    { name: 'Employee 5', online: false, starred: false },
  ]);

  const [isChannelModalOpen, setChannelModalOpen] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  
  const handleAddChannel = (channelName) => {
    if (channelName) {
      setChannels([...channels, { name: channelName, starred: false }]);
    }
  };

  const handleAddEmployee = (employeeName) => {
    if (employeeName) {
      setEmployees([...employees, { name: employeeName, online: false , starred: false }]);
    }
  }; 

  const handleChannelClick = (channel) => {
    setCurrentChannel(channel);
    setSelectedEmployee(null);
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setCurrentChannel(null);
  };

  const handleLogout = () => {
    console.log("User logged out");
    setLogoutModalOpen(false);
  };

  const toggleStarredChannel = (channelName) => {
    setChannels(channels.map(channel =>
      channel.name === channelName
        ? { ...channel, starred: !channel.starred }
        : channel
    ));
  };

  const toggleStarredEmployee = (employeeName) => {
    setEmployees(employees.map(employee =>
      employee.name === employeeName
        ? { ...employee, starred: !employee.starred }
        : employee
    ));
  };

  const starredChannels = channels.filter(channel => channel.starred);
  const nonStarredChannels = channels.filter(channel => !channel.starred);
  const starredEmployees = employees.filter(employee => employee.starred);
  const nonStarredEmployees = employees.filter(employee => !employee.starred);

  return (
    <>
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="md:hidden fixed top-4 left-4 z-20 p-2 bg-purple-800 text-white rounded-md"
      >
        <FaBars className="h-6 w-6" />
      </button>

      <aside
        className={`fixed md:relative transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out w-64 bg-purple-800 text-white flex flex-col p-4 space-y-4 z-10 md:translate-x-0 h-full`}
      >
        <div className="text-lg font-semibold">Kala Interiors</div>

        <div className="flex-grow overflow-y-auto space-y-4">
          <div>
            <h3 className="text-gray-300 font-semibold mb-2">Channels</h3>
            <ul className="space-y-1">
              {starredChannels.map((channel, index) => (
                <li
                  key={`starred-${index}`}
                  onClick={() => handleChannelClick(channel.name)}
                  className={`flex items-center p-2 ${currentChannel === channel.name ? 'bg-purple-600' : 'hover:bg-purple-900'} rounded-lg cursor-pointer`}
                >
                  <span className="text-lg mr-2">#</span> {channel.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleStarredChannel(channel.name);
                    }}
                    className="ml-auto text-yellow-400"
                  >
                    ★
                  </button>
                </li>
              ))}
              {nonStarredChannels.map((channel, index) => (
                <li
                  key={`nonstarred-${index}`}
                  onClick={() => handleChannelClick(channel.name)}
                  className={`flex items-center p-2 ${currentChannel === channel.name ? 'bg-purple-600' : 'hover:bg-purple-900'} rounded-lg cursor-pointer`}
                >
                  <span className="text-lg mr-2">#</span> {channel.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleStarredChannel(channel.name);
                    }}
                    className="ml-auto text-yellow-400"
                  >
                    ☆
                  </button>
                </li>
              ))}
              <li
                onClick={() => setChannelModalOpen(true)}
                className="flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer text-blue-400"
              >
                + Add channels
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-gray-300 font-semibold mb-2">Direct Messages</h3>
            <ul className="space-y-1">
              {starredEmployees.map((employee, index) => (
                <li
                  key={`starred-emp-${index}`}
                  onClick={() => handleEmployeeClick(employee)}
                  className={`flex items-center p-2 ${selectedEmployee?.name === employee.name ? 'bg-purple-600' : 'hover:bg-purple-900'} rounded-lg cursor-pointer`}
                >
                  <span className={`w-3 h-3 rounded-full mr-2 ${employee.online ? 'bg-green-500' : 'bg-gray-500'}`} />
                  {employee.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleStarredEmployee(employee.name);
                    }}
                    className="ml-auto text-yellow-400"
                  >
                    ★
                  </button>
                </li>
              ))}
              {nonStarredEmployees.map((employee, index) => (
                <li
                  key={`nonstarred-emp-${index}`}
                  onClick={() => handleEmployeeClick(employee)}
                  className={`flex items-center p-2 ${selectedEmployee?.name === employee.name ? 'bg-purple-600' : 'hover:bg-purple-900'} rounded-lg cursor-pointer`}
                >
                  <span className={`w-3 h-3 rounded-full mr-2 ${employee.online ? 'bg-green-500' : 'bg-gray-500'}`} />
                  {employee.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleStarredEmployee(employee.name);
                    }}
                    className="ml-auto text-yellow-400"
                  >
                    ☆
                  </button>
                </li>
              ))}
              <li
                onClick={() => setEmployeeModalOpen(true)}
                className="flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer text-blue-400"
              >
                + Add Employees
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <button
            className="flex items-center p-2 hover:bg-purple-600 rounded-lg"
            onClick={() => setProfileModalOpen(true)}
          >
            <FaUser className="mr-2" /> Profile
          </button>
        </div>
      </aside>
      {/* </div> */}
      {isChannelModalOpen && (
        <AddChannelModal
        onClose={() => setChannelModalOpen(false)}
        onAddChannel={handleAddChannel}
        existingChannels={channels}
      />
      )}
      {isEmployeeModalOpen && (
        <AddEmployeeModal
          onClose={() => setEmployeeModalOpen(false)}
          onAddEmployee={(newEmployee) => {
            handleAddEmployee(newEmployee);
            setEmployeeModalOpen(false);
          }}
        />
      )}
      
      <Modal isOpen={isLogoutModalOpen} onClose={() => setLogoutModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">Are you sure you want to logout?</h2>
        <div className="flex justify-end space-x-2">
          <button onClick={() => setLogoutModalOpen(false)} className="px-4 py-2 border rounded hover:bg-gray-100">
            Cancel
          </button>
          <button onClick={handleLogout} className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">
            Logout
          </button>
        </div>
      </Modal>
      <ProfileModal isOpen={isProfileModalOpen} onClose={() => setProfileModalOpen(false)} />
    </>
  )
}

function ChannelList() {
  const channels = ['Group 1', 'Group 2', 'Group 3']
  return (
    <div>
      <div className="text-gray-300 font-semibold mb-2">Channels</div>
      <ul>
        {channels.map((channel, index) => (
          <li key={index} className={`flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer ${index === 1 ? 'bg-purple-600' : ''}`}>
            <span className="text-lg mr-2">#</span> {channel}
          </li>
        ))}
        <li className="flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer text-blue-400">
          + Add channels
        </li>
      </ul>
    </div>
  )
}

function DirectMessageList() {
  const employees = [
    { name: 'Employee1', status: 'online' },
    { name: 'Employee2', status: 'online' },
    { name: 'Employee3', status: 'offline' },
  ]
  return (
    <div>
      <div className="text-gray-300 font-semibold mb-2">Direct Messages</div>
      <ul>
        {employees.map((employee, index) => (
          <li key={index} className="flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer">
            <span className={`w-3 h-3 ${employee.status === 'online' ? 'bg-green-500' : 'bg-gray-500'} rounded-full mr-2`}></span> {employee.name}
          </li>
        ))}
        <li className="flex items-center p-2 hover:bg-purple-600 rounded-lg cursor-pointer text-blue-400">
          + Add Employees
        </li>
      </ul>
    </div>
  )
}

function ProfileItem({ icon, text }) {
  return (
    <div className="flex items-center text-gray-300 font-semibold mb-2">
      {icon}
      {text}
    </div>
  )
}