// InventoryFinance.js
import React, { useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { format } from 'date-fns';

const InventoryFinance = ({ inventoryFinanceTab, setInventoryFinanceTab, lineData, pieData }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2">Inventory & Finance</h2>
      <p className="text-gray-600 mb-4">Manage inventory and finance reports.</p>
      <div className="flex space-x-4">
        <button
          className={`py-2 px-4 ${inventoryFinanceTab === 'inventory' ? 'bg-blue-500 text-white' : 'text-gray-700'}`}
          onClick={() => setInventoryFinanceTab('inventory')}
        >
          Inventory
        </button>
        <button
          className={`py-2 px-4 ${inventoryFinanceTab === 'finance' ? 'bg-blue-500 text-white' : 'text-gray-700'}`}
          onClick={() => setInventoryFinanceTab('finance')}
        >
          Finance
        </button>
      </div>

      {inventoryFinanceTab === 'inventory' && (
        <div className="space-y-4 mt-4">
          <h3 className="text-lg font-medium">Inventory Management</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="inventory-category" className="block text-sm font-medium text-gray-700">
                Select Inventory Category
              </label>
              <select
                id="inventory-item"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
              >
                <option>Select category</option>
                <option value="category1">Item 1</option>
                <option value="category2">Item 2</option>
              </select>
            </div>
            <div className="space-y-2">
              <label htmlFor="inventory-category" className="block text-sm font-medium text-gray-700">
                Select Inventory Category
              </label>
              <select
                id="inventory-category"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
              >
                <option>Select category</option>
                <option value="category1">Category 1</option>
                <option value="category2">Category 2</option>
              </select>
            </div>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <div className="flex-1 space-y-2">
                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                <input
                  type="date"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
                  value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                />
              </div>
              <div className="flex-1 space-y-2">
                <label className="block text-sm font-medium text-gray-700">End Date</label>
                <input
                  type="date"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
                  value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
                  onChange={(e) => setEndDate(new Date(e.target.value))}
                />
              </div>
            </div>
          </div>
          <button className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Add Inventory Report
          </button>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Inventory Usage Over Time</h3>
              <div className="mt-5 h-64 bg-gray-50">
                <Line data={lineData} />
              </div>
            </div>
          </div>
        </div>
      )}

      {inventoryFinanceTab === 'finance' && (
        <div className="space-y-4 mt-4">
          <h3 className="text-lg font-medium">Finance Management</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="financial-metric" className="block text-sm font-medium text-gray-700">Financial Metric</label>
              <select id="financial-metric" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
                <option>Select metric</option>
                <option value="revenue">Revenue</option>
                <option value="profit">Profit</option>
                <option value="loss">Loss</option>
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Date Range</label>
              <input
                type="date"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
              />
            </div>
          </div>
          <button className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Generate Finance Report
          </button>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Financial Overview</h3>
              <div className="mt-5 h-64 bg-gray-50">
                <Pie data={pieData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryFinance;
