import { useState, useEffect } from "react";
import { FaTrash, FaPaperPlane, FaEye, FaDownload } from "react-icons/fa";
import axiosInstance from "../../services/AxiosInstance";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logoHast from "../../assets/logo";
import QuotationModal from "./QuotationModal";

const GetQuote = () => {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get("/backend/api/contact/customer/quote-form");
                console.log(response.data);

                if (Array.isArray(response.data)) {
                    setQuotes(response.data);
                } else {
                    setError("Data format error: Quotes data is not an array.");
                }
                setLoading(false);
            } catch (err) {
                setError("Failed to load data.");
                setLoading(false);
            }
        };
        fetchQuotes();
    }, []);


    const handleViewDetails = (quotation) => {
        setSelectedQuotation(quotation);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedQuotation(null); // Clear the selected quotation
    };


    // const generatePdf = () => {
    //     return new Promise((resolve) => {
    //         const doc = new jsPDF();

    //         // Title and Logo
    //         doc.setFont("helvetica", "bold");
    //         doc.setFontSize(22);
    //         doc.text("Kala Interiors", 14, 22);

    //         doc.addImage(logoHast, 'PNG', 90, 0, 30, 30); // Adjust position and size as needed

    //         doc.setFont("helvetica", "normal");
    //         doc.setFontSize(12);

    //         // Company Info
    //         doc.text("Address: Office no. 1, Near Madgiri Garden,", 14, 30);
    //         doc.text("Indrayani Nagar, Bhosari, Pune 411026", 14, 35);
    //         doc.text("Phone: +91-8378989511", 14, 40);
    //         doc.text("Email: amit.s.landge@gmail.com", 14, 45);
    //         doc.text("Website: www.kalainteriors.in", 14, 50);
    //         doc.text("GST No: Gstin0000000000", 14, 55);

    //         // Customer Info
    //         const pageWidth = doc.internal.pageSize.getWidth();
    //         const marginRight = 14;
    //         const maxWidth = pageWidth - marginRight;


    //         const QuoteFor = "Quote for";
    //         const quoteNo = "Quote No : qn56123";
    //         const name = `Name: ${selectedQuote?.name || "NA"}`;
    //         const mobile = `Phone: ${selectedQuote?.phone || "NA"}`;
    //         const email = `Email: ${selectedQuote?.email || "NA"}`;

    //         doc.setFont("helvetica", "bold");
    //         doc.text(QuoteFor, maxWidth - doc.getTextWidth(QuoteFor), 20);
    //         doc.text(quoteNo, maxWidth - doc.getTextWidth(quoteNo), 30);
    //         doc.text(name, maxWidth - doc.getTextWidth(name), 35);
    //         doc.text(mobile, maxWidth - doc.getTextWidth(mobile), 40);
    //         doc.text(email, maxWidth - doc.getTextWidth(email), 45);

    //         doc.line(14, 70, 200, 70); // Horizontal line

    //         doc.setFont("Helvetica", "italic");
    //         doc.text(`Dear ${selectedQuote?.name}`, 16, 80);

    //         doc.setFont("Times", "normal");
    //         doc.text("We are pleased to provide you with the following quotation:", 20, 85);

    //         // Quote Items Table and Subtotal Calculation
    //         let subtotal = 0;
    //         const itemsData = quoteItems.map(item => {
    //             const finalCost = parseFloat(calculateFinalCost(quoteItems.indexOf(item)));
    //             subtotal += finalCost; // Sum up the final cost for each item to get subtotal
    //             return [
    //                 item.quantity,
    //                 item.description,
    //                 item.amount,
    //                 item.discount,
    //                 finalCost.toFixed(2), // Format the final cost to 2 decimal places
    //             ];
    //         });

    //         doc.autoTable({
    //             startY: 95,
    //             head: [['Quantity', 'Description', 'Unit Price', 'Discount', 'Final Cost']],
    //             body: itemsData,
    //             headStyles: { fontStyle: 'bold' },
    //         });

    //         // Calculate GST and Grand Total
    //         const gstRate = 0.18; // 18% as a decimal
    //         const gstAmount = subtotal * gstRate;
    //         const grandTotal = subtotal + gstAmount;

    //         // Format amounts to 2 decimal places
    //         const formattedSubtotal = subtotal.toFixed(2);
    //         const formattedGstAmount = gstAmount.toFixed(2);
    //         const formattedGrandTotal = grandTotal.toFixed(2);

    //         // Ensure the currency symbol is correct
    //         const finalY = doc.lastAutoTable.finalY || 95;
    //         doc.setFont("helvetica", "bold");
    //         doc.setFontSize(12);

    //         // Add properly formatted values with correct currency symbol
    //         doc.text(`Subtotal:  ${formattedSubtotal}`, maxWidth - 50, finalY + 10);
    //         doc.text(`GST (18%):  ${formattedGstAmount}`, maxWidth - 50, finalY + 15);
    //         doc.text(`Grand Total:  ${formattedGrandTotal}`, maxWidth - 50, finalY + 20);


    //         // Terms and Conditions
    //         const terms = [
    //             "1. Payment Terms: 50% advance payment required...",
    //             "2. Timeline: Development and deployment...",
    //             "3. Service Duration: This quotation covers a service period of 1 year.",
    //             "4. Validity: This quotation is valid for 30 days from the date of issue."
    //         ];

    //         let yOffset = finalY + 50; // Adjust space for the terms section
    //         terms.forEach(term => {
    //             doc.setFont("helvetica", "normal");
    //             doc.text(term, 14, yOffset);
    //             yOffset += 10;
    //         });

    //         // Generate the PDF Blob
    //         const pdfBlob = doc.output('blob');
    //         resolve(pdfBlob);
    //     });
    // };






    const handleDelete = async (quoteId) => {
        try {
            await axiosInstance.delete(`/backend/api/quotes/${quoteId}`);
            Swal.fire("Deleted", "Quote deleted successfully!", "success");
            setQuotes(quotes.filter(quote => quote.id !== quoteId));
        } catch (err) {
            Swal.fire("Error", "Failed to delete the quote.", "error");
        }
    };






    return (
        <div className="container mx-auto p-2 rounded-lg dark:bg-primaryDark">
            {loading ? (
                <p className="text-center text-gray-500 dark:text-textDark h-screen flex justify-center items-center">
                    Loading...
                </p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : (
                <>
                    <div className="overflow-x-auto dark:bg-primaryDark p-1">
                        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-4 text-center transition-colors duration-1000 ease-in dark:text-textDark">
                            Quote Details
                        </h3>
                        <table className="min-w-full text-left text-sm font-light border dark:text-textDark">
                            <thead className="border-b font-medium dark:bg-dark">
                                <tr className="text-nowrap" >
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Email
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Phone
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Project Type
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Price Range
                                    </th>
                                    <th scope="col" className="px-4 py-4  text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Address
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        GST No.
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        View send Quote
                                    </th>
                                    <th scope="col" className="px-4 py-4  text-gray-800 transition-colors duration-1000 ease-in dark:text-textDark">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {quotes.map((quote) => (
                                    <tr key={quote.id} className="border-b dark:border-neutral-500">
                                        <td className="px-4 py-2 text-nowrap ">{quote.name}</td>
                                        <td className="px-4 py-2">{quote.email}</td>
                                        <td className="px-4 py-2">{quote.phone}</td>
                                        <td className="px-4 py-2">{quote.projectType}</td>
                                        <td className="px-4 py-2">{quote.priceRange}</td>
                                        <td className="px-4 py-2 min-w-60">{quote.address}</td>
                                        <td className="px-4 py-2">{quote.gst}</td>
                                        <td className="px-4 py-2">
                                            <button
                                                onClick={() => handleViewDetails(quote)} // Open modal with details
                                                className="bg-green-500 text-white px-2 text-nowrap py-2 rounded hover:bg-green-600 transition duration-300"
                                            >
                                                View Quotation
                                            </button>
                                        </td>

                                        <td className="px-4 py-2 flex justify-around">

                                            <button
                                                onClick={() => handleDelete(quote.id)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded"
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Modal for quotation details */}
                    <QuotationModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        quotationDetails={selectedQuotation || {}}
                    />
                </>
            )}
        </div>
    );
};

export default GetQuote;
