import React from 'react';

export default function GroupSettingsModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-96 shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Group Settings</h2>
        
        <div className="mb-4">
          <label className="font-semibold">Group Name:</label>
          <input type="text" defaultValue="Marketing Team" className="border w-full p-2 rounded mt-1" />
        </div>

        <div className="mb-4">
          <label className="font-semibold">Group Description:</label>
          <textarea defaultValue="This group is dedicated to coordinating marketing strategies." className="border w-full p-2 rounded mt-1" />
        </div>       

        <div className="mb-4">
          <label className="font-semibold">Notifications:</label>
          <select className="border w-full p-2 rounded mt-1">
            <option>All Activity</option>
            <option>Mentions Only</option>
            <option>Mute</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="font-semibold">Group Privacy:</label>
          <select className="border w-full p-2 rounded mt-1">
            <option>Public</option>
            <option>Private</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="font-semibold">Group Admins:</label>
          <p className="text-gray-600">Alice Johnson, John Smith</p>
        </div>

        <div className="mb-4">
          <label className="font-semibold">Membership Approval:</label>
          <select className="border w-full p-2 rounded mt-1">
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div className="text-center mt-4">
          <button className="bg-red-500 text-white px-4 py-2 rounded">Delete Group</button>
          <button onClick={onClose} className="ml-4 bg-gray-300 text-black px-4 py-2 rounded">Cancel</button>
        </div>
      </div>
    </div>
  );
}