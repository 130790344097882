import React, { useState, useEffect } from 'react'
import BarChartComponent from './Graph/BarChartComponent'
import LineChartComponent from './Graph/LineChartComponent'
import PieChartComponent from './Graph/PieChartComponent'
import ExpenseBarChartComponent from './Graph/ExpenseBarChartComponent'

// Payroll Data for BarChart
const payrollData = [
  { month: 'Jan', amount: 50000 },
  { month: 'Feb', amount: 55000 },
  { month: 'Mar', amount: 60000 },
  { month: 'Apr', amount: 58000 },
  { month: 'May', amount: 62000 },
  { month: 'Jun', amount: 65000 },
];

// Deal Status Data
const dealStatusData = [
  { name: 'Won', value: 400 },
  { name: 'Lost', value: 300 },
  { name: 'Pending', value: 300 },
];

const customerData = [
  { name: 'Jan', customers: 400 },
  { name: 'Feb', customers: 450 },
  { name: 'Mar', customers: 500 },
  { name: 'Apr', customers: 550 },
  { name: 'May', customers: 600 },
  { name: 'Jun', customers: 650 },
];

// Expenses Data
const expenses = [
  { id: 1, projectName: "Project A", expenseDate: "2023-05-01", amount: 1500.0, category: "Travel", description: "Team travel for client meeting" },
  { id: 2, projectName: "Project B", expenseDate: "2023-05-03", amount: 500.5, category: "Supplies", description: "Office supplies" },
  { id: 3, projectName: "Project A", expenseDate: "2023-05-05", amount: 2000.0, category: "Labor", description: "Contractor payment" },
  { id: 4, projectName: "Overhead", expenseDate: "2023-05-07", amount: 1000.0, category: "Rent", description: "Office rent" },
  { id: 5, projectName: "Project C", expenseDate: "2023-05-10", amount: 750.25, category: "Software", description: "Software license renewal" },
];

const MetricCard = ({ title, value, icon, change, changeType }) => {
  return (
    <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-md flex flex-col justify-between">
      <div className="flex items-center justify-between mb-2 sm:mb-4">
        <h3 className="text-base sm:text-lg font-semibold text-gray-700">{title}</h3>
        {icon}
      </div>
      <p className="text-xl sm:text-3xl font-bold text-gray-900 mb-1 sm:mb-2">{value}</p>
      <p className={`text-xs sm:text-sm ${changeType === 'positive' ? 'text-green-600' : 'text-red-600'}`}>
        {change} from last month
      </p>
      <button className='bg-blue-500 text-white py-1 sm:py-2 px-2 sm:px-4 font-semibold mt-2 sm:mt-4 rounded-md shadow hover:bg-blue-600 transition duration-300 ease-in-out transform hover:-translate-y-1'>
        View More
      </button>
    </div>
  )
}

const FinanceDashboard = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortBy, setSortBy] = useState('dateAsc');
  const [chartWidth, setChartWidth] = useState(500);

  const filteredExpenses = expenses
    .filter(expense => {
      const matchesSearch =
        expense.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.category.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTab =
        currentTab === "all" ||
        (currentTab === "project" && expense.projectName !== "Overhead") ||
        (currentTab === "overhead" && expense.projectName === "Overhead");
      const matchesCategory = categoryFilter === "all" || expense.category === categoryFilter;

      return matchesSearch && matchesTab && matchesCategory;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case "dateAsc":
          return new Date(a.expenseDate).getTime() - new Date(b.expenseDate).getTime();
        case "dateDesc":
          return new Date(b.expenseDate).getTime() - new Date(a.expenseDate).getTime();
        case "amountAsc":
          return a.amount - b.amount;
        case "amountDesc":
          return b.amount - a.amount;
        default:
          return 0;
      }
    });

  const totalExpenses = filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);

  const expensesByCategory = filteredExpenses.reduce((acc, expense) => {
    acc[expense.category] = (acc[expense.category] || 0) + expense.amount;
    return acc;
  }, {});

  const pieChartData = Object.entries(expensesByCategory).map(([name, value]) => ({ name, value }));

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth < 640) {
        setChartWidth(window.innerWidth - 40); // Padding for mobile screens
      } else if (window.innerWidth < 1024) {
        setChartWidth(window.innerWidth / 1.5);
      } else {
        setChartWidth(500);
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <div className="p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800">Dashboard Overview</h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <div className="w-full">
          <BarChartComponent data={payrollData} width={chartWidth} height={300} />
        </div>
        <div className="w-full">
          <LineChartComponent data={customerData} width={chartWidth} height={300} />
        </div>
        <div className="w-full">
          <PieChartComponent data={pieChartData} width={chartWidth} height={300} />
        </div>
        <div className="w-full">
          <ExpenseBarChartComponent width={chartWidth} height={300} />
        </div>
      </div>
    </div>
  );
}

export default FinanceDashboard;
