import React, { useEffect, useState } from 'react';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import EmergencyDetails from './EmergencyDetails';
import WorkQualification from './WorkQualification';
import axiosInstance from '../../services/AxiosInstance';

export default function Profile() {
  const [selectedPage, setSelectedPage] = useState('personal');
  const [employeeId, setEmployeeId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);




  useEffect(() => {
    const storedEmployeeId = localStorage.getItem("employeeId");
    if (storedEmployeeId) {
      setEmployeeId(storedEmployeeId);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      const fetchEmployeeDetails = async () => {
        setLoading(true);
        setError("");
        try {
          const response = await axiosInstance.get(`/backend/api/employees/${employeeId}`);
          // console.log(response.data)
          setEmployee(response.data);
        } catch (err) {
          console.error("Error fetching employee details:", err);
          setError("Failed to fetch employee details. Please try again later.");
        } finally {
          setLoading(false);
        }
      };

      fetchEmployeeDetails();
    }
  }, [employeeId]);

  // Get the profile image URL
  const profileImage = employee?.personalDetails?.profileImage
    ? `${axiosInstance.defaults.baseURL}/backend/api/quote/file/${employee?.personalDetails.profileImage}`
    : "/images/default image.jpg";

  const handlePersonalForm = (personalDetails) => {
    setEmployee((prev) => {
      // Check if personalDetails already exists in employee
      const updatedPersonalDetails = prev.personalDetails
        ? { ...prev.personalDetails, ...personalDetails } // Update existing personalDetails
        : { ...personalDetails }; // Create new personalDetails

      return {
        ...prev,
        personalDetails: updatedPersonalDetails, // Set updated personalDetails
      };
    });
  };

  const handleContactForm = (contactDetails) => {
    setEmployee((prev) => {
      const updateContactDetails = prev.contactDetails
        ? { ...prev.contactDetails, ...contactDetails }
        : { ...contactDetails }

      return {
        ...prev,
        contactDetails: updateContactDetails,
      };
    })
  }


  const handleEmergencyDetails = (emergency) => {
    setEmployee((prev) => ({
      ...prev,
      emergencyDetails: [...prev.emergencyDetails, emergency],
    }));
  };



  const handleEmergencyDelete = (id) => {
    setEmployee((prevEmployee) => {
      if (!prevEmployee?.emergencyDetails) return prevEmployee;

      return {
        ...prevEmployee,
        emergencyDetails: prevEmployee.emergencyDetails.filter(
          (contact) => contact.id !== id
        ),
      };
    });
  };



  return (
    <div className="flex flex-col h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
      {/* Navbar */}
      <nav className="bg-blue-600 dark:bg-secondaryDark text-white p-4">
        <div className="flex flex-col md:flex-row  justify-between">
          <div className="flex justify-between items-center">

            {/* Profile Section */}
            <div className="flex items-center md:mb-0">
              {/* Profile Picture */}
              <div
                className="w-20 h-20 rounded-full bg-gray-300 overflow-hidden mr-3 cursor-pointer"
                onClick={() => setIsModalOpen(true)} // Open modal on click
              >
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/default image.jpg";
                  }}
                  loading='lazy' 
                />
              </div>

              {/* Employee Name */}
              <h2 className="text-xl text-nowrap font-semibold">
                {employee?.personalDetails?.firstName && employee?.personalDetails?.lastName
                  ? `${employee.personalDetails.firstName} ${employee.personalDetails.lastName}`
                  : employee?.fullName || "Profile Details"}
              </h2>


              {/* Modal for displaying large image */}
              {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                  <div className="relative bg-white rounded-lg ">
                    {/* Close Button */}
                    <button
                      className="absolute top-2 right-2 text-3xl text-red-600 hover:text-red-800"
                      onClick={() => setIsModalOpen(false)}
                    >
                      &times;
                    </button>
                    {/* Large Image */}
                    <img
                      src={profileImage}
                      alt="Profile"
                      className="max-w-full max-h-[80vh] rounded"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/images/default image.jpg";
                      }}
                      loading='lazy' 
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Hamburger Menu */}
            <button
              onClick={() => setSidebarOpen((prev) => !prev)}
              className="md:hidden text-gray-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>

          </div>
          {/* Navbar Links */}
          <div className={`w-full md:flex flex-grow justify-end space-x-4 ${sidebarOpen ? 'hidden' : 'hidden md:block'}`}>
            <ul className={`md:flex flex-col md:flex-row space-x-0 md:space-x-4`}>
              {['personal', 'contact', 'emergency', 'qualifications'].map((page) => (
                <li key={page} className="py-2">
                  <button
                    onClick={() => setSelectedPage(page)}
                    className={`py-2 px-4 rounded text-sm ${selectedPage === page
                      ? 'text-blue-600 bg-blue-100'
                      : 'text-white hover:bg-blue-700'}`}
                  >
                    {page.charAt(0).toUpperCase() + page.slice(1)} Details
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Modal for Mobile */}
      {sidebarOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-50 dark:bg-secondaryDark p-6 rounded-md w-80">
            <button onClick={() => setSidebarOpen(false)} className="absolute top-2 right-2 text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <ul>
              {['personal', 'contact', 'emergency', 'qualifications'].map((page) => (
                <li key={page} className="py-2">
                  <button
                    onClick={() => {
                      setSelectedPage(page);
                      setSidebarOpen(false);
                    }}
                    className={`py-2 px-4 rounded text-sm ${selectedPage === page
                      ? 'text-blue-600 bg-blue-100'
                      : 'text-gray-800 hover:bg-blue-100'}`}
                  >
                    {page.charAt(0).toUpperCase() + page.slice(1)} Details
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="flex-1 p-8 overflow-y-auto "  >
        {loading &&
          <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
            <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
            <p className='dark:text-secondaryDark' >Loading..</p>
          </div>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            {selectedPage === 'personal' && <PersonalDetails employeeId={employeeId} employee={employee} addPersonal={handlePersonalForm} />}
            {selectedPage === 'contact' && <ContactDetails employeeId={employeeId} employee={employee} addContact={handleContactForm} />}
            {selectedPage === 'emergency' && <EmergencyDetails employeeId={employeeId} employee={employee} addEmergencyDetails={handleEmergencyDetails} deleteEmergency={handleEmergencyDelete} />}
            {selectedPage === 'qualifications' && <WorkQualification />}
          </>
        )}
      </main>
    </div>
  );
}
