import React, { useState, useRef } from 'react';

export default function VideoModal({ onClose,onSendVideo }) {
  const [isRecording, setIsRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const startVideoRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing video device:', err);
    }
  };

  const stopVideoRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    stopCameraStream();
  };

  const stopCameraStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleClose = () => {
    stopCameraStream(); // Stop the camera when closing the modal
    setVideoUrl(null);
    onClose();
  };
  const handleSend = () => {
    if (videoUrl) {
      onSendVideo(videoUrl);
      handleClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-lg font-semibold mb-2">Video Recording</h2>
        
        <div className="bg-gray-200 w-full h-64 rounded mb-4 flex items-center justify-center relative">
          {!isRecording && !videoUrl && (
            <p className="absolute text-gray-700">Press start to record</p>
          )}
          <video ref={videoRef} className="w-full h-full" autoPlay muted />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => {
              if (isRecording) {
                stopVideoRecording();
              } else {
                setVideoUrl(null);
                startVideoRecording();
              }
            }}
          >
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </button>

          {videoUrl && (
            <>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={() => setVideoUrl(null)}
              >
                Delete
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                onClick={handleSend}
              >
                Send
              </button>
            </>
          )}

          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={handleClose}
          >
            Close
          </button>
        </div>

        {videoUrl && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Recording Preview</h3>
            <video className="w-full h-64" controls>
              <source src={videoUrl} type="video/webm" />
            </video>
          </div>
        )}
      </div>
    </div>
  );
}
