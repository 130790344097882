import React, { useEffect, useState } from 'react';
import MockInvoices from './MockInvoices';
import CreateInvoiceForm from './CreateInvoiceForm';
import axiosInstance from '../../services/AxiosInstance';

const InvoiceLayout = () => {
    const [customers, setCustomers] = useState([]); // Store customer data
    const [loading, setLoading] = useState(true);   // Loading state for API data
    const [error, setError] = useState('');         // Error state

    // Function to fetch customers from the API
    const fetchCustomers = async () => {
        try {
            setLoading(true); // Set loading state to true before fetching
            const response = await axiosInstance.get(`/backend/api/contact/customer`);
            if (response.status === 200) {
                setCustomers(response.data); // Set fetched customers data
                setError(''); // Clear any existing error
            } else {
                setError('Failed to fetch customers'); // Handle failure
            }
        } catch (error) {
            setError('Failed to fetch data'); // Handle error during fetching
        } finally {
            setLoading(false); // Ensure loading state is set to false after completion
        }
    };

    // Call fetchCustomers once when the component mounts
    useEffect(() => {
        fetchCustomers();
    }, []);



    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl h-[calc(100vh-4rem)] overflow-y-auto">
                {/* Left side - Create Invoice Form */}

                <div className="rounded-lg shadow-lg h-[calc(100vh-4rem)] overflow-y-auto">
                    <CreateInvoiceForm
                        customer={customers}
                        error={error}
                        loading={loading}
                        refreshCustomers={fetchCustomers}
                    />
                </div>

                {/* Right side - Invoices Display */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    <MockInvoices
                        customer={customers}
                        error={error}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default InvoiceLayout;
