import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { EyeIcon, EditIcon } from 'lucide-react';
import PurchaseOrderInvoice from './PurchaseOrderInvoice';

const PurchaseOrderTable = ({ purchaseOrder }) => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showInvoice, setShowInvoice] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState('');

    const fetchPurchaseOrders = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await axiosInstance.get('/backend/api/purchase-orders');
            // console.log(response.data)
            setPurchaseOrders(response.data);
        } catch (error) {
            console.error('Error fetching purchase orders:', error);
            setErrorMessage('Failed to fetch purchase orders.');
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchPurchaseOrders();
    }, []);

    useEffect(() => {
        fetchPurchaseOrders();
    }, [purchaseOrder]);

    const statusBadge = (status) => {
        const badgeStyles = {
            Pending: 'text-yellow-800 bg-yellow-300',
            Delivered: 'text-green-800 bg-green-300',
            Default: 'text-gray-800 bg-gray-300',
        };

        return (
            <span
                className={`text-xs font-semibold inline-block py-1 px-3 rounded-full ${badgeStyles[status] || badgeStyles.Default}`}
            >
                {status || 'Unknown'}
            </span>
        );
    };

    const showPurchaseInvoice = (order) => {
        setSelectedOrder(order);
        setShowInvoice(true);
    };

    const onCloseInvoice = () => {
        setSelectedOrder(null);
        setShowInvoice(false);
    };

    const openEditModal = (order) => {
        setSelectedOrder(order);
        setUpdatedStatus(order.status);
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setSelectedOrder(null);
        setUpdatedStatus('');
    };

    const handleStatusUpdate = async () => {
        if (!selectedOrder) return;

        try {
            const response = await axiosInstance.put(`/backend/api/purchase-orders/${selectedOrder.id}`, {
                status: updatedStatus,
                orderDate: selectedOrder.orderDate,
            });
            console.log('Status updated:', response.data);
            fetchPurchaseOrders(); // Refresh the list
            closeEditModal(); // Close the modal
        } catch (error) {
            console.error('Error updating status:', error);
            setErrorMessage('Failed to update status.');
        }
    };

    return (
        <div className="bg-gray-50 p-6 rounded-lg shadow-lg max-w-6xl mx-auto w-full dark:bg-secondaryDark">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center dark:text-gray-200">
                Purchase Orders
            </h2>

            {loading && (
                <div className="flex justify-center items-center gap-3 h-[calc(100vh-3.5rem)]">
                    <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                    <p className="dark:text-secondaryDark">Loading purchase orders...</p>
                </div>
            )}

            {errorMessage && (
                <div className="p-4 bg-red-100 text-red-800 rounded-md mb-4">{errorMessage}</div>
            )}

            <div className="grid gap-6">
                {!loading && purchaseOrders.length > 0 ? (
                    purchaseOrders.map((order) => (
                        <div
                            key={order.id}
                            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition dark:bg-primaryDark"
                        >
                            <div className="flex justify-between items-center mb-4">
                                <div>
                                    <p className="text-lg font-bold text-gray-800 dark:text-gray-200">
                                        Order ID: {order.id}
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        Supplier: {order.supplier?.name || 'N/A'}
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        Order Date: {order.orderDate || 'N/A'}
                                    </p>
                                </div>
                                <div>{statusBadge(order.status)}</div>
                            </div>
                            <div className="border-t border-gray-200 mt-4 pt-4">
                                <p className="font-semibold text-gray-600 mb-2 dark:text-gray-300">
                                    Products:
                                </p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {order.purchaseOrderProducts?.map((product) => (
                                        <div
                                            key={product.id}
                                            className="p-4 bg-gray-50 rounded-md shadow dark:bg-secondaryDark"
                                        >
                                            <p className="text-sm font-bold text-gray-700 dark:text-gray-200">
                                                {product.product?.supplierProduct || 'Unknown'}
                                            </p>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                Quantity: {product.quantity}
                                            </p>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                Unit Price: ₹{product.unitPrice}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <p className="font-bold text-gray-800 dark:text-gray-200">
                                    Total Price: ₹{order.totalPrice || 'N/A'}
                                </p>
                                <div className="flex gap-4">
                                    <button
                                        onClick={() => showPurchaseInvoice(order)}
                                        className="text-blue-500 hover:underline flex items-center gap-2"
                                    >
                                        <EyeIcon size={16} />
                                        View Invoice
                                    </button>
                                    <button
                                        onClick={() => openEditModal(order)}
                                        className="text-green-500 hover:underline flex items-center gap-2"
                                    >
                                        <EditIcon size={16} />
                                        Edit Status
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    !loading && (
                        <div className="text-center text-gray-600">No purchase orders available.</div>
                    )
                )}
            </div>

            {/* Invoice Modal */}
            {showInvoice && selectedOrder && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <PurchaseOrderInvoice orderInvoice={selectedOrder} close={onCloseInvoice} />
                </div>
            )}

            {/* Edit Status Modal */}
            {showEditModal && selectedOrder && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96 dark:bg-secondaryDark">
                        <h3 className="text-lg font-bold mb-4 text-gray-800 dark:text-gray-200">
                            Edit Status for Order ID: {selectedOrder.id}
                        </h3>
                        <select
                            className="w-full p-2 border rounded-md dark:bg-primaryDark dark:text-gray-200"
                            value={updatedStatus}
                            onChange={(e) => setUpdatedStatus(e.target.value)}
                        >
                            <option value="Pending">Pending</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Cancelled">Cancelled</option>
                        </select>
                        <div className="flex justify-end mt-4 gap-2">
                            <button
                                onClick={closeEditModal}
                                className="px-4 py-2 bg-gray-500 text-white rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleStatusUpdate}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PurchaseOrderTable;
