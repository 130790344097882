import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ThemeToggle from "../ThemeToggle";
import { IoMdNotifications } from "react-icons/io";
import CreateAdminModal from "./CreateAdminModal";
import axiosInstance from "../../services/AxiosInstance";
import { FaBoxes, FaBusinessTime, FaChartBar, FaFileInvoice, FaHandshake, FaMoneyBillAlt, FaProjectDiagram, FaSignOutAlt, FaUsers, FaUserTie } from 'react-icons/fa';
import { AiOutlineLogin } from 'react-icons/ai';

import { BiMessageSquareDetail } from "react-icons/bi";


const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [openProfile, setOpenProfile] = useState(false);

  const [user, setUser] = useState([]);

  const [role, setRole] = useState('')






  useEffect(() => {
    const email = localStorage.getItem("email")
    const role = localStorage.getItem("userRole")
    setRole(role)
    // console.log(role)
    const fetchLoggedInUserData = async () => {


      try {
        const response = await axiosInstance.get(`backend/api/auth/${email}`)
        // console.log(response.data)
        if (response.status === 200) {
          setUser(response.data)
        }

      } catch (error) {
        console.log(error)
      }
    }

    fetchLoggedInUserData();

  }, [])


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAdmin = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  // Toggle function to show/hide the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // toggle dropdown
  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prevId) => (prevId === dropdownId ? null : dropdownId));
  };

  // toggle profile
  const toggleProfie = () => {
    setOpenProfile(!openProfile);
  };

  const navigate = useNavigate();

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full max-h-16  overflow-hidden  bg-gray-50 border-b border-gray-200 dark:bg-primaryDark dark:border-secondaryDark">
        <div className="px-3 py-2 mt-[-35px] lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar}
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <NavLink
                to="/admin/dashboard"
                className="flex items-center space-x-3 rtl:space-x-reverse"
              >
                <img
                  src="/images/navbar-logo.png"
                  className="h-32 object-contain "
                  alt="Company logo"
                  loading='lazy'
                />
              </NavLink>
            </div>

            <div className="flex items-center">
              <div className="flex justify-center items-center p-2 bg-gray-100 dark:bg-dark shadow-lg border rounded-lg mr-2">
                <Link to="/admin/notification">
                  <IoMdNotifications className="text-green-600" />
                </Link>
              </div>
              <div className="flex justify-center items-center ">
                <ThemeToggle />
              </div>

            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16  transition-transform bg-white border-r border-gray-200 dark:bg-primaryDark dark:border-secondaryDark ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } md:translate-x-0 lg:translate-x-0 sm:translate-x-0 `}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-50 dark:bg-primaryDark">
          <ul className="space-y-2 font-medium">
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                onClick={() => handleToggleDropdown("project")}
                aria-controls="project"
              >
                <FaProjectDiagram size={25} color="#007bff" />

                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Project Management
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="project"
                className={`${openDropdown === "project" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/project-planning"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Project Planning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/task-management"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Task Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/resource-allocation"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Resource Allocation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/budget-tracking"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Budget Tracking
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/project-status"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Project Monitoring
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/show-project"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    All Projects
                  </Link>
                </li>
              </ul>
            </li>

            {/* CRM */}

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                onClick={() => handleToggleDropdown("crm")}
                aria-controls="crm"
              >
                <FaUsers size={25} color="#4A90E2" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  CRM
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="crm"
                className={`${openDropdown === "crm" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/customer-profile"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Customer Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/sale-pipeline"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Sale Pipeline
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/quotation"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Quotations
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/follow-up"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Follo-Up Reminders
                  </Link>
                </li>
              </ul>
            </li>



            {/* 
            <li>
            <ul
                id="crm"
                className={`${openDropdown === "crm" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                 <li>
                  <Link
                    to="/admin/design-template"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    DesignTemplates
                  </Link>
                </li>
                </ul>

            </li> */}




            {/* Inventry management */}

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="inventry"
                onClick={() => handleToggleDropdown("inventry")}
              >
                <FaBoxes size={25} color="#28a745" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Inventry Management
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="inventry"
                className={`${openDropdown === "inventry" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/stock-management"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Stock Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/material-requirement"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Material Requirement{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/record-alert"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Record Alert
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/supplier-management"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Supplier Management
                  </Link>
                </li>
              </ul>
            </li>

            {/* vendor */}

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="vendor"
                onClick={() => handleToggleDropdown("vendor")}
              >
                <FaBusinessTime size={25} color="#007bff" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Vendor Management
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="vendor"
                className={`${openDropdown === "vendor" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/VendorDatabase"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Vendor Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/OrderManagement"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Order Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/ContractManagement"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Contract Management
                  </Link>
                </li>
              </ul>
            </li>




            {/* HRM */}

            <li>
              <button type="button" className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="HRM" onClick={() => handleToggleDropdown('HRM')} >
                <FaUserTie size={25} color="#007bff" />

                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">HRM Management</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
              </button>
              <ul id="HRM" className={`${openDropdown === 'HRM' ? 'block' : 'hidden'} py-2 space-y-2 ml-8`}>
                <li>
                  <Link to="/admin/HRM-Employee" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Employee Management</Link>
                </li>
                <li>
                  <Link to="/admin/HRM-Attendence" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Attendence Management</Link>
                </li>
                <li>
                  <Link to="/admin/HRM-Performance" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Performance Management</Link>
                </li>
                {/* <li>
                  <Link to="/admin/HRM-Payroll" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Payroll Management</Link>
                </li> */}

              </ul>
            </li>

            {/* Invoicing  */}

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="invoice"
                onClick={() => handleToggleDropdown("invoice")}
              >
                <FaMoneyBillAlt size={25} color="#28a745" />

                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Finance & Accounting
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="inventry"
                className={`${openDropdown === "invoice" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/finance-dashboard"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Finacial Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/invoice-management"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Invoice Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/expense-management"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Expense Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/payroll"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    PayrollDashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/financial-report"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Finacial Report
                  </Link>
                </li>
                {/* <li>
                                    <Link to="/admin/report-analytics" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"></Link>
                                </li> */}
              </ul>
            </li>

            {/* Design-Management */}

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="Design"
                onClick={() => handleToggleDropdown("design")}
              >
                <FaBusinessTime size={25} color="#007bff" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Design Management
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="design"
                className={`${openDropdown === "design" ? "block" : "hidden"
                  } py-2 space-y-2 ml-8`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <li>
                  <Link
                    to="/admin/design-template"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Design Templates
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/design-approval"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Design Approval Workflow
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/mood-board"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Mood Board Management
                  </Link>
                </li>

              </ul>
            </li>

            {/* Report & Analytics */}
            <li onClick={() => setIsSidebarOpen(false)}>
              <Link
                to="/admin/report-analytics"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <FaChartBar size={25} color="#007bff" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Report & Analytics
                </span>
              </Link>
            </li>

            <li onClick={() => setIsSidebarOpen(false)}>
              <Link
                to="/admin/show-quotes"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <FaFileInvoice size={25} color="#007bff" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Quotes Details
                </span>
                <span className="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-textDark">
                  Pro
                </span>
              </Link>
            </li>


            <li onClick={() => setIsSidebarOpen(false)}>
              <Link
                to="/admin/show-contacts"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <FaHandshake size={25} color="#007bff" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Contact Us
                </span>
                <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  3
                </span>
              </Link>
            </li>

            {/* feedback */}
            <li onClick={() => setIsSidebarOpen(false)}>
              <Link
                to="/admin/feedback"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <BiMessageSquareDetail size={25} color="#007bff" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Clients Feedbacks
                </span>

              </Link>
            </li>





            <li>
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("email");
                  localStorage.removeItem("userRole")
                  navigate("/login");
                }}
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <FaSignOutAlt size={25} color="#dc3545" />

                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
              </button>
            </li>



            <li className="relative">
              <div className="flex items-center ms-3">
                <button
                  type="button"
                  className="flex items-center text-sm w-full rounded-full p-1.5 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  onClick={toggleProfie}
                >
                  <img
                    className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-600"
                    src="/images/Kala_Int_PNGLogo.png"
                    alt="user photo"
                    loading='lazy'
                  />
                  <span className="ml-3 text-gray-900 uppercase font-medium dark:text-textDark">
                    {user.username || "No Name"}
                  </span>
                </button>
              </div>

              {/* Profile Dropdown Menu */}
              <div
                className={`${openProfile ? "block" : "hidden"} z-50 absolute left-0 bottom-14 mt-3 w-60 text-base list-none bg-white dark:bg-secondaryDark rounded-lg shadow-lg`}
                id="dropdown-user"
              >
                <div className="px-4 py-3 text-center border-b dark:border-primaryDark">
                  <p className="text-sm text-gray-800 font-semibold dark:text-textDark uppercase">{user.username || "No Name"}</p>
                  <p className="text-sm text-gray-500 dark:text-textDark">{user.email || "No Email"}</p>
                </div>
                <ul className="py-2">

                  {/* Conditionally render Create Admin button for admin role */}
                  {role === "admin" && (
                    <li>
                      <button
                        onClick={handleAdmin}
                        className="block px-4 py-2 text-sm text-left w-full text-gray-700 hover:bg-gray-100 dark:text-textDark dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Create Admin
                      </button>
                    </li>
                  )}
                  {role === "admin" && (
                    <li onClick={() => setIsSidebarOpen(false)}>
                      <Link
                        to="/admin/login-details"
                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      >
                        <AiOutlineLogin size={25} color="#007bff" />

                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Login Details
                        </span>

                      </Link>
                    </li>
                  )}

                  <li>
                    <button
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        localStorage.removeItem("userRole");
                        navigate("/login");
                      }}
                      className="block px-4 py-2 text-sm w-full text-left text-gray-700 hover:bg-gray-100 dark:text-textDark dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Sign out
                    </button>
                  </li>
                </ul>
              </div>
            </li>


          </ul>
        </div>
      </aside>

      <div
        className=" px-1 mt-14 sm:ml-64 dark:bg-dark"
        onClick={() => setIsSidebarOpen(false) || setOpenProfile(false)}
      >
        <Outlet />

        {/* Render the CreateAdminModal and pass the open state and close function */}
        <CreateAdminModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default Sidebar;
