import React, { useState } from "react";
import axiosInstance from "../../services/AxiosInstance";


const WorkQualification = ({employeeId}) => {
    const [newWorkExperience, setNewWorkExperience] = useState({
        company: "",
        jobTitle: "",
        fromDate: "",
        toDate: "",
        comment: "",
    });

    const [education, setEducation] = useState({
        level: "",
        institute: "",
        major: "",
        year: "",
        gpa: "",
        startDate: "",
        endDate: "",
    });

    const [loadingExperience, setLoadingExperience] = useState(false);
    const [loadingEducation, setLoadingEducation] = useState(false);

    const [experienceMessage, setExperienceMessage] = useState(""); // For experience submission message
    const [educationMessage, setEducationMessage] = useState("");

    const handleExperienceInputChange = (e) => {
        const { name, value } = e.target;
        setNewWorkExperience((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleEducationInputChange = (e) => {
        const { id, value } = e.target;
        setEducation((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleAddExperience = async (e) => {
        e.preventDefault();
        setLoadingExperience(true);
        setExperienceMessage("");
        console.log(employeeId);
        const submitWorkQualification = {
            companyName:newWorkExperience.company,
            jobTitle:newWorkExperience.jobTitle,
            experienceFrom:newWorkExperience.fromDate,
            experienceTo:newWorkExperience.toDate,
            comment:newWorkExperience.comment
        }
        try {
            const response = await axiosInstance.post(`/backend/api/employee/experience/${employeeId}`,submitWorkQualification);
            console.log("Work experience added:", response.data);
            setExperienceMessage("Work experience successfully added!"); 


            // Clear form after submission
            setNewWorkExperience({
                company: "",
                jobTitle: "",
                fromDate: "",
                toDate: "",
                comment: "",
            });
        } catch (error) {
            console.error("Error adding work experience:", error);
            setExperienceMessage("Failed to add work experience."); 
        } finally {
            setLoadingExperience(false); // End loading
        }
    };

    const handleAddEducation = async (e) => {
        e.preventDefault();
        setLoadingEducation(true);
        setEducationMessage("");
        const submitWorkQualification = {
            educationLevel:education.level,
            institute:education.institute,
            specialization:education.major,
            year:education.year,
            gpaScore:education.gpa,
            startDate:education.startDate,
            endDate:education.endDate

        }
        try {
            const response = await axiosInstance.post(`/backend/api/employee/education/${employeeId}`,education);
            console.log("Education added:", response.data);
            setEducationMessage("Education successfully added!"); 
            // Clear form after submission
            setEducation({
                level: "",
                institute: "",
                major: "",
                year: "",
                gpa: "",
                startDate: "",
                endDate: "",
            });
        } catch (error) {
            console.error("Error adding education:", error);
            setEducationMessage("Failed to add education.");
        } finally {
            setLoadingEducation(false); // End loading
        }
    };

    return (
        <div>
            <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow mb-8">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Qualifications</h1>
            </div>

            <div className="space-y-6">
                {/* Add Work Experience */}
                <h3 className="text-lg font-medium text-gray-700 dark:text-textDark">Add Work Experience</h3>
                <form onSubmit={handleAddExperience} className="bg-gray-50 dark:bg-secondaryDark shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-2">
                            <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Company<span className="text-red-500">*</span>
                            </label>
                            <input
                                id="company"
                                name="company"
                                type="text"
                                value={newWorkExperience.company}
                                onChange={handleExperienceInputChange}
                                required
                                className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder="Company name"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Job Title<span className="text-red-500">*</span>
                            </label>
                            <input
                                id="jobTitle"
                                name="jobTitle"
                                type="text"
                                value={newWorkExperience.jobTitle}
                                onChange={handleExperienceInputChange}
                                required
                                className="mt-1 block p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder="Job title"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                From
                            </label>
                            <input
                                id="fromDate"
                                name="fromDate"
                                type="date"
                                value={newWorkExperience.fromDate}
                                onChange={handleExperienceInputChange}
                                className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="toDate" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                To
                            </label>
                            <input
                                id="toDate"
                                name="toDate"
                                type="date"
                                value={newWorkExperience.toDate}
                                onChange={handleExperienceInputChange}
                                className="mt-1 block p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="space-y-2 md:col-span-2">
                            <label htmlFor="comment" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Comment
                            </label>
                            <textarea
                                id="comment"
                                name="comment"
                                value={newWorkExperience.comment}
                                onChange={handleExperienceInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 min-h-[100px]"
                                placeholder="Your comment"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 mt-4">
                        <button type="button" className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none "
                        disabled={loadingExperience} >
                            Cancel
                        </button>
                        <button type="submit" className='px-4 py-2 text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none${
                                loadingExperience ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-700"
                            }'
                            disabled={loadingExperience}>
                            {loadingExperience ? "Saving..." : "Save"}
                        </button>
                    </div>
                </form>

                {/* Add Education */}
                <h2 className="text-2xl font-semibold mb-4">Add Education</h2>
                <form onSubmit={handleAddEducation} className="bg-gray-50 dark:bg-secondaryDark shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label htmlFor="level" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Level*
                            </label>
                            <select
                                id="level"
                                value={education.level}
                                onChange={handleEducationInputChange}
                                required
                                className="mt-1 p-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                                <option value="">-- Select --</option>
                                <option value="high-school">High School</option>
                                <option value="bachelor">Bachelor's</option>
                                <option value="master">Master's</option>
                                <option value="phd">Ph.D.</option>
                            </select>
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="institute" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Institute
                            </label>
                            <input
                                type="text"
                                id="institute"
                                value={education.institute}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="Enter institute name"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="major" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Major/Specialization
                            </label>
                            <input
                                type="text"
                                id="major"
                                value={education.major}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="Enter major or specialization"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="year" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Year
                            </label>
                            <input
                                type="text"
                                id="year"
                                value={education.year}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="Enter year"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="gpa" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                GPA/Score
                            </label>
                            <input
                                type="text"
                                id="gpa"
                                value={education.gpa}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="Enter GPA or score"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                Start Date
                            </label>
                            <input
                                type="date"
                                id="startDate"
                                value={education.startDate}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 dark:text-dark">
                                End Date
                            </label>
                            <input
                                type="date"
                                id="endDate"
                                value={education.endDate}
                                onChange={handleEducationInputChange}
                                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 mt-4">
                        <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                            disabled={loadingEducation}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 text-sm font-medium rounded-md text-white ${
                                loadingEducation ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-700"
                            }`}
                            disabled={loadingEducation}
                        >
                            {loadingEducation ? "Saving..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WorkQualification;
