import React, { useState, useRef } from 'react';

export default function AudioModal({ onClose, onSendAudio }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const timerRef = useRef(null);

  const startAudioRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
  
      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };
  
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        clearInterval(timerRef.current);
        setRecordingTime(0);
      };
  
      mediaRecorder.start();
      setIsRecording(true);
  
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (err) {
      console.error('Error accessing audio device:', err);
    }
  };
  
  const stopAudioRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  
    if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleClose = () => {
    if (isRecording) {
      stopAudioRecording();
    }
    setAudioUrl(null);
    onClose();
  };

  const handleSend = () => {
    if (audioUrl) {
      onSendAudio(audioUrl); // Sends the audio URL to the parent component
      handleClose(); // Closes the modal after sending
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-lg font-semibold mb-2">Audio Recording</h2>
        
        <div className="bg-gray-200 w-full h-24 rounded mb-4 flex items-center justify-center">
          {isRecording ? (
            <p className="text-lg font-semibold text-red-600">
              Recording: {formatTime(recordingTime)}
            </p>
          ) : (
            <p className="text-gray-700">Press start to record audio</p>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            onClick={() => {
              if (isRecording) {
                stopAudioRecording();
              } else {
                setAudioUrl(null);
                startAudioRecording();
              }
            }}
          >
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </button>

          {audioUrl && (
            <>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                onClick={() => setAudioUrl(null)}
              >
                Delete
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                onClick={handleSend}
              >
                Send
              </button>
            </>
          )}

          <button
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition-colors"
            onClick={handleClose}
          >
            Close
          </button>
        </div>

        {audioUrl && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Recording Preview</h3>
            <audio className="w-full" controls>
              <source src={audioUrl} type="audio/webm" />
            </audio>
          </div>
        )}
      </div>
    </div>
  );
}