// KPI_Dashboard.js
import React from 'react';
import { Line, Bar } from 'react-chartjs-2';

const KPIDashboard = ({ handleFilter, filteredData, lineData, barData, projectDepartment, setProjectDepartment }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2">KPI Dashboard</h2>
      <p className="text-gray-600 mb-4">Track key performance indicators across projects and departments.</p>
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label htmlFor="time-range" className="block text-sm font-medium text-gray-700">Time Range</label>
            <select id="time-range" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
              <option>Select time range</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="annually">Annually</option>
            </select>
          </div>
          <div className="space-y-2">
            <label htmlFor="project-department" className="block text-sm font-medium text-gray-700">Project/Department</label>
            <select
              id="project-department"
              value={projectDepartment}
              onChange={(e) => setProjectDepartment(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
            >
              <option>Select project or department</option>
              <option value="project1">Project 1</option>
              <option value="project2">Project 2</option>
            </select>
          </div>
        </div>
        <button onClick={handleFilter} className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Show Dashboard
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm font-medium text-gray-500 truncate">{item.title}</dt>
                        <dd>
                          <div className="text-lg font-medium text-gray-900">{item.value}</div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-5 py-3">
                  <div className="text-sm">
                    <span className="font-medium text-green-600 hover:text-green-900">{item.change}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="col-span-4 text-gray-500">No data found for the selected criteria.</p>
          )}
        </div>
        <div className="space-y-4">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">KPI Trends</h3>
              <div className="mt-5 h-64 bg-gray-200">
                <Line data={lineData} />
              </div>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Performance Comparison</h3>
              <div className="mt-5 h-64 bg-gray-200">
                <Bar data={barData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPIDashboard;
