import { useState, useEffect } from "react";
import React from "react";
import axiosInstance from "../../services/AxiosInstance";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✖
        </button>
        {children}
      </div>
    </div>
  );
};

export default function StockManagement({ newStock }) {
  const [stock, setStock] = useState([]);
  const [movements, setMovements] = useState([]);
  const [activeTab, setActiveTab] = useState("stock");
  const [newMovement, setNewMovement] = useState({ productId: "", type: "inflow", quantity: "", reason: "Purchase", location: "" });
  const [stockSearch, setStockSearch] = useState("");
  const [movementSearch, setMovementSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [movedStockIds, setMovedStockIds] = useState(new Set()); // Track moved stock IDs

  // Fetch stock data from API
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/backend/api/products");
        setStock(response.data);
      } catch (error) {
        setErrorMessage("Failed to fetch stock data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  useEffect(() => {
    // console.log(newStock);

    if (newStock && newStock.length > 0) {
      setStock((prev) => [...prev, ...newStock]);
    }
  }, [newStock]);

  useEffect(() => {
    const fetchStockMovements = async () => {
      try {
        const response = await axiosInstance.get("/backend/api/stock-movements");
        setMovements(response.data);
      } catch (error) {
        setErrorMessage("Failed to fetch stock movements. Please try again.");
      }
    };

    fetchStockMovements();
  }, []);

  const handleNewMovement = async (e) => {
    e.preventDefault();
    try {
      const updatedStock = stock.map((item) => {
        if (item.id === parseInt(newMovement.productId)) {
          const newQuantity =
            newMovement.type === "inflow"
              ? item.quantity + parseInt(newMovement.quantity)
              : item.quantity - parseInt(newMovement.quantity);

          if (newQuantity <= 0) {
            // If quantity becomes 0 or less, mark it as moved
            setMovedStockIds((prev) => new Set(prev.add(item.id)));
          }

          return {
            ...item,
            quantity: newQuantity,
            location: newMovement.location || item.location,
          };
        }
        return item;
      });

      const newMovementEntry = {
        movementType: newMovement.type,
        quantityMoved: parseInt(newMovement.quantity),
        dateOfMovement: new Date().toISOString().split("T")[0],
        location: newMovement.location,
        reason: newMovement.reason,
      };

      // Post updated stock movement to backend
      await axiosInstance.post(`/backend/api/stock-movements/${newMovement.productId}`, newMovementEntry);

      // Update stock and movements in the state
      setStock(updatedStock);
      setMovements((prevMovements) => [...prevMovements, newMovementEntry]);

      setNewMovement({ productId: "", type: "inflow", quantity: "", reason: "Purchase", location: "" });
      setIsModalOpen(false);
    } catch (error) {
      setErrorMessage("Failed to update stock. Please try again.");
    }
  };

  const handleUpdateStockClick = (productId) => {
    setNewMovement({ ...newMovement, productId });
    setIsModalOpen(true);
  };

  return (
    <div className="mx-auto w-full bg-gray-100 rounded-lg shadow-md dark:bg-secondaryDark">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">Stock Management</h1>

      <div className="flex justify-around mb-4">
        <button onClick={() => setActiveTab("stock")} className={`py-2 px-4 ${activeTab === "stock" ? "bg-colorBlue text-white " : "bg-gray-300 text-gray-700"} rounded-md`}>
          Current Stock Levels
        </button>
        <button onClick={() => setActiveTab("movements")} className={`py-2 px-4 ${activeTab === "movements" ? "bg-colorBlue text-white " : "bg-gray-300 text-gray-700"} rounded-md`}>
          Stock Movement Tracking
        </button>
      </div>

      {loading ? (
        <p className="text-center text-gray-500">Loading stock data...</p>
      ) : errorMessage ? (
        <p className="text-center text-red-500">{errorMessage}</p>
      ) : (
        <>
          {activeTab === "stock" && (
            <div className="bg-gray-50 dark:bg-secondaryDark p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-dark">Current Stock Levels</h2>

              <input
                type="text"
                placeholder="Search by name or SKU"
                value={stockSearch}
                onChange={(e) => setStockSearch(e.target.value)}
                className="mb-4 w-full p-2 border border-gray-300 rounded-md"
              />
              <div className="overflow-y-auto">

                <table className="table-auto w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-200 text-left dark:bg-primaryDark dark:text-gray-50 text-gray-600 text-nowrap">
                      <th className="p-3 font-medium ">Item Name</th>
                      <th className="p-3 font-medium ">SKU</th>
                      <th className="p-3 font-medium ">Quantity</th>
                      <th className="p-3 font-medium ">Min Level</th>
                      <th className="p-3 font-medium ">Max Level</th>
                      <th className="p-3 font-medium ">Reason</th>
                      <th className="p-3 font-medium ">Location</th>
                      <th className="p-3 font-medium ">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(stock) && stock
                      .filter((item) => !movedStockIds.has(item.id)) // Filter out moved stock
                      .filter((item) =>
                        item.name.toLowerCase().includes(stockSearch.toLowerCase()) ||
                        item.skuNumber.toLowerCase().includes(stockSearch.toLowerCase())
                      )
                      .map((item) => (
                        <tr key={item.id} className="border-b">
                          <td className="p-3">{item.name}</td>
                          <td className="p-3">{item.skuNumber}</td>
                          <td className="p-3">{item.quantity}</td>
                          <td className="p-3">{item.minStockLevel}</td>
                          <td className="p-3">{item.maxStockLevel}</td>
                          <td className="p-3">{item.reorderPoint}</td>
                          <td className="p-3">{item.stockLocation}</td>
                          <td className="p-3">
                            <button
                              onClick={() => handleUpdateStockClick(item.id)}
                              className="bg-colorBlue text-white py-1 px-2 rounded text-nowrap"
                            >
                              Update Stock
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>

                </table>
              </div>

            </div>
          )}

          {activeTab === "movements" && (
            <div className="bg-gray-50 p-6 rounded-lg shadow-lg dark:bg-secondaryDark">
              <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-dark">Stock Movement Tracking</h2>

              <input
                type="text"
                placeholder="Search by product name"
                value={movementSearch}
                onChange={(e) => setMovementSearch(e.target.value)}
                className="mb-4 w-full p-2 border border-gray-300 rounded-md"
              />

              <table className="table-auto w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200 text-left dark:bg-primaryDark dark:text-gray-50 text-gray-600">
                    <th className="p-3 font-medium ">Product</th>
                    <th className="p-3 font-medium ">Movement Type</th>
                    <th className="p-3 font-medium ">Quantity Moved</th>
                    <th className="p-3 font-medium ">Date of Movement</th>
                    <th className="p-3 font-medium ">Location</th>
                    <th className="p-3 font-medium ">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(movements) &&
                    movements
                      .filter((movement) =>
                        movement.product.name.toLowerCase().includes(movementSearch.toLowerCase())
                      )
                      .map((movement, index) => (
                        <tr key={index} className="border-b">
                          <td className="p-3">{movement.product.name}</td>
                          <td className="p-3">{movement.movementType}</td>
                          <td className="p-3">{movement.quantityMoved}</td>
                          <td className="p-3">{movement.dateOfMovement}</td>
                          <td className="p-3">{movement.location}</td>
                          <td className="p-3">{movement.reason}</td>
                        </tr>
                      ))}
                </tbody>

              </table>
            </div>
          )}

          {/* Modal for New Movement */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}   >
            <div className="bg-gray-50 ">

              <h2 className="text-2xl font-semibold mb-4 dark:text-dark">Update Stock Movement</h2>
              <form onSubmit={handleNewMovement} className="">
                <div className="mb-4">
                  <label className="block mb-2" htmlFor="productId">Product ID</label>
                  <input
                    type="text"
                    id="productId"
                    value={newMovement.productId}
                    onChange={(e) => setNewMovement({ ...newMovement, productId: e.target.value })}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>

                <div className="mb-4">
                  <label className="block mb-2" htmlFor="type">Movement Type</label>
                  <select
                    id="type"
                    value={newMovement.type}
                    onChange={(e) => setNewMovement({ ...newMovement, type: e.target.value })}
                    className="border border-gray-300 p-2 rounded w-full"
                  >
                    <option value="inflow">Inflow</option>
                    <option value="outflow">Outflow</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block mb-2" htmlFor="quantity">Quantity</label>
                  <input
                    type="number"
                    id="quantity"
                    value={newMovement.quantity}
                    onChange={(e) => setNewMovement({ ...newMovement, quantity: e.target.value })}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>

                <div className="mb-4">
                  <label className="block mb-2" htmlFor="reason">Reason</label>
                  <input
                    type="text"
                    id="reason"
                    value={newMovement.reason}
                    onChange={(e) => setNewMovement({ ...newMovement, reason: e.target.value })}
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>

                <div className="mb-4">
                  <label className="block mb-2" htmlFor="location">Location</label>
                  <input
                    type="text"
                    id="location"
                    value={newMovement.location}
                    onChange={(e) => setNewMovement({ ...newMovement, location: e.target.value })}
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>

                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                  Submit Movement
                </button>
              </form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}
