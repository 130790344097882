import React, { useState } from 'react'
import Sidebar from '../components/Communicationmodule/Sidebar';
import MainContent from '../components/Communicationmodule/MainContent';

export default function SlackLayout() {
  const [currentChannel, setCurrentChannel] = useState('Group 2');
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  return (
    <div className="flex h-screen w-full bg-gray-100 rounded-lg shadow-md">
      <Sidebar 
        currentChannel={currentChannel} 
        setCurrentChannel={setCurrentChannel}
        selectedEmployee={selectedEmployee}  // Add this prop
        setSelectedEmployee={setSelectedEmployee}
      />
      <MainContent 
        currentChannel={currentChannel} 
        selectedEmployee={selectedEmployee}
      />
    </div>
  );
}
