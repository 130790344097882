import React, { useState } from "react";

// Mock data
const expenses = [
  { id: 1, projectName: "Project A", expenseDate: "2023-05-01", amount: 1500.0, category: "Travel", description: "Team travel for client meeting" },
  { id: 2, projectName: "Project B", expenseDate: "2023-05-03", amount: 500.5, category: "Supplies", description: "Office supplies" },
  { id: 3, projectName: "Project A", expenseDate: "2023-05-05", amount: 2000.0, category: "Labor", description: "Contractor payment" },
  { id: 4, projectName: "Overhead", expenseDate: "2023-05-07", amount: 1000.0, category: "Rent", description: "Office rent" },
  { id: 5, projectName: "Project C", expenseDate: "2023-05-10", amount: 750.25, category: "Software", description: "Software license renewal" },
];

export default function ExpenseManagementDashboard() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentTab, setCurrentTab] = useState("all");
  const [sortBy, setSortBy] = useState("dateDesc");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [newExpense, setNewExpense] = useState({
    projectName: "",
    expenseDate: "",
    amount: "",
    category: "",
    description: ""
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredExpenses = expenses
    .filter(expense => {
      const matchesSearch =
        expense.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.category.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTab =
        currentTab === "all" ||
        (currentTab === "project" && expense.projectName !== "Overhead") ||
        (currentTab === "overhead" && expense.projectName === "Overhead");
      const matchesCategory = categoryFilter === "all" || expense.category === categoryFilter;

      return matchesSearch && matchesTab && matchesCategory;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case "dateAsc":
          return new Date(a.expenseDate).getTime() - new Date(b.expenseDate).getTime();
        case "dateDesc":
          return new Date(b.expenseDate).getTime() - new Date(a.expenseDate).getTime();
        case "amountAsc":
          return a.amount - b.amount;
        case "amountDesc":
          return b.amount - a.amount;
        default:
          return 0;
      }
    });

  const totalExpenses = filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExpense((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New expense submitted:", newExpense);
    setNewExpense({
      projectName: "",
      expenseDate: "",
      amount: "",
      category: "",
      description: ""
    });
    setIsModalOpen(false);
  };

  return (
    <div className="bg-inherit container mx-auto p-6">
      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark">
        <h1 className="text-3xl font-bold ">Expense Management Dashboard</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-colorBlue text-white px-4 py-2 rounded-md"
        >
          Add Expense
        </button>
      </div>

      {/* Filter and Search */}
      <div className="mb-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 gap-4 md:gap-0">
          <input
            type="text"
            placeholder="Search expenses..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border rounded-md p-2 w-full md:max-w-sm"
          />
          <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
            <select
              onChange={(e) => setCategoryFilter(e.target.value)}
              className="border rounded-md p-2 w-full md:w-auto"
            >
              <option value="all">All Categories</option>
              <option value="Travel">Travel</option>
              <option value="Supplies">Supplies</option>
              <option value="Labor">Labor</option>
              <option value="Rent">Rent</option>
              <option value="Software">Software</option>
            </select>
            <select
              onChange={(e) => setSortBy(e.target.value)}
              className="border rounded-md p-2 w-full md:w-auto"
            >
              <option value="dateDesc">Date (Newest First)</option>
              <option value="dateAsc">Date (Oldest First)</option>
              <option value="amountDesc">Amount (High to Low)</option>
              <option value="amountAsc">Amount (Low to High)</option>
            </select>
          </div>
        </div>
        <div className="flex items-start mb-2">
          <button
            onClick={() => setCurrentTab("all")}
            className={`px-4 py-2  ${currentTab === "all" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"}`}
          >
            All Expenses
          </button>
          <button
            onClick={() => setCurrentTab("project")}
            className={`px-4 py-2  ${currentTab === "project" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"}`}
          >
            Project-Specific
          </button>
          <button
            onClick={() => setCurrentTab("overhead")}
            className={`px-4 py-2  ${currentTab === "overhead" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"}`}
          >
            Overhead
          </button>
        </div>

        {/* Expenses Table */}
        <div className="overflow-x-auto bg-gray-50 dark:bg-secondaryDark p-4 sm:p-6 rounded-lg shadow-md border border-1">
          <table className="min-w-full">
            <thead>
              <tr className="text-left">
                <th className="p-2 sm:p-4">Project Name</th>
                <th className="p-2 sm:p-4">Expense Date</th>
                <th className="p-2 sm:p-4">Amount</th>
                <th className="p-2 sm:p-4">Category</th>
                <th className="p-2 sm:p-4">Description</th>
              </tr>
            </thead>
            <tbody>
              {filteredExpenses.map((expense) => (
                <tr key={expense.id} className="border-t">
                  <td className="p-2 sm:p-4">{expense.projectName}</td>
                  <td className="p-2 sm:p-4">{expense.expenseDate}</td>
                  <td className="p-2 sm:p-4">${expense.amount.toFixed(2)}</td>
                  <td className="p-2 sm:p-4">{expense.category}</td>
                  <td className="p-2 sm:p-4">{expense.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Adding Expense */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-semibold mb-4">Add New Expense</h2>
            <form onSubmit={handleSubmit}>
              <div className="grid gap-4">
                <div className="mb-2">
                  <label className="font-semibold mr-4">Expense Type:</label>
                  <label className="mr-4">
                    <input
                      type="radio"
                      name="expenseType"
                      value="overhead"
                      checked={newExpense.expenseType === 'overhead'}
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    Overhead
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="expenseType"
                      value="projectSpecific"
                      checked={newExpense.expenseType === 'projectSpecific'}
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    Project Specific
                  </label>
                </div>

                {/* Conditionally show project selection field if "Project Specific" is selected */}
                {newExpense.expenseType === 'projectSpecific' && (
                  <div className="mb-2">
                    <input
                      type="text"
                      name="projectName"
                      placeholder="Project Name"
                      value={newExpense.projectName}
                      onChange={handleInputChange}
                      className="border rounded-md p-2 w-full"
                      required
                    />
                  </div>
                )}

                <input
                  type="date"
                  name="expenseDate"
                  value={newExpense.expenseDate}
                  onChange={handleInputChange}
                  className="border rounded-md p-2"
                  required
                />
                <input
                  type="number"
                  name="amount"
                  placeholder="Amount"
                  value={newExpense.amount}
                  onChange={handleInputChange}
                  className="border rounded-md p-2"
                  required
                />
                <select
                  name="category"
                  value={newExpense.category}
                  onChange={handleInputChange}
                  className="border rounded-md p-2"
                  required
                >
                  <option value="">Select Category</option>
                  <option value="Travel">Travel</option>
                  <option value="Supplies">Supplies</option>
                  <option value="Labor">Labor</option>
                  <option value="Rent">Rent</option>
                  <option value="Software">Software</option>
                </select>
                <textarea
                  name="description"
                  placeholder="Description"
                  value={newExpense.description}
                  onChange={handleInputChange}
                  className="border rounded-md p-2 h-24"
                ></textarea>
              </div>
              <div className="flex justify-end gap-4 mt-4">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 rounded-md bg-gray-300"
                >
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 rounded-md bg-colorBlue text-white">
                  Add Expense
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}
