import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Next Arrow
const NextArrow = ({ onClick }) => (
    <div
        className="absolute right-[-35px] top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
        onClick={onClick}
    >
        <button
            className="bg-gradient-to-r from-blue-500 to-purple-500 text-white p-3 rounded-full shadow-lg hover:scale-110 transform transition-transform duration-300 hover:shadow-2xl"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                />
            </svg>
        </button>
    </div>
);

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => (
    <div
        className="absolute left-[-35px] top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
        onClick={onClick}
    >
        <button
            className="bg-gradient-to-r from-purple-500 to-blue-500 text-white p-3 rounded-full shadow-lg hover:scale-110 transform transition-transform duration-300 hover:shadow-2xl"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </button>
    </div>
);

const ImageSlider = ({ images, isLTR, onImageChange }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        rtl: !isLTR,
        centerMode: true,
        centerPadding: '30px',
        nextArrow: <NextArrow />, 
        prevArrow: <PrevArrow />, 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '20px',
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '15px',
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '10px',
                }
            }
        ],
        afterChange: (index) => {
            if (onImageChange) {
                onImageChange(index);
            }
        }
    };

    return (
        <div className="w-full m-auto px-6 relative">
            <div>
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="bg-white text-black rounded-xl mb-4 px-1 mx-2"
                        >
                            <div className="w-full h-full flex justify-center items-center rounded-xl overflow-hidden">
                                <img
                                    src={image}
                                    alt={`image-${index}`}
                                    className="w-[400px] h-[300px] object-cover rounded-lg"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
