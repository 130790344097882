import { Star } from 'lucide-react';
import React from 'react';

const PerformanceGoals = ({ reviews }) => {
  return (
    <div className="bg-gray-50 dark:bg-secondaryDark shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Performance Goals</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="text-gray-700 dark:text-dark">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Metric</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Rating</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Feedback</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Goal Achieved</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Employee</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {reviews && reviews.length > 0 ? (
              reviews.map((review) => (
                <tr key={review.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {review.metric || 'No Metric'}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex items-center">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          className={`h-4 w-4 cursor-pointer ${star <= (review.rating || 0) ? 'text-yellow-400 fill-current' : 'text-gray-300'
                            }`}
                        />
                      ))}
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {review.feedback || 'No Feedback'}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {review.goalAchieved || 'No Goal Achieved'}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div>
                      <div className="font-bold">
                        {review.employee?.fullName || 'No Name Provided'}
                      </div>
                      <div className="text-sm text-gray-500">
                        {review.employee?.role || 'No Role Provided'}
                      </div>
                      <div className="text-sm text-gray-500">
                        {review.employee?.email || 'No Email Provided'}
                      </div>
                      <div className="text-sm text-gray-500">
                        {review.employee?.phoneNumber || 'No Phone Number Provided'}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-900">
                  No reviews available.
                </td>
              </tr>
            )}
          </tbody>


        </table>
      </div>
    </div>
  );
};

export default PerformanceGoals;
