import { useState, useEffect } from "react";
import PerformanceManagement from "./PerformanceManagement";
import PerformanceReview from "./PerformanceReview";
import axiosInstance from "../../services/AxiosInstance";

const HrmPerformanceLayout = () => {
    // States for loading, error, and data
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reviews, setReviews] = useState([]);

    // Fetch performance reviews from the API
    useEffect(() => {
        const fetchPerformanceReviews = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get("/backend/api/performance-reviews/all");
                setReviews(response.data);
            } catch (err) {
                setError("Failed to fetch performance reviews.");
            } finally {
                setLoading(false);
            }
        };

        fetchPerformanceReviews();
    }, []);

    // Function to handle adding a new review
        const handleReview = (review) => {
            setReviews((prev) =>  [...prev, review] )
        };

       
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark overflow-y-scroll">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl h-[calc(100vh-4rem)] overflow-y-scroll">
                {/* Left side - Performance Review Component */}

                <div className="h-[calc(100vh-4rem)] overflow-y-auto">

                    <PerformanceReview addReview={handleReview} />
                </div>
                {/* Right side - Performance Management */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <p className="text-gray-500 dark:text-gray-300">Loading...</p>
                        </div>
                    ) : error ? (
                        <div className="flex justify-center items-center h-full">
                            <p className="text-red-500 dark:text-red-300">{error}</p>
                        </div>
                    ) : (
                        <PerformanceManagement reviews={reviews} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HrmPerformanceLayout;
