import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the default styles

const CircularProgressIndicator = ({ progress }) => {
    return (
        <div className="p-4 sm:p-6 lg:p-8 flex justify-center">
            <div className="w-40 h-40">
                <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    styles={buildStyles({
                        textSize: '16px',
                        pathColor: '#4db6ac',
                        textColor: '#4db6ac',
                        trailColor: '#dcdcdc',
                    })}
                />
            </div>
        </div>
    );
};

export default CircularProgressIndicator;
