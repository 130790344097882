import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/AxiosInstance";

const EmergencyDetails = ({ employeeId, employee, addEmergencyDetails, deleteEmergency }) => {
    const [newEmergencyContact, setNewEmergencyContact] = useState({
        name: "",
        relationship: "",
        homePhone: "",
        mobilePhone: "",
        workPhone: "",
    });
    const [emergencyContacts, setEmergencyContacts] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState(null); // Message for success or error
    const [error, setError] = useState(null); // Error state

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEmergencyContact((prev) => ({ ...prev, [name]: value }));
    };


    // Handle add emergency contact
    const handleAddEmergencyContact = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axiosInstance.post(
                `/backend/api/employee/emergency/${employeeId}`,
                newEmergencyContact
            );
            if (response.status === 200) {
                addEmergencyDetails(response.data)
                // setEmergencyContacts((prev) => [...prev, response.data]);
                setNewEmergencyContact({
                    name: "",
                    relationship: "",
                    homePhone: "",
                    mobilePhone: "",
                    workPhone: "",
                });
                setMessage("Emergency contact added successfully!");
            }

        } catch (err) {
            console.error("Error adding emergency contact:", err);
            setError("Failed to add emergency contact.");
        } finally {
            setLoading(false);
        }
    };

    // Handle delete emergency contact
    const handleDeleteContact = async (id) => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/backend/api/employee/emergency/${id}`);

            deleteEmergency(id)
            setMessage("Contact deleted successfully!");
        } catch (err) {
            console.error("Error deleting contact:", err);
            setError("Failed to delete contact.");
        } finally {
            setLoading(false);
        }
    };

    // Render loading spinner or text
    const renderLoading = () =>
        loading ? <p className="text-blue-500">Loading...</p> : null;

    // Render success or error messages
    const renderMessage = () =>
        message ? <p className="text-green-500">{message}</p> : null;

    const renderError = () =>
        error ? <p className="text-red-500">{error}</p> : null;

    return (
        <div>
            <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow mb-8">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">
                    Emergency Contacts
                </h1>
            </div>

            {/* Add Emergency Contact Form */}
            <form
                onSubmit={handleAddEmergencyContact}
                className="bg-gray-50 dark:bg-secondaryDark shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                        Name*
                    </label>
                    <input
                        className="border rounded w-full py-2 px-3"
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        value={newEmergencyContact.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                        Relationship*
                    </label>
                    <input
                        className="border rounded w-full py-2 px-3"
                        name="relationship"
                        type="text"
                        placeholder="Relationship"
                        value={newEmergencyContact.relationship}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="grid grid-cols-3 gap-4 mb-6">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                            Home Phone
                        </label>
                        <input
                            className="border rounded w-full py-2 px-3"
                            name="homePhone"
                            type="tel"
                            placeholder="Home Phone"
                            value={newEmergencyContact.homePhone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                            Mobile Phone
                        </label>
                        <input
                            className="border rounded w-full py-2 px-3"
                            name="mobilePhone"
                            type="tel"
                            placeholder="Mobile Phone"
                            value={newEmergencyContact.mobilePhone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark">
                            Work Phone
                        </label>
                        <input
                            className="border rounded w-full py-2 px-3"
                            name="workPhone"
                            type="tel"
                            placeholder="Work Phone"
                            value={newEmergencyContact.workPhone}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-dark dark:hover:bg-primaryDark"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? "Adding..." : "Add Contact"}
                    </button>
                </div>
            </form>

            {/* Display Emergency Contacts */}
            {renderLoading()}
            {renderMessage()}
            {renderError()}
            <ul className="bg-gray-50 rounded-lg shadow-md p-6 divide-y divide-gray-200 dark:bg-secondaryDark dark:shadow-none">
                {employee?.emergencyDetails.map((contact) => (
                    <li key={contact.id} className="py-4">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-semibold text-gray-800 dark:text-dark">{contact.name}</h3>
                            <button
                                className="text-sm font-medium text-red-500 hover:text-red-700 dark:text-red-400 disabled:opacity-50"
                                onClick={() => handleDeleteContact(contact.id)}
                                disabled={loading}
                            >
                                {loading ? "Deleting..." : "Delete"}
                            </button>
                        </div>
                        <p className="text-sm text-gray-600 dark:text-dark">
                            <span className="font-medium">Relationship:</span> {contact.relationship}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-dark">
                            <span className="font-medium">Mobile Number:</span> {contact.mobilePhone || "N/A"}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-dark">
                            <span className="font-medium">Home Contact:</span> {contact.homePhone || "N/A"}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-dark">
                            <span className="font-medium">Work Contact:</span> {contact.workPhone || "N/A"}
                        </p>
                    </li>
                ))}
            </ul>

        </div>
    );
};

export default EmergencyDetails;
