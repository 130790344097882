import { AttendanceDashboard } from "./AttendanceDashboard";
import { LeaveStatus } from "./LeaveStatus";

export default function AttendanceManagement({ error, loading, employee, handleCheckIn , setEmployee }) {



  return (
    <div className="container mx-auto p-2 space-y-4 bg-gray-100 rounded-lg shadow-md dark:bg-dark">
      <div className=" flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-8 dark:bg-secondaryDark">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Attendance Management</h1>
        <p className="text-green-600 text-2xl dark:text-textDark ">
          Welcome, <span className="font-semibold dark:text-textDark">{employee?.fullName || 'Employee'}</span>!
        </p>
      </div>

      {loading && <p>Loading...</p>} {/* Display loading text */}
      {error && <p className="text-red-500">{error}</p>} {/* Display error message */}

      <AttendanceDashboard employee={employee} error={error} loading={loading} addCheckIn={handleCheckIn} setEmployee={setEmployee} />
      {/* <LeaveRequestForm /> */}
      <LeaveStatus employee={employee} error={error} loading={loading} />
    </div>
  );
}
