import React from 'react';
import { FiPlus, FiSmile, FiAtSign, FiVideo, FiMic } from 'react-icons/fi';
import { AiOutlineSend } from 'react-icons/ai';

export default function ActionButtons({
  handleFileClick,
  toggleEmojiPicker,
  insertAtSymbol,
  openVideoModal,
  openAudioModal,
  handleSendMessage,
  emojiButtonRef, // Pass the ref for positioning
}) {
  return (
    <div className="flex flex-wrap space-x-2 space-y-2 sm:space-x-2 sm:space-y-0 items-center justify-center sm:justify-start">
      <button className="text-gray-500 hover:text-gray-800" onClick={handleFileClick}>
        <FiPlus size={20} />
      </button>
      <button
        ref={emojiButtonRef} // Attach ref to the smile button
        onClick={toggleEmojiPicker}
        className="text-gray-500 hover:text-gray-800"
      >
        <FiSmile size={20} />
      </button>
      <button className="text-gray-500 hover:text-gray-800" onClick={insertAtSymbol}>
        <FiAtSign size={20} />
      </button>
      <button className="text-gray-500 hover:text-gray-800" onClick={openVideoModal}>
        <FiVideo size={20} />
      </button>
      <button className="text-gray-500 hover:text-gray-800" onClick={openAudioModal}>
        <FiMic size={20} />
      </button>
      <button className="text-blue-500 hover:bg-blue-100 px-2 py-2 rounded-lg" onClick={handleSendMessage}>
        <AiOutlineSend size={20} />
      </button>
    </div>
  );
}
