
import PropTypes from "prop-types";
import styles from "./frame-component2.module.css";
import AccordionItem from "./AccordionItem";
import { useState } from "react";
import ContactModal from "./ContactModal";

const FrameComponent2 = ({ className = "" }) => {


  // const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // // Function to open the contact modal
  // const openContactModal = () => {
  //   setIsContactModalOpen(true);
  //   console.log(isContactModalOpen);

  // };

  // // Function to close the contact modal
  // const closeContactModal = () => {
  //   setIsContactModalOpen(false);
  // };


  return (
    <div className={[styles.faq2Wrapper, className].join(" ")}>
      <div className={styles.faq2}>
        <div className={styles.sectionTitle}>
          <b className={styles.faqs}>FAQs</b>
          <div
            className={styles.fAQ}
          >{`Everything You Need to Know About Interior Design `}</div>
        </div>
        <div className={styles.accordion}>

          <AccordionItem />


        </div>
        <div className={styles.content}>
          <div className={styles.content1}>
            <b className={styles.stillHaveA}>Still have a questions?</b>
            <div className={styles.text}>
              Have More Questions?
            </div>
          </div>
          <a href="tel:8378989511" className={styles.button}  >
            <b className={styles.button1}>Contact</b>
          </a>
        </div>
      </div>
      {/* {isContactModalOpen && <ContactModal onClose={closeContactModal} openContact={isContactModalOpen} />} */}
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
