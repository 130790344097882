import React, { useState, useRef } from 'react';
import FormattingButtons from './FormattingButtons';
import ActionButtons from './ActionButtons';
import EmojiPicker from './EmojiPicker';
import VideoModal from './VideoRecordModal';
import AudioModal from './AudioRecordModal';

export default function MessageInput({ onSendMessage, onVideoMessage, onAudioMessage, onFileUpload }) {
  const [text, setText] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const editableRef = useRef(null);
  const fileInputRef = useRef(null);
  const emojiButtonRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      onFileUpload(fileUrl, file.name, file.type);
      event.target.value = ''; // Reset input
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji) => {
    document.execCommand('insertText', false, emoji);
    setShowEmojiPicker(false);
  };

  const insertAtSymbol = () => {
    document.execCommand('insertText', false, '@');
  };

  const handleSendMessage = () => {
    if (text.trim()) {
      onSendMessage(text);
      setText('');
      editableRef.current.innerText = '';
    }
  };

  const handleVideoSend = (videoUrl) => {
    onVideoMessage(videoUrl);
    setShowVideoModal(false);
  };

  const handleAudioSend = (audioUrl) => {
    onAudioMessage(audioUrl);
    setShowAudioModal(false);
  };

  return (
    <div className="border-t border-gray-200 p-4 bg-white">
      <div className="flex flex-col sm:flex-row items-center sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
        {/* Formatting Buttons */}
        <div className="flex-shrink-0">
          <FormattingButtons />
        </div>

        {/* Editable Input Field */}
        <div
          ref={editableRef}
          contentEditable
          className="flex-grow border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 min-w-0"
          style={{ minHeight: '40px' }}
          placeholder="Message #hr"
          onInput={() => setText(editableRef.current.innerHTML)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />

        {/* Action Buttons */}
        <div className="flex flex-shrink-0">
          <ActionButtons
            handleFileClick={handleFileClick}
            toggleEmojiPicker={toggleEmojiPicker}
            insertAtSymbol={insertAtSymbol}
            openVideoModal={() => setShowVideoModal(true)}
            openAudioModal={() => setShowAudioModal(true)}
            handleSendMessage={handleSendMessage}
            emojiButtonRef={emojiButtonRef}
          />
        </div>
      </div>

      {/* Hidden File Input */}
      <input 
        ref={fileInputRef} 
        type="file" 
        onChange={handleFileChange} 
        className="hidden"
        accept="image/*,.pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx,.csv,.zip,.rar,.7z"
      />

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <EmojiPicker onEmojiSelect={handleEmojiSelect} buttonRef={emojiButtonRef} />
      )}

      {/* Video Modal */}
      {showVideoModal && (
        <VideoModal 
          onClose={() => setShowVideoModal(false)}
          onSendVideo={handleVideoSend}
        />
      )}

      {/* Audio Modal */}
      {showAudioModal && (
        <AudioModal 
          onClose={() => setShowAudioModal(false)}
          onSendAudio={handleAudioSend}
        />
      )}
    </div>
  );
}
