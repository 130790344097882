import PropTypes from "prop-types";

const MapsFullMap = ({ className = "", onContactClick }) => {
  return (
    <div className={`p-2 rounded-lg shadow-lg bg-gradient-to-r from-gray-800 to-gray-900 ${className}`}>
      <div className="flex flex-col gap-6">
        {/* Map Embed */}
        <div className="relative overflow-hidden rounded-lg shadow-md">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1890.3958978307482!2d73.848612!3d18.630966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9f8a23d41cd%3A0x9a5c98d4f111dd3e!2sIndrayani%20Nagar%2C%20Bhosari%2C%20Pune%2C%20Maharashtra%20411026!5e0!3m2!1sen!2sin!4v1728000000000!5m2!1sen!2sin"
            className="w-full h-48  rounded-lg border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        {/* Contact Button */}
        <div className="text-center">
          <button
            className="px-6 py-3 bg-colorBlue text-white rounded-full shadow-lg hover:scale-105 transition-transform focus:outline-none focus:ring-4 focus:ring-blue-300"
            onClick={onContactClick}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};


MapsFullMap.propTypes = {
  className: PropTypes.string,
  onContactClick: PropTypes.func.isRequired,
};

export default MapsFullMap;
