import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from '../../services/AxiosInstance';

function Invoicing({ newInvoice, customers }) {
    const today = new Date().toISOString().split('T')[0];

  const [formData, setFormData] = useState({
    customerId: '',
    invoiceDate: today,
    dueDate: '',
    itemDescription: '',

    total: '',
    notes: '',
    status: '', // Add status to form data
  });

  const [customerLoading, setCustomerLoading] = useState(false)

  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [statusOptions] = useState([
    { value: 'paid', label: 'Paid' },
    { value: 'unpaid', label: 'Unpaid' },
  ]); // Status options

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      customerId: selectedOption.value, // Set customer ID
    }));
  };

  const handleStatusChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      status: selectedOption.value, 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');


    try {
      const response = await axiosInstance.post(`/backend/api/invoices/${formData.customerId}`, {

        invoiceDate: formData.invoiceDate,
        dueDate: formData.dueDate,
        status: formData.status,
        totalAmount: formData.total
      });
      if (response.status === 200) {
        setIsSubmitted(true);
        newInvoice(response.data)
        setSuccessMessage('Invoice created successfully!');
        // Reset the form data
        setFormData({
          customerId: '',
          invoiceDate: '',
          dueDate: '',
          itemDescription: '',
          total: '',
          notes: '',
          status: '',
        });
      }
    } catch (error) {
      setErrorMessage('Failed to submit the invoice. Please try again.');
      console.error('Error submitting the form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 w-full mx-auto bg-white shadow-md rounded-lg max-w-xl">
      <h2 className="text-xl font-semibold mb-4">Invoicing Form</h2>

      {/* Success or Error Messages */}
      {successMessage && (
        <div className="bg-green-100 text-green-700 p-3 rounded mb-4">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            Customer Name:
            {
              customerLoading && !customers.length ? (
                <p>loading Customers</p>) : (
                <Select
                  options={customers}
                  onChange={handleSelectChange}
                  className="mt-1"
                  isSearchable
                  maxMenuHeight={150}
                  placeholder="select customer"
                  required
                />

              )
            }

          </label>
        </div>

        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            Invoice Date:
            <input
              type="date"
              name="invoiceDate"
              value={formData.invoiceDate}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </label>
        </div>

        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            Due Date:
            <input
              type="date"
              name="dueDate"
              value={formData.dueDate}
              min={formData.invoiceDate}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </label>
        </div>

        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            Status:
            <Select
              options={statusOptions}
              onChange={handleStatusChange}
              className="mt-1"
              required
            />
          </label>
        </div>

        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            Total Amount:
            <input
              type="number"
              name="total"
              value={formData.total}
              onChange={handleChange}
              placeholder="Enter total amount"
              min="1"
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </label>
        </div>

        <button
          type="submit"
          className={`w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform ${loading ? 'bg-gray-400 cursor-not-allowed' : 'transform hover:scale-105'}`}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default Invoicing;
