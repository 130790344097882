import React, { useState, useRef, useEffect } from 'react';

const AddChannelModal = ({ onClose, onAddChannel, existingChannels }) => {
  const [newChannel, setNewChannel] = useState('');
  const [error, setError] = useState('');
  const modalRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    // Auto-focus the input when modal opens
    inputRef.current?.focus();
  }, []);

  const handleSubmit = () => {
    const trimmedChannel = newChannel.trim();
    
    if (!trimmedChannel) {
      setError('Channel name cannot be empty');
      return;
    }

    // Check if channel name already exists (case insensitive)
    const channelExists = existingChannels.some(
      channel => channel.name.toLowerCase() === trimmedChannel.toLowerCase()
    );

    if (channelExists) {
      setError('A channel with this name already exists');
      return;
    }

    onAddChannel(trimmedChannel);
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
    // Clear error when user starts typing again
    setError('');
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div
        ref={modalRef}
        className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
      >
        <h2 className="text-lg font-semibold mb-4 text-center md:text-left">Add New Channel</h2>
        <div className="space-y-2">
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter channel name"
            className={`border ${error ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-purple-500`}
            value={newChannel}
            onChange={(e) => {
              setNewChannel(e.target.value);
              setError('');
            }}
            onKeyDown={handleKeyDown}
          />
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <button 
            onClick={onClose} 
            className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-150"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition duration-150"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddChannelModal;