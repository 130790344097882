import React, { useState, useEffect } from 'react';
import { FiDownload, FiEye, FiSearch, FiCalendar, FiDollarSign } from 'react-icons/fi';

const FinancialReports = () => {
  const [activeTab, setActiveTab] = useState('profit-loss');
  const [dateRange, setDateRange] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [reports, setReports] = useState([]); // Loaded reports
  const [filteredReports, setFilteredReports] = useState([]); // Filtered reports
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 6; // Number of reports per page

  // Mock API call to fetch financial reports
  useEffect(() => {
    const fetchReports = async () => {
      // Simulate an API call to fetch reports
      const mockReports = [
        { type: 'Profit and Loss', date: '2024-10-10', revenue: 130000, expenses: 90000, profit: 50000 },
        { type: 'Budget Variance', date: '2024-10-08', revenue: 125000, expenses: 85000, profit: 60000 },
        { type: 'Profit and Loss', date: '2024-09-25', revenue: 120000, expenses: 80000, profit: 70000 },
        { type: 'Budget Variance', date: '2024-09-15', revenue: 115000, expenses: 75000, profit: 80000 },
        { type: 'Profit and Loss', date: '2024-08-20', revenue: 110000, expenses: 70000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-07-30', revenue: 105000, expenses: 65000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-06-15', revenue: 100000, expenses: 60000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-04-10', revenue: 95000, expenses: 55000, profit: 40000 },
        { type: 'Profit and Loss', date: '2023-09-05', revenue: 90000, expenses: 50000, profit: 40000 },
        { type: 'Budget Variance', date: '2023-05-01', revenue: 85000, expenses: 45000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-10-10', revenue: 130000, expenses: 90000, profit: 50000 },
        { type: 'Budget Variance', date: '2024-10-08', revenue: 125000, expenses: 85000, profit: 60000 },
        { type: 'Profit and Loss', date: '2024-09-25', revenue: 120000, expenses: 80000, profit: 70000 },
        { type: 'Budget Variance', date: '2024-09-15', revenue: 115000, expenses: 75000, profit: 80000 },
        { type: 'Profit and Loss', date: '2024-08-20', revenue: 110000, expenses: 70000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-07-30', revenue: 105000, expenses: 65000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-06-15', revenue: 100000, expenses: 60000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-04-10', revenue: 95000, expenses: 55000, profit: 40000 },
        { type: 'Profit and Loss', date: '2023-09-05', revenue: 90000, expenses: 50000, profit: 40000 },
        { type: 'Budget Variance', date: '2023-05-01', revenue: 85000, expenses: 45000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-10-10', revenue: 130000, expenses: 90000, profit: 50000 },
        { type: 'Budget Variance', date: '2024-10-08', revenue: 125000, expenses: 85000, profit: 60000 },
        { type: 'Profit and Loss', date: '2024-09-25', revenue: 120000, expenses: 80000, profit: 70000 },
        { type: 'Budget Variance', date: '2024-09-15', revenue: 115000, expenses: 75000, profit: 80000 },
        { type: 'Profit and Loss', date: '2024-08-20', revenue: 110000, expenses: 70000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-07-30', revenue: 105000, expenses: 65000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-06-15', revenue: 100000, expenses: 60000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-04-10', revenue: 95000, expenses: 55000, profit: 40000 },
        { type: 'Profit and Loss', date: '2023-09-05', revenue: 90000, expenses: 50000, profit: 40000 },
        { type: 'Budget Variance', date: '2023-05-01', revenue: 85000, expenses: 45000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-10-10', revenue: 130000, expenses: 90000, profit: 50000 },
        { type: 'Budget Variance', date: '2024-10-08', revenue: 125000, expenses: 85000, profit: 60000 },
        { type: 'Profit and Loss', date: '2024-09-25', revenue: 120000, expenses: 80000, profit: 70000 },
        { type: 'Budget Variance', date: '2024-09-15', revenue: 115000, expenses: 75000, profit: 80000 },
        { type: 'Profit and Loss', date: '2024-08-20', revenue: 110000, expenses: 70000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-07-30', revenue: 105000, expenses: 65000, profit: 40000 },
        { type: 'Profit and Loss', date: '2024-06-15', revenue: 100000, expenses: 60000, profit: 40000 },
        { type: 'Budget Variance', date: '2024-04-10', revenue: 95000, expenses: 55000, profit: 40000 },
        { type: 'Profit and Loss', date: '2023-09-05', revenue: 90000, expenses: 50000, profit: 40000 },
        { type: 'Budget Variance', date: '2023-05-01', revenue: 85000, expenses: 45000, profit: 40000 },

      ];

      setReports(mockReports);
      // Immediately filter reports based on the activeTab
      const filteredByTab = mockReports.filter(report =>
        (activeTab === 'profit-loss' && report.type === 'Profit and Loss') ||
        (activeTab === 'budget-variance' && report.type === 'Budget Variance')
      );
      setFilteredReports(filteredByTab);
    };

    fetchReports();
  }, []); // Fetch reports only once on component mount

  // Handle filtering and sorting
  useEffect(() => {
    let filtered = [...reports];

    // Apply date range filter
    if (dateRange) {
      const now = new Date();
      filtered = filtered.filter((report) => {
        const reportDate = new Date(report.date);
        switch (dateRange) {
          case 'last-week': {
            const lastWeek = new Date(now);
            lastWeek.setDate(now.getDate() - 7);
            return reportDate > lastWeek;
          }
          case 'last-month': {
            const lastMonth = new Date(now);
            lastMonth.setMonth(now.getMonth() - 1);
            return reportDate > lastMonth;
          }
          case 'last-quarter': {
            const lastQuarter = new Date(now);
            lastQuarter.setMonth(now.getMonth() - 3);
            return reportDate > lastQuarter;
          }
          case 'last-year': {
            const lastYear = new Date(now);
            lastYear.setFullYear(now.getFullYear() - 1);
            return reportDate > lastYear;
          }
          default:
            return true;
        }
      });
    }

    // Apply sorting
    if (sortBy) {
      filtered.sort((a, b) => {
        if (sortBy === 'date-newest') return new Date(b.date) - new Date(a.date);
        if (sortBy === 'date-oldest') return new Date(a.date) - new Date(b.date);
        if (sortBy === 'amount-high') return b.profit - a.profit;
        if (sortBy === 'amount-low') return a.profit - b.profit;
        return 0;
      });
    }

    // Filter reports based on active tab
    const filteredByTab = filtered.filter(report =>
      (activeTab === 'profit-loss' && report.type === 'Profit and Loss') ||
      (activeTab === 'budget-variance' && report.type === 'Budget Variance')
    );

    // Set the filtered and sorted reports
    setFilteredReports(filteredByTab);
    setCurrentPage(1); // Reset to first page after filtering or sorting
  }, [dateRange, sortBy, reports, activeTab]); // Added activeTab as dependency

  const handleViewClick = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  // Handle pagination
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);

  const [selectedReport, setSelectedReport] = useState(null); // For viewing report details
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark">
        <h1 className="text-4xl font-bold  text-center ">Financial Reports Dashboard</h1>
      </div>

      {/* Search and Filter */}
      <div className="flex flex-wrap items-center justify-between mb-2">
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0 ">
          <input
            type="text"
            placeholder="Search reports..."
            className="w-full  pr-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out text-center "
          />
          {/* <FiSearch className="absolute left-4 top-3 text-gray-400" /> */}
        </div>

        <div className="flex space-x-4 w-full md:w-auto">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-auto"
          >
            <option value="">Filter by Date Range</option>
            <option value="last-week">Last Week</option>
            <option value="last-month">Last Month</option>
            <option value="last-quarter">Last Quarter</option>
            <option value="last-year">Last Year</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-auto text-center "
          >
            <option value="">Sort By</option>
            <option value="date-newest">Date (Newest First)</option>
            <option value="date-oldest">Date (Oldest First)</option>
            <option value="amount-high">Amount (High to Low)</option>
            <option value="amount-low">Amount (Low to High)</option>
          </select>
        </div>
      </div>

      {/* Tabs */}
      <div className="mb-4">
        <div className="border-b">
          <nav className="flex justify-start space-x-4 md:space-x-6 lg:space-x-8 xl:space-x-16"> {/* Add space-x-16 for larger screens */}

            <button
              className={`pb-1 border-b-2 font-medium text-lg transition-colors ${activeTab === 'profit-loss'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              onClick={() => {
                setActiveTab('profit-loss');
                setCurrentPage(1); // Reset to first page on tab change
              }}
            >
              Profit and Loss Statements
            </button>
            <button
              className={`pb-1 border-b-2 font-medium text-lg transition-colors ${activeTab === 'budget-variance'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              onClick={() => {
                setActiveTab('budget-variance');
                setCurrentPage(1); // Reset to first page on tab change
              }}
            >
              Budget Variance Reports
            </button>
          </nav>
        </div>
      </div>


      {/* Report Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentReports.map((report, index) => (
          <div key={index} className="bg-gray-50 dark:bg-secondaryDark border rounded-lg shadow-sm p-4">
            <h2 className="text-xl font-bold dark:to-dark">{report.type}</h2>
            <p className="text-gray-600 dark:text-dark">Date: {report.date}</p>
            <p className="text-gray-600 dark:text-dark">Revenue: ${report.revenue}</p>
            <p className="text-gray-600 dark:text-dark">Expenses: ${report.expenses}</p>
            <p className="text-gray-600 dark:text-dark">Profit: ${report.profit}</p>
            <div className="flex justify-between mt-4">
              <button className="text-blue-500 hover:underline" onClick={() => handleViewClick(report)}>
                <FiEye className="inline-block mr-1" /> View
              </button>
              <button className="text-green-500 hover:underline">
                <FiDownload className="inline-block mr-1" /> Download
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-center mt-8 space-x-4">
        {/* Previous Button */}
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`flex items-center px-4 py-2 border rounded-lg transition duration-200 
            ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-500'}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          {/* Previous */}
        </button>

        {/* Current Page Display */}
        <span className="text-lg font-medium">
          Page {currentPage} of {Math.ceil(filteredReports.length / reportsPerPage)}
        </span>

        {/* Next Button */}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredReports.length / reportsPerPage)}
          className={`flex items-center px-4 py-2 border rounded-lg transition duration-200 
            ${currentPage === Math.ceil(filteredReports.length / reportsPerPage) ? 'opacity-50 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-500'}`}
        >
          {/* Next */}
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>


      {/* Modal for Viewing Report */}
      {isModalOpen && selectedReport && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white flex flex-col text-left rounded-lg p-8 shadow-lg w-full max-w-lg">
            <h2 className="text-2xl font-bold text-blue-600 mb-4 text-center">{selectedReport.type} Report</h2>

            <div className="border-b border-gray-300 mb-4 pb-4">
              <h3 className="text-lg font-semibold mb-2 text-center">Report Details</h3>
              <p className="flex justify-between"><span className="font-medium">Date:</span> <span>{selectedReport.date}</span></p>
              <p className="flex justify-between"><span className="font-medium">Revenue:</span> <span className="text-green-600">${selectedReport.revenue}</span></p>
              <p className="flex justify-between"><span className="font-medium">Expenses:</span> <span className="text-red-600">${selectedReport.expenses}</span></p>
              <p className="flex justify-between"><span className="font-medium">Profit:</span> <span className="text-green-700 font-bold">${selectedReport.profit}</span></p>
            </div>

            <div className="flex justify-end mt-6">
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default FinancialReports;
