import React, { useEffect, useState } from 'react';
// import PerformanceDashboard from './PerformanceDashboard';
// import PerformanceReview from './PerformanceReview';
import PerformanceGoals from './PerformanceGoals';



const PerformanceManagement = ({reviews}) => {

  const [review, setReview] = useState([])

  useEffect(() => {
    setReview(reviews)
  }, [reviews])
  


  

  return (
    <div className="container mx-auto p-3 space-y-8  rounded-lg shadow-md ">
      <div className="bg-gray-50 p-4 rounded-lg shadow mb-4 dark:bg-secondaryDark">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-dark ">Performance Management</h1>
      </div>
      <PerformanceGoals reviews={review} />
    </div>
  );
};

export default PerformanceManagement;
