import React from 'react';

export default function EmojiPicker({ onEmojiSelect, buttonRef }) {
  const emojis = [
    '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊',
    '😍', '😘', '😜', '😎', '🤩', '🥺', '😢', '😰', '😱', '😳',
    '😡', '😤', '😷', '🤒', '🤕', '💪', '👀', '👋', '👍',
  ];

  const buttonRect = buttonRef.current?.getBoundingClientRect();

  return (
    <div
      className="absolute z-10 bg-white border border-gray-300 rounded-lg p-2 h-48 overflow-y-auto shadow-lg"
      style={{
        top: buttonRect ? buttonRect.top - 250 + window.scrollY : 'auto',
        left: buttonRect ? buttonRect.left - 250 + window.scrollX : 'auto',
        width: '320px',
      }}
    >
      <div className="grid grid-cols-4 gap-2">
        {emojis.map((emoji, index) => (
          <button
            key={index}
            onClick={() => onEmojiSelect(emoji)}
            className="text-xl hover:bg-gray-100 p-1 rounded-md"
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );
}
