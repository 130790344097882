import EmployeeManagement from "./Employemng"

const HrmEmployeeLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 overflow-y-scroll h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
           
            <EmployeeManagement />
        
        </div>
    )
}

export default HrmEmployeeLayout