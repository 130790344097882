import React from 'react';
import { FiAlertTriangle, FiCheckCircle, FiXCircle } from 'react-icons/fi';

const LowStockTable = ({ products }) => {
  // Helper component to render the status icon
  const StatusIcon = ({ status }) => {
    switch (status) {
      case 'critical':
        return <FiXCircle className="w-6 h-6 text-red-500" />;
      case 'warning':
        return <FiAlertTriangle className="w-6 h-6 text-yellow-500" />;
      case 'normal':
        return <FiCheckCircle className="w-6 h-6 text-green-500" />;
      default:
        return null;
    }
  };

  // Function to determine the stock status
  const getStatus = (quantity, minStockLevel) => {
    if (quantity <= minStockLevel / 2) return 'critical';
    if (quantity <= minStockLevel) return 'warning';
    return 'normal';
  };

  return (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg dark:bg-secondaryDark">
      <table className="min-w-full">
        <thead className="bg-gray-50 dark:bg-primaryDark">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-textDark"
            >
              Product
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-textDark"
            >
              Current Stock
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-textDark"
            >
              Min Stock Level
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-textDark"
            >
              Reorder Point
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-textDark"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-dark">
          {products.map((product) => {
            const status = getStatus(product.quantity, product.minStockLevel);
            return (
              <tr key={product.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-textDark">
                  {product.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                  {product.quantity}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                  {product.minStockLevel}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                  {product.reorderPoint}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <StatusIcon status={status} />
                    <span className="ml-2 text-sm text-gray-500 capitalize dark:text-textDark">
                      {status}
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LowStockTable;
