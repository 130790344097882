import ContractManagement from "./ContractManagement"

const ContractLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 overflow-y-scroll h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
           
            <ContractManagement />
            
          
        </div>
    )
}

export default ContractLayout