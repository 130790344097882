import React from 'react';

export default function LeaveConfirmationModal({ isOpen, onConfirm, onCancel }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-80 shadow-lg text-center">
        <h2 className="text-xl font-semibold mb-4">Are you sure you want to leave the group?</h2>
        <div className="flex justify-center space-x-4 mt-4">
          <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded">Leave</button>
          <button onClick={onCancel} className="bg-gray-300 text-black px-4 py-2 rounded">Cancel</button>
        </div>
      </div>
    </div>
  );
}