import React, { useEffect, useState } from 'react';
import LowStockTable from './LowStockTable';
import RestockTable from './RestockTable';
import axiosInstance from '../../services/AxiosInstance';

const RecordAlert = () => {
  const [currentView, setCurrentView] = useState('lowStock');
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch products data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosInstance.get('/backend/api/products');
        setProducts(response.data);
        setFilteredProducts(response.data); // Initialize filtered products
      } catch (err) {
        setError(err.message || 'Failed to fetch products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Update filtered products when search query changes
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  return (
    <div className="container mx-auto px-4 py-4">
      {/* Header */}
      <header className="mb-8 bg-gray-50 p-4 rounded-lg shadow-md flex items-center justify-between dark:bg-secondaryDark">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Reorder Alerts</h1>

        {/* Search Bar */}
        <div className="w-full max-w-xs">
          <input
            type="text"
            placeholder="Search product"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </header>

      {/* Header Tabs */}
      <div className="flex items-center justify-start space-x-8 border-b-2 border-gray-200 pb-2">
        <h2
          className={`cursor-pointer text-xl font-semibold ${currentView === 'lowStock'
              ? 'text-colorBlue border-b-2 border-blue-500'
              : 'text-gray-700'
            }`}
          onClick={() => setCurrentView('lowStock')}
        >
          Low Stock Products
        </h2>
        <h2
          className={`cursor-pointer text-xl font-semibold ${currentView === 'upcomingRestocks'
              ? 'text-colorBlue border-b-2 border-blue-500'
              : 'text-gray-700'
            }`}
          onClick={() => setCurrentView('upcomingRestocks')}
        >
          Upcoming Restocks
        </h2>
      </div>

      {/* Conditional Rendering */}
      <div className="mt-8">
        {loading ? (
          <div className="flex justify-center items-center gap-3 h-[calc(100vh-3.5rem)]">
            <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
            <p className="dark:text-secondaryDark">Loading</p>
          </div>
        ) : error ? (
          <p className="text-center text-red-500"> {error}</p>
        ) : currentView === 'lowStock' ? (
          <LowStockTable products={filteredProducts} />
        ) : (
          <RestockTable  />
        )}
      </div>
    </div>
  );
};

export default RecordAlert;
