import React from 'react'

function FinancialForm() {
  return (
    <div className=" bg-gray-50 dark:bg-secondaryDark  shadow-lg p-6">
        <h3 className="text-xl text-center font-bold text-gray-800 mb-4">Generate New Report</h3>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2  dark:text-dark">Report Type:</label>
            <select className="w-full border border-gray-300 rounded-lg px-4 py-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="profit-loss">Profit and Loss</option>
              <option value="budget-variance">Budget Variance</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2 dark:text-dark">From Date:</label>
            <input type="date" className="w-full border border-gray-300 rounded-lg px-4 py-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2 dark:text-dark">To Date:</label>
            <input type="date" className="w-full border border-gray-300 rounded-lg px-4 py-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" />
          </div>
          <div className='flex justify-center'>
          <button className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-600 transition-colors dark:bg-dark dark:hover:bg-primaryDark">
            Generate Report
          </button>
          </div>
        </form>
      </div>
  )
}

export default FinancialForm