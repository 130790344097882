import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Download, Star, ArrowLeft, ArrowRight, StarIcon } from 'lucide-react';
import axiosInstance from '../services/AxiosInstance';

const FrameComponent = ({ className = "", onContactClick, onGetQuoteClick }) => {
  const [backgroundImage, setBackgroundImage] = useState("../../images/herobgimage2.png");
  const [isDownloading, setIsDownloading] = useState(false);

  const handleArrow2Click = () => {
    setBackgroundImage("../../images/herobgimage2.png");
  };

  const handleArrow3Click = () => {
    setBackgroundImage("../../images/herosection-bg.jpeg");
  };

  const handleGetQuoteClick = () => {
    console.log("Get Quote button clicked");
    onGetQuoteClick();
  };

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    try {
      const response = await axiosInstance.get("/backend/api/download-apk", { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "app-arm64-v8a-release.apk");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <section className={`w-full ${className}`}>
      <div className="relative ">
        <div
          className="bg-cover bg-center h-[60vh] md:h-[70vh] lg:h-[80vh]"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="absolute bottom-0 left-0  right-0 bg-black bg-opacity-50 p-4 ">
            <div className="flex justify-around text-white mb-4">
              <div className="text-center">
                <p className="text-2xl md:text-4xl font-bold">400+</p>
                <p className="text-sm md:text-base">Project Complete</p>
              </div>
              <div className="text-center">
                <p className="text-2xl md:text-4xl font-bold">600+</p>
                <p className="text-sm md:text-base">Satisfied Clients</p>
              </div>
              <div className="text-center">
                <p className="text-2xl md:text-4xl font-bold">100+</p>
                <p className="text-sm md:text-base">Unique Styles</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-4 right-4 flex space-x-2">
          <button
            className="bg-white bg-opacity-10 p-2 rounded hover:bg-opacity-20 transition-colors"
            onClick={handleArrow3Click}
          >
            <ArrowLeft className="h-4 w-4 text-white" />
          </button>
          <button
            className="bg-white bg-opacity-10 p-2 rounded hover:bg-opacity-20 transition-colors"
            onClick={handleArrow2Click}
          >
            <ArrowRight className="h-4 w-4 text-white" />
          </button>
        </div>
      </div>
      <div className="p-4 md:p-8 bg-gray-200 justify-start">
        <div className="max-w-4xl mx-auto space-y-6 j">
          <h2 className="md:text-5xl text-4xl lg:text-5xl xl:text-5xl font-bold text-gray-800 text-center ">
            Bring Your Ideas Into Your Beautiful World With Our Design Expertise
          </h2>
          <h3 className="text-xl  text-gray-600 text-justify">
            Our team of experienced interior designers will transform your home or office into a beautiful, functional space that reflects your unique style.
          </h3>
          <div className="flex flex-wrap gap-4 justify-center">
            <button
              className="border-2  border-black bg-orange-500 text-white px-4 py-3 rounded flex items-center hover:bg-orange-700"
              onClick={handleGetQuoteClick}
            >
              Get quote
              {/* <ChevronRight className="ml-2 h-4 w-4" /> */}
              <img className="ml-2" alt="" src="/images/icon--relume-9.svg" loading='lazy' />
            </button>
            <button
              className=" border-2 border-black text-white px-4 py-3 bg-[#084479] hover:bg-blue-700 rounded flex items-center "
              onClick={onContactClick}
            >
              Contact Us
              {/* <ChevronRight className="ml-2 h-4 w-4" /> */}
              <img className="ml-2" alt="" src="/images/icon--relume-9.svg" loading='lazy' />
            </button>
            <button
              className=" border-2 border-black text-white bg-green-800 px-4 py-3 rounded flex items-center hover:bg-green-600 "
              onClick={handleDownloadClick}
              disabled={isDownloading}
            >
              {isDownloading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Downloading...
                </>
              ) : (
                <>
                  Download APP
                  <Download className="ml-2 h-4 w-4" />
                </>
              )}
            </button>

            <button
              className=" border-2 border-black text-white px-4 py-3 bg-cyan-700 hover:bg-cyan-800 rounded flex items-center "
            >
              Live Projects
              {/* <ChevronRight className="ml-2 h-4 w-4" /> */}
              {/* <img className="ml-2" alt="" src="/images/icon--relume-9.svg" loading='lazy' /> */}
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-sm md:text-base text-black">Google Review</div>
            <div className="flex">
              <img className="icons" alt="" src="/images/star-icons.svg" loading='lazy' />
              <img className="icons" alt="" src="/images/frame-1.svg" loading='lazy' />
              <img className="icons" alt="" src="/images/frame-2.svg" loading='lazy' />
              <img className="icons" alt="" src="/images/frame-3.svg" loading='lazy' />
              <img className="icons" alt="" src="/images/frame-4.svg" loading='lazy' />
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  onContactClick: PropTypes.func,
  onGetQuoteClick: PropTypes.func,
};

export default FrameComponent;