import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';

const EquipmentAllocation = ({addEquipment}) => {
    const [assignees, setAssignees] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [formData, setFormData] = useState({
        equipmentName: '',
        assignee: null,
        task: null,
        startDate: '',
        endDate: '',
        status: "Assigned",
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });
    const [buttonLoading, setButtonLoading] = useState(false);

    // Fetch assignees and tasks from the backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [assigneesResponse, tasksResponse] = await Promise.all([
                    axiosInstance.get('/backend/api/user'),
                    axiosInstance.get('/backend/projects/tasks')
                ]);

                const assigneeOptions = assigneesResponse.data.map(assignee => ({
                    value: assignee.id,
                    label: assignee.name,
                }));

                const taskOptions = tasksResponse.data.map(task => ({
                    value: task.id,
                    label: task.title,
                }));

                setAssignees(assigneeOptions);
                setTasks(taskOptions);
                setLoading(false);
            } catch (error) {
                setMessage({ type: 'error', text: 'Failed to fetch data' });
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle select changes for assignee and task
    const handleSelectChange = (selectedOption, name) => {
        setFormData({ ...formData, [name]: selectedOption });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true);

        const submissionData = {
            equipmentName: formData.equipmentName,
            assignedTo: { id: formData.assignee.value }, // Use the selected option's value
            task: { id: formData.task.value }, // Use the selected option's value
            startDate: formData.startDate,
            endDate: formData.endDate,
            status:"Assigned",
        };

        try {
            setLoading(true);
           const response =  await axiosInstance.post('/backend/api/equipment/allocate', submissionData);
           addEquipment(response.data);
            setMessage({ type: 'success', text: 'Equipment allocated successfully!' });
            setFormData({
                equipmentName: '',
                assignee: null,
                task: null,
                startDate: '',
                endDate: '',
            });
        } catch (error) {
            setMessage({ type: 'error', text: 'Failed to allocate equipment' });
        } finally {
            setLoading(false);
            setButtonLoading(false);
        }
    };

    return (
        <div className="space-y-6 bg-gray-50 dark:bg-secondaryDark p-4 sm:p-6 lg:p-8 shadow-lg rounded-lg max-w-full sm:max-w-4xl mx-auto transition-colors duration-1000 ease-in">
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 dark:text-dark mb-6 text-center">
                Equipment Allocation
            </h2>

            {/* Message Display */}
            {message.text && (
                <div className={`p-4 rounded-md mb-4 ${message.type === 'success' ? 'bg-green-200 text-green-800 text-sm' : 'bg-red-200 text-red-800 textsm'}`}>
                    {message.text}
                </div>
            )}

            {/* Loading State */}
            {loading && <div className="text-center text-gray-600 dark:text-dark">Loading...</div>}

            {/* Equipment Allocation Form */}
            <form className="space-y-4 text-gray-700 dark:text-dark" onSubmit={handleSubmit}>
                <div>
                    <label className="text-sm sm:text-base block mb-1">Equipment Name:</label>
                    <input
                        type="text"
                        name="equipmentName"
                        placeholder="Equipment name"
                        value={formData.equipmentName}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 "
                        required
                    />
                </div>

                <div>
                    <label className="text-sm sm:text-base block mb-1">Assigned To:</label>
                    <Select
                        name="assignee"
                        value={formData.assignee}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'assignee')}
                        options={assignees}
                        className="w-full"
                        placeholder="Select Assignee"
                        isClearable
                        maxMenuHeight={200}
                        required
                    />
                </div>

                <div>
                    <label className="text-sm sm:text-base block mb-1">Task:</label>
                    <Select
                        name="task"
                        value={formData.task}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'task')}
                        options={tasks}
                        className="w-full"
                        placeholder="Select Task"
                        isClearable
                        maxMenuHeight={200}
                        required
                    />
                </div>

                <div>
                    <label className="text-sm sm:text-base block mb-1">Start Date:</label>
                    <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 "
                        required
                    />
                </div>

                <div>
                    <label className="text-sm sm:text-base block mb-1">End Date:</label>
                    <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 "
                        required
                    />
                </div>

                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
                    <button
                        type="submit"
                        className={`w-full sm:w-auto px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500  dark:text-textDark hover:bg-blue-500 transition-colors duration-300 ${buttonLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-colorBlue text-gray-50 dark:bg-primaryDark'}`}
                        disabled={buttonLoading}
                    >
                        {buttonLoading ? 'Allocating...' : 'Allocate'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EquipmentAllocation;
