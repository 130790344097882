import React, { useEffect, useState } from 'react'

import { Edit, Trash2, Eye } from 'lucide-react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';
import Swal from 'sweetalert2';

export default function PayrollDashboard() {
  const [currentPage, setCurrentPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [loadEmployee, setLoadEmployee] = useState(false)
  const [emperror, setEmperror] = useState('')
  const [employee, setEmployee] = useState([])
  const [loading, setLoading] = useState(false); // Add loading state
  const [error, setError] = useState(""); // Add error state
  const [payrollData, setPayrollData] = useState([]);

  const [loadPayroll, setLoadPayroll] = useState(false)
  const [errorPayload, setErrorPayload] = useState('')



  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("dateDesc")
  const [activeTab, setActiveTab] = useState("all")

  const currentDate = new Date();
  const thisYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const currentMonthYear = `${thisYear}-${currentMonth}`;
  const [selectedMonthYear, setSelectedMonthYear] = useState(currentMonthYear);

  const [selectedEmployee, setSelectedEmployee] = useState(null)


  const [newPayment, setNewPayment] = useState({
    employeeId: "",
    paymentMonth: "",
    paymentYear: "",
    baseSalary: 0,
    bonuses: 0,
    deductions: 0,
    netPay: 0,
    notes: "",
    status: ""
  });

  useEffect(() => {
    if (selectedEmployee) {
      setNewPayment({
        employeeId: selectedEmployee.employee.employeeId,
        paymentMonth: selectedEmployee.paymentMonth || "",
        paymentYear: selectedEmployee.paymentYear || "",
        baseSalary: selectedEmployee.baseSalary || 0,
        bonuses: selectedEmployee.bonuses || 0,
        deductions: selectedEmployee.deductions || 0,
        netPay: selectedEmployee.netPay || 0,
        notes: selectedEmployee.notes || "",
        status: selectedEmployee.status || ""
      });
      // console.log("Selected employee data:", selectedEmployee.employee.employeeId);
    }
  }, [selectedEmployee]);





  const fetchEmployee = async () => {
    setLoadEmployee(true);
    setEmperror(''); // Reset error message before fetching

    try {
      const response = await axiosInstance.get(`/backend/api/employees`);

      if (response.status === 200 && response.data) {
        console.log(response.data);

        setEmployee(response.data)
      } else {
        setEmperror('Failed to fetch employees');
      }
    } catch (error) {
      setEmperror('Failed to fetch employees');
    } finally {
      setLoadEmployee(false);
    }
  };

  const fetchPayrollData = async () => {
    setLoadPayroll(true);  // Start loading
    try {
      const response = await axiosInstance.get(`/backend/api/payroll-management/all`);
      // console.log(response.data);
      setPayrollData(response.data || []);  // Set the payroll data
      setErrorPayload('');  // Clear any previous errors
    } catch (error) {
      setErrorPayload("Failed to fetch payroll data.");  // Set error message
    } finally {
      setLoadPayroll(false);  // Stop loading
    }
  };



  useEffect(() => {

    fetchEmployee();
    fetchPayrollData();
  }, []);

  useEffect(() => {
    // If you want to ensure this only runs once on mount, you can check if `selectedMonthYear` is empty before setting it.
    setSelectedMonthYear(currentMonthYear);
  }, [currentMonthYear]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(""); // Reset error state before submitting

    try {
      let response;

      if (selectedEmployee && selectedEmployee.payrollManagementId) {
        // If payrollId exists, use PUT request to update
        response = await axiosInstance.put(
          `/backend/api/payroll-management/${selectedEmployee.payrollManagementId}`,
          newPayment
        );
      } else {
        // Otherwise, use POST request to create
        response = await axiosInstance.post(
          `/backend/api/payroll-management/${newPayment.employeeId}`,
          newPayment
        );
      }

      if (response.status === 200) {
        // console.log("Payment scheduled successfully:", response.data);

        // Update payrollData state to add new or updated entry
        setPayrollData((prevPayrollData) => {
          const existingIndex = prevPayrollData.findIndex(
            payroll => payroll.employee.employeeId === response.data.employee.employeeId
          );
          if (existingIndex !== -1) {
            // Update existing entry
            return prevPayrollData.map((payroll, index) =>
              index === existingIndex ? response.data : payroll
            );
          } else {
            // Add new entry if not found
            return [...prevPayrollData, response.data];
          }
        });



        // Update employee state with the new or updated data for the specific employee
        setEmployee((prevEmployees) => {
          const existingEmployeeIndex = prevEmployees.findIndex(
            emp => emp.employeeId === response.data.employee.employeeId
          );

          if (existingEmployeeIndex !== -1) {
            // If employee exists, update the existing entry
            return prevEmployees.map((emp, index) =>
              index === existingEmployeeIndex ? { ...emp, ...response.data.employee } : emp
            );
          } else {
            // If employee doesn't exist, add the new data to the array
            return [...prevEmployees, response.data.employee];
          }
        });

        setShowModal(false);
        setSelectedEmployee(null)
        setNewPayment({
          employeeId: "",
          paymentMonth: "",
          paymentYear: "",
          baseSalary: 0,
          bonuses: 0,
          deductions: 0,
          netPay: 0,
          notes: "",
          status: ""
        });
      } else {
        setError("Failed to schedule the payment. Please try again.");
      }
    } catch (error) {
      setError("An error occurred while scheduling the payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  // Step 1: Filter payrollData based on search term, selected tab, and selected month/year
  const filteredPayrollData = payrollData
    .filter(payroll =>
      payroll.employee.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payroll.employee.employeeId.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(payroll => {
      // Filter based on the selected tab
      if (activeTab === "all") return true;
      if (activeTab === "pending") return payroll.status === "Pending";
      if (activeTab === "processed") return payroll.status === "Processed";
      if (activeTab === "currentMonth") {
        return payroll.paymentYear === thisYear && payroll.paymentMonth === currentMonth;
      }
      return true;
    })
    .filter(payroll => {
      // Month-year filter: only apply if selectedMonthYear is set; otherwise, include all months
      if (selectedMonthYear) {
        const [selectedYear, selectedMonth] = selectedMonthYear.split("-");
        return payroll.paymentYear === parseInt(selectedYear) && payroll.paymentMonth === parseInt(selectedMonth);
      }
      return true;
    });

  // Step 2: Get a list of employee IDs with payroll data for the selected month/year or all data if no month/year is selected
  const employeesWithPayroll = payrollData.map(payroll => payroll.employee.employeeId);

  // Step 3: Identify employees from `employee` who do not have any payroll data in any month
  const employeesWithoutPayroll = employee.filter(emp => {
    return !employeesWithPayroll.includes(emp.employeeId);
  });

  // Step 4: Combine `filteredPayrollData` with employees who do not have payroll data, marking them as "Not Submitted"
  const combinedFilteredData = [
    ...filteredPayrollData,
    ...employeesWithoutPayroll.map(emp => ({
      employee: emp,
      status: "Not Submitted" // Mark status as "Not Submitted" for employees without payroll data
    }))
  ];

  // Step 5: Sort the combined data based on selected criteria
  const sortedEmployees = [...combinedFilteredData].sort((a, b) => {
    switch (sortBy) {
      case "nameAsc":
        return a.employee.fullName.localeCompare(b.employee.fullName);
      case "nameDesc":
        return b.employee.fullName.localeCompare(a.employee.fullName);
      case "dateAsc":
        if (a.paymentYear === b.paymentYear) {
          return a.paymentMonth - b.paymentMonth;
        } else {
          return a.paymentYear - b.paymentYear;
        }
      case "dateDesc":
        if (a.paymentYear === b.paymentYear) {
          return b.paymentMonth - a.paymentMonth;
        } else {
          return b.paymentYear - a.paymentYear;
        }
      default:
        return 0;
    }
  });


  // Create a set to track unique month-year combinations for the dropdown
  const uniqueMonthYears = new Set();
  const uniquePayrollMonths = payrollData.filter(payroll => {
    const monthYear = `${payroll.paymentYear}-${payroll.paymentMonth}`;
    if (!uniqueMonthYears.has(monthYear)) {
      uniqueMonthYears.add(monthYear);
      return true;
    }
    return false;
  });



  const itemsPerPage = 6
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = sortedEmployees.slice(indexOfFirstItem, indexOfLastItem)
  const totalPages = Math.ceil(sortedEmployees.length / itemsPerPage)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPayment((prevPayment) => {
      const updatedPayment = { ...prevPayment, [name]: value };

      // Calculate netPay when relevant fields are updated
      const baseSalary = parseFloat(updatedPayment.baseSalary) || 0;
      const bonuses = parseFloat(updatedPayment.bonuses) || 0;
      const deductions = parseFloat(updatedPayment.deductions) || 0;
      updatedPayment.netPay = baseSalary + bonuses - deductions;

      return updatedPayment;
    });
  };

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);


  const handleTabSwitch = (tab) => {
    setActiveTab(tab)
    setCurrentPage(1) // Reset to page 1 when switching tabs
  }

  const handleEditModel = (employee) => {
    setSelectedEmployee(employee)
    setShowModal(true)
    // console.log("edit model", employee)
  };

  const handleViewDetails = (employee) => {
    // Logic to view details
    console.log("view model", employee)
  };


  const handleDelete = async (id) => {
    if (!id) {
      Swal.fire('Error', 'First set the payroll', 'warning');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(`/backend/api/payroll-management/${id}`);
          if (response.status === 200) {

            // Update payrollData state to remove the deleted payroll entry
            setPayrollData((prevPayrollData) =>
              prevPayrollData.filter((payroll) => payroll.payrollManagementId !== id)
            );

            Swal.fire('Deleted!', 'Your Payroll has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'Failed to delete the Payroll. Please try again.', 'error');
          }


        } catch (err) {
          Swal.fire('Error!', 'Failed to delete the Payroll. Please try again.', 'error');
        }
      }
    });
  };


  const handleClose = () => {
    setShowModal(false)
    setSelectedEmployee(null)
  }




  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark">
        <h1 className="text-3xl font-extrabold  text-gray-800 dark:text-dark" > Payroll Dashboard</h1 >
      </div>

      {/* Show loading state */}
      {
        loadPayroll ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full border-t-4 border-colorBlue w-16 h-16"></div>
            <p className="ml-4 text-gray-600">Loading payroll data...</p>
          </div>
        ) : errorPayload ? (
          <div className="bg-red-100 text-red-800 p-4 rounded-md">
            {errorPayload}
          </div>
        ) : (
          <>
            {/* Search and Filter */}
            <div className="flex flex-col gap-4 md:flex-row justify-between items-center mb-6">
              <input
                type="text"
                placeholder="Search employees..."
                className="pl-10 pr-4 py-2 border rounded-md w-full md:w-80 focus:outline-none focus:ring focus:border-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="border rounded-md px-3 py-2 w-full md:w-auto focus:outline-none focus:ring focus:border-blue-500"
              >
                <option value="nameAsc">Name (A-Z)</option>
                <option value="nameDesc">Name (Z-A)</option>
                <option value="dateDesc">Payment Date (Newest First)</option>
                <option value="dateAsc">Payment Date (Oldest First)</option>
              </select>

              <select
                onChange={(e) => setSelectedMonthYear(e.target.value)}
                value={selectedMonthYear}
                className="border rounded-md px-3 py-2 w-full md:w-auto focus:outline-none focus:ring focus:border-blue-500"
              >
                <option value="">All Payrolls</option>
                {uniquePayrollMonths
                  .sort((a, b) => {
                    if (a.paymentYear !== b.paymentYear) {
                      return b.paymentYear - a.paymentYear; // Sort by year in descending order
                    }
                    return b.paymentMonth - a.paymentMonth; // Sort by month in descending order if years are the same
                  })
                  .map((payroll) => (
                    <option
                      key={`${payroll.paymentYear}-${payroll.paymentMonth}`}
                      value={`${payroll.paymentYear}-${payroll.paymentMonth}`}
                    >
                      {`${payroll.paymentMonth}/${payroll.paymentYear}`}
                    </option>
                  ))}
              </select>


              <button
                className="bg-colorBlue text-white px-5 py-2 rounded-lg shadow hover:bg-blue-500 transition duration-300 w-full md:w-auto"
                onClick={() => setShowModal(true)}
              >
                Schedule Payment
              </button>
            </div>

            {/* Tabs */}
            <div className="border-b mb-6 overflow-x-auto">
              <nav className="flex space-x-4">
                {['all', 'pending', 'processed'].map((tab) => (
                  <button
                    key={tab}
                    className={`py-3 px-5 border-b-2 text-sm font-medium whitespace-nowrap transition duration-300 ${activeTab === tab
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }`}
                    onClick={() => handleTabSwitch(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)} Payments
                  </button>
                ))}
              </nav>
            </div>


            {/* Employee List */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {currentItems.map((employee) => (
                <div
                  key={employee.payrollManagementId || employee.employee.employeeId}
                  className="bg-gray-50 shadow rounded-lg p-6 mb-4 dark:bg-secondaryDark"
                >
                  <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                    <div>
                      <h3 className="text-xl font-semibold text-gray-800 text-left">
                        {employee.employee.fullName}
                      </h3>
                      <p className="text-gray-600 text-left">
                        Employee ID: {employee.employee.employeeId}
                      </p>
                      {employee.paymentMonth && employee.paymentYear ? (
                        <p className="text-gray-600 text-left">
                          Payment Date: {employee.paymentMonth} - {employee.paymentYear}
                        </p>
                      ) : (
                        <p className="text-red-600 text-left">Payment Not Distributed</p>
                      )}
                    </div>
                    {employee.status ? (
                      <div
                        className={`px-4 py-2 rounded-full text-xs mt-2 md:mt-0 ${employee.status === "Processed"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                          }`}
                      >
                        {employee.status}
                      </div>
                    ) : (
                      <div className="px-4 py-2 rounded-full text-xs mt-2 md:mt-0 bg-gray-100 text-gray-800">
                        Not Processed
                      </div>
                    )}
                  </div>

                  {employee.baseSalary ? (
                    <>
                      <p className="mt-4 text-gray-800">Base Salary: ₹{employee.baseSalary}</p>
                      <p className="mt-2 text-gray-700">Bonus: ₹{employee.bonuses}</p>
                      <p className="mt-2 text-red-500">Deduction: ₹{employee.deductions}</p>
                      <p className="mt-2 text-green-500 font-semibold">
                        Total Amount: ₹{employee.netPay}
                      </p>
                    </>
                  ) : (
                    <p className="mt-4 text-red-500 font-semibold">Payment Not Distributed</p>
                  )}

                  <div className="flex justify-end gap-2">
                    <button
                      className="p-2 text-gray-500 hover:text-blue-500"
                      onClick={() => handleViewDetails(employee)}
                    >
                      <Eye size={20} />
                    </button>
                    <button
                      className="p-2 text-gray-500 hover:text-green-500"
                      onClick={() => handleEditModel(employee)}
                    >
                      <Edit size={20} />
                    </button>
                    <button
                      className="p-2 text-gray-500 hover:text-red-500"
                      onClick={() => handleDelete(employee.payrollManagementId)}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-8">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition duration-300 mr-2"
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="mx-4 py-2 text-gray-600">Page {currentPage} of {totalPages}</span>
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition duration-300 ml-2"
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </>
        )
      }


      {/* Modal for Payment Scheduling */}
      {
        showModal && (
          <div className="fixed mt-10 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-50 dark:bg-secondaryDark p-8 rounded-lg shadow-md w-full max-w-md">
              <h2 className="text-2xl font-bold mb-2">Schedule Payment</h2>
              {selectedEmployee && (
                <div className="flex items-center text-center p-2 mb-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg shadow-lg">
                  <div className="text-2xl font-bold"> {`Create Payement for `}
                    {selectedEmployee.employee?.fullName}
                  </div>
                </div>
              )}


              {loadEmployee && <div>Loading employees...</div>} {/* Loading indicator */}
              {emperror && <div className="text-red-500">{emperror}</div>} {/* Error message */}
              {error && <div className="text-red-500">{error}</div>} {/* Error message */}
              <form onSubmit={handleSubmit}>

                {!selectedEmployee?.employee.employeeId && (
                  <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Select Employee</label>
                    <Select
                      onChange={(selectedOption) => {
                        setNewPayment((prevPayment) => ({
                          ...prevPayment,
                          employeeId: selectedOption ? selectedOption.value : '', // Update the employeeId based on the selection
                        }));
                      }}
                      isClearable
                      maxMenuHeight={250}
                      options={employee.map(emp => ({
                        label: emp.fullName,
                        value: emp.employeeId,
                      }))}
                      isLoading={loadEmployee} // Show loading spinner in the select
                      placeholder="Select an Employee"
                    />
                  </div>
                )}



                {/* Payment Month and Year in One Row */}
                <div className="mb-2 grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Payment Month</label>
                    <select
                      name="paymentMonth"
                      className="mt-1 block  p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.paymentMonth}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Payment Year</label>
                    <select
                      name="paymentYear"
                      className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.paymentYear}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Base Salary and Bonuses in One Row */}
                <div className="mb-2 grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Base Salary</label>
                    <input
                      type="number"
                      name="baseSalary"
                      min={0}
                      className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.baseSalary}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Bonuses</label>
                    <input
                      type="number"
                      name="bonuses"
                      className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.bonuses}
                      min={0}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* Deductions and Net Pay in One Row */}
                <div className="mb-2 grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Deductions</label>
                    <input
                      type="number"
                      name="deductions"
                      className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.deductions}
                      min={0}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Net Pay</label>
                    <input
                      type="number"
                      name="netPay"
                      className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                      value={newPayment.netPay}
                      readOnly
                    />
                  </div>
                </div>

                {/* Notes Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">Notes</label>
                  <textarea
                    name="notes"
                    className="mt-1 block w-full p-2  border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                    value={newPayment.notes}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Status</label>
                  <select
                    name="status"
                    className="mt-1 block w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500"
                    value={newPayment.status}
                    onChange={handleInputChange}
                  >
                    <option value="" selected >Select Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Processed">Processed</option>
                  </select>
                </div>


                {/* Submit Buttons */}
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition duration-300 mr-2"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-colorBlue text-white px-4 py-2 rounded-lg shadow hover:bg-blue-500 transition duration-300"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border animate-spin"></span>
                    ) : (
                      selectedEmployee?.payrollManagementId ? "Update Payroll" : "Schedule PayRoll"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )
      }
    </div >
  )
}
