import PayrollManagement from "./PayrollManagement"

const HrmPayrollLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16  h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark overflow-y-scroll">
          
            <PayrollManagement />
         
        </div>
    )
}

export default HrmPayrollLayout