import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

export default function ContactDetails({ employeeId, employee, addContact }) {
  const [contactData, setContactData] = useState({
    id: employee?.contactDetails?.id || '',
    street1: employee?.contactDetails?.street1 || '',
    street2: employee?.contactDetails?.street2 || '',
    city: employee?.contactDetails?.city || '',
    state: employee?.contactDetails?.state || '',
    zipCode: employee?.contactDetails?.zipCode || '',
    country: employee?.contactDetails?.country || '',
    home: employee?.contactDetails?.home || '',
    mobile: employee?.contactDetails?.mobile || '',
    work: employee?.contactDetails?.work || '',
    workEmail: employee?.contactDetails?.workEmail || '',
    otherEmail: employee?.contactDetails?.otherEmail || '',
    attachment: null,
  });


  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0)


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setContactData((prev) => ({ ...prev, attachment: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSuccess('');

    try {
      const formData = new FormData();
      // Append all contact data fields except for `attachment`
      Object.keys(contactData).forEach((key) => {
        if (key !== 'attachment') {
          formData.append(key, contactData[key]);
        }
      });

      // Append attachment only if it's not null
      if (contactData.attachment) {
        formData.append('addressProof', contactData.attachment);
      }

      // Determine if it's a create (POST) or update (PUT) request
      const url = contactData.id
        ? `/backend/api/employee/contact/${contactData.id}`
        : `/backend/api/employee/contact/${employeeId}`;
      const method = contactData.id ? 'put' : 'post';

      const response = await axiosInstance[method](url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }, onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });

      if (response.status === 200) {
        setIsSuccess(`${contactData.id ? 'Updated' : 'Created'} contact details successfully:`);
        addContact(response.data)
      }


    } catch (error) {
      console.error('Error submitting contact details:', error);
      // alert('Failed to save contact details. Please try again.');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };


  return (
    <div className=''>
      <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow mb-8">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Contact Details</h1>
      </div>

      {isLoading && (
        <div className="flex justify-center mb-4">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500" />
          <span className="ml-2 text-gray-700">Submitting...</span>
        </div>
      )}

      {isSuccess && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          <strong>Success!</strong> {isSuccess}
        </div>
      )}

      <form
        className="bg-gray-50 dark:bg-secondaryDark shadow-md rounded p-2 px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        <div className="space-y-4 p-3 ">
          <h2 className="text-lg font-medium text-gray-600 dark:text-dark">Address</h2>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Street 1
              </label>
              <input
                type="text"
                name="street1"
                value={contactData.street1}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Street 2
              </label>
              <input
                type="text"
                name="street2"
                value={contactData.street2}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                City
              </label>
              <input
                type="text"
                name="city"
                value={contactData.city}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                State/Province
              </label>
              <input
                type="text"
                name="state"
                value={contactData.state}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Zip/Postal Code
              </label>
              <input
                type="text"
                name="zipCode"
                value={contactData.zipCode}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Country
              </label>
              <select
                name="country"
                value={contactData.country}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="">-- Select --</option>
                <option value="IN">India</option>
                <option value="UK">United Kingdom</option>
                <option value="CA">Canada</option>
                <option value="AU">Australia</option>
              </select>
            </div>
          </div>
        </div>
        <div className="space-y-4  p-3">
          <h2 className="text-lg font-medium text-gray-600 dark:text-dark">Telephone</h2>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Home
              </label>
              <input
                type="tel"
                name="home"
                value={contactData.home}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Mobile
              </label>
              <input
                type="tel"
                name="mobile"
                value={contactData.mobile}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Work
              </label>
              <input
                type="tel"
                name="work"
                value={contactData.work}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="space-y-4  p-3">
          <h2 className="text-lg font-medium text-gray-600 dark:text-dark">Email</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Work Email
              </label>
              <input
                type="email"
                name="workEmail"
                value={contactData.workEmail}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-dark">
                Other Email
              </label>
              <input
                type="email"
                name="otherEmail"
                value={contactData.otherEmail}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {isLoading && (
          <div className="mt-4 w-72">
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 h-2.5 shadow-lg">
              <div
                className="h-full bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full transition-all duration-300 ease-out"
                style={{ width: `${uploadProgress}%` }}
              >
                <span className="text-xs font-medium text-white flex items-center justify-center h-full">
                  {uploadProgress}%
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="  p-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 dark:text-dark"
            htmlFor="attachment"
          >
            Address Proof
          </label>
          <input
            type="file"
            id="attachment"
            name="attachment"
            onChange={handleFileChange}
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {contactData.attachment && (
            <p className="mt-2 text-sm text-gray-500">
              Attached file: {contactData.attachment.name}
            </p>
          )}

          <div className="mt-4">
            <p className="text-sm text-gray-700 dark:text-gray-300">
              View uploaded document:{" "}
              {employee?.contactDetails?.addressProof ? (
                <a
                  href={`${axiosInstance.defaults.baseURL}/backend/api/quote/file/${employee.contactDetails.addressProof}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline font-semibold"
                >
                  Address Proof
                </a>
              ) : (
                <span className="text-gray-500 italic">No document uploaded</span>
              )}
            </p>
          </div>

        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 dark:bg-dark dark:hover:bg-primaryDark"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}