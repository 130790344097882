import React, { useState } from "react";

const EmployeePerformance = ({ goals, handleEdit }) => {

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            console.log("Delete clicked for ID:", id);
            // Implement delete functionality here
        }
    };
   

    return (
        <div className="bg-gray-50 dark:bg-secondaryDark shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Performance Goals</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="text-gray-700 dark:text-dark">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Project</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Task</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Description</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Start Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Deadline</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Performance</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {goals.map((goal) => {
                            const startDate = new Date(goal.startDate);
                            const deadline = new Date(goal.deadline);
                            const today = new Date();

                            let performance = 0;

                            if (goal.status === "Done") {
                                if (today <= deadline) {
                                    performance = 100; // Task completed within the deadline
                                } else {
                                    const overdueDays = Math.ceil((today - deadline) / (1000 * 60 * 60 * 24));
                                    const totalDurationDays = Math.ceil((deadline - startDate) / (1000 * 60 * 60 * 24));
                                    performance = Math.max(100 - (overdueDays / totalDurationDays) * 100, 0);
                                }
                            } else if (goal.status === "In Progress") {
                                const totalDuration = deadline - startDate;
                                const elapsedTime = today - startDate;

                                if (today > deadline) {
                                    const overdueDays = Math.ceil((today - deadline) / (1000 * 60 * 60 * 24));
                                    const totalDurationDays = Math.ceil((deadline - startDate) / (1000 * 60 * 60 * 24));
                                    performance = Math.max(100 - (overdueDays / totalDurationDays) * 100, 0);
                                } else {
                                    performance = Math.max((1 - elapsedTime / totalDuration) * 100, 0);
                                }
                            }

                            performance = Math.round(performance);

                            return (
                                <tr key={goal.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {goal.projects?.name || "N/A"}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {goal.title}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                        {goal.description}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                        {new Date(goal.startDate).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                        {new Date(goal.deadline).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span
                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${goal.status === "Done"
                                                    ? "bg-green-100 text-green-800"
                                                    : goal.status === "In Progress"
                                                        ? "bg-yellow-100 text-yellow-800"
                                                        : "bg-red-100 text-red-800"
                                                }`}
                                        >
                                            {goal.status}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center gap-2">
                                            <span className="text-sm font-medium">{performance}%</span>
                                            <div className="w-32 h-2 bg-gray-200 rounded">
                                                <div
                                                    className="h-2 rounded bg-blue-500"
                                                    style={{ width: `${performance}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-2">
                                        <button
                                            onClick={() => handleEdit(goal)}
                                            className="text-blue-500 hover:text-blue-700"
                                            title="Edit"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDelete(goal.id)}
                                            className="text-red-500 hover:text-red-700"
                                            title="Delete"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>


            </div>

           
        </div>
    );
};

export default EmployeePerformance;
