import React from 'react';

export default function ViewOrder({ order, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-50 dark:bg-secondaryDark p-8 rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-dark">View Order #{order.id}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-4 mb-6">
          <DetailRow label="Vendor" value={order.vendor?.name || 'N/A'} />
          <DetailRow label="Product" value={order.productService || 'N/A'} />
          <DetailRow label="Quantity" value={order.quantity || 'N/A'} />
          <DetailRow label="Price" value={`₹${order.unitPrice.toFixed(2)}`} />
          <DetailRow label="Order Date" value={order.orderDate || 'N/A'} />
          <DetailRow label="Status" value={order.orderStatus || 'N/A'} />
        </div>

        <div className="flex justify-end">
          <button onClick={onClose} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition dark:bg-dark dark:hover:bg-primaryDark " >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

// Helper component for displaying each detail row
const DetailRow = ({ label, value }) => (
  <div className="flex justify-between border-b pb-2">
    <span className="text-gray-600 font-medium dark:text-textDark">{label}:</span>
    <span className="text-gray-800 dark:text-dark">{value}</span>
  </div>
);
