import React, { useState, useEffect } from 'react';
import axiosInstance from "../../services/AxiosInstance";
import Select from 'react-select';

const BudgetPlanning = ({ addExpense }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [totalBudget, setTotalBudget] = useState('');
    const [estimatedCost, setEstimatedCost] = useState('');
    const [miscCosts, setMiscCosts] = useState('');
    const [showExpenseForm, setShowExpenseForm] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [expenseName, setExpenseName] = useState('');
    const [plannedCost, setPlannedCost] = useState('');
    const [actualCost, setActualCost] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [budgetExists, setBudgetExists] = useState(false); // State to check if budget exists
    const [budgetId, setBudgetId] = useState('');



    // Automatically calculate total budget when estimatedCost or miscCosts changes
    useEffect(() => {
        setTotalBudget(Number(estimatedCost) + Number(miscCosts));
    }, [estimatedCost, miscCosts]);

    // Fetch projects from the backend
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axiosInstance.get('/backend/projects');
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
                setMessage({ type: 'error', text: 'Failed to load projects.' });
            }
        };
        fetchProjects();
    }, []);

    // Fetch budget data when a project is selected
    useEffect(() => {
        const fetchBudget = async () => {
            if (selectedProject) {
                try {
                    const response = await axiosInstance.get(`/backend/projects/budget/${selectedProject.value}`);
                    if (response.data) {
                        const budgetData = response.data;
                        setTotalBudget(budgetData.total);
                        setEstimatedCost(budgetData.estimateCost);
                        setMiscCosts(budgetData.miscellaneousCost);
                        setBudgetId(budgetData.id);
                        setBudgetExists(true); // Set budgetExists to true if budget is fetched
                    } else {
                        // No budget found, clear fields for creating a new budget
                        setTotalBudget('');
                        setEstimatedCost('');
                        setMiscCosts('');
                        setBudgetExists(false);
                    }
                } catch (error) {
                    console.error('Error fetching budget:', error);
                    setMessage({ type: 'error', text: 'Failed to load budget data.' });
                    setBudgetExists(false); // Reset if fetching fails
                }
            } else {
                // Reset budget fields if no project is selected
                setTotalBudget('');
                setEstimatedCost('');
                setMiscCosts('');
                setBudgetExists(false); // Reset when no project is selected
            }
        };
        fetchBudget();
    }, [selectedProject]);

    // Handle budget form submission
    const handleSubmitBudget = async (e) => {
        e.preventDefault();

        if (!selectedProject) {
            setMessage({ type: 'error', text: 'Please select a project.' });
            return;
        }

        setLoading(true);
        const budgetData = {
            total: totalBudget,
            estimateCost: estimatedCost,
            miscellaneousCost: miscCosts,
        };

        try {
            if (budgetExists) {
                // Update existing budget
                await axiosInstance.put(`/backend/projects/budget/${budgetId}`, budgetData);
                setMessage({ type: 'success', text: 'Budget updated successfully!' });
            } else {
                // console.log(budgetData);

                // Create new budget
                await axiosInstance.post(`/backend/projects/budget/${selectedProject.value}`, budgetData);
                setMessage({ type: 'success', text: 'Budget created successfully!' });
                setBudgetExists(true); // Set to true after successful creation
            }
        } catch (error) {
            console.error('Error submitting budget:', error);
            setMessage({ type: 'error', text: 'Failed to submit budget.' });
        } finally {
            setLoading(false);
        }
    };

    // Handle expense form submission
    const handleAddExpense = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (!expenseName || !plannedCost || !actualCost) {
            setMessage({ type: 'error', text: 'Please fill in all expense fields.' });
            return;
        }
        if (!selectedProject) {
            setMessage({ type: 'error', text: 'Please select a project.' });
            return;
        }

        const newExpense = { expenseName, plannedCost, actualCost };

        const submissionData = {
            name: expenseName,
            plannedCost: plannedCost,
            actualCost: actualCost,
            variance: actualCost - plannedCost,
        };

        try {
            const response = await axiosInstance.post(`/backend/projects/budget/${selectedProject.value}/expenses`, submissionData);
            console.log(response.data);

            addExpense(response.data);
            setExpenses([...expenses, newExpense]);
            setMessage({ type: 'success', text: 'Expense added successfully!' });
        } catch (error) {
            console.error('Error adding expense:', error);
            setMessage({ type: 'error', text: 'Failed to add expense.' });
        } finally {
            setLoading(false)
        }

        // Reset expense form
        setExpenseName('');
        setPlannedCost('');
        setActualCost('');
    };

    return (
        <div className="container mx-auto p-6 space-y-6 bg-gray-50 dark:bg-secondaryDark shadow-lg rounded-lg max-w-4xl">
            <h2 className="text-3xl font-bold text-gray-800 dark:text-textDark text-center mb-8">Budget Planning</h2>

            {/* Message display */}
            {message && (
                <div className={`p-4 rounded-md text-white ${message.type === 'success' ? 'bg-green-500' : 'bg-red-500'}`}>
                    {message.text}
                </div>
            )}

            {/* Project Selection */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Select Project</label>
                <Select
                    value={selectedProject}
                    onChange={setSelectedProject}
                    options={projects.map(project => ({ value: project.id, label: project.name }))}
                    className="mt-2"
                    placeholder="Select a project"
                    isClearable
                    required
                />
            </div>

            {/* Buttons to toggle forms */}
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    onClick={() => { setShowExpenseForm(false); setMessage(null); }}
                    className={`py-2 px-3 text-sm text-white rounded-full shadow-md font-semibold transition-transform duration-200 ease-in-out transform ${!showExpenseForm ? 'bg-colorBlue hover:bg-blue-500 ' : 'bg-gray-400'}`}
                >
                    Add Budget
                </button>
                <button
                    onClick={() => { setShowExpenseForm(true); setMessage(null); }}
                    className={`py-2 px-3 text-sm text-white rounded-full shadow-md font-semibold transition-transform duration-200 ease-in-out transform ${showExpenseForm ? 'bg-colorBlue hover:bg-blue-500 ' : 'bg-gray-400'}`}
                >
                    Add Expense
                </button>
            </div>

            {/* Budget Form */}
            {!showExpenseForm && (
                <div className="space-y-6">
                    <form onSubmit={handleSubmitBudget}>
                        <h3 className="text-2xl font-semibold text-gray-800 dark:text-textDark mb-4">Budget Form</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Estimated Cost</label>
                                <input
                                    type="number"
                                    value={estimatedCost}
                                    onChange={(e) => setEstimatedCost(e.target.value)}
                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter estimated cost"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Miscellaneous Costs</label>
                                <input
                                    type="number"
                                    value={miscCosts}
                                    onChange={(e) => setMiscCosts(e.target.value)}
                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter miscellaneous costs"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Total Budget</label>
                                <input
                                    type="number"
                                    value={totalBudget}

                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter total budget"
                                    readOnly
                                    required
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className="mt-4 w-full bg-colorBlue text-white py-2 px-4 rounded-md shadow-md font-medium hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >

                            {budgetExists ? 'Update Budget' : 'Create Budget'}
                        </button>
                    </form>
                </div>
            )}

            {/* Expense Form */}
            {showExpenseForm && (
                <div className="space-y-6">
                    <form onSubmit={handleAddExpense}>
                        <h3 className="text-2xl font-semibold text-gray-800 dark:text-textDark mb-4">Expense Form</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Expense Name</label>
                                <input
                                    type="text"
                                    value={expenseName}
                                    onChange={(e) => setExpenseName(e.target.value)}
                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter expense name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Actual Cost <span className='text-green-500' >(Show to customer)</span> </label>
                                <input
                                    type="tel"
                                    value={actualCost}
                                    onChange={(e) => setActualCost(e.target.value)}
                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter actual cost"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-textDark">Planned Cost  <span className='text-green-500' >(Original Price)</span> </label>
                                <input
                                    type="tel"
                                    value={plannedCost}
                                    onChange={(e) => setPlannedCost(e.target.value)}
                                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter planned cost"
                                    required
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className="mt-4 w-full bg-colorBlue text-white py-2 px-4 rounded-md shadow-md font-medium hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                            {loading ?

                                <div className="animate-spin border-t-4 w-8 h-8 text-blue-600"></div>

                                : "Add Expense"}

                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default BudgetPlanning;
