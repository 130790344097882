import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const VendorForm = ({ vendor, onCancel, addVendor }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const formData = new FormData(e.target);
      const updatedVendor = {
        id: vendor ? vendor.id : Date.now(),
        name: formData.get('name'),
        contact: formData.get('contact'),
        email: formData.get('email'),
        address: formData.get('location'),
        category: formData.get('category'),
        rating: parseInt(formData.get('rating')),
      };

      const response = vendor
        ? await axiosInstance.put(`/backend/api/vendor/${vendor.id}`, updatedVendor)
        : await axiosInstance.post('/backend/api/vendor', updatedVendor);

      setSuccessMessage(vendor ? 'Vendor updated successfully!' : 'Vendor saved successfully!');
      addVendor(response.data);
    } catch (error) {
      setErrorMessage('Error saving the vendor. Please try again.');
    } finally {
      setLoading(false);
    }

    setTimeout(() => onCancel(), 1000);
    e.target.reset();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-50 p-8 rounded-lg shadow-lg max-w-md w-full dark:bg-secondaryDark">
        <h2 className="text-2xl font-bold text-center mb-6 dark:text-textDark">
          {vendor ? 'Edit Vendor' : 'Add New Vendor'}
        </h2>

        {/* Loading, Success, and Error Messages */}
        {loading && <p className="text-blue-500 text-center mb-4">Saving vendor, please wait...</p>}
        {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}
        {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

        <form onSubmit={handleSubmit} className="space-y-4 dark:text-dark">
          {/* Vendor Name Field */}
          <div>
            <label htmlFor="name" className="block font-semibold mb-1">Vendor Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue={vendor?.name || ''}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Contact and Email Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="contact" className="block font-semibold mb-1">Contact:</label>
              <input
                type="text"
                id="contact"
                name="contact"
                defaultValue={vendor?.contact || ''}
                required
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block font-semibold mb-1">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                defaultValue={vendor?.email || ''}
                required
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Location and Category Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="location" className="block font-semibold mb-1">Location:</label>
              <input
                type="text"
                id="location"
                name="location"
                defaultValue={vendor?.address || ''}
                required
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="category" className="block font-semibold mb-1">Category:</label>
              <input
                type="text"
                id="category"
                name="category"
                defaultValue={vendor?.category || ''}
                required
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Rating Field */}
          <div>
            <label htmlFor="rating" className="block font-semibold mb-1">Rating:</label>
            <input
              type="number"
              id="rating"
              name="rating"
              min="1"
              max="5"
              defaultValue={vendor?.rating || ''}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={onCancel}
              disabled={loading}
              className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              {vendor ? 'Update Vendor' : 'Save Vendor'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorForm;
