import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./AccordionItem.css";

const AccordionItem = ({ className = "", propPadding }) => {
  // State to track the open/close state for each item (array of booleans)
  const [openItems, setOpenItems] = useState({});

  const accordionItemStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  // Array of interior design-related questions and answers
  const accordionData = [
    {
      question: "What services do you offer in interior design?",
      answer:
        "We offer a wide range of services, including residential and commercial interior design, space planning, furniture selection, lighting design, and project management to ensure that your space reflects your unique style and needs.",
    },
    {
      question: "How do you charge for your services?",
      answer:
        "Our fees vary depending on the scope of the project. We offer both hourly consultation rates and flat fees for larger projects. We can provide an estimate after an initial consultation to understand your needs better.",
    },
    {
      question: "What is the typical timeline for an interior design project?",
      answer:
        "The timeline for an interior design project depends on the size and complexity of the space. On average, smaller projects take around 4-6 weeks, while larger, more detailed projects can take several months from the initial concept to final execution.",
    },
    {
      question: "Do you work with existing furniture or décor?",
      answer:
        "Yes, we can incorporate your existing furniture or décor into the design plan. We believe in creating spaces that feel personal and can work with what you have while also suggesting new pieces to complement the design.",
    },
    {
      question: "Can you help with sustainable or eco-friendly design options?",
      answer:
        "Absolutely! We prioritize sustainable design practices and can recommend eco-friendly materials, energy-efficient lighting, and sustainable furniture options to create a green and environmentally conscious space.",
    },
    {
      question: "How involved will I be in the design process?",
      answer:
        "We value client collaboration throughout the entire design process. You will be involved in key decisions, such as style direction, color palettes, and materials. We work together to ensure the final design meets your vision.",
    },
    {
      question: "What should I expect during the first consultation?",
      answer:
        "During the first consultation, we will discuss your design preferences, project goals, budget, and timeline. We’ll also assess the space and provide an initial design concept or ideas for moving forward with the project.",
    },
  ];

  // Toggle function for each item
  const handleToggle = (index) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [index]: !prevOpenItems[index], // Toggle the state for the current index
    }));
  };

  return (
    <div className={`accordion-container ${className}`} style={accordionItemStyle}>
      {accordionData.map((item, index) => (
        <div key={index} className="accordion-item">
          <div className="question" onClick={() => handleToggle(index)}>
            <b className="question1">{item.question}</b>
            <img
              className="icon"
              alt={openItems[index] ? "Close" : "Toggle"}
              src={openItems[index] ? "/images/cross-image.svg" : "/images/icon.svg"} // Toggle icons based on open/close state
              style={{ cursor: "pointer" }}
              loading='lazy' 
            />
          </div>
          {openItems[index] && (
            <div className="answer">
              <div className="text3">{item.answer}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

AccordionItem.propTypes = {
  className: PropTypes.string,
  propPadding: PropTypes.any,
};

export default AccordionItem;
