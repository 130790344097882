import { useState, useEffect } from "react";
import { FaTrash } from 'react-icons/fa';
import axiosInstance from "../../services/AxiosInstance";
import Swal from "sweetalert2";

const ShowContacts = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/backend/api/contact/customer/contact-form');
                // console.log(response.data);

                // Ensure response data is an array
                if (Array.isArray(response.data)) {
                    setContacts(response.data);
                } else {
                    setError("Data format error: Contacts data is not an array.");
                }
                setLoading(false);
            } catch (err) {
                setError("Failed to load data.");
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axiosInstance.delete(`/backend/api/contact/customer/${id}`);
                    setContacts((prevContacts) => prevContacts.filter(contact => contact.id !== id));
                    Swal.fire('Deleted!', 'Your contact has been deleted.', 'success');
                } catch (err) {
                    Swal.fire('Error!', 'Failed to delete the contact. Please try again.', 'error');
                }
            }
        });
    };

    const handleCheckboxChange = async (id, isContacted) => {

        console.log(isContacted ? "yes" : "no");

        try {
            // Fire the PUT API to update the contact's contacted status
            await axiosInstance.put(`/backend/api/contact/customer/connected/${id}`, null, {
                params: {
                  connected: !isContacted, 
                }
              });
              

            // Update the local state after successful API call
            setContacts((prevContacts) =>
                prevContacts.map(contact =>
                    contact.id === id ? { ...contact, connected: !isContacted } : contact
                )
            );
        } catch (err) {
            Swal.fire('Error!', 'Failed to update contact status. Please try again.', 'error');
        }
    };

    return (
        <div className=" mx-auto p-2 rounded-lg   dark:bg-primaryDark">
            {loading ? (
                <p className="text-center text-gray-500 dark:text-textDark h-screen flex justify-center items-center">Loading...</p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : (
                <div className="   rounded-lg">

                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-4 text-center transition-colors duration-1000 ease-in dark:text-textDark">
                        Contacts Details
                    </h3>
                    <table className="min-w-full bg-gray-50 dark:bg-primaryDark border border-gray-200 dark:border-gray-600 shadow-md rounded-lg dark:text-textDark text-sm">
                        <thead>
                            <tr className="bg-gray-200 dark:bg-secondaryDark">
                                <th className="px-4 py-2 text-left">#</th>
                                <th className="px-4 py-2 text-left">Name</th>
                                <th className="px-4 py-2 text-left">Email</th>
                                <th className="px-4 py-2 text-left">Contact</th>
                                <th className="px-4 py-2 text-left">Project Type</th>
                                <th className="px-4 py-2 text-left">Price Range</th>
                                <th className="px-4 py-2 text-left">Communication Method</th>
                                <th className="px-4 py-2 text-left">Description</th>
                                <th className="px-4 py-2 text-center">Contacted</th>
                                <th className="px-4 py-2 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.length > 0 ? (
                                contacts.map((contact, index) => (
                                    <tr key={contact.id} className="border-t text-nowrap dark:border-gray-600">
                                        <td className="px-4 py-2">{index + 1}</td>
                                        <td className="px-4 py-2">{contact.name}</td>
                                        <td className="px-4 py-2">{contact.email}</td>
                                        <td className="px-4 py-2">{contact.phone}</td>
                                        <td className="px-4 py-2">{contact.projectType}</td>
                                        <td className="px-4 py-2">{contact.priceRange}</td>
                                        <td className="px-4 py-2">{contact.communicationMethod}</td>
                                        <td className="px-4 py-2">{contact.description}</td>
                                        <td className="px-4 py-2 text-center">
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={contact.connected || false}
                                                    onChange={() => handleCheckboxChange(contact.id, contact.connected)}
                                                    className="sr-only peer"
                                                />
                                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                            </label>
                                        </td>
                                        <td className="px-4 py-2 flex justify-center space-x-2">
                                            <button
                                                className="text-red-500 hover:text-red-700"
                                                onClick={() => handleDelete(contact.id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="11" className="text-center py-4">
                                        No contacts found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            )}
        </div>

    );
};

export default ShowContacts;
