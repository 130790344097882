import React, { useState } from 'react';
import { Star, Upload, X } from 'lucide-react';
import Select from 'react-select';
import axiosInstance from '../../services/AxiosInstance';

export function ClientFeedback({ projects, loading, customerId }) {
  const [project, setProject] = useState('');
  const [feedbackType, setFeedbackType] = useState('General');
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState('');
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handlers for user inputs
  const handleProjectChange = (e) => setProject(e.target.value);
  const handleFeedbackTypeChange = (e) => setFeedbackType(e.target.value);
  const handleRatingChange = (newRating) => setRating(newRating);
  const handleCommentsChange = (e) => setComments(e.target.value);

  // Handle file selection from file input or drag and drop
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files ? e.target.files[0] : null;
    setFile(uploadedFile);
  };

  // Handle drag and drop events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  // Handle removing the uploaded file
  const handleRemoveFile = () => {
    setFile(null);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data
    const feedbackData = {
      project,
      feedbackType,
      rating,
      comments,
      file,
    };
    // console.log(feedbackData)
    setFormLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      // Post request to the server
      const formData = new FormData();
      formData.append('customerId', customerId);
      formData.append('project', feedbackData.project);
      formData.append('feedbackType', feedbackData.feedbackType);
      formData.append('rating', feedbackData.rating);
      formData.append('comments', feedbackData.comments);
      if (feedbackData.file) {
        formData.append('file', feedbackData.file);
      }

      const response = await axiosInstance.post('/backend/api/feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setSuccessMessage('Feedback submitted successfully!');
        setProject('')
        setComments('')
        setFile(null)
        setRating('')
        setComments('')
      }

      // console.log(response.data)

      // If successful, show success message
    } catch (error) {
      setError('There was an error submitting your feedback.');
    } finally {
      setFormLoading(false); // Stop loading
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">Client Feedback</h2>
      </div>
      {error && <div className="text-center text-red-500">{error}</div>}
      {successMessage && <div className="text-center text-green-500">{successMessage}</div>}
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="project" className="block text-sm font-medium text-gray-700">
              Project
            </label>
            <Select
              options={projects.map(project => ({
                label: project.name,
                value: project.id,
              }))}
              onChange={(selectedOption) => setProject(selectedOption?.label)}
              value={projects.find(project => project.id === project)}
              isClearable
              isSearchable
              maxMenuHeight={250}
              autoFocus
              placeholder={"Select project"}
              isLoading={loading}
              required
            />
          </div>

          <div>
            <label htmlFor="feedback-type" className="block text-sm font-medium text-gray-700">
              Feedback Type
            </label>
            <select
              id="feedback-type"
              name="feedback-type"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              value={feedbackType}
              onChange={handleFeedbackTypeChange}
              required
            >
              <option>General</option>
              <option>Issue Report</option>
              <option>Suggestion</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Rating</label>
            <div className="mt-1 flex items-center">
              {[1, 2, 3, 4, 5].map((starRating) => (
                <Star
                  key={starRating}
                  className={`w-5 h-5 cursor-pointer ${starRating <= rating ? 'text-yellow-400' : 'text-gray-400'}`}
                  onClick={() => handleRatingChange(starRating)}
                />
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="comments" className="block text-sm font-medium text-gray-700">
              Comments
            </label>
            <textarea
              id="comments"
              name="comments"
              rows={4}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Your feedback here..."
              value={comments}
              onChange={handleCommentsChange}
            ></textarea>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Attachment</label>
            <div
              className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${dragActive ? 'border-blue-500' : 'border-gray-300'} border-dashed rounded-md`}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            >
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileUpload}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>

                {file && (
                  <div className="flex items-center justify-center space-x-2">
                    <p className="text-xs text-gray-500">Selected file: {file.name}</p>
                    <button
                      type="button"
                      className="text-gray-500 hover:text-red-500"
                      onClick={handleRemoveFile}
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Loading, error, and success messages */}
          {/* {formLoading && <div className="text-center text-blue-500">Submitting...</div>} */}


          <div>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={formLoading}
            >
              {formLoading ?
                <div className="flex ">
                  <div className="animate-spin  rounded-full border-t-4 border-blue-600 w-8 h-8 "></div>
                  <p>Submitting Please wait...</p>
                </div> : "Submit Feedback"
              }

            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
