import React, { useState } from 'react';

const DesignApprovalWorkflow = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stats = {
    pending: 5,
    approved: 12,
    revisions: 3,
  };

  const workflowData = [
    { id: 1, name: 'Homepage Redesign', submittedBy: 'John Doe', date: '2023-05-15', status: 'Pending', reviewers: ['Alice', 'Bob'] },
    { id: 2, name: 'Mobile App UI', submittedBy: 'Jane Smith', date: '2023-05-14', status: 'Approved', reviewers: ['Charlie'] },
    { id: 3, name: 'Logo Update', submittedBy: 'Mike Johnson', date: '2023-05-13', status: 'Revision Requested', reviewers: ['David', 'Eve'] },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Design Approval Workflow</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <StatCard title="Pending Approvals" value={stats.pending} />
        <StatCard title="Approved Designs" value={stats.approved} />
        <StatCard title="Revision Requests" value={stats.revisions} />
      </div>

      <div className="mb-4">
        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      <div className="space-y-4">
        {activeTab === 'pending' && (
          <>
            <ApprovalRequestForm />
            <WorkflowTable 
              data={workflowData.filter(item => item.status === 'Pending')} 
              onApprove={() => setIsModalOpen(true)} 
            />
          </>
        )}
        {activeTab === 'approved' && (
          <WorkflowTable 
            data={workflowData.filter(item => item.status === 'Approved')} 
          />
        )}
        {activeTab === 'revisions' && (
          <WorkflowTable 
            data={workflowData.filter(item => item.status === 'Revision Requested')} 
            onApprove={() => setIsModalOpen(true)} 
          />
        )}
      </div>

      {isModalOpen && <ApprovalModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <div className="bg-white shadow rounded-lg p-4">
    <h2 className="text-sm font-medium text-gray-500">{title}</h2>
    <p className="text-2xl font-bold">{value}</p>
  </div>
);

const TabNavigation = ({ activeTab, setActiveTab }) => (
  <div className="flex border-b">
    {['pending', 'approved', 'revisions'].map((tab) => (
      <button
        key={tab}
        className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500' : ''}`}
        onClick={() => setActiveTab(tab)}
      >
        {tab.charAt(0).toUpperCase() + tab.slice(1)}
      </button>
    ))}
  </div>
);

const ApprovalRequestForm = () => {
  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <h2 className="text-xl font-bold mb-4">Submit Design for Approval</h2>
      <form className="space-y-4">
        <div>
          <label htmlFor="designTitle" className="block text-sm p-2 font-medium text-gray-700">Design Title</label>
          <input type="text" id="designTitle" name="designTitle" className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" placeholder="Enter design title" />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm p-2 font-medium text-gray-700">Description</label>
          <textarea id="description" name="description" rows="3" className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" placeholder="Enter design description"></textarea>
        </div>
        <div>
          <label htmlFor="file" className="block text-sm  font-medium text-gray-700">Attach File</label>
          <input type="file" id="file" name="file" className="mt-1 block w-full" />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Assign Reviewer(s)</label>
          <select multiple className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
            <option value="alice">Alice</option>
            <option value="bob">Bob</option>
            <option value="charlie">Charlie</option>
          </select>
        </div>
        <div className="flex space-x-2">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Submit for Approval</button>
          <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300">Cancel</button>
        </div>
      </form>
    </div>
  );
};

const WorkflowTable = ({ data, onApprove }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Design Name</th>
            <th className="py-3 px-6 text-left">Submitted By</th>
            <th className="py-3 px-6 text-left">Submission Date</th>
            <th className="py-3 px-6 text-left">Current Status</th>
            <th className="py-3 px-6 text-left">Reviewer(s)</th>
            <th className="py-3 px-6 text-left">Actions</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {data.map((item) => (
            <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-100">
              <td className="py-3 px-6 text-left whitespace-nowrap">{item.name}</td>
              <td className="py-3 px-6 text-left">{item.submittedBy}</td>
              <td className="py-3 px-6 text-left">{item.date}</td>
              <td className="py-3 px-6 text-left">{item.status}</td>
              <td className="py-3 px-6 text-left">{item.reviewers.join(', ')}</td>
              <td className="py-3 px-6 text-left">
                <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2">View Design</button>
                {item.status !== 'Approved' && (
                  <>
                    <button className="bg-green-500 text-white px-4 py-2 rounded mr-2" onClick={onApprove}>Approve</button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded">Request Revision</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ApprovalModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Approve Design</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Review and approve the design or request revisions.
            </p>
          </div>
          <div className="mt-4 space-y-4">
            <div className="aspect-video bg-gray-200 rounded-lg"></div>
            <textarea className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" rows="4" placeholder="Enter approval notes or revision requests"></textarea>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
              onClick={onClose}
            >
              Approve
            </button>
            <button
              id="cancel-btn"
              className="mt-3 px-4 py-2 bg-yellow-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-300"
            >
              Request Revision
            </button>
            <button
              id="close-btn"
              className="mt-3 px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignApprovalWorkflow;

