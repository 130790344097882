import React from 'react'
import ThemeToggle from '../ThemeToggle'
import EventCalender from './EventCalender'

const Dashboard = () => {
  return (
    <div>
      {/* <ThemeToggle /> */}
      {/* Dashboard */}
      <EventCalender />
    </div>
  )
}

export default Dashboard