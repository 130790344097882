import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import MapsFullMap from "./maps-full-map";
import ContactModal from "./ContactModal";
import './footer.module.css';
import axiosInstance from "../services/AxiosInstance";

export default function Footer() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    try {
      const response = await axiosInstance.get("/backend/api/download-apk", { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "app-arm64-v8a-release.apk");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <footer className="bg-gradient-to-t from-gray-900 via-gray-800 to-gray-900 text-gray-300 py-10">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <img
              src="../../images/navbar-logo.png"
              alt="Kala Interiors Logo"
              className="mb-2 w-60 ml-[-30px] hover:scale-105 transition-transform"
              loading="lazy"
            />
            <h3 className="text-lg font-semibold mb-3 text-white">Quick Links:</h3>
            <nav className="mb-3" >
              <ul className="flex flex-row space-x-6 md:space-x-8">
                {[
                  { text: "Home", route: "/" },
                  { text: "About", route: "/about" },
                  { text: "Services", route: "/#service" },
                  { text: "Projects", route: "/projects" },
                ].map(({ text, route }, index) => (
                  <li key={index}>
                    <Link
                      to={route}
                      className="hover:text-blue-400 transition-colors"
                    >
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>


            <h3 className="text-lg font-semibold mb-3 text-white">Follow Us:</h3>
            <div className="flex space-x-6 mb-6">
              {[
                { icon: faWhatsapp, color: "text-green-400" },
                { icon: faFacebookF, color: "text-blue-600" },
                { icon: faInstagram, color: "text-red-500" },
                { icon: faLinkedinIn, color: "text-blue-500" },
                { icon: faYoutube, color: "text-red-600" }
              ].map(({ icon, color }, index) => (
                <a key={index} href="#" className={`hover:scale-110 transition-transform ${color}`}>
                  <FontAwesomeIcon icon={icon} size="xl" className="fill-current" />
                </a>
              ))}
            </div>

            <h3 className="text-lg font-semibold mb-3 text-white">Download Our App:</h3>
            <button onClick={handleDownloadClick}>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Google Play"
                className="w-24 hover:scale-105 transition-transform"
              />
            </button>
          </div>

          {/* Column 2 */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Testimonials:</h3>
            <div className="space-y-4">
              {[
                { quote: "Kala Interiors transformed my home into a dream space! Highly recommended.", name: "– Rushi." },
                { quote: "Exceptional service and beautiful designs. Couldn't be happier!", name: "– Abhay." },
                { quote: "Their attention to detail is remarkable. Love their work!", name: "– Vishal." },
              ].map((testimonial, index) => (
                <blockquote key={index} className="bg-gray-800 p-4 rounded-lg shadow-md">
                  <p className="text-sm text-gray-300 italic mb-2">"{testimonial.quote}"</p>
                  <span className="block text-sm text-colorGreen font-semibold">{testimonial.name}</span>
                </blockquote>
              ))}
            </div>
          </div>

          {/* Column 3 */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Our Location:</h3>
            <p className="mb-2 flex items-center text-gray-300">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-blue-400 w-10" />
              Office no. 1, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, 411026
            </p>
            <p className="mb-2 flex items-center text-gray-300">
              <FontAwesomeIcon icon={faPhone} className="mr-2 text-green-400" />
              8378989511 / 8378989507
            </p>
            <p className="mb-6 flex items-center text-gray-300">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-red-400" />
              hemalandge@gmail.com
            </p>
            <div className="relative h-72  w-full overflow-hidden rounded-lg shadow-lg mb-6">
              <MapsFullMap onContactClick={openContactModal} />
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-4">
          <div className="flex flex-col md:flex-row justify-between items-center text-sm">
            <p className="mb-4 md:mb-0">© 2024 Kala Interiors. All rights reserved.</p>
            <div className="flex flex-wrap justify-center gap-6 items-center">
              {["Privacy Policy", "Terms of Service", "Cookies Settings"].map((text, index) => (
                <span key={index} className="hover:underline cursor-pointer text-gray-400">
                  {text}
                </span>
              ))}
              <span className="text-gray-400">|</span>
              <span className="text-gray-400">Designed by Ashdip IT Solutions</span>
            </div>
          </div>
        </div>
      </div>
      {isContactModalOpen && <ContactModal onCloseContact={closeContactModal} openContact={isContactModalOpen} />}
    </footer>
  );
}
