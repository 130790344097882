import React from "react";

function MoodBoardGrid({ moodBoards, onEdit, onDelete }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {moodBoards.map((board) => (
        <div key={board.id} className="bg-white p-4 shadow rounded">
          <div className="h-32 bg-gray-200 mb-4 rounded"></div>
          <h2 className="font-bold text-lg">{board.title}</h2>
          <p className="text-sm text-gray-600">
            Created by: {board.createdBy || "You"}
          </p>
          <p className="text-sm text-gray-600">
            Date: {board.creationDate || new Date().toLocaleDateString()}
          </p>
          <div className="flex space-x-2 mt-4">
            <button
              onClick={() => onEdit(board)}
              className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
            >
              Edit
            </button>
            <button
              onClick={() => alert("Share functionality not implemented yet!")}
              className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            >
              Share
            </button>
            <button
              onClick={() => onDelete(board.id)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MoodBoardGrid;
