import React, { useState, useCallback, useEffect } from 'react';
import CustomReports from './CustomReports';
import KPIDashboard from './KPIDashboard';
import InventoryFinance from './InventoryFinance';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';

const ReportAnalyticsDashboard = () => {
  const [activeTab, setActiveTab] = useState('custom-reports'); // Track the active tab
  const [inventoryFinanceTab, setInventoryFinanceTab] = useState('inventory');
  const [projectDepartment, setProjectDepartment] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [timeRange, setTimeRange] = useState('');

  // Dummy Data
  const data = [
    { id: 1, name: 'John Doe', age: 30 },
    { id: 2, name: 'Jane jake', age: 25 },
    { id: 3, name: 'Peter', age: 35 },
  ];

  const kpidata = [
    { title: "Revenue", value: "$45,231.89", change: "+20.1% from last month", project: "finance", time: "monthly" },
    { title: "Project Completion", value: "85%", change: "+15% from last week", project: "project1", time: "weekly" },
    { title: "Milestones Hit", value: "12/15", change: "+2 this quarter", project: "project1", time: "quarterly" },
    { title: "Active Projects", value: "7", change: "+1 from last month", project: "finance", time: "monthly" },
    { title: "Interior Projects", value: "10", change: "+1 from last month", project: "project1", time: "monthly" },
  ];

  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Revenue',
        data: [65, 59, 80, 81, 56],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
      },
    ],
  };

  const barData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
      },
    ],
  };

  const pieData = {
    labels: ['Revenue', 'Expenses', 'Profit'],
    datasets: [
      {
        label: 'Finance Overview',
        data: [300, 50, 100],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  // const pieData = {
  //   labels: ['Rent', 'Salaries', 'Utilities', 'Miscellaneous'],
  //   datasets: [
  //     {
  //       label: 'Expense Distribution',
  //       data: [400, 300, 200, 100],
  //       backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
  //     },
  //   ],
  // };

  const handleFilter = useCallback(() => {
    const result = kpidata.filter(item => {
      const matchesTimeRange = timeRange ? item.time === timeRange : true;
      const matchesProjectDept = projectDepartment ? item.project === projectDepartment : true;
      return matchesTimeRange && matchesProjectDept;
    });
    setFilteredData(result);
  }, [projectDepartment, timeRange, kpidata]);

  useEffect(() => {
    // On first render, display all data
    setFilteredData(kpidata);
  }, []);

  const exportToPDF = () => {
    // Export to PDF logic goes here
    const doc = new jsPDF();
    doc.text('Custom Report', 20, 10);

    const tableColumn = ["ID", "Name", "Age"];
    const tableRows = data.map(item => [item.id, item.name, item.age]);

    // Generate the table using autoTable plugin
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("custom_report.pdf");
  };

  const exportToExcel = () => {
    // Export to Excel logic goes here
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "custom_report.xlsx");
  };

  const exportToCSV = () => {
    // Export to CSV logic goes here
    const csvData = data.map(row => ({
      ID: row.id,
      Name: row.name,
      Age: row.age,
    }));

    const ws = XLSX.utils.json_to_sheet(csvData);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "custom_report.csv");
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">Reporting & Analytics</h1>
      <div className="flex space-x-2 border-b">
        <button
          className={`py-2 px-4 ${activeTab === 'custom-reports' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setActiveTab('custom-reports')}
        >
          Custom Reports
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'kpi-dashboard' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setActiveTab('kpi-dashboard')}
        >
          KPI Dashboard
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'inventory-finance' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setActiveTab('inventory-finance')}
        >
          Inventory & Finance
        </button>
      </div>

      {/* Conditional Rendering of Components Based on Active Tab */}
      {activeTab === 'custom-reports' && (
        <CustomReports
          exportToPDF={exportToPDF}
          exportToExcel={exportToExcel}
          exportToCSV={exportToCSV}
          data={data}
        />
      )}
      {activeTab === 'kpi-dashboard' && (
        <KPIDashboard
          handleFilter={handleFilter}
          filteredData={filteredData}
          lineData={lineData}
          barData={barData}
          projectDepartment={projectDepartment}
          setProjectDepartment={setProjectDepartment}
        />
      )}
      {activeTab === 'inventory-finance' && (
        <InventoryFinance
          inventoryFinanceTab={inventoryFinanceTab}
          setInventoryFinanceTab={setInventoryFinanceTab}
          lineData={lineData}
          pieData={pieData}
        />
      )}
    </div>
  );
};

export default ReportAnalyticsDashboard;
