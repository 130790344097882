import React from 'react'
import VisionMission from '../components/VisionMission'
import JoinUs from '../components/JoinUs'
import KeyPeople from '../components/KeyPeople'
import AboutHeader from '../components/AboutHeader'

const AboutPage = () => {
  return (
    <div className=" border-t mt-8 bg-blue-50">
      <AboutHeader />
      <VisionMission />
      <KeyPeople />

      <div className="flex w-full p-4 justify-center">
        <JoinUs />
      </div>
    </div>)
}

export default AboutPage