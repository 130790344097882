import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for drag, drop, and click
import './calendar.css';
import axiosInstance from '../../services/AxiosInstance';

const EventCalendar = () => {
    const [events, setEvents] = useState([]);

    // Fetch events from backend on component mount
    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await axiosInstance.get('/backend/api/events');
            setEvents(response.data.map(event => ({
                id: event.id,
                title: event.title,
                start: event.startDate,  // FullCalendar expects `start`
                end: event.endDate        // FullCalendar expects `end`
            })));
        } catch (error) {
            console.error("Error fetching events", error);
        }
    };

    // Handle event creation after selecting a date range
    const handleSelect = async (selectionInfo) => {
        const title = prompt('Enter a title for the new event:');
        if (title) {
            const newEvent = {
                title,
                startDate: selectionInfo.startStr, // Use startStr (with date & time)
                endDate: selectionInfo.endStr || selectionInfo.startStr, // Use endStr or startStr if no end time
            };

            try {
                const response = await axiosInstance.post('/backend/api/events', newEvent);
                setEvents([...events, {
                    id: response.data.id,
                    title: response.data.title,
                    start: response.data.startDate,
                    end: response.data.endDate || response.data.startDate,
                }]);

                alert('Event added successfully!'); // Success alert
            } catch (error) {
                console.error("Error creating event", error);
                alert('Failed to add event.'); // Failure alert
            }
        } else {
            alert('Event creation cancelled.'); // Cancel alert
        }
    };

    // Handle event click to delete the event
    const handleEventClick = async (info) => {
        const confirmDelete = window.confirm(`Do you want to delete the event '${info.event.title}'?`);
        if (confirmDelete) {
            try {
                await axiosInstance.delete(`/backend/api/events/${info.event.id}`);
                setEvents(events.filter((event) => event.id !== parseInt(info.event.id)));
            } catch (error) {
                console.error("Error deleting event", error);
            }
        }
    };

    // Handle event drop or resize to update event dates
    const handleEventChange = async (info) => {
        const updatedEvent = {
            id: info.event.id,
            title: info.event.title,
            startDate: info.event.startStr,
            endDate: info.event.endStr || info.event.startStr,
        };

        try {
            await axiosInstance.put(`/backend/api/events/${info.event.id}`, updatedEvent);
            const updatedEvents = events.map((event) => {
                if (event.id === parseInt(info.event.id)) {
                    return {
                        id: event.id,
                        title: event.title,
                        start: updatedEvent.startDate,
                        end: updatedEvent.endDate,
                    };
                }
                return event;
            });
            setEvents(updatedEvents);
        } catch (error) {
            console.error("Error updating event", error);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
            {/* Calendar Section */}
            <div className="lg:w-2/3 w-full mb-4 lg:mb-0">
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    selectable={true}
                    select={handleSelect}
                    eventClick={handleEventClick}
                    editable={true}
                    droppable={true}
                    eventDrop={handleEventChange}
                    eventResize={handleEventChange}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    height="auto"
                    eventColor="#4F46E5"
                    dayMaxEvents={3}
                    className="max-w-full text-xs md:text-base" // Adjust font size and responsiveness
                    buttonText={{
                        today: 'Today',
                        month: 'Month',
                        week: 'Week',
                        day: 'Day',
                    }}
                />
            </div>

            {/* Events List Section */}
            <div className="lg:w-1/3 w-full p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Event List</h3>
                <ul className="space-y-3">
                    {events.map((event) => (
                        <li
                            key={event.id}
                            className="p-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm"
                        >
                            <p className="text-sm font-medium text-gray-700 dark:text-gray-300">{event.title}</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                {new Date(event.start).toLocaleDateString()} to{' '}
                                {event.end ? new Date(event.end).toLocaleDateString() : new Date(event.start).toLocaleDateString()}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default EventCalendar;
