import React, { useState, useEffect } from "react";
import TaskAssignmentForm from "./TaskAssignmentForm";
import KanbanBoard from "./KanbanBoard";
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";

const TaskManagementLayout = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchTasks = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/backend/projects/tasks');
            console.log("task data response ", response.data)
            const fetchedTasks = Array.isArray(response.data)
                ? response.data.map(task => ({
                    id: task.id.toString(),
                    title: task.title,
                    status: task.status || 'To Do',
                    assignee: task.employee ? task.employee.fullName : 'Unassigned',
                    dueDate: task.deadline,
                    priority: task.priority,
                    project: task.projects.name,
                    updatedAt: task.updatedAt,
                }))
                : [];

                console.log("task data", fetchTasks)

            const sortedTasks = fetchedTasks.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            setTasks(sortedTasks);
        } catch (error) {
            toast.error("Failed to fetch tasks.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, []);

    
    // Function to update tasks after a new task is assigned
    const addTask = (newTask) => {
        const formattedTask = {
            id: newTask.id.toString(),
            title: newTask.title,
            status: newTask.status || 'To Do',
            assignee: newTask.employee ? newTask.employee.fullName : 'Unassigned',
            dueDate: newTask.deadline,
            priority: newTask.priority,
            project: newTask.projects.name,
            updatedAt: newTask.updatedAt,
        };

        setTasks((prevTasks) => [...prevTasks, formattedTask]);
        // console.log('New Task Added:', formattedTask); // Debugging line
    };



    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100  dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full max-w-7xl">
                <div className="bg-white rounded-lg shadow-lg h-[calc(100vh-4rem)] overflow-y-auto">
                    {/* Left side - Task Assignment Form */}
                    <TaskAssignmentForm addTask={addTask} />
                </div>

                {/* Right side - Kanban Board */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto bg-gray-50 rounded-lg">
                    <KanbanBoard tasks={tasks} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default TaskManagementLayout;
