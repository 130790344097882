import React, { useState, useMemo,useRef } from 'react';
import { Search, ChevronDown, Eye, Edit, Download, Trash2, X, RotateCcw, Plus, ChevronLeft, ChevronRight, Upload, FileText, Image, Link } from 'lucide-react';

const DesignTemplates = () => {
  const [templates, setTemplates] = useState([
    { id: 1, name: 'Brand Guidelines', uploadDate: '2023-05-01', revisionCount: 3, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/55efd3fc-197d-40af-a83a-d06b674ce8a4/Leonardo_Phoenix_A_comprehensive_brand_guidelines_document_spr_3.jpg' },
    { id: 2, name: 'Website Mockup', uploadDate: '2023-05-02', revisionCount: 2, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/7f718b76-90d4-4c63-a7b1-382beab1bfbc/Leonardo_Phoenix_A_modern_sleek_and_minimalist_website_mockup_3.jpg' },
    { id: 3, name: 'Mobile App UI', uploadDate: '2023-05-03', revisionCount: 1, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/d100c5c4-c2f2-43a2-8ae0-2e9a6138ded6/Leonardo_Phoenix_A_modern_mobile_app_user_interface_featuring_3.jpg' },
    { id: 4, name: 'Social Media Kit', uploadDate: '2023-05-04', revisionCount: 4, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/4453850c-9c41-451c-9534-bf8a85777616/Leonardo_Phoenix_A_vibrant_social_media_kit_consisting_of_a_co_3.jpg' },
    { id: 5, name: 'Email Newsletter Template', uploadDate: '2023-05-05', revisionCount: 2, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/3eadc3c9-b06e-434e-b2b4-3f81b494e6bd/Leonardo_Phoenix_A_modern_email_newsletter_template_with_a_cle_3.jpg' },
    { id: 6, name: 'Product Packaging Design', uploadDate: '2023-05-06', revisionCount: 5, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/b8cae41c-164c-40bf-8df7-b69ad8e50dfb/Leonardo_Phoenix_A_vibrant_attentiongrabbing_product_packaging_3.jpg' },
    { id: 7, name: 'Infographic Template', uploadDate: '2023-05-07', revisionCount: 1, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/b8cae41c-164c-40bf-8df7-b69ad8e50dfb/Leonardo_Phoenix_A_vibrant_attentiongrabbing_product_packaging_1.jpg' },
    { id: 8, name: 'Business Card Design', uploadDate: '2023-05-08', revisionCount: 3, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/4453850c-9c41-451c-9534-bf8a85777616/Leonardo_Phoenix_A_vibrant_social_media_kit_consisting_of_a_co_0.jpg' },
    { id: 9, name: 'Presentation Template', uploadDate: '2023-05-09', revisionCount: 2, thumbnail: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/55efd3fc-197d-40af-a83a-d06b674ce8a4/Leonardo_Phoenix_A_comprehensive_brand_guidelines_document_spr_0.jpg' },
  ]);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMethod, setSortMethod] = useState('none');
  const templatesPerPage = 6;

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewingTemplate, setViewingTemplate] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);
  const editFileInputRef = useRef(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: '',
    description: '',
    thumbnail: ''
  });

  // View handlers
  const handleView = (template) => {
    setViewingTemplate(template);
    setIsViewModalOpen(true);
  };

  const closeViewModal = () => {
    setViewingTemplate(null);
    setIsViewModalOpen(false);
  };

  // Edit handlers
  const handleEdit = (template) => {
    setEditingTemplate(template);
    setEditFormData({
      name: template.name,
      description: template.description || '',
      thumbnail: template.thumbnail
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingTemplate(null);
    setIsEditModalOpen(false);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const updatedTemplates = templates.map(t => 
      t.id === editingTemplate.id 
        ? { ...t, ...editFormData }
        : t
    );
    setTemplates(updatedTemplates);
    closeEditModal();
  };
  const handleFileUpload = (e, isEdit = false) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (isEdit) {
          setEditFormData(prev => ({
            ...prev,
            thumbnail: reader.result
          }));
        } else {
          setThumbnailPreview(reader.result);
        }
        setUploadedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadSubmit = (e) => {
    e.preventDefault();
    const templateName = e.target.templateName.value;
    const description = e.target.description.value;
    const thumbnailUrl = e.target.thumbnailUrl.value;

    const newTemplate = {
      id: templates.length + 1,
      name: templateName,
      description: description,
      thumbnail: thumbnailPreview || thumbnailUrl,
      uploadDate: new Date().toISOString().split('T')[0],
      revisionCount: 1
    };

    setTemplates([...templates, newTemplate]);
    closeUploadModal();
    // Reset upload-related states
    setThumbnailPreview(null);
    setUploadedFile(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };


  // Delete handlers
  const handleDelete = (template) => {
    setDeletingTemplate(template);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeletingTemplate(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    setTemplates(templates.filter(t => t.id !== deletingTemplate.id));
    closeDeleteModal();
  };

  // Download handler
  const handleDownload = (template) => {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = template.thumbnail;
    link.download = `${template.name}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const [viewingTemplate, setViewingTemplate] = useState(null);
  // const [editingTemplate, setEditingTemplate] = useState(null);
  // const [deletingTemplate, setDeletingTemplate] = useState(null);

  const openUploadModal = () => setIsUploadModalOpen(true);
  const closeUploadModal = () => setIsUploadModalOpen(false);

  const openRevisionModal = (template) => {
    setSelectedTemplate(template);
    setIsRevisionModalOpen(true);
  };
  const closeRevisionModal = () => setIsRevisionModalOpen(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredTemplates = useMemo(() => {
    let sorted = templates.filter(template =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (sortMethod === 'date') {
      sorted.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
    } else if (sortMethod === 'revisions') {
      sorted.sort((a, b) => b.revisionCount - a.revisionCount);
    }
    return sorted;
  }, [templates, searchTerm, sortMethod]);

  const currentTemplates = useMemo(() => {
    const indexOfLastTemplate = currentPage * templatesPerPage;
    const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
    return filteredTemplates.slice(indexOfFirstTemplate, indexOfLastTemplate);
  }, [filteredTemplates, currentPage]);

  const totalPages = Math.ceil(filteredTemplates.length / templatesPerPage);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Design Templates</h1>

      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="w-full md:w-1/3 relative">
          <input
            type="text"
            placeholder="Search templates..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <div className="flex flex-col sm:flex-row gap-2 w-full md:w-auto ml-auto">
          <div className="relative">
            <button
              onClick={() => setFilterOpen(!filterOpen)}
              className="w-full sm:w-auto px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 flex items-center justify-center"
            >
              Filter
              <ChevronDown className="ml-2 h-5 w-5" />
            </button>
            {filterOpen && (
              <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                <div className="py-1" role="menu" aria-orientation="vertical">
                  <button
                    onClick={() => {
                      setSortMethod('date');
                      setFilterOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sort by Upload Date
                  </button>
                  <button
                    onClick={() => {
                      setSortMethod('revisions');
                      setFilterOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sort by Revision Count
                  </button>
                  <button
                    onClick={() => {
                      setSortMethod('none');
                      setFilterOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Clear Sorting
                  </button>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={openUploadModal}
            className="w-full sm:w-auto px-4 py-2 bg-colorBlue text-white rounded-md flex items-center justify-center"
          >
            <Plus className="mr-2 h-5 w-5" />
            Upload Template
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentTemplates.map((template) => (
          <div key={template.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img src={template.thumbnail} alt={template.name} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800">{template.name}</h3>
              <p className="text-sm text-gray-600">Uploaded: {template.uploadDate}</p>
              <p className="text-sm text-gray-600">Revisions: {template.revisionCount}</p>
              <div className="mt-4 flex justify-between">
                <button onClick={() => handleView(template)} className="text-blue-600 hover:text-blue-800" title="View">
                  <Eye className="h-5 w-5" />
                </button>
                <button onClick={() => handleEdit(template)} className="text-green-600 hover:text-green-800" title="Edit">
                  <Edit className="h-5 w-5" />
                </button>
                <button onClick={() => handleDownload(template)} className="text-yellow-600 hover:text-yellow-800" title="Download">
                  <Download className="h-5 w-5" />
                </button>
                <button onClick={() => handleDelete(template)} className="text-red-600 hover:text-red-800" title="Delete">
                  <Trash2 className="h-5 w-5" />
                </button>
                <button
                  onClick={() => openRevisionModal(template)}
                  className="text-purple-600 hover:text-purple-800"
                  title="Revision History"
                >
                  History
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>


      {/* View Modal */}
      {isViewModalOpen && viewingTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="relative max-w-4xl w-full bg-white rounded-lg overflow-hidden">
            <div className="relative">
              {/* Image and Details */}
              <img
                src={viewingTemplate.thumbnail}
                alt={viewingTemplate.name}
                className="w-full h-auto max-h-[80vh] object-contain"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                <h3 className="text-xl font-semibold text-white">{viewingTemplate.name}</h3>
                <p className="text-gray-200">Uploaded: {viewingTemplate.uploadDate}</p>
              </div>
            </div>

            {/* Footer with Cancel Button */}
            <div className="p-4 flex justify-end bg-gray-100">
              <button
                onClick={closeViewModal}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {isEditModalOpen && editingTemplate && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
            <div className="p-6">
              <button
                onClick={closeEditModal}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
              <h3 className="text-xl font-semibold mb-4">Edit Template</h3>
              <form onSubmit={handleEditSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    value={editFormData.name}
                    onChange={(e) => setEditFormData({ ...editFormData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Description</label>
                  <textarea
                    value={editFormData.description}
                    onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                {/* Thumbnail URL Input */}
                <div>
                    <label className="block text-gray-700 text-left font-semibold mb-2">
                      Thumbnail
                    </label>
                    <div className="flex items-center space-x-2">
                      <div className="relative rounded-md shadow-sm flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Link className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          id="thumbnailUrl"
                          type="url"
                          placeholder="Enter thumbnail URL"
                        />
                      </div>
                      <div>
                        <label className="inline-block bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md cursor-pointer">
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => handleFileUpload(e)}
                            accept="image/*"
                            className="hidden"
                          />
                          Add File
                        </label>
                      </div>
                    </div>
                    {/* Thumbnail Preview */}
                    {(thumbnailPreview || uploadedFile) && (
                      <div className="mt-2 flex items-center">
                        <img 
                          src={thumbnailPreview} 
                          alt="Thumbnail Preview" 
                          className="h-20 w-20 object-cover rounded-md mr-2"
                        />
                        <span className="text-sm text-gray-500">{uploadedFile?.name}</span>
                      </div>
                    )}
                  </div>

                  <div className="mt-6 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                      type="submit"
                      className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-colorBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Upload className="mr-2 h-5 w-5" />
                      Upload
                    </button>
                    <button
                      type="button"
                      className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={closeUploadModal}
                    >
                      Cancel
                    </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && deletingTemplate && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-sm bg-white rounded-lg shadow-xl">
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Template</h3>
              <p className="text-sm text-gray-500">
                Are you sure you want to delete "{deletingTemplate.name}"? This action cannot be undone.
              </p>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={closeDeleteModal}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded-md text-sm font-medium hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


{isUploadModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full p-4 flex items-center justify-center z-50">
          <div className="relative w-full max-w-md mx-auto bg-white rounded-lg shadow-lg">
            <div className="p-4 sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  onClick={closeUploadModal}
                  className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 className="text-xl sm:text-2xl leading-6 font-bold text-gray-900 mb-4">Upload New Template</h3>
                <form onSubmit={handleUploadSubmit} className="mt-4 space-y-4">
                  <div>
                    <label className="block text-gray-700 text-left font-semibold mb-2" htmlFor="templateName">
                      Template Name
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FileText className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        id="templateName"
                        type="text"
                        placeholder="Enter template name"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-left font-semibold mb-2" htmlFor="description">
                      Description
                    </label>
                    <textarea
                      className="shadow-sm focus:ring-blue-500 p-2 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      id="description"
                      rows="3"
                      placeholder="Enter description"
                    />
                  </div>
                  
                  {/* Thumbnail URL Input */}
                  <div>
                    <label className="block text-gray-700 text-left font-semibold mb-2">
                      Thumbnail
                    </label>
                    <div className="flex items-center space-x-2">
                      <div className="relative rounded-md shadow-sm flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Link className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          id="thumbnailUrl"
                          type="url"
                          placeholder="Enter thumbnail URL"
                        />
                      </div>
                      <div>
                        <label className="inline-block bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md cursor-pointer">
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => handleFileUpload(e)}
                            accept="image/*"
                            className="hidden"
                          />
                          Add File
                        </label>
                      </div>
                    </div>
                    {/* Thumbnail Preview */}
                    {(thumbnailPreview || uploadedFile) && (
                      <div className="mt-2 flex items-center">
                        <img 
                          src={thumbnailPreview} 
                          alt="Thumbnail Preview" 
                          className="h-20 w-20 object-cover rounded-md mr-2"
                        />
                        <span className="text-sm text-gray-500">{uploadedFile?.name}</span>
                      </div>
                    )}
                  </div>

                  <div className="mt-6 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                      type="submit"
                      className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-colorBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Upload className="mr-2 h-5 w-5" />
                      Upload
                    </button>
                    <button
                      type="button"
                      className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={closeUploadModal}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

{isRevisionModalOpen && selectedTemplate && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative min-h-screen md:flex md:items-center md:justify-center p-4">
            <div className="relative w-full max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
              <div className="p-4 sm:p-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg sm:text-xl font-medium text-gray-900">Revision History: {selectedTemplate.name}</h3>
                  <button onClick={closeRevisionModal} className="text-gray-500 hover:text-gray-700">
                    <X className="h-6 w-6" />
                  </button>
                </div>
                <div className="mt-2 overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Version</th>
                          <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Change Summary</th>
                          <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Upload Date</th>
                          <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Revised By</th>
                          <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {[
                          { version: 3, summary: 'Updated color scheme', date: '2023-05-03', revisedBy: 'John Doe' },
                          { version: 2, summary: 'Added new section', date: '2023-05-02', revisedBy: 'Jane Smith' },
                          { version: 1, summary: 'Initial upload', date: '2023-05-01', revisedBy: 'John Doe' },
                        ].map((revision) => (
                          <tr key={revision.version}>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{revision.version}</td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{revision.summary}</td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{revision.date}</td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{revision.revisedBy}</td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
                              <div className="flex space-x-2">
                                <button className="text-blue-600 hover:text-blue-900" title="View">
                                  <Eye className="h-5 w-5" />
                                </button>
                                {/* <button className="text-green-600 hover:text-green-900" title="Revert">
                                  <RotateCcw className="h-5 w-5" />
                                </button> */}
                                <button className="text-yellow-600 hover:text-yellow-900" title="Download">
                                  <Download className="h-5 w-5" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                  currentPage === i + 1
                    ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                }`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default DesignTemplates;









