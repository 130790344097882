import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Swal from 'sweetalert2';
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';  // Importing icons from react-icons

const VendorTable = ({ onView, onEdit , vendors, loading, error, onDelete}) => {
 
  

  if (loading) {
    return <p>Loading vendors...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Vendor Name</th>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Contact Information</th>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Location</th>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Category</th>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Rating</th>
            <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vendors.length > 0 ? vendors.map((vendor) => (
            <tr key={vendor.id}>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{vendor.name}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {vendor.contact}<br />
                {vendor.email}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{vendor.address}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{vendor.category}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {[...Array(5)].map((_, i) => (
                  <span key={i} style={{ color: i < vendor.rating ? 'gold' : 'gray' }}>★</span>
                ))}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <button 
                  onClick={() => onView(vendor)} 
                  style={{ marginRight: '5px', padding: '5px 10px',  border: 'none', borderRadius: '3px' }}
                >
                  <AiOutlineEye className='text-green-600' />
                </button>
                <button 
                  onClick={() => onEdit(vendor)} 
                   style={{ marginRight: '5px', padding: '5px 10px',border: 'none', borderRadius: '3px' }}
                >
                  <AiOutlineEdit className='text-yellow-600' /> 
                </button>
                <button 
                  onClick={() => onDelete(vendor.id)} 
                 style={{ padding: '5px 10px', border: 'none', borderRadius: '3px' }}
                >
                  <AiOutlineDelete className='text-red-600' /> 
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center', padding: '10px' }}>No vendors found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VendorTable;
