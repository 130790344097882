import React, { useState } from 'react';

const AdminAttendanceManagement = ({ employees, error, loading }) => {
    // State for the selected date
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
    // State for the search term
    const [searchTerm, setSearchTerm] = useState('');
    // State for sorting
    const [sortConfig, setSortConfig] = useState({ key: 'fullName', direction: 'ascending' });

    // Function to handle date change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Function to handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Function to request sorting
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Map employees to include attendance status for the selected date
    const employeesWithAttendance = employees.map((employee) => {
        // Check if the employee has a matching attendance record for the selected date
        const recordForSelectedDate = employee.attendanceRecords?.find(
            (record) => record.checkInTime && record.checkInTime.slice(0, 10) === selectedDate
        );

        return {
            ...employee,
            checkInTime: recordForSelectedDate ? new Date(recordForSelectedDate.checkInTime).toLocaleTimeString() : "N/A",
            checkOutTime: recordForSelectedDate && recordForSelectedDate.checkOutTime
                ? new Date(recordForSelectedDate.checkOutTime).toLocaleTimeString()
                : "N/A",
            status: recordForSelectedDate
                ? (recordForSelectedDate.checkOutTime ? "Checked Out" : "Checked In")
                : "Absent",
        };
    });

    // Filter out employees who have joined on or before the selected date
    const filteredEmployees = employeesWithAttendance.filter(employee => {
        return !employee.dateOfJoining || new Date(employee.dateOfJoining) <= new Date(selectedDate);
    });

    // Further filter based on search term
    const searchedEmployees = filteredEmployees.filter(employee =>
        employee.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort employees based on the sort configuration
    const sortedEmployees = [...searchedEmployees].sort((a, b) => {
        const isAsc = sortConfig.direction === 'ascending';
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return isAsc ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return isAsc ? 1 : -1;
        }
        return 0;
    });

    return (
        <div className="container mx-auto p-2 space-y-4  rounded-lg shadow-md ">
            {/* Header */}
            <div className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Attendance Management</h1>
                <p className="text-green-600 text-2xl dark:text-green-400">
                    Welcome, <span className="font-semibold">ADMIN</span>!
                </p>
            </div>
            <div className="flex gap-5">

                {/* Date Filter */}
                <div className="mb-4">
                    <label htmlFor="attendanceDate" className="mr-2 text-lg dark:text-textDark">Select Date:</label>
                    <input
                        type="date"
                        id="attendanceDate"
                        value={selectedDate}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={handleDateChange}
                        className="border border-gray-300 rounded p-2"
                    />
                </div>


                {/* Search Input */}
                <div className="mb-4">
                    <label htmlFor="searchEmployee" className="mr-2 text-lg dark:text-textDark">Search Employee:</label>
                    <input
                        type="text"
                        id="searchEmployee"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name..."
                        className="border border-gray-300 rounded p-2 "
                    />
                </div>
            </div>

            {/* Loading, Error, or Attendance Records */}
            {loading ? (
                <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
                <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                <p className='dark:text-secondaryDark' >Loading Attendence records..</p>
              </div>
            ) : error ? (
                <p className="text-center text-red-600">{error}</p>
            ) : (
                <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-darkBlue">Attendance for {selectedDate}</h2>

                    {sortedEmployees.length === 0 ? (
                        <p className="text-center text-gray-600">No employees found.</p>
                    ) : (
                        <table className="min-w-full ">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b cursor-pointer" onClick={() => requestSort('employeeId')}>
                                        Employee ID
                                        {sortConfig.key === 'employeeId' && (sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽')}
                                    </th>
                                    <th className="py-2 px-4 border-b cursor-pointer" onClick={() => requestSort('fullName')}>
                                        Full Name
                                        {sortConfig.key === 'fullName' && (sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽')}
                                    </th>
                                    <th className="py-2 px-4 border-b">Check-In Time</th>
                                    <th className="py-2 px-4 border-b">Check-Out Time</th>
                                    <th className="py-2 px-4 border-b">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedEmployees.map((employee, index) => (
                                    <tr key={index} className="text-center">
                                        <td className="py-2 px-4 border-b">{employee.employeeId}</td>
                                        <td className="py-2 px-4 border-b text-left">{employee.fullName}</td>
                                        <td className="py-2 px-4 border-b">{employee.checkInTime}</td>
                                        <td className="py-2 px-4 border-b">{employee.checkOutTime}</td>
                                        <td className="py-2 px-4 border-b">{employee.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdminAttendanceManagement;
