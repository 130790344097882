import React, { useState } from "react";
import GetQuoteModal from "./GetQuoteModal";

const JoinUs = ({ className = "" }) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section className={`flex justify-center items-start max-w-full px-4 ${className}`}>
            <div className="flex flex-col items-center max-w-4xl w-full gap-10">
                <div className="flex flex-col items-center text-center">
                    {/* Main Heading */}
                    <div className="relative">
                        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-colorBlue leading-tight tracking-tight">
                            Let’s Join With Us
                        </h1>
                        <img
                            className="text-colorBlue mt-4 "
                            alt="Line"
                            src="images/line-26.svg"
                            loading="lazy"
                        />
                    </div>

                    {/* Sub Heading */}
                    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-semibold leading-tight bg-gradient-to-r from-colorBlue via-colorGreen to-silver  bg-clip-text text-transparent">
                        To Make Your Dream Come True
                    </h1>
                </div>

                {/* Call-to-Action Button */}
                <button
                    onClick={openModal}
                    className="flex items-center justify-center gap-3 bg-gradient-to-r from-colorGreen to-colorBlue text-white px-12 py-4 rounded-full border-2 border-yellow-500 hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl text-xl"
                >
                    Get Quote
                    <img
                        className="ml-3 w-8 h-8"
                        alt="Icon"
                        src="/images/icon--relume-24.svg"
                        loading="lazy"
                    />
                </button>

                {/* Modal */}
                <GetQuoteModal open={showModal} onCloseQuote={closeModal} />
            </div>
        </section>
    );
};

export default JoinUs;
