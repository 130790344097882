import React, { useState } from 'react';
import { Star, Upload } from 'lucide-react';

export function DesignApproval() {
  const [feedbackType, setFeedbackType] = useState('General Feedback');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [file, setFile] = useState(null);

  const handleFeedbackChange = (e) => setFeedback(e.target.value);
  const handleFeedbackTypeChange = (e) => setFeedbackType(e.target.value);
  const handleRatingChange = (newRating) => setRating(newRating);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (actionType) => {
    const feedbackData = {
      designName: 'Homepage Redesign v2.0',
      feedbackType,
      feedback,
      rating,
      file,
      actionType,
    };

    console.log('Submitted Data:', feedbackData);
    // Further processing like API calls can go here
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4 md:p-6">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Design Approval & Feedback
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        <form className="space-y-6">
          <div>
            <label htmlFor="design-name" className="block text-sm font-medium text-gray-700">
              Design Name
            </label>
            <input
              type="text"
              id="design-name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value="Homepage Redesign v2.0"
              readOnly
            />
          </div>
          <div>
            <label htmlFor="feedback-type" className="block text-sm font-medium text-gray-700">
              Feedback Type
            </label>
            <select
              id="feedback-type"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              value={feedbackType}
              onChange={handleFeedbackTypeChange}
            >
              <option>General Feedback</option>
              <option>Request Change</option>
              <option>Approval</option>
            </select>
          </div>
          <div>
            <label htmlFor="feedback" className="block text-sm font-medium text-gray-700">
              Feedback
            </label>
            <textarea
              id="feedback"
              rows={3}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Your feedback here..."
              value={feedback}
              onChange={handleFeedbackChange}
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Rating</label>
            <div className="mt-1 flex items-center">
              {[1, 2, 3, 4, 5].map((starRating) => (
                <Star
                  key={starRating}
                  className={`w-5 h-5 cursor-pointer ${starRating <= rating ? 'text-yellow-400' : 'text-gray-400'}`}
                  onClick={() => handleRatingChange(starRating)}
                />
              ))}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Attachment</label>
            <div
              className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileUpload}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                {file && (
                  <p className="text-xs text-gray-500">Uploaded file: {file.name}</p>
                )}
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3">
            <button
              type="button"
              onClick={() => handleSubmit('Approval')}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Approve
            </button>
            <button
              type="button"
              onClick={() => handleSubmit('Request Change')}
              // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            
            >
              Request Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
