import React, { useEffect } from 'react';
import { FaFilePdf } from 'react-icons/fa'; // Import an icon for the files
import axiosInstance from '../../services/AxiosInstance';

const QuotationModal = ({ isOpen, onClose, quotationDetails }) => {
    if (!isOpen) return null;

    useEffect(() => {
        console.log("Quotation details: ", quotationDetails);
    }, [quotationDetails]);

    return (
        <div className="fixed inset-0 z-50 flex items-center h-screen justify-center bg-gradient-to-br from-gray-600 to-gray-400 bg-opacity-70">
            <div className="bg-white rounded-xl shadow-xl w-11/12 max-w-2xl  transform transition-all duration-300 ease-in-out">
                {/* Modal Header */}
                <div className="p-4 border-b">
                    <h2 className="text-2xl font-bold text-center text-purple-600">Quotation Details</h2>
                    <h3 className="text-lg font-semibold text-center text-gray-800 mt-2">
                        Name: <span className="text-gray-600">{quotationDetails.name}</span>
                    </h3>
                </div>

                {/* Scrollable Content */}
                <div className="p-4 overflow-y-auto ">
                    <ul className="space-y-3">
                        {quotationDetails.detailsSendList.map((item, index) => (
                            <li key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm">
                                <h5 className="font-medium text-purple-500">Quotation Title: {item.quotationTitle}</h5>
                                <ul className="list-disc list-inside pl-5 text-gray-700">
                                    {item.quoteItems.map((quoteItem, quoteIndex) => (
                                        <li key={quoteIndex} className="mt-2">
                                            <span className="font-semibold">Description:</span> {quoteItem.description},
                                            <span className="font-semibold"> Quantity:</span> {quoteItem.quantity},
                                            <span className="font-semibold"> Amount:</span> {quoteItem.amount},
                                            <span className="font-semibold"> Total Amount:</span> {quoteItem.totalAmount}
                                        </li>
                                    ))}
                                </ul>

                                {/* Display PDF files */}
                                {item.files && item.files.length > 0 && (
                                    <div className="mt-4">
                                        <ul className="space-y-2">
                                            {item.files.map((file, fileIndex) => (
                                                <li key={fileIndex} className="flex items-center space-x-2">
                                                    <FaFilePdf className="text-red-500" />

                                                    <a
                                                        href={`${axiosInstance.defaults.baseURL}/backend/api/quote/file/${file.fileName}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-indigo-600 hover:text-indigo-900 "
                                                    >
                                                        {file.fileName}
                                                    </a>


                                                    {/* <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">


                                                        {file.fileName}
                                                    </a> */}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Modal Footer */}
                <div className="p-4 border-t flex justify-end">
                    <button
                        className="px-5 py-2 bg-purple-600 text-white rounded-lg shadow hover:bg-purple-700 focus:outline-none focus:ring focus:ring-purple-300"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default QuotationModal;
