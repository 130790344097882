import React from 'react'
import { FaStar } from 'react-icons/fa'

export default function SupplierPerformanceTracking() {
  const suppliers = [
    {
      id: 1,
      name: 'ABC Supplies',
      onTimeRate: 92,
      quality: 4.5,
      deliveryTime: 3,
    },
    {
      id: 2,
      name: 'XYZ Manufacturing',
      onTimeRate: 85,
      quality: 4.0,
      deliveryTime: 5,
    },
    {
      id: 3,
      name: 'Global Traders',
      onTimeRate: 88,
      quality: 4.2,
      deliveryTime: 4,
    },
  ]

  return (
    <div className='bg-gray-100 min-h-screen p-6 dark:bg-secondaryDark rounded-lg'>
      <h2 className="text-2xl font-bold text-center mb-8 text-indigo-800">Supplier Performance Dashboard</h2>
      <div className="grid grid-cols-1 gap-8">
        {suppliers.map((supplier) => (
          <div
            key={supplier.id}
            className="bg-gray-50 dark:bg-secondaryDark p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow border-t-4 border-indigo-500"
          >
            <h3 className="text-xl font-semibold text-indigo-600 mb-4 dark:text-primaryDark">{supplier.name}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* On-time Delivery Rate */}
              <div className="flex flex-col">
                <h4 className="font-medium text-gray-700">On-time Delivery Rate</h4>
                <div className="w-full bg-gray-200 rounded-full h-3 mt-2">
                  <div
                    className="bg-indigo-500 h-3 rounded-full transition-all"
                    style={{ width: `${supplier.onTimeRate}%` }}
                  ></div>
                </div>
                <span className="text-sm text-gray-500 mt-1">{supplier.onTimeRate}%</span>
              </div>

              {/* Quality Rating */}
              <div className="flex flex-col">
                <h4 className="font-medium text-gray-700">Quality Rating</h4>
                <div className="flex items-center mt-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <FaStar
                      key={star}
                      size={22}
                      className={star <= supplier.quality ? 'text-yellow-400' : 'text-gray-300'}
                    />
                  ))}
                  <span className="ml-2 text-gray-600 font-semibold">{supplier.quality.toFixed(1)}</span>
                </div>
              </div>

              {/* Delivery Time */}
              <div className="flex flex-col">
                <h4 className="font-medium text-gray-700">Average Order Completion Time</h4>
                <p className="mt-2 text-lg font-semibold text-gray-600">{supplier.deliveryTime} days</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
