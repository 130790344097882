import React, { useState, useEffect } from "react";

import CreateProject from "./CreateProject";
import GanttChart from "./GanttChart";
import MilestoneSection from "./MilestoneSection";
import axiosInstance from "../../services/AxiosInstance";


const ProjectPlanningLayout = () => {

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [milestone, setMilestone] = useState([])



    // Fetching all projects on component mount
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axiosInstance.get('/backend/projects');
                const sortedProject = response.data.sort((a, b) => {
                    //  return new Date(a.createdAt) - new Date(b.createdAt)
                    return a.name.localeCompare(b.name)
                })
                setProjects(sortedProject);
            } catch (error) {
                setError('Error fetching project data');
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);



    const addProject = (newProject) => {
        setProjects((prevProjects) => [...prevProjects, newProject]);
    };

    const addMilestone = (newMilestone) => {
        setMilestone((prevMilestone) => [...prevMilestone, newMilestone]);
    }


    return (
        <div className="flex justify-center items-start  mt-16 h-[100%-4rem]  bg-gray-100  dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-7xl">
                {/* Left side - Project Creation Form */}
                <CreateProject addProject={addProject} />

                {/* Right side - Gantt Chart and Milestone Tracking */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-screen overflow-y-auto">
                    {/* Gantt Chart */}
                    <GanttChart projects={projects} error={error} loading={loading} milestone={milestone} />

                    {/* Milestone Tracking */}
                    {/* {loading && <p>Loading projects...</p>}
                    {error && <p className="text-red-500">{error}</p>} */}
                    <MilestoneSection projects={projects} projectLoading={loading} projectError={error} addMilestone={addMilestone} />
                </div>
            </div>
        </div>



    );
};

export default ProjectPlanningLayout;
