import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const InvoiceDisplay = ({ invoiceData, close }) => {
    const [date, setDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString();
        setDate(formattedDate);
    }, []);

    // Function to determine the status color
    const getStatusColor = (status) => {
        switch (status) {
            case 'Paid':
                return 'bg-green-100 text-green-600';
            case 'Pending':
                return 'bg-yellow-100 text-yellow-600';
            default:
                return 'bg-gray-100 text-gray-600';
        }
    };

    // Print invoice function
    const printInvoice = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Invoice</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            background: white;
                            color: #333;
                        }
                        .header {
                            background: linear-gradient(to right, #2E236C, #433D8B);
                            color: white;
                            padding: 20px;
                            text-align: center;
                            border-radius: 8px;
                        }
                        .header h1 {
                            margin: 0;
                            font-size: 2em;
                        }
                        .invoice-content {
                            margin-top: 20px;
                            border: 1px solid #ddd;
                            padding: 20px;
                            border-radius: 8px;
                        }
                        .invoice-section {
                            margin-bottom: 20px;
                        }
                        .invoice-section h2 {
                            border-bottom: 2px solid #2E236C;
                            padding-bottom: 5px;
                            color: #2E236C;
                        }
                        .status {
                            padding: 10px;
                            border-radius: 5px;
                            font-weight: bold;
                        }
                        .footer {
                            margin-top: 30px;
                            text-align: center;
                            font-size: 0.9em;
                            color: #777;
                        }
                    </style>
                </head>
                <body>
                    <div class="header">
                        <h1>Kala Interiors</h1>
                        <p>Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, Maharashtra 411026</p>
                    </div>
                    <div class="invoice-content">
                        <div class="invoice-section">
                            <h2>Invoice Details</h2>
                            <p><strong>Invoice Date:</strong> ${date}</p>
                            <p><strong>Due Date:</strong> ${invoiceData.dueDate}</p>
                            <p><strong>Invoice No:</strong> ${invoiceData.invoiceNumber || 'N/A'}</p>
                        </div>
                        <div class="invoice-section">
                            <h2>Customer Information</h2>
                            <p><strong>Name:</strong> ${invoiceData.customerName || 'N/A'}</p>
                            <p><strong>Email:</strong> ${invoiceData.customerEmail || 'N/A'}</p>
                            <p><strong>Phone:</strong> ${invoiceData.phone || 'N/A'}</p>
                        </div>
                        <div class="invoice-section">
                            <h2>Invoice Status</h2>
                            <div class="status ${getStatusColor(invoiceData.status)}">
                                ${invoiceData.status || 'N/A'}
                            </div>
                        </div>
                        <div class="invoice-section">
                            <h2>Description</h2>
                            <p>${invoiceData.description || 'N/A'}</p>
                        </div>
                    </div>
                    <div class="footer">
                        Thank you for your business!
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    return (
        <div className="max-w-3xl mx-auto bg-gray-50 dark:bg-secondaryDark rounded-lg shadow-lg print:border print:border-gray-300">
            {/* Header: Company Name and Close Button */}
            <div className="bg-gradient-to-r from-primaryDark to-secondaryDark text-white p-4 rounded-t-md flex justify-between items-center print:hidden">
                <div>
                    <h1 className="text-2xl font-bold text-uppercase text-center">Kala Interiors</h1>
                    <p className="text-sm text-center">Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, Maharashtra 411026</p>
                </div>
                <button className="cursor-pointer text-white" onClick={close}>
                    <X />
                </button>
            </div>

            {/* Main Content */}
            <div className="bg-gray-50 dark:bg-secondaryDark p-6 rounded-b-md">
                {/* Invoice Date */}
                <div className="flex justify-between mb-4">
                    <div>
                        <h2 className="text-gray-700 font-medium">Invoice Date: <span className="font-normal">{date}</span></h2>
                        <h2 className="text-gray-700 font-medium">Due Date: <span className="font-normal">{invoiceData.dueDate}</span></h2>
                    </div>
                    <h2 className="text-gray-700 font-medium">Invoice No: <span className="font-normal">{invoiceData.invoiceNumber || 'N/A'}</span></h2>
                </div>

                {/* Customer Information */}
                <div className="mb-4 border-b border-gray-300 pb-4">
                    <h2 className="text-gray-700 font-medium">Customer Information:</h2>
                    <p><strong>Name:</strong> {invoiceData.customerName || 'N/A'}</p>
                    <p><strong>Email:</strong> {invoiceData.customerEmail || 'N/A'}</p>
                    <p><strong>Phone:</strong> {invoiceData.phone || 'N/A'}</p>
                </div>

                {/* Invoice Status */}
                <div className={`mb-6 p-2 rounded-md ${getStatusColor(invoiceData.status)}`}>
                    <h2 className="text-gray-700 font-medium">Invoice Status:</h2>
                    <p className="font-semibold">{invoiceData.status || 'N/A'}</p>
                </div>

                {/* Invoice Description */}
                <div className="mb-6 border-b border-gray-300 pb-4">
                    <h2 className="text-gray-700 font-medium">Description:</h2>
                    <p>{invoiceData.description || 'N/A'}</p>
                </div>

                {/* Print Button */}
                <div className="text-right">
                    <button
                        className="bg-primaryDark text-white px-6 py-2 rounded-md shadow-md hover:bg-secondaryDark transition duration-150"
                        onClick={printInvoice}
                    >
                        Print Invoice
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InvoiceDisplay;
