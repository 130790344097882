import VendorManagement from "./VendorDatabase"

const VendorLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
          
            <VendorManagement />
            
         
        </div>
    )
}

export default VendorLayout