import React, { useState } from 'react';
import axiosInstance from '../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';

export default function Signup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return;
        }

        const userData = { username, email, password };

        try {
            const response = await axiosInstance.post('/backend/api/auth/regadminuser', userData);
            console.log(response.data);

            if (response.data) {
                setErrorMessage('');
                setSuccessMessage('Signup successful! Redirecting to login...');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Signup failed, please try again.');
        }
    };


    return (
        <div className="container-fluid d-flex align-items-center justify-content-center pb-3"
            style={{
                backgroundImage: 'url(.././images/loginkala-bg.jpeg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh'
            }}>
            <div style={{ width: '400px' }}>
                <div className="card shadow-lg bg-white text-black align-items-center mt-3">
                    <div style={{ width: '-webkit-fill-available' }}>
                        <h3 className="card-title text-center mb-2 fw-bold fs-4">
                            SIGN UP
                        </h3>
                        <p className="text-muted text-center fs-6">
                            Create a new account to get started
                        </p>
                        {errorMessage && <div className="alert alert-danger fs-6 mt-2">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success fs-6 mt-2">{successMessage}</div>}

                        <form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <label htmlFor="username" className="fs-6 fw-bold form-label">Username</label>
                                <input
                                    id="username"
                                    type="text"
                                    className="form-control"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="email" className="fs-6 fw-bold form-label">Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="password" className="fs-6 fw-bold form-label">Password</label>
                                <input
                                    id="password"
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="confirmPassword" className="fs-6 fw-bold form-label">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    type="password"
                                    className="form-control"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-dark mt-2">
                                    Sign Up
                                </button>
                            </div>
                        </form>

                        <div className="text-center mt-3">
                            <p className="text-muted fs-6">
                                Already have an account?
                                <button onClick={() => navigate('/login')} className="btn btn-link p-0">
                                    Login
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
