import React, { useEffect, useRef } from "react";
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaSignOutAlt } from "react-icons/fa";

export default function VideoCallModal({ isOpen, onClose }) {
  const jitsiContainerRef = useRef(null);
  const jitsiApiRef = useRef(null);

  const domain = "meet.jit.si"; // Publicly hosted Jitsi Meet server
  const roomName = "Group2Channel"; // Unique room name

  useEffect(() => {
    if (!isOpen) return;

    const initializeJitsi = () => {
      if (!jitsiContainerRef.current || jitsiApiRef.current) return;

      jitsiApiRef.current = new window.JitsiMeetExternalAPI(domain, {
        parentNode: jitsiContainerRef.current,
        roomName,
        configOverwrite: {
          startWithAudioMuted: false,
          startWithVideoMuted: false,
        },
        interfaceConfigOverwrite: {
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        },
        userInfo: {
          displayName: "You",
        },
      });

      // Handle events like leaving the meeting
      jitsiApiRef.current.addListener("readyToClose", handleLeaveCall);
    };

    const handleLeaveCall = () => {
      if (jitsiApiRef.current) {
        jitsiApiRef.current.dispose();
        jitsiApiRef.current = null;
      }
      onClose();
    };

    // Load Jitsi script dynamically if not already loaded
    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = initializeJitsi;
    document.body.appendChild(script);

    return () => {
      handleLeaveCall();
      script?.remove();
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center">
      <div className="w-full h-full max-w-5xl max-h-[90vh] bg-gray-900 rounded-lg p-12">
        <div className="flex flex-col h-full">
          {/* Jitsi Video Container */}
          <div ref={jitsiContainerRef} className="flex-grow bg-gray-800 rounded-lg overflow-hidden"></div>

          <div className="h-16 flex items-center justify-center space-x-4 bg-gray-800 rounded-lg">
            <button
              onClick={() => jitsiApiRef.current?.executeCommand("toggleAudio")}
              className="p-3 rounded-full bg-gray-600"
            >
              <FaMicrophone className="text-white text-xl" />
            </button>
            <button
              onClick={() => jitsiApiRef.current?.executeCommand("toggleVideo")}
              className="p-3 rounded-full bg-gray-600"
            >
              <FaVideo className="text-white text-xl" />
            </button>
            <button
              onClick={onClose}
              className="p-3 rounded-full bg-red-500"
            >
              <FaSignOutAlt className="text-white text-xl" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
