import React from 'react'
import RecordAlert from './RecordAlert'

const RecordAlertLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] overflow-y-scroll bg-gray-100 dark:bg-dark">
            <RecordAlert />
        </div>
    )
}

export default RecordAlertLayout