import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';
import { Gantt, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import ViewSwitcher from './ViewSwitcher';
import MilestoneModal from './MilestoneModel';

const CustomTask = ({ task }) => {
    return (
        <div style={{ padding: '5px', backgroundColor: task.isDisabled ? '#f0f0f0' : '#fff' }}>
            <span>{task.name}</span>
            <div style={{ width: '100%', backgroundColor: '#e0e0e0' }}>
                <div style={{
                    width: `${task.progress}%`,
                    backgroundColor: '#4caf50',
                    height: '5px'
                }} />
            </div>
        </div>
    );
};

const GanttChart = ({ projects, loading, error }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [milestoneLoading, setMilestoneLoading] = useState(false);
    const [milestoneError, setMilestoneError] = useState(null);
    const [projectDetails, setProjectDetails] = useState(null);
    const [view, setView] = useState(ViewMode.Day);
    const [isChecked, setIsChecked] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [updateMilestone, setUpdateMilestone] = useState(null); // Change from [] to null since it's not an array
    const [isModalOpen, setIsModalOpen] = useState(false); // New state to handle modal
    const [selectedMilestone, setSelectedMilestone] = useState(null); // New state for selected milestone


    useEffect(() => {
        if (selectedProject) {
            fetchMilestones(selectedProject);
            const project = projects.find((p) => p.id === selectedProject);
            setProjectDetails(project);
        }
    }, [selectedProject, projects]);

    const fetchMilestones = async (projectId) => {
        setMilestoneLoading(true);
        setMilestoneError(null);
        try {
            const response = await axiosInstance.get(`/backend/projects/${projectId}/milestones`);
            const sortedMilestones = response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            setMilestones(sortedMilestones);
        } catch (error) {
            setMilestoneError("Failed to fetch milestones.");
        } finally {
            setMilestoneLoading(false);
        }
    };

    const mapMilestonesToTasks = () => {
        if (!projectDetails) return [];

        // Project Task
        const projectTask = {
            id: "project",
            name: projectDetails.name,
            start: new Date(projectDetails.startDate),
            end: new Date(projectDetails.endDate),
            type: 'project',
            progress: projectDetails.completionPercentage || 0,
            isDisabled: true,
        };

        // Map milestones to tasks
        const milestoneTasks = milestones.map((milestone) => ({
            id: String(milestone.id),
            name: milestone.description,
            start: new Date(milestone.date),
            end: new Date(milestone.endDate || milestone.date),
            type: 'task',
            progress: milestone.completionPercentage || 0,
            dependencies: ["project"], // Set project task as dependency
        }));

        return [projectTask, ...milestoneTasks]; // Return both project and milestone tasks
    };

    const handleTaskSelect = (task, isSelected) => {
        // console.log(`Task ${task.id} selected: ${isSelected}`);
    };

    const handleTaskDoubleClick = (task) => {
        console.log(`Task ${task.id} double-clicked`);
    };

    const handleTaskClick = (task) => {
        // console.log(`Task ${task.id} clicked`);
        // console.log('Clicked Task:', task);

        // Ensure that IDs are of the same type for comparison
        const milestone = milestones.find((m) => String(m.id) === String(task.id));

        // Log milestone search results
        if (!milestone) {
            console.error(`No milestone found for task ID: ${task.id}`);
        } else {
            // console.log('Matched Milestone:', milestone);
            setSelectedMilestone(milestone); // Set the selected milestone
            setIsModalOpen(true); // Open the modal
        }
    };

    const handleTaskDelete = (task) => {
        console.log(`Task ${task.id} deleted`);
        // Implement your delete logic here
    };

    const handleDateChange = async (task, children) => {
        // Optimistically update the task dates in the local state
        const updatedTask = {
            ...task,
            start: task.start,
            end: task.end,
        };

        // Update the local state for optimistic rendering
        setMilestones((prevMilestones) =>
            prevMilestones.map((milestone) =>
                milestone.id === task.id ? { ...milestone, ...updatedTask } : milestone
            )
        );

        // Introduce a delay before sending the API request
        setTimeout(async () => {
            try {
                const response = await axiosInstance.put(`/backend/projects/milestone/${task.id}/update`, {
                    date: task.start,
                    endDate: task.end,
                    description: task.description,
                    status: task.status,
                    completionPercentage: task.completionPercentage,
                });
                setUpdateMilestone(response.data); // Update milestone with API response
                // console.log(`Updated task ${task.id}`, response.data);
            } catch (error) {
                // console.error("Failed to update task:", error);
                // Revert changes if API call fails
                setMilestones((prevMilestones) =>
                    prevMilestones.map((milestone) =>
                        milestone.id === task.id ? { ...milestone, start: new Date(milestone.date), end: new Date(milestone.endDate || milestone.date) } : milestone
                    )
                );
            }
        }, 1000); // Delay the API call by 1 second (1000ms)
    };


    useEffect(() => {
        if (updateMilestone && Object.keys(updateMilestone).length > 0) {
            // Update the specific milestone in the state
            setMilestones((prevMilestones) =>
                prevMilestones.map((milestone) =>
                    milestone.id === updateMilestone.id ? { ...milestone, ...updateMilestone } : milestone
                )
            );
        }
    }, [updateMilestone]);

    const handleProgressChange = (task, children) => {
        // console.log(`Progress changed for task ${task.id}`);
        // Update task progress logic here
    };

    const handleTaskChange = (taskId, start, end) => {
        // Update task dates
        setTasks((prevTasks) =>
            prevTasks.map((task) =>
                task.id === taskId ? { ...task, start, end } : task
            )
        );
    };

    return (
        <div className="bg-gray-50 dark:bg-secondaryDark dark:text-dark p-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">Project Gantt Chart</h2>

            <div className="mb-4 ">
                <label className="block text-sm font-medium  mb-2">Select Project</label>
                {loading ? (
                    <p className="text-gray-500 text-sm">Loading projects...</p>
                ) : (
                    <Select
                        value={selectedProject ? { value: selectedProject, label: projectDetails?.name } : null}
                        onChange={(option) => setSelectedProject(option.value)}
                        options={projects.map((project) => ({ value: project.id, label: project.name }))}
                        placeholder="Search project"
                        className="w-full max-w-xs sm:max-w-md lg:max-w-lg dark:bg-primaryDark dark:text-dark"
                    />
                )}
            </div>

            {milestoneLoading ? (
                <p>Loading milestones...</p>
            ) : milestoneError ? (
                <p className="text-red-500">{milestoneError}</p>
            ) : (
                selectedProject && milestones.length > 0 ? (
                    <div>
                        <ViewSwitcher
                            onViewModeChange={setView}
                            onViewListChange={() => setIsChecked(prev => !prev)}
                            isChecked={isChecked}
                        />
                        <Gantt
                            tasks={mapMilestonesToTasks()}
                            viewMode={view}
                            listCellWidth={isChecked ? "155px" : ""}
                            TaskComponent={CustomTask}
                            onSelect={handleTaskSelect}
                            // onDoubleClick={handleTaskDoubleClick}
                            onDoubleClick={handleTaskClick}
                            onDelete={handleTaskDelete}
                            onDateChange={handleDateChange}
                            onProgressChange={handleProgressChange}
                        />
                    </div>
                ) : (
                    <p className='text-sm sm:text-lg'>Please select a project to display the Gantt chart.</p>
                )
            )}



            {/* Milestone Modal */}
            <MilestoneModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                milestone={selectedMilestone}
                onUpdateMilestone={handleDateChange}
            />
        </div>
    );
};

export default GanttChart;
