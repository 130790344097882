import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import Desktop1280x from "./pages/desktop1280x";
import Footer from "./components/footer";
import NavWrapper from "./components/NavWrapper";
import AboutPage from "./pages/AboutPage";
import Projectpage from "./pages/ProjectPage";
import ContactModal from "./components/ContactModal";
import Dashboard from "./components/admin/Dashboard";
import ProjectStatusMonitoring from "./components/admin/ProjectStatusMonitoring";
import ProjectDashboard from "./components/admin/ProjectDashboard";
import ProgressReports from "./components/admin/ProgressReports";
import EventCalender from "./components/admin/EventCalender";
import LoginPage from "./pages/LoginPage";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import ShowContacts from "./components/admin/ShowContacts";
import GetQuote from "./components/admin/GetQuote";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectPlanningLayout from "./components/admin/ProjectPlanningLayout";
import TaskManagementLayout from "./components/admin/TaskManagementLayout";
import ResourceAllocationLayout from "./components/admin/ResourceAllocationLayout";
import BudgetManagementLayout from "./components/admin/BudgetManagementLayout";
import SalePipelineLayout from "./components/admin/SalePipelineLayout";
import Sidebar from "./components/admin/Sidebar";
import QuotationLayout from "./components/admin/QuotationLayout";
import FollowUpLayout from "./components/admin/FollowUpLayout";
import MaterialRequirementLayout from "./components/admin/MaterialRequirementLayout";
import RecordAlertLayout from "./components/admin/RecordAlertLayout";
import SupplierManagementLayout from "./components/admin/SupplierManagementLayout";
import StockManagementLayout from "./components/admin/StockManagementLayout";
import ShowProject from "./components/admin/ShowProject";
import CustomerProfile from "./components/admin/CustomerProfile";
import PrivateRoute from "./PrivateRoute";
import InvoiceLayout from "./components/admin/InvoiceLayout";
import VendorLayout from "./components/admin/VendorLayout";
import ContractLayout from "./components/admin/ContractLayout";
import OrderLayout from "./components/admin/OrderLayout";
import Dashboard1 from "./components/admin/Dashboard1";

import HrmEmployeeLayout from "./components/admin/HrmEmployeeMnglayout";
import HrmAttendenceLayout from "./components/admin/HrmAttendenceLayout";
import HrmPerformanceLayout from "./components/admin/HrmPerformanceLayout";
import HrmPayrollLayout from "./components/admin/HrmPayrollLayout";
import HrmDashboard from "./components/admin/HrmDashboard";

import ExpenseManagementDashboard from "./components/admin/ExpenseManagementDashboard";
import PayrollDashboard from "./components/admin/PayrollDashboard";
import FinancialLayout from "./components/admin/FinacialLayout";
import FinanceDashboard from "./components/admin/FinanceDashBoard";
import ReportAnalyticsDashboard from "./components/admin/ReportAnalyticsDashboard";
import ClientPortal from "./components/ClientPortal/ClientPortal";
import NotificationComponent from "./components/admin/NotificationComponent";
import NotFound from "./components/NotFound";
import FeedbackTable from "./components/admin/FeedbackTable";
import Profile from "./components/EmployeePortal/Profile";
import EmployeeSidebar from "./components/EmployeePortal/EmployeeSidebar";
import EmployeePerformanceManagement from "./components/EmployeePortal/EmployeePerformanceManagement";
import LoginsTable from "./components/admin/LoginsTable";
import SlackLayout from "./pages/communicationmodule";
import DesignTemplates from "./components/admin/DesignTemplates";
import DesignApprovalWorkflow from "./components/admin/DesignApprovalWorkflow";
import MoodBoardManagement from "./components/admin/MoodBoardManagement";



const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      {!isAdminRoute && <NavWrapper />}
      <main>{children}</main>
      {!isAdminRoute && <Footer />}
    </>
  );
};



const App = () => {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  const userRole = localStorage.getItem("userRole");
  // const userRole = 'admin'


  const renderAdminRoutes = () => (
    <Route path="/admin/*" element={<PrivateRoute allowedRoles={['admin']} />}>
      <Route element={<Sidebar />}>
        {/* dashboard */}
        <Route path="dashboard" element={<Dashboard />} />



        {/* project planning */}
        <Route path="project-planning" element={<ProjectPlanningLayout />} />
        <Route path="task-management" element={<TaskManagementLayout />} />
        <Route path="resource-allocation" element={<ResourceAllocationLayout />} />
        <Route path="budget-tracking" element={<BudgetManagementLayout />} />


        {/* CRM */}
        <Route path="customer-profile" element={<CustomerProfile />} />
        <Route path="sale-pipeline" element={<SalePipelineLayout />} />
        <Route path="quotation" element={<QuotationLayout />} />
        <Route path="follow-up" element={<FollowUpLayout />} />
        {/* <Route path="design-template" element={<DesignTemplates />} /> */}
        
    

        {/* Design Management */}
        <Route path="design-template" element={<DesignTemplates />} />
        <Route path="design-approval" element={<DesignApprovalWorkflow />} />
        <Route path="mood-board" element={<MoodBoardManagement />} />
      
        




        {/* Inventry management */}
        <Route path="stock-management" element={<StockManagementLayout />} />
        <Route path="material-requirement" element={<MaterialRequirementLayout />} />
        <Route path="record-alert" element={<RecordAlertLayout />} />
        <Route path="supplier-management" element={<SupplierManagementLayout />} />


        {/* Vendor management */}
        <Route path="vendor-dashboard" element={<Dashboard1 />} />
        <Route path="VendorDatabase" element={<VendorLayout />} />
        <Route path="ContractManagement" element={<ContractLayout />} />
        <Route path="OrderManagement" element={<OrderLayout />} />


        {/* HRM management */}
        <Route path="HRM-dashboard" element={<HrmDashboard />} />
        <Route path="HRM-Employee" element={<HrmEmployeeLayout />} />
        <Route path="HRM-Attendence" element={<HrmAttendenceLayout />} />
        <Route path="HRM-Performance" element={<HrmPerformanceLayout />} />
        {/* <Route path="HRM-Payroll" element={<HrmPayrollLayout />} /> */}

   {/* communication management */}


        {/* Finance and Accounting  */}
        <Route path="invoice-management" element={<InvoiceLayout />} />
        <Route path="expense-management" element={<ExpenseManagementDashboard />} />
        <Route path="payroll" element={<PayrollDashboard />} />
        <Route path="financial-report" element={<FinancialLayout />} />
        <Route path="finance-dashboard" element={<FinanceDashboard />} />
        <Route path="report-analytics" element={<ReportAnalyticsDashboard />} />


        <Route path="report-analytics" element={<ReportAnalyticsDashboard />} />

        <Route path="project-status" element={<ProjectStatusMonitoring />}>
          <Route path="project-dashboard" element={<ProjectDashboard />} />
          <Route path="progress-report" element={<ProgressReports />} />
        </Route>

        <Route path="calender" element={<EventCalender />} />
        <Route path="show-contacts" element={<ShowContacts />} />
        <Route path="show-quotes" element={<GetQuote />} />
        <Route path="show-project" element={<ShowProject />} />

        <Route path="notification" element={<NotificationComponent />} />
        <Route path="feedback" element={<FeedbackTable />} />
        <Route path="login-details" element={<LoginsTable />} />

        {/* Catch-all 404 route for undefined customer paths */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>

  );

  const renderCustomerRoutes = () => (
    <Route path="/customer/*" element={<PrivateRoute allowedRoles={['customer']} />}>
      {/* Catch-all 404 route for undefined customer paths */}
      <Route path="*" element={<NotFound />} />
      <Route path="client-portal" element={<ClientPortal />} />
    </Route>
  );

  const renderEmployeeRoutes = () => (
    <Route path="/employee/*" element={<PrivateRoute allowedRoles={['employee']} />}>

      <Route element={<EmployeeSidebar />}>
        <Route path="HRM-Attendence" element={<HrmAttendenceLayout />} />
        <Route path="HRM-Performance" element={<EmployeePerformanceManagement />} />
        <Route path="HRM-Payroll" element={<HrmPayrollLayout />} />
        <Route path="profile" element={<Profile />} />
      </Route>

      {/* Catch-all 404 route for undefined customer paths */}
      <Route path="*" element={<NotFound />} />
    </Route>

  );

  return (
    <>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme="light"
      />

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/" element={<Layout>  <Desktop1280x />  </Layout>} />
        <Route path="/projects" element={<Layout>  <Projectpage />  </Layout>} />
        <Route path="/about" element={<Layout>  <AboutPage /> </Layout>} />
        <Route path="/contact" element={<Layout>  <ContactModal /> </Layout>} />
       <Route path="/communication" element={<SlackLayout />} />



        {/* 404 Not Found Route */}
        <Route path="*" element={<NotFound />} />

        {/* Render routes based on user role */}
        {userRole === "admin" && renderAdminRoutes()}
        {userRole === "customer" && renderCustomerRoutes()}
        {userRole === "employee" && renderEmployeeRoutes()}
      </Routes>

    </>
  );
};
export default App;
