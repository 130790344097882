import React, { useState } from 'react'
import StockManagement from './StockManagement'
import AddStock from './AddStock'






const StockManagementLayout = () => {



    const [newStock, setNewStock] = useState([]);

    // Rename to a more appropriate name like addNewStock
    const addNewStock = (newStockItem) => {
      setNewStock((prev) => [...prev, newStockItem]);
    };

    return (
        <div className="flex justify-center mt-16 items-start h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl">
                {/* Left side - Sales Pipeline Component */}
                <div className="h-[calc(100vh-4rem)] overflow-y-auto">

                    <AddStock addStock={addNewStock}   />
                </div>
                {/* Right side - Kanban Board and Deal Table */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    {/* <SupplierManagement /> */}
                    <StockManagement newStock={newStock} />
                </div>
            </div>
        </div>
    )
}

export default StockManagementLayout