import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { FaRegEye, FaEdit, FaTrashAlt } from "react-icons/fa";

const ManpowerAssessment = ({ manpower }) => {
    const [formData, setFormData] = useState({
        name: '',
        role: 'worker',
        availability: '',
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });
    const [manpowerList, setManpowerList] = useState([]);
    const [selectedMemberTasks, setSelectedMemberTasks] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchManpower = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axiosInstance.get('/backend/api/user');
                setManpowerList(response.data);
            } catch (error) {
                setMessage({ type: 'error', text: 'Error fetching manpower data. Please try again.' });
            } finally {
                setLoading(false); // End loading
            }
        };
        fetchManpower();
    }, []);


    // Update manpowerList when the manpower prop changes
    useEffect(() => {
        if (manpower && manpower.length > 0) {
            setManpowerList(prevList => [...prevList, ...manpower]);
        }
    }, [manpower]);


    // Function to open the modal and show tasks of a selected member
    const handleOpenModal = (tasks) => {
        setSelectedMemberTasks(tasks);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setSelectedMemberTasks(null);
        setIsModalOpen(false);
    };

    // Function to get the color based on task status
    const getStatusColor = (status) => {
        switch (status) {
            case 'In Progress':
                return 'bg-yellow-200 text-yellow-800';
            case 'Done':
                return 'bg-green-200 text-green-800';
            default:
                return 'bg-gray-200 text-gray-800';
        }
    };

    // Function to handle edit action (dummy for now)
    const handleEdit = (task) => {
        // Implement edit functionality
        console.log('Edit task:', task);
    };

    // Function to handle delete action (dummy for now)
    const handleDelete = (taskId) => {
        // Implement delete functionality
        console.log('Delete task with ID:', taskId);
    };

    return (
        <div className="space-y-6 p-3 md:p-4 shadow-lg rounded-lg max-w-full mx-auto transition-colors duration-1000 ease-in dark:bg-secondaryDark">
            {/* Manpower Assignment Header */}
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-800 mb-6 text-center dark:text-dark">
                Manpower Assignment
            </h2>

            {/* Message Display */}
            {message.text && (
                <div className={`mb-4 p-2 rounded-lg ${message.type === 'success' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                    {message.text}
                </div>
            )}

            {/* Loading State */}
            {loading ? (
                <div className="text-center text-gray-500 w-full dark:text-gray-300">Loading manpower data...</div>
            ) : (
                /* Manpower Assignment Table */
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-50 border border-gray-300 rounded-md shadow-md dark:text-dark dark:bg-secondaryDark transition-colors duration-1000 ease-in">
                        <thead className="bg-gray-50 dark:bg-secondaryDark dark:text-dark">
                            <tr>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">#</th>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">Team Member</th>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">Assigned Tasks</th>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">Availability (Hours)</th>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">Roles</th>
                                <th className="py-2 px-4 text-center text-nowrap text-sm sm:text-base">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {manpowerList.map((member, index) => (
                                <tr key={index} className='hover:bg-gray-100'>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-center">{index + 1}</td>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-left text-nowrap">{member.name}</td>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-center text-nowrap">
                                        <button onClick={() => handleOpenModal(member.tasks)}>
                                            <FaRegEye className='text-blue-700 text-lg' />
                                        </button>
                                    </td>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-center text-nowrap">{member.availability} hours</td>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-center text-nowrap">{member.role}</td>
                                    <td className="py-2 px-4 border-b text-sm sm:text-base text-center">
                                        <button onClick={() => handleEdit(member)}>
                                            <FaEdit className='text-green-500 text-lg mx-1' />
                                        </button>
                                        <button onClick={() => handleDelete(member.id)}>
                                            <FaTrashAlt className='text-red-500 text-lg mx-1' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Task Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-white dark:bg-primaryDark p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4 dark:text-textDark">Assigned Tasks</h3>
                        <div className="space-y-4">
                            {selectedMemberTasks && selectedMemberTasks.length > 0 ? (
                                selectedMemberTasks.map((task, idx) => (
                                    <div
                                        key={idx}
                                        className={`border p-3 rounded-lg ${getStatusColor(task.status)} dark:bg-secondaryDark`}
                                    >
                                        <h4 className="font-semibold dark:text-textDark">{task.title}</h4>
                                        <p className="text-sm dark:text-textDark">
                                            Status: {task.status || 'To Do'}
                                        </p>
                                        <div className="flex justify-end">
                                            <button onClick={() => handleEdit(task)}>
                                                <FaEdit className='text-green-500 text-lg mx-1' />
                                            </button>
                                            <button onClick={() => handleDelete(task.id)}>
                                                <FaTrashAlt className='text-red-500 text-lg mx-1' />
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-800 dark:text-textDark">No tasks assigned to this member.</p>
                            )}
                        </div>
                        <button onClick={handleCloseModal} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManpowerAssessment;
