import PropTypes from "prop-types";
import "./ProjectDetails.css";


const ProjectDetails = ({ project, onBack }) => {
    if (!project) {
        return <div className="no-details">No project details available</div>;
    }

    return (
        <div className="project-details-container" >
            <div className="project-details-card"  style={{ backgroundImage: `url(${project.background})` }} >
                <button onClick={onBack} className="back-button">← Back to Projects</button>
                <div className="project-header">
                    <h2 className="project-title">{project.projectName}</h2>
                    {/* <img
                        className="project-image"
                        alt={project.projectName}
                        src={project.background}
                    /> */}
                </div>
                <div className="project-content">
                    <p className="project-description-details">{project.description}</p>
                    <div className="project-details-info">
                        <p className="pb-2"><strong>More details about the project:</strong></p>
                        <ul>
                            <li><span className="detail-label">Project Type:</span> {project.type}</li>
                            <li><span className="detail-label">Location:</span> {project.location}</li>
                            <li><span className="detail-label">Completion Date:</span> {project.completionDate}</li>
                            <li><span className="detail-label">Design Style:</span> {project.designStyle}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProjectDetails.propTypes = {
    project: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default ProjectDetails;
