import React, { useEffect, useState } from "react";
import EmployeePerformance from "./EmployeePerformance";
import axiosInstance from "../../services/AxiosInstance";
import Select from "react-select";

const EmployeePerformanceManagement = () => {
    const [employeeId, setEmployeeId] = useState(null);
    const [goals, setGoals] = useState([]);
    const [currentGoal, setCurrentGoal] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [projectOptions, setProjectOptions] = useState([]);
    const [projectLoading, setProjectLoading] = useState(false);
    const [projectError, setProjectError] = useState("");
    const [message, setMessage] = useState(""); // For success or error messages

    // Retrieve employeeId from localStorage
    useEffect(() => {
        const storedEmployeeId = localStorage.getItem("employeeId");
        if (storedEmployeeId) {
            setEmployeeId(storedEmployeeId);
        }
    }, []);

    const statusOptions = [
        { value: "To Do", label: "To Do" },
        { value: "In Progress", label: "In Progress" },
        { value: "Done", label: "Done" }
    ];

    // Fetch employee goals when employeeId is set
    useEffect(() => {
        if (employeeId) {
            const fetchEmployeeDetails = async () => {
                setLoading(true);
                setError("");
                try {
                    const response = await axiosInstance.get(
                        `/backend/projects/employee/${employeeId}`
                    );
                    setGoals(response.data || []);
                } catch (err) {
                    const errorMessage =
                        err.response?.data?.message ||
                        "Failed to fetch employee details. Please try again later.";
                    setError(errorMessage);
                } finally {
                    setLoading(false);
                }
            };

            fetchEmployeeDetails();
        }
    }, [employeeId]);

    // Fetch project list for the dropdown
    useEffect(() => {
        const fetchProjects = async () => {
            setProjectLoading(true);
            setProjectError("");
            try {
                const response = await axiosInstance.get("/backend/projects/list");
                const options = response.data.map((project) => ({
                    value: project.id,
                    label: project.name,
                }));
                setProjectOptions(options);
            } catch (err) {
                const errorMessage =
                    err.response?.data?.message ||
                    "Failed to fetch projects. Please try again later.";
                setProjectError(errorMessage);
            } finally {
                setProjectLoading(false);
            }
        };

        fetchProjects();
    }, []);

    // Open modal for editing an existing goal
    const handleEdit = (goal) => {
        setCurrentGoal(goal);
        setIsEditModalOpen(true);
    };

    // Open modal for adding a new goal
    const handleAddGoal = () => {
        setCurrentGoal(null);
        setIsEditModalOpen(true);
    };

    // Close the modal
    const handleModalClose = () => {
        setIsEditModalOpen(false);
        setCurrentGoal(null);
        setMessage(""); // Clear message on modal close
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentGoal((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Save the edited/added goal
    const handleModalSave = async () => {
        const newTask = {
            title: currentGoal.title,
            description: currentGoal.description,
            deadline: currentGoal.deadline,
            priority: currentGoal.priority,
            employee: employeeId ? { employeeId: employeeId } : null,
            status: currentGoal.status,
        };

        try {
            if (currentGoal.id) {
                // Update an existing goal
                const response = await axiosInstance.put(
                    `/backend/projects/task/${currentGoal.id}`,
                    newTask
                );
                // Update the goal in the state
                setGoals((prev) =>
                    prev.map((goal) =>
                        goal.id === currentGoal.id ? response.data : goal
                    )
                );
                setMessage("Goal updated successfully!"); // Success message
            } else {
                // Add a new goal
                const response = await axiosInstance.post(
                    `/backend/projects/${currentGoal.project}/tasks`,
                    newTask
                );
                // Add the new goal to the state
                setGoals((prev) => [...prev, response.data]);
                setMessage("Goal added successfully!"); // Success message
            }
            handleModalClose();
        } catch (err) {
            console.error("Error saving goal:", err);
            setMessage("Failed to save goal. Please try again later."); // Error message
        }
    };


    return (
        <div className="container mx-auto p-3 space-y-8 rounded-lg shadow-md mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="bg-gray-50 p-4 rounded-lg shadow mb-4 dark:bg-secondaryDark">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">
                    Performance Management
                </h1>
                <button
                    onClick={handleAddGoal}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Add New Goal
                </button>
            </div>

            {loading ? (
                <div className="flex justify-center items-center gap-3 h-[calc(100vh-3.5rem)]">
                    <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                    <p className="dark:text-secondaryDark">Loading goals...</p>
                </div>
            ) : error ? (
                <div className="text-center text-red-500">{error}</div>
            ) : (
                <EmployeePerformance goals={goals} handleEdit={handleEdit} />
            )}

            {/* Success/Error Message */}
            {message && (
                <div
                    className={`text-center py-2 px-4 rounded mb-4 ${message.includes("Failed") ? "bg-red-500 text-white" : "bg-green-500 text-white"
                        }`}
                >
                    {message}
                </div>
            )}

            {isEditModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white dark:bg-secondaryDark p-6 rounded shadow-lg w-96">
                        <h3 className="text-lg font-semibold mb-4">
                            {currentGoal?.id ? "Edit Goal" : "Add Goal"}
                        </h3>
                        <form>
                            {/* Select Project */}
                            <div className="mb-4">
                                <label htmlFor="project" className="block text-sm font-medium">
                                    Select Project
                                </label>
                                {projectLoading ? (
                                    <p>Loading projects...</p>
                                ) : projectError ? (
                                    <p className="text-red-500">{projectError}</p>
                                ) : (
                                    <Select
                                        id="project"
                                        name="project"
                                        value={
                                            projectOptions.find((option) => option.value === currentGoal?.project) || null
                                        }
                                        onChange={(selectedOption) =>
                                            setCurrentGoal((prev) => ({ ...prev, project: selectedOption.value }))
                                        }
                                        options={projectOptions}
                                        placeholder="Select a project"
                                        className="mt-1"
                                    />
                                )}
                            </div>

                            {/* Task Title */}
                            <div className="mb-4">
                                <label htmlFor="title" className="block text-sm font-medium">
                                    Task Title
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title" // Corrected name
                                    value={currentGoal?.title || ""}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>

                            {/* Description */}
                            <div className="mb-4">
                                <label htmlFor="description" className="block text-sm font-medium">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={currentGoal?.description || ""}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>

                            {/* Deadline */}
                            <div className="mb-4">
                                <label htmlFor="deadline" className="block text-sm font-medium">
                                    Deadline
                                </label>
                                <input
                                    type="date"
                                    id="deadline"
                                    name="deadline"
                                    value={currentGoal?.deadline || ""}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>

                            {/* Priority */}
                            <div className="mb-4">
                                <label htmlFor="priority" className="block text-sm font-medium">
                                    Priority
                                </label>
                                <select
                                    id="priority"
                                    name="priority"
                                    value={currentGoal?.priority || "Low"}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                >
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>

                            {/* Status */}
                            <div className="mb-4">
                                <label htmlFor="status" className="block text-sm font-medium">
                                    Status
                                </label>
                                <select
                                    id="status"
                                    name="status"
                                    value={currentGoal?.status || "To Do"}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                >
                                    {statusOptions.map((status) => (
                                        <option key={status.value} value={status.value}>
                                            {status.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex justify-between mt-4">
                                <button
                                    type="button"
                                    onClick={handleModalClose}
                                    className="px-4 py-2 bg-gray-300 rounded text-black hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleModalSave}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployeePerformanceManagement;
