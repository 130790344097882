import { useEffect } from "react";

function EmployeeCard({ employee, onView, onEdit }) {





    return (
      <div className="bg-gray-50 shadow-sm rounded-lg p-4 cursor-pointer hover:shadow-md transition-shadow dark:bg-secondaryDark">
        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-gray-50 font-bold text-xl dark:text-textDark">
            {employee.fullName? employee.fullName.charAt(0) :"?"}
          </div>
          <div>
            <h3 className="font-semibold dark:text-dark">{employee.fullName || employee.name || "No Name"}</h3>
            <p className="text-sm text-gray-600 dark:text-textDark">{employee.role || "No Role"}</p>
          </div>
        </div>
        <div className="mt-2 text-sm">
          <p>Status: {employee.status || "Unknown"}</p>
        </div>
  
        {/* Adding buttons */}
        <div className="mt-4 flex space-x-2">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition dark:bg-dark dark:hover:bg-primaryDark "
            onClick={onView}
          >
            View
          </button>
          <button
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition dark:bg-primaryDark dark:hover:bg-dark"
            onClick={onEdit}
          >
            Edit
          </button>
        </div>
      </div>
    );
  }
  
  export default EmployeeCard; // Don't forget to export the component
  