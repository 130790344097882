import React, { useState, useEffect } from 'react';

const MilestoneModal = ({ isOpen, onClose, milestone, onUpdateMilestone }) => {

    const [description, setDescription] = useState(milestone?.description || '');
    const [startDate, setStartDate] = useState(milestone?.date || '');
    const [endDate, setEndDate] = useState(milestone?.endDate || '');
    const [status, setStatus] = useState(milestone?.status || '');
    const [completionPercentage, setCompletionPercentage] = useState(milestone?.completionPercentage || 0);

    useEffect(() => {
        setDescription(milestone?.description || '');
        setStartDate(milestone?.date || '');
        setEndDate(milestone?.endDate || '');
        setCompletionPercentage(milestone?.completionPercentage || 0);

        // console.log("milestone from model" , milestone, isOpen);

    }, [milestone]);

    const handleUpdate = () => {
        const updatedMilestone = {
            ...milestone,
            description,
            start: startDate,
            end: endDate,
            completionPercentage,
            status
        };
        onUpdateMilestone(updatedMilestone);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-gray-50 dark:bg-secondaryDark dark:text-dark p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Update Milestone</h2>
                <div className="mb-4">
                    <label className="block text-sm font-medium ">Description</label>
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium ">Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium ">End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>


                <div className="mb-4">
                    <label className="block text-sm font-medium ">Completion Percentage</label>
                    <input
                        type="number"
                        min="0"
                        max="100"
                        value={completionPercentage}
                        onChange={(e) => setCompletionPercentage(Number(e.target.value))}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">Status</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="in-progress">In Progress</option>
                        <option value="Upcoming">Upcoming</option>
                        <option value="Completed">Completed</option>
                    </select>
                </div>
                <div className="flex justify-between">
                    <button onClick={handleUpdate} className="bg-blue-500 text-white px-4 py-2 rounded">Update</button>
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default MilestoneModal;
