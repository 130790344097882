import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ShowProject = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [isMilestonesModalOpen, setIsMilestonesModalOpen] = useState(false);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editFormData, setEditFormData] = useState({
    name: '',
    description: '',
    startDate: '',
    endDate: ''
  });

  const itemsPerPage = 5;

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    axiosInstance.get('/backend/projects')
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch project data');
        setLoading(false);
      });
  };

  const handleShowMilestones = (milestones) => {
    setSelectedMilestones(milestones);
    setIsMilestonesModalOpen(true);
  };

  const handleShowBudget = (budget) => {
    setSelectedBudget(budget);
    setIsBudgetModalOpen(true);
  };

  const handleView = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    setSelectedProject(project);
    setIsViewModalOpen(true);
  };

  const handleUpdate = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    setSelectedProject(project);
    setEditFormData({
      name: project.name,
      description: project.description,
      startDate: project.startDate,
      endDate: project.endDate
    });
    setIsEditModalOpen(true);
  };

  const handleDelete = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    setSelectedProject(project);
    setIsDeleteModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/backend/projects/${selectedProject.id}`, editFormData);
      fetchProjects(); // Refresh the projects list
      setIsEditModalOpen(false);
      alert("Project updated successfully!");
    } catch (error) {
      console.error("Failed to update project", error);
      alert("Failed to update the project.");
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await axiosInstance.delete(`/backend/projects/${selectedProject.id}`);
      setProjects(projects.filter((project) => project.id !== selectedProject.id));
      setIsDeleteModalOpen(false);
      alert("Project deleted successfully!");
    } catch (error) {
      console.error("Failed to delete project", error);
      alert("Failed to delete the project.");
    }
  };

  // Modal close handlers
  const closeViewModal = () => setIsViewModalOpen(false);
  const closeEditModal = () => setIsEditModalOpen(false);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const closeMilestonesModal = () => setIsMilestonesModalOpen(false);
  const closeBudgetModal = () => setIsBudgetModalOpen(false);

  // Pagination handlers
  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const paginatedProjects = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center gap-3 h-[calc(100vh-3.5rem)]">
        <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
        <p className='dark:text-secondaryDark'>Loading projects</p>
      </div>
    );
  }

  if (error) {
    return <div className="text-center mt-16 h-[calc(100vh-3.5rem)] text-red-600">{error}</div>;
  }

  return (
    <div className="p-2 h-[calc(100vh-3.5rem)]">
      <div className="dark:bg-secondaryDark dark:text-dark p-4 rounded-md mb-2 shadow-lg">
        <h1 className="text-2xl font-semibold text-center">All Projects</h1>
      </div>

      {/* Projects Table */}
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-[1000px] bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal dark:bg-primaryDark dark:text-textDark">
              <th className="py-3 px-6 text-left">#</th>
              <th className="py-3 px-6 text-left">Project Name</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">Leader</th>
              <th className="py-3 px-6 text-left">Start Date</th>
              <th className="py-3 px-6 text-left">End Date</th>
              <th className="py-3 px-6 text-left">Milestones</th>
              <th className="py-3 px-6 text-left">Budget</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm dark:bg-secondaryDark dark:text-dark font-semibold">
            {paginatedProjects.map((project, index) => (
              <tr key={project.id} className="border-b border-gray-200 hover:bg-gray-50">
                <td className="py-3 px-6">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td className="py-3 px-6">{project.name}</td>
                <td className="py-3 px-6">{project.description}</td>
                <td className="py-3 px-6">{project.employee?.fullName}</td>
                <td className="py-3 px-6">{project.startDate}</td>
                <td className="py-3 px-6">{project.endDate}</td>
                <td className="py-3 px-6">
                  <button
                    onClick={() => handleShowMilestones(project.milestones)}
                    className="text-colorBlue hover:underline"
                  >
                    Show Milestones
                  </button>
                </td>
                <td className="py-3 px-6">
                  <button
                    onClick={() => handleShowBudget(project.budget)}
                    className="text-colorBlue hover:underline"
                  >
                    Show Budget
                  </button>
                </td>
                <td className="py-3 px-6 text-center flex justify-center">
                  <button
                    onClick={() => handleView(project.id)}
                    className="text-green-600 mx-2 hover:text-green-800"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                  <button
                    onClick={() => handleUpdate(project.id)}
                    className="text-blue-600 mx-2 hover:text-blue-800"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDelete(project.id)}
                    className="text-red-600 mx-2 hover:text-red-800"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* View Modal */}
      {isViewModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-2xl w-96 transform transition-all duration-300 ease-in-out scale-100">
            {/* Header with decorative line */}
            <div className="border-b pb-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-800 text-center">Project Details</h2>
            </div>

            {/* Content with improved spacing and styling */}
            {selectedProject && (
              <div className="space-y-4">
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="flex items-center">
                    <span className="text-black-600 font-semibold w-32">Project Name:</span>
                    <span className="text-gray-700">{selectedProject.name}</span>
                  </p>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="flex items-center">
                    <span className="text-black-600 font-semibold w-32">Description:</span>
                    <span className="text-gray-700">{selectedProject.description}</span>
                  </p>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="flex items-center">
                    <span className="text-black-600 font-semibold w-32">Leader:</span>
                    <span className="text-gray-700">{selectedProject.employee?.fullName}</span>
                  </p>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="flex items-center">
                    <span className="text-black-600 font-semibold w-32">Start Date:</span>
                    <span className="text-gray-700">{selectedProject.startDate}</span>
                  </p>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="flex items-center">
                    <span className="text-black-600 font-semibold w-32">End Date:</span>
                    <span className="text-gray-700">{selectedProject.endDate}</span>
                  </p>
                </div>
              </div>
            )}

            {/* Footer with improved button styling */}
            <div className="mt-6">
              <button
                onClick={closeViewModal}
                className="w-full py-2 bg-colorBlue text-white rounded-lg hover:bg-blue-500 transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-2xl w-96 transform transition-all duration-300 ease-in-out scale-100">
            {/* Header with decorative line */}
            <div className="border-b pb-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-800 text-center">Edit Project</h2>
            </div>

            {/* Form Content */}
            <form onSubmit={handleEditSubmit} className="space-y-4">
              {/* Project Name Field */}
              <div className="bg-gray-50 p-3 rounded-lg">
                <label className="block text-black-600 font-semibold mb-2">
                  Project Name:
                </label>
                <input
                  type="text"
                  value={editFormData.name}
                  onChange={(e) => setEditFormData({ ...editFormData, name: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Description Field */}
              <div className="bg-gray-50 p-3 rounded-lg">
                <label className="block text-black-600 font-semibold mb-2">
                  Description:
                </label>
                <textarea
                  value={editFormData.description}
                  onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                  rows="3"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Start Date Field */}
              <div className="bg-gray-50 p-3 rounded-lg">
                <label className="block text-black-600 font-semibold mb-2">
                  Start Date:
                </label>
                <input
                  type="date"
                  value={editFormData.startDate}
                  onChange={(e) => setEditFormData({ ...editFormData, startDate: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* End Date Field */}
              <div className="bg-gray-50 p-3 rounded-lg">
                <label className="block text-black-600 font-semibold mb-2">
                  End Date:
                </label>
                <input
                  type="date"
                  value={editFormData.endDate}
                  onChange={(e) => setEditFormData({ ...editFormData, endDate: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Action Buttons */}
              <div className="flex gap-3 mt-6">
                <button
                  type="submit"
                  className="flex-1 py-2 bg-black-600 text-white rounded-lg hover:bg-blue-700 transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={closeEditModal}
                  className="flex-1 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-xl shadow-2xl w-[400px] sm:w-[500px] md:w-[600px] text-center border-2 border-gray-200">
            {/* Header */}
            <div className="border-b pb-4 mb-6">
              <h2 className="text-3xl font-bold text-red-600">Delete Project</h2>
            </div>

            {/* Confirmation Text */}
            <p className="text-gray-700 text-lg mb-8">
              Are you sure you want to delete this project? This action cannot be undone.
            </p>

            {/* Action Buttons */}
            <div className="flex gap-4">
              <button
                onClick={handleDeleteConfirmation}
                className="flex-1 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="flex-1 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-600 text-white py-1 px-3 rounded-lg disabled:opacity-50"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-600 text-white py-1 px-3 rounded-lg disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Modals for Milestones and Budget (existing code) */}
      {isMilestonesModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Project Milestones</h2>
            <div className="max-h-64 overflow-y-auto">
              {selectedMilestones.length > 0 ? (
                selectedMilestones.map((milestone) => (
                  <div key={milestone.id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                    <p><strong>Description:</strong> {milestone.description}</p>
                    <p><strong>Status:</strong> <span className={`${milestone.status === 'Completed' ? 'text-green-600' : 'text-yellow-600'}`}>{milestone.status}</span></p>
                    <p><strong>Completion:</strong> <span className="font-bold">{milestone.completionPercentage}%</span></p>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">No milestones available</p>
              )}
            </div>
            <button onClick={closeMilestonesModal} className="mt-6 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Close</button>
          </div>
        </div>
      )}

      {isBudgetModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Budget Overview</h2>
            {selectedBudget ? (
              <>
                {/* Display Total Budget */}
                <p className="text-lg font-bold text-gray-700">
                  <strong>Total Budget:</strong> <span className="text-green-600">₹{selectedBudget.total || 0}</span>
                </p>
                {/* List Expenses */}
                <div className="max-h-64 overflow-y-auto mt-4">
                  {selectedBudget.expenses && selectedBudget.expenses.length > 0 ? (
                    selectedBudget.expenses.map((expense) => (
                      <div key={expense.id} className="mb-3 p-4 bg-gray-100 rounded-lg shadow-md">
                        <p className="text-gray-800">
                          <strong>Expense:</strong> {expense.name}
                        </p>
                        <p className="text-gray-600">
                          <strong>Cost:</strong> <span className="text-red-600">${expense.actualCost}</span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-gray-500">No expenses available</p>
                  )}
                </div>
                {/* Calculate and Display Summary */}
                <div className="mt-4 border-t border-gray-300 pt-4">
                  {(() => {
                    const totalExpense =
                      selectedBudget.expenses?.reduce((total, expense) => total + expense.actualCost, 0) || 0;
                    const variance = selectedBudget.total - totalExpense;
                    const spent = totalExpense; // Assuming all expenses are spent
                    const remaining = selectedBudget.total - spent;

                    return (
                      <>
                        <p className="text-lg font-semibold text-gray-700">
                          <strong>Total Expense:</strong> <span className="text-red-600">₹{totalExpense}</span>
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                          <strong>Variance:</strong>{" "}
                          <span
                            className={`font-bold ${variance >= 0 ? "text-green-600" : "text-red-600"
                              }`}
                          >
                            ₹{variance}
                          </span>
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                          {/* <strong>Total Budget:</strong> <span className="text-green-600">${selectedBudget.total}</span> */}
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                          <strong>Amount Spent:</strong> <span className="text-red-600">₹{spent}</span>
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                          <strong>Remaining:</strong>{" "}
                          <span
                            className={`font-bold ${remaining >= 0 ? "text-blue-600" : "text-red-600"
                              }`}
                          >
                            ₹{remaining}
                          </span>
                        </p>
                      </>
                    );
                  })()}
                </div>
              </>
            ) : (
              <p className="text-center text-gray-500">No budget data available</p>
            )}
            <button
              onClick={closeBudgetModal}
              className="mt-6 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default ShowProject;
