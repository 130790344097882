import React, { useState, useEffect } from 'react';
import { Download } from 'react-feather';
import { format } from 'date-fns';
import Select from 'react-select';
import axiosInstance from '../../services/AxiosInstance';

const CustomReports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [project, setProject] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);

  const reportTypeOptions = [
    { value: 'project-specific', label: 'Project-Specific' },
    // { value: 'department-wise', label: 'Department-Wise' },
    // { value: 'sales', label: 'Sales' },
    { value: 'revenue', label: 'Revenue' },
  ];

  const filterOptions = [
    { value: 'last7days', label: 'Last 7 Days' },
    { value: 'thismonth', label: 'This Month' },
    { value: 'lastmonth', label: 'Last Month' },
    { value: 'custom', label: 'Custom' },
  ];

  const fetchProjectOptions = async () => {
    try {
      const response = await axiosInstance.get('/backend/projects/list');
      setProjectOptions(
        response.data.map((project) => ({
          value: project.id,
          label: project.name,
        }))
      );
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.get('/backend/api/reports/list',);
  //     console.log('API Response:', response.data); 
  //     setData(response.data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // fetchData();
    fetchProjectOptions();
  }, []);

  const validateFields = () => {
    if (!reportType) {
      alert('Please select a report type.');
      return false;
    }
    if (filter?.value === 'custom' && (!startDate || !endDate)) {
      alert('Please provide a start and end date for custom filters.');
      return false;
    }
    return true;
  };

  const generateReport = async () => {
    if (!validateFields()) return;
    setLoading(true);
    try {
      const response = await axiosInstance.get(`backend/projects/id/${project.value}`
        // params: {
        //   projectId: project?.value || null,
        //   reportType: reportType?.value,
        //   startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
        //   endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
        //   filter: filter?.value || '',
        // },
      );

      console.log(response.data)
      alert('Report generated successfully!');
      setData(response.data);
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Failed to generate report.');
    } finally {
      setLoading(false);
    }
  };

  const exportReport = async (format) => {
    if (!validateFields()) return;
    setLoading(true);
  
    try {
      const response = await axiosInstance.post(
        '/backend/api/reports/export',
        {
          reportId: null, 
          format,
        },
        { responseType: 'blob' } 
      );
  
      // Create a link to trigger the download
      const blob = new Blob([response.data], { type: getFileMimeType(format) });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `report.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      alert(`Report exported as ${format.toUpperCase()}`);
    } catch (error) {
      console.error(`Error exporting to ${format}:`, error);
      alert(`Failed to export report as ${format.toUpperCase()}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Helper function to return MIME type based on the format
  const getFileMimeType = (format) => {
    switch (format) {
      case 'pdf':
        return 'application/pdf';
      case 'excel':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      default:
        return 'application/octet-stream';
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 space-y-6">
      <h2 className="text-2xl font-semibold">Custom Reports</h2>
      <p className="text-gray-600">Generate and export reports tailored to your needs.</p>

      {/* Filters Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div>
          <label htmlFor="project" className="block text-sm font-medium text-gray-700">
            Select Project
          </label>
          <Select
            id="project"
            options={projectOptions}
            value={project}
            onChange={setProject}
            placeholder="Select project"
          />
        </div>
        <div>
          <label htmlFor="report-type" className="block text-sm font-medium text-gray-700">
            Report Type
          </label>
          <Select
            id="report-type"
            options={reportTypeOptions}
            value={reportType}
            onChange={setReportType}
            placeholder="Select report type"
          />
        </div>
        <div>
          <label htmlFor="filter" className="block text-sm font-medium text-gray-700">
            Filter
          </label>
          <Select
            id="filter"
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            placeholder="Select filter"
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="flex-1 space-y-2">
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
              value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </div>
          <div className="flex-1 space-y-2">
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
              value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
        </div>

      
      {/* Actions */}
      <button
        onClick={generateReport}
        className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        disabled={loading}
      >
        {loading ? 'Generating...' : 'Generate Report'}
      </button>

      {/* Export Buttons */}
      <div className="flex flex-wrap gap-4">
        {['pdf', 'excel', 'csv'].map((format) => (
          <button
            key={format}
            onClick={() => exportReport(format)}
            className="flex items-center px-4 py-2 border rounded-md shadow-sm hover:bg-gray-50"
            disabled={loading}
          >
            <Download className="mr-2" />
            Export as {format.toUpperCase()}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomReports;







// import React, { useState, useEffect } from 'react';
// import { Download } from 'react-feather';
// import { format } from 'date-fns';
// import axiosInstance from '../../services/AxiosInstance';

// const CustomReports = () => {
//   const [data, setData] = useState([]);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [project, setProject] = useState('');
//   const [department, setDepartment] = useState('');
//   const [reportType, setReportType] = useState('');
//   const [filter, setFilter] = useState('');

//   // Fetch data from backend on component mount
//   const fetchData = async () => {
//     try {
//       const response = await axiosInstance.get('/backend/api/reports');
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   // Call fetchData when the component mounts
//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Generate Report
//   const generateReport = async () => {
//     try {
//       const payload = {
//         startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
//         endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
//         project,
//         department,
//         reportType,
//         filter,
//       };
//       const response = await axiosInstance.post('/backend/api/reports/generate', payload);
//       console.log('Report generated:', response.data);
//       alert('Report generated successfully!');
//     } catch (error) {
//       console.error('Error generating report:', error);
//       alert('Failed to generate report.');
//     }
//   };

//   // Export to PDF
//   const exportToPDF = async () => {
//     try {
//       const response = await axiosInstance.get('/backend/api/reports/export/pdf', {
//         responseType: 'blob',
//       });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'report.pdf');
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.error('Error exporting to PDF:', error);
//     }
//   };

//   // Export to Excel
//   const exportToExcel = async () => {
//     try {
//       const response = await axiosInstance.get('/backend/api/reports/export/excel', {
//         responseType: 'blob',
//       });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'report.xlsx');
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.error('Error exporting to Excel:', error);
//     }
//   };

//   // Export to CSV
//   const exportToCSV = async () => {
//     try {
//       const response = await axiosInstance.get('/backend/api/reports/export/csv', {
//         responseType: 'blob',
//       });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'report.csv');
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.error('Error exporting to CSV:', error);
//     }
//   };

//   return (
//     <div className="bg-white shadow rounded-lg p-6">
//       <h2 className="text-xl font-semibold mb-2">Custom Reports</h2>
//       <p className="text-gray-600 mb-4">Generate custom reports based on your requirements.</p>
//       <div className="space-y-4">
//         {/* Filters Section */}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//           <div className="space-y-2">
//             <label htmlFor="project" className="block text-sm font-medium text-gray-700">
//               Select Project
//             </label>
//             <select
//               id="project"
//               value={project}
//               onChange={(e) => setProject(e.target.value)}
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
//             >
//               <option>Select project</option>
//               <option value="project1">Project 1</option>
//               <option value="project2">Project 2</option>
//             </select>
//           </div>
//           <div className="space-y-2">
//             <label htmlFor="department" className="block text-sm font-medium text-gray-700">
//               Select Department
//             </label>
//             <select
//               id="department"
//               value={department}
//               onChange={(e) => setDepartment(e.target.value)}
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
//             >
//               <option>Select department</option>
//               <option value="sales">Sales</option>
//               <option value="finance">Finance</option>
//             </select>
//           </div>
//           <div className="space-y-2">
//             <label htmlFor="report-type" className="block text-sm font-medium text-gray-700">
//               Report Type
//             </label>
//             <select
//               id="report-type"
//               value={reportType}
//               onChange={(e) => setReportType(e.target.value)}
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
//             >
//               <option>Select report type</option>
//               <option value="project-specific">Project-Specific</option>
//               <option value="department-wise">Department-Wise</option>
//               <option value="sales">Sales</option>
//               <option value="revenue">Revenue</option>
//             </select>
//           </div>
//         </div>
        // <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        //   <div className="flex-1 space-y-2">
        //     <label className="block text-sm font-medium text-gray-700">Start Date</label>
        //     <input
        //       type="date"
        //       className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
        //       value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        //       onChange={(e) => setStartDate(new Date(e.target.value))}
        //     />
        //   </div>
        //   <div className="flex-1 space-y-2">
        //     <label className="block text-sm font-medium text-gray-700">End Date</label>
        //     <input
        //       type="date"
        //       className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
        //       value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
        //       onChange={(e) => setEndDate(new Date(e.target.value))}
        //     />
        //   </div>
        // </div>
//         <div className="space-y-2">
//           <label htmlFor="filter" className="block text-sm font-medium text-gray-700">
//             Filter
//           </label>
//           <select
//             id="filter"
//             value={filter}
//             onChange={(e) => setFilter(e.target.value)}
//             className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md"
//           >
//             <option>Select filter</option>
//             <option value="last7days">Last 7 days</option>
//             <option value="thismonth">This Month</option>
//             <option value="lastmonth">Last Month</option>
//             <option value="custom">Custom</option>
//           </select>
//         </div>
//         <button
//           onClick={generateReport}
//           className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//         >
//           Generate Report
//         </button>

//         {/* Export Buttons */}
//         <div className="flex flex-wrap gap-2">
//           <button
//             onClick={exportToPDF}
//             className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
//           >
//             <Download className="mr-2 h-4 w-4" />
//             Export as PDF
//           </button>
//           <button
//             onClick={exportToExcel}
//             className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
//           >
//             <Download className="mr-2 h-4 w-4" />
//             Export as Excel
//           </button>
//           <button
//             onClick={exportToCSV}
//             className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
//           >
//             <Download className="mr-2 h-4 w-4" />
//             Export as CSV
//           </button>
//         </div>
        // <div className="border rounded p-4">
        //   <table className="min-w-full table-auto border-collapse bg-white shadow-lg rounded-lg overflow-hidden">
        //     <thead className="bg-gray-100 text-black">
        //       <tr>
        //         <th className="px-4 py-2 text-left">ID</th>
        //         <th className="px-4 py-2 text-left">Name</th>
        //         <th className="px-4 py-2 text-left">Age</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {data.map(item => (
        //         <tr key={item.id} className="even:bg-gray-100 hover:bg-gray-100 transition-colors duration-300">
        //           <td className="border px-4 py-2">{item.id}</td>
        //           <td className="border px-4 py-2">{item.name}</td>
        //           <td className="border px-4 py-2">{item.age}</td>
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        // </div> 
//       </div>
//     </div>
//   );
// };

// export default CustomReports;


 