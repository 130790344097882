import React from 'react';
import { Bar, BarChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const ExpenseBarChartComponent = () => {
  const expenses = [
    { id: 1, projectName: 'Project A', expenseDate: '2023-05-01', amount: 1500.0, category: 'Travel', description: 'Team travel for client meeting' },
    { id: 2, projectName: 'Project B', expenseDate: '2023-05-03', amount: 500.5, category: 'Supplies', description: 'Office supplies' },
    { id: 3, projectName: 'Project A', expenseDate: '2023-05-05', amount: 2000.0, category: 'Labor', description: 'Contractor payment' },
    { id: 4, projectName: 'Overhead', expenseDate: '2023-05-07', amount: 1000.0, category: 'Rent', description: 'Office rent' },
    { id: 5, projectName: 'Project C', expenseDate: '2023-05-10', amount: 750.25, category: 'Software', description: 'Software license renewal' },
  ];

  const barChartDataArray = expenses.map((expense) => ({
    date: expense.expenseDate,
    amount: expense.amount,
  }));

  return (
    <div className="bg-white p-6 rounded-lg shadow-md col-span-1 lg:col-span-2">
      <h2 className="text-xl font-semibold mb-2">Expenses Over Time</h2>
      <div style={{ height: 300 }}> {/* Fixed height for the chart */}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={barChartDataArray}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ExpenseBarChartComponent;
