import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { Star } from 'lucide-react';

const FeedbackTable = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [feedbacksPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const response = await axiosInstance.get('/backend/api/feedback');
                const feedbacks = response.data.map(feedback => ({
                    id: feedback.id,
                    projectName: feedback.projectName,
                    feedbackType: feedback.feedbackType,
                    rating: feedback.rating,
                    comment: feedback.comment,
                    customerName: feedback.customer?.name || 'N/A',
                    customerEmail: feedback.customer?.email || 'N/A',
                    customerPhone: feedback.customer?.phone || 'N/A',
                    file: feedback.fileName || null
                }));
                setFeedbacks(feedbacks);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedbacks();
    }, []);

    const filterfeedback = feedbacks.filter(feedback =>
        feedback.customerName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastFeedback = currentPage * feedbacksPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
    const currentFeedbacks = filterfeedback.slice(indexOfFirstFeedback, indexOfLastFeedback);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container mx-auto p-4">
            <div className="bg-white p-4 rounded-lg shadow mb-8">
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold text-gray-800 capitalize">Feedback List</h1>

                    {/* Search Bar on the Right */}
                    <div className="relative w-full sm:w-64">
                        <input
                            type="text"
                            placeholder="Search customer name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 border rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center gap-3">
                    <div className="animate-spin rounded-full border-t-4 border-blue-600 w-16 h-16"></div>
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    {filterfeedback.length === 0 ? (
                        <div className="text-center text-xl text-gray-500">No results found.</div>
                    ) : (
                        <>
                            <div className="overflow-x-auto bg-white dark:bg-gray-800 shadow-md rounded-lg">
                                <table className="min-w-full table-auto">
                                    <thead>
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Customer (Name & Email)
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Customer Mobile
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Project
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Feedback Type
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Rating
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                Comments
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider border-b-2 border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700">
                                                File
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentFeedbacks.map((feedback) => (
                                            <tr key={feedback.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    <div>
                                                        <div>{feedback.customerName}</div>
                                                        <div className="text-sm text-gray-500">{feedback.customerEmail}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {feedback.customerPhone}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {feedback.projectName}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {feedback.feedbackType}
                                                </td>
                                                <td className="px-6 py-4 flex whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {[1, 2, 3, 4, 5].map((starRating) => (
                                                        <Star
                                                            key={starRating}
                                                            className={`w-5 h-5 ${starRating <= feedback.rating ? 'text-yellow-400' : 'text-gray-400'}`}
                                                        />
                                                    ))}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {feedback.comment}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-700 text-sm text-gray-900 dark:text-gray-200">
                                                    {feedback.file ? (
                                                        <a
                                                            href={`${axiosInstance.defaults.baseURL}/backend/api/quote/file/${feedback.file}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-indigo-600 hover:text-indigo-900"
                                                        >
                                                            View File
                                                        </a>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination */}
                            <div className="flex justify-center mt-4">
                                <nav>
                                    <ul className="flex list-none">
                                        {Array.from({ length: Math.ceil(filterfeedback.length / feedbacksPerPage) }).map((_, index) => (
                                            <li key={index} className="mx-1">
                                                <button
                                                    onClick={() => paginate(index + 1)}
                                                    className={`px-3 py-1 rounded ${currentPage === index + 1
                                                        ? 'bg-blue-500 text-white'
                                                        : 'bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
                                                        }`}
                                                >
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default FeedbackTable;
