import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const  HrmDashboard = () => {
  const barData = {
    labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Expenses',
        data: [6000, 5000, 4000, 5000, 6000, 6500, 4000, 4500, 2500, 3000, 3500, 6000],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const pieData = {
    labels: ['Punctuality', 'TeamCollaboration ', 'ProductivityScore'],
    datasets: [
      {
        label: 'Vendor Performance',
        data: [30, 30, 40], // Example data percentages
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56',],
        hoverOffset: 4,
      },
    ],
  };

  return (
            <div className="p-6 bg-gray-100">
          {/* Header Section */}
          <header className="mb-6 bg-white p-4 rounded-lg shadow-md flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-700">Human Resource Management</h1>
            
            {/* Search Bar */}
            <div className="w-full max-w-xs mr-4">
              <input
                type="text"
                placeholder="Search..."
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </header>
        

      {/* Overview Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <h4 className="text-gray-600">Total Annual Value</h4>
          <p className="text-2xl font-bold">$12,345</p>
          <span className="text-xs text-green-500">⬆ 20% Than Last Month</span>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <h4 className="text-gray-600">Total Payroll</h4>
          <p className="text-2xl font-bold">213392</p>
          <span className="text-xs text-green-500">⬆ 8% Than Last Month</span>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <h4 className="text-gray-600">Total Employees</h4>
          <p className="text-2xl font-bold">920</p>
          <span className="text-xs text-green-500">⬆ 32% Than Last Month</span>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <h4 className="text-gray-600">Next Payroll Date</h4>
          <p className="text-2xl font-bold">2024-10-30</p>
          <span className="text-xs text-green-500">⬆ 3% Than Last Month</span>
        </div>
      </div>

      {/* Bar and Pie Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h4 className="text-lg font-semibold mb-4">Payroll Expenses Over Month</h4>
          <div className="h-64">
            <Bar data={barData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </div>

        {/* Vendor Performance Pie Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h4 className="text-lg font-semibold mb-4">HRM Performance</h4>
          <div className="h-64">
            <Pie data={pieData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrmDashboard;
