import React, { useEffect, useState } from 'react';
import VendorDetails from './VendorDetails';
import VendorForm from './VendorForm';
import VendorTable from './VendorTable';
import axiosInstance from '../../services/AxiosInstance';
import Swal from 'sweetalert2';



const VendorManagement = () => {
  // const [vendors, setVendors] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [filterRating, setFilterRating] = useState('All Ratings');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [getVendor, setGetVendor] = useState([]);

  useEffect(() => {

    const fetchVendors = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/backend/api/vendor');

        if (response.status === 200) {
          // console.log(response.data);

          setGetVendor(response.data);

        } else {
          setError('Failed to fetch vendors');

        }
      } catch (err) {
        setError('Failed to fetch vendors');
      }
      setLoading(false);
    };

    fetchVendors();
  }, []);

  const handleViewDetails = (vendor) => {
    setSelectedVendor(vendor);
    setShowModal(true);
  };

  const handleAddEdit = (vendor = null) => {
    setSelectedVendor(vendor);
    setShowForm(true);
  };

  const handleDelete = (vendorId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(`/backend/api/vendor/${vendorId}`);

          if (response.status === 200) {
            // Update the vendors list, removing the deleted vendor
            setGetVendor((prevVendors) => prevVendors.filter((vendor) => vendor.id !== vendorId));
            Swal.fire('Deleted!', 'Vendor has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'The vendor could not be found or deleted.', 'error');
          }
        } catch (error) {
          // Different error handling based on the error type
          if (error.response && error.response.status === 404) {
            Swal.fire('Error!', 'Vendor not found. It may have already been deleted.', 'error');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the vendor.', 'error');
          }
        }
      }
    });
  };



  // Simple filtering and sorting logic
  const filteredAndSortedVendors = getVendor
    .filter(vendor =>
      vendor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor.contact.includes(searchQuery) ||
      vendor.category.includes(searchQuery)
    )
    .sort((a, b) => {
      if (filterRating !== 'All Ratings') {
        return b.rating - a.rating; // Sort by rating if specific rating is selected
      }
      return 0; // No sorting if 'All Ratings' is selected
    });

  const handleAddVendor = (vendor) => {
    setGetVendor((prevVendors) => {
      // Check if the vendor already exists in the list (by ID)
      const vendorExists = prevVendors.some((v) => v.id === vendor.id);

      if (vendorExists) {
        // If vendor exists, update it in the list
        return prevVendors.map((v) => (v.id === vendor.id ? vendor : v));
      } else {
        // If vendor doesn't exist, add it to the list
        return [...prevVendors, vendor];
      }
    });
  };


  return (
    <div className="container mr-0 ml-0 p-2 space-y-8">
      <div className="container mx-auto px-4 py-2">
        {/* Header Section with White Background and Search Bar */}
        <header className="mb-8 bg-gray-50 p-4 rounded-lg shadow-md flex items-center justify-between dark:bg-secondaryDark">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Vendor Management</h1>

          {/* Search Bar */}
          <div className="w-full max-w-xs">
            <input
              type="text"
              placeholder="Search by name..."
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}  // Update search query state
            />
          </div>
        </header>

        <div className="flex justify-between mb-5">
          <button
            onClick={() => handleAddEdit()}
            className="px-5 py-2 bg-colorBlue dark:bg-primaryDark text-gray-50 rounded"
          >
            Add New Vendor
          </button>
          <div className="flex items-center">
            <label htmlFor="rating-filter" className="mr-2 dark:text-textDark">Filter by Rating:</label>
            <select
              id="rating-filter"
              value={filterRating}
              onChange={(e) => setFilterRating(e.target.value)}
              className="p-1 border border-gray-300 rounded dark:border-gray-600 dark:bg-primaryDark dark:text-textDark"
            >
              <option value="All Ratings">All Ratings</option>
              {[1, 2, 3, 4, 5].map(rating => (
                <option key={rating} value={rating}>
                  {rating} Star{rating !== 1 ? 's' : ''}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-secondaryDark p-5 rounded shadow-lg">
          <VendorTable
            vendors={filteredAndSortedVendors}
            error={error}
            loading={loading}
            onView={handleViewDetails}
            onEdit={handleAddEdit}
            onDelete={handleDelete}
          />
        </div>


        {showModal && (
          <VendorDetails
            vendor={selectedVendor}
            onClose={() => setShowModal(false)}
          />
        )}

        {showForm && (
          <VendorForm
            vendor={selectedVendor}
            onCancel={() => setShowForm(false)}
            addVendor={handleAddVendor}
          />
        )}
      </div>
    </div>
  );
};

export default VendorManagement;
