import CountStats from "./CountStats";
import PropTypes from "prop-types";
import "./Counting.css";

const Counting = ({ className = "" }) => {
    return (
        <section className={`stats-wrapper-wrapper ${className}`}>
            <div className="stats-wrapper">
                <div className="stats-content">
                    <div className="counter-container pb-2">
                        <h1 className="counting-title">Our Success in Numbers</h1>
                        <div className="counter-subtitle">
                            Join us in crafting your dream project. We bring passion and creativity to every new vision.
                        </div>
                    </div>
                    <div className="counts">
                        <CountStats
                            cOuntDescription="Projects Completed"
                            count="400+"
                            propAlignSelf="unset"
                            propDisplay="inline-block"
                            propAlignSelf1="stretch"
                            projectComplete="Projects Completed"
                            propAlignSelf2="stretch"
                        />
                        <CountStats
                            cOuntDescription="Happy Clients"
                            count="600+"
                            propAlignSelf="stretch"
                            propDisplay="unset"
                            projectComplete="Happy Clients"
                        />
                        <CountStats
                            cOuntDescription="Creative Designs"
                            count="100+"
                            projectComplete="Creative Designs"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

Counting.propTypes = {
    className: PropTypes.string,
};

export default Counting;
