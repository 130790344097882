import React, { useState, useEffect } from "react";
import Select from "react-select";
import axiosInstance from "../../services/AxiosInstance";

export default function CreatePurchaseOrder({ order, onCancel, onSave }) {
  const [formData, setFormData] = useState({
    vendor: order ? order.vendor : "",
    product: order ? order.productService : "",
    quantity: order ? order.quantity : "",
    price: order ? order.unitPrice : "",
    orderDate: order ? order.orderDate : "",
    status: order ? order.orderStatus : "",
  });

  const [vendors, setVendors] = useState([]); // State to hold vendor options
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch vendors on component mount
    const fetchVendors = async () => {
      try {
        const response = await axiosInstance.get("/backend/api/vendor"); // Replace with your endpoint
        const vendorOptions = response.data.map((vendor) => ({
          value: vendor.id, // or any unique identifier for vendor
          label: vendor.name, // or any name field for vendor
        }));
        setVendors(vendorOptions);
      } catch (err) {
        console.error("Failed to fetch vendors", err);
      }
    };

    fetchVendors();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError("");

    try {


      let response;
      if (order && order.id) {
        // EDIT MODE: PUT request to update order
        response = await axiosInstance.put(`/backend/api/vendor-purchase/${order.id}`, {
          productService: formData.product,
          quantity: formData.quantity,
          unitPrice: formData.price,
          orderDate: formData.orderDate,
          orderStatus: formData.status,
        });
        setMessage("Order updated successfully!");
        onSave(response.data)
      } else {
        // CREATE MODE: POST request to create new order
        response = await axiosInstance.post(`/backend/api/vendor-purchase/${formData.vendor}`, {
          productService: formData.product,
          quantity: formData.quantity,
          unitPrice: formData.price,
          orderDate: formData.orderDate,
          orderStatus: formData.status,
        });
        setMessage("Order created successfully!");
        onSave(response.data)
      }



      // Reset form
      setFormData({
        vendor: "",
        product: "",
        quantity: "",
        price: "",
        orderDate: "",
        status: "",
      });

      // Close modal after 2 seconds
      setTimeout(() => {
        onCancel();
      }, 1000);

    } catch (err) {
      console.error("Failed to save order", err);
      setError("Failed to save order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleVendorChange = (selectedOption) => {
    setFormData({
      ...formData,
      vendor: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-50 dark:bg-secondaryDark rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 dark:text-dark">
          {order ? "Edit" : "Create"} Purchase Order
        </h2>

        {loading && <p>Loading...</p>}
        {message && <p className="text-green-600">{message}</p>}
        {error && <p className="text-red-600">{error}</p>}

        <form onSubmit={handleSubmit} className="grid gap-4">
          <Select
            options={vendors}
            value={vendors.find((vendor) => vendor.value === formData.vendor)}
            onChange={handleVendorChange}
            placeholder="Select Vendor"
            isClearable
            maxMenuHeight={150}
            isSearchable
            required
          />

          {/* Other form fields */}
          <input
            type="text"
            name="product"
            value={formData.product}
            onChange={handleChange}
            placeholder="Product/Service"
            className="border border-gray-300 rounded px-2 py-1"
            required
          />
          <input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            placeholder="Quantity"
            className="border border-gray-300 rounded px-2 py-1"
            required
          />
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            step="0.01"
            placeholder="Unit Price"
            className="border border-gray-300 rounded px-2 py-1"
            required
          />
          <input
            type="date"
            name="orderDate"
            value={formData.orderDate}
            onChange={handleChange}
            className="border border-gray-300 rounded px-2 py-1"
            required
          />
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="border border-gray-300 rounded px-2 py-1"
            required
          >
            <option value="Pending">Pending</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
          </select>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white dark:bg-dark dark:hover:bg-primaryDark font-bold py-2 px-4 rounded"
              disabled={loading}
            >
              Save Order
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 
