import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';

const DealTable = ({ deals, loading, error }) => {
  const [dealsWithProjects, setDealsWithProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentDealId, setCurrentDealId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchProjectTerm, setSearchProjectTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  // Update `dealsWithProjects` whenever `deals` changes
  useEffect(() => {
    setDealsWithProjects(deals);
  }, [deals]);

  // Fetch projects for the dropdown
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/backend/projects');
        const projectOptions = response.data.map(project => ({
          value: project.id,
          label: project.name,
        }));
        setProjects(projectOptions);
      } catch (err) {
        console.error('Error fetching projects:', err);
      }
    };
    fetchProjects();
  }, []);

  const filteredDeals = dealsWithProjects
    .filter(deal =>
      deal.leadName?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (statusFilter === '' || deal.status === statusFilter)
    )
    .filter(deal =>
      (deal.projectType?.toLowerCase() || '').includes(searchProjectTerm.toLowerCase()) ||
      (deal.project?.name?.toLowerCase() || '').includes(searchProjectTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.status === 'Won' && b.status !== 'Won') return -1;
      if (a.status !== 'Won' && b.status === 'Won') return 1;
      return a.leadName?.localeCompare(b.leadName || '');
    });

  const handleAddProject = async (dealId) => {
    const selectedProject = selectedProjects[dealId];
    if (selectedProject) {
      setIsSubmitting(true);
      setMessage('');

      try {
        const response = await axiosInstance.put(
          `/backend/api/leads/${dealId}/project/${selectedProject.value}`
        );
        const updatedProject = response.data.project;

        setDealsWithProjects(prevDeals =>
          prevDeals.map(deal =>
            deal.id === dealId
              ? { ...deal, project: updatedProject }
              : deal
          )
        );
        setMessage('Project added successfully!');
      } catch (error) {
        console.error('Error adding project:', error);
        setMessage('Failed to add project. Please try again.');
      } finally {
        setIsSubmitting(false);
        setShowModal(false);
        setSelectedProjects(prev => ({ ...prev, [dealId]: null }));
      }
    }
  };

  if (loading) {
    return <div className="text-center py-4 text-gray-600">Loading deals...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-600">{error}</div>;
  }

  return (
    <div className="p-2 w-full">
      {/* Search and Filter Inputs */}
      <div className="flex flex-col md:flex-row items-center gap-4 mb-3">
        <input
          type="text"
          placeholder="Search by deal name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded w-full md:w-1/3"
        />
        <input
          type="text"
          placeholder="Search by project name..."
          value={searchProjectTerm}
          onChange={(e) => setSearchProjectTerm(e.target.value)}
          className="border p-2 rounded w-full md:w-1/3"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="border p-2 rounded w-full md:w-1/3"
        >
          <option value="">All Statuses</option>
          <option value="New">Active</option>
          <option value="Contacted">Contacted</option>
          <option value="Won">Won</option>
          <option value="Lost">Lost</option>
        </select>
      </div>
      {/* Display Success/Error Message */}
      {message && <div className="text-center py-2 text-gray-600">{message}</div>}

      {/* Deals Table */}
      <table className="min-w-full bg-gray-50 dark:bg-secondaryDark border border-gray-200 text-sm font-semibold">
        <thead className="bg-gray-200 dark:bg-primaryDark dark:text-gray-50">
          <tr>
            <th className="px-3 md:px-6 py-3 border-b text-center">#</th>
            <th className="px-3 md:px-6 py-3 border-b text-center">Deal Name</th>
            <th className="px-3 md:px-6 py-3 border-b text-center">Project Type</th>
            <th className="px-3 md:px-6 py-3 border-b text-center">Status</th>
            <th className="px-3 md:px-6 py-3 border-b text-center">Project Name</th>
            <th className="px-3 md:px-6 py-3 border-b text-center">Add Project</th>
          </tr>
        </thead>
        <tbody>
          {filteredDeals.length > 0 ? (
            filteredDeals.map((deal, index) => (
              <tr key={deal.id} className="hover:bg-gray-200 text-center text-dark">
                <td className="px-3 md:px-6 py-4 border-b">{index + 1}</td>
                <td className="px-3 md:px-6 py-4 border-b text-left">{deal.leadName}</td>
                <td className="px-3 md:px-6 py-4 border-b">{deal.projectType || 'N/A'}</td>
                <td className={`px-3 md:px-6 py-4 border-b font-bold ${deal.status === 'Won' ? 'text-green-600' : 'text-red-600'}`}>
                  {deal.status}
                </td>
                <td className={`px-3 md:px-6 py-4 border-b ${!deal.project ? 'text-red-500' : ''}`}>
                  {deal.project?.name || 'No project selected'}
                </td>
                <td className="px-3 md:px-6 py-4 border-b">
                  {deal.status === 'Won' ? (
                    <button
                      onClick={() => {
                        setCurrentDealId(deal.id);
                        setShowModal(true);
                      }}
                      className="bg-blue-500 text-white rounded px-3 py-1"
                    >
                      Add Project
                    </button>
                  ) : (
                    <span>No action</span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="px-6 py-4 text-center text-gray-500">No deals available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for Adding Project */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded shadow-lg p-4 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-3">Select a Project</h2>
            <Select
              options={projects}
              value={selectedProjects[currentDealId] || null}
              onChange={(option) => setSelectedProjects(prev => ({ ...prev, [currentDealId]: option }))}
              placeholder="Select a project..."
            />
            <div className="flex mt-4 justify-end">
              <button
                onClick={() => handleAddProject(currentDealId)}
                className={`bg-blue-500 text-white rounded px-3 py-1 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Adding...' : 'Confirm'}
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="ml-3 text-gray-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealTable;
