import React, { useState } from 'react'
import BudgetPlanning from './BudgetPlanning'
import BudgetTracking from './BudgetTracking'

const BudgetManagementLayout = () => {

    const [expense, setExpense] = useState([]);

    const addExpense = (newExpense) => {
        setExpense(prev => [...prev, newExpense])
    }

    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)]  bg-gray-100  dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full max-w-7x overflow-x-auto ">
                <div className=" rounded-lg shadow-lg md:h-[calc(100vh-4rem)] lg:h-[calc(100vh-4rem)] overflow-y-auto">
                    {/* Left side - Project Creation Form */}
                    <BudgetPlanning addExpense={addExpense} />
                </div>

                {/* Right side - Gantt Chart and Milestone Tracking */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto bg-gray-50 dark:bg-dark ">
                    <BudgetTracking expense={expense} />
                </div>
            </div>
        </div>
    )
}

export default BudgetManagementLayout