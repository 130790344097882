import React from 'react';

const ProgressReports = () => {
    return (

        <div className=" space-y-6 p-3  md:p-4 shadow-lg rounded-lg max-w-full sm:max-w-lg mx-auto transition-colors duration-1000 ease-in dark:bg-primaryDark">
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 dark:text-textDark mb-6 text-center">
                Progress Reports
            </h2>

            {/* Responsive Select Dropdowns */}
            <div className="flex flex-col justify-center sm:flex-row sm:gap-4 mb-4">
                <select className="p-2 sm:p-3 lg:p-4 border border-gray-300 rounded-md h-12 mb-4 sm:mb-0 w-full sm:w-auto">
                    <option>Date Range</option>
                    <option>Last Week</option>
                    <option>Last Month</option>
                    <option>Custom Range</option>
                </select>

                <select className="p-2 sm:p-3 lg:p-4 border border-gray-300 rounded-md h-12 w-full sm:w-auto">
                    <option>Project</option>
                    <option>Project A</option>
                    <option>Project B</option>
                    <option>Project C</option>
                </select>
            </div>

            {/* Responsive Buttons */}
            <div className="flex flex-col justify-center sm:flex-row sm:gap-4">
                <button className="bg-blue-500 text-white px-4 sm:px-6 lg:px-8 py-2 rounded-md hover:bg-blue-600 mb-4 sm:mb-0 h-12 flex items-center justify-center w-full sm:w-auto dark:bg-dark dark:text-textDark">
                    Generate Report
                </button>
                <button className="bg-gray-200 text-gray-700 px-4 sm:px-6 lg:px-8 py-2 rounded-md hover:bg-gray-300 h-12 flex items-center justify-center w-full sm:w-auto">
                    Export as PDF
                </button>
            </div>
        </div>
    );
};

export default ProgressReports;
