import React, { useState } from "react";
import ImageSlider from "./ImageSlider"; 

export default function Gallery({ className = "" }) {
  const videos = [
    "/videos/video-3.mp4",
    "/videos/video-2.mp4",
    "/videos/video-4.mp4",
    "/videos/house-interior1.mp4",
  ];

  const images = {
    all: [
      "/siteimages/company-1.jpeg",
      "/images/gallary-image1.png",
      "/images/gallary-image2.jpg",
      "/images/gallary-image3.jpg",
      "/siteimages/nacl-6.jpeg",
      "/images/gallary-image4.jpg",
      "/images/gallary-image5.jpg",
      "/images/gallary-image6.jpg",
      "/images/gallary-image7.jpg",
      "/siteimages/restaurant-4.jpeg",
      "/siteimages/shree-jwel-showroom1.png",
      "/siteimages/shree-jwel-showroom2.png",
      "/siteimages/peperry-kick.png",
      "/siteimages/kitchen-3.jpeg",
    ]
  }; 

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [selectedDesign, setSelectedDesign] = useState("all"); // Set default design to "all"
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to handle video sliding
  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

    // Function to update current image index
    const updateImageIndex = (index) => {
      setCurrentImageIndex(index);
    };


  return (
    <section className={`${className} mx-auto px-4 py-10`} style={{ width: '-webkit-fill-available' }}>
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold mb-2">Featured Projects</h1>
        <p className="text-lg text-gray-600">
          Discover the art of interior design through our curated projects. Each space tells a unique story, crafted with care and innovation. We blend functionality with aesthetic appeal, ensuring every corner resonates with your style.
        </p>
      </div>

      {/* Video carousel */}
      <div className="relative w-full max-w-5xl mx-auto pb-5">
        <video
          key={currentVideoIndex}
          className="w-full  h-auto max-h-[400px] aspect-[16/9] object-cover rounded-lg shadow-md"
          src={videos[currentVideoIndex]}
          autoPlay
          loop
          muted
          controls
          playsInline
        />

        {/* Navigation Buttons for Video Carousel */}
        <button
          className="absolute top-1/2 left-3 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-3 rounded-full z-10"
          onClick={handlePrevVideo}
        >
          &#9664; {/* Left Arrow */}
        </button>
        <button
          className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-3 rounded-full z-10"
          onClick={handleNextVideo}
        >
          &#9654; {/* Right Arrow */}
        </button>
      </div>

      {/* Image carousel with continuous sliding effect */}
      <div className="w-fill">
        <ImageSlider images={images[selectedDesign]} isLTR={true}  onImageChange={updateImageIndex} /> {/* Display images based on selected design */}
      </div>

          {/* Image Indicator Dots */}
          <div className="flex justify-center mt-4 space-x-2">
                {images[selectedDesign].map((_, index) => (
                  <div
                    key={index}
                    className={`h-2 w-2 rounded-full ${index === currentImageIndex ? "bg-gray-800" : "bg-gray-400"}`}
                  ></div>
                ))}
              </div>
    </section>
  );
}
